@charset "UTF-8";
/*
Template Name: Hyper - Responsive Bootstrap 5 Admin Dashboard
Version: 1.6.0
Author: CoderThemes
Email: support@coderthemes.com
File: Icons Css File
*/
@font-face {
  font-family: 'unicons';
  src: url("../fonts/unicons.eot?34404611");
  src: url("../fonts/unicons.eot?34404611#iefix") format("embedded-opentype"), url("../fonts/unicons.woff2?34404611") format("woff2"), url("../fonts/unicons.woff?34404611") format("woff"), url("../fonts/unicons.ttf?34404611") format("truetype"), url("../fonts/unicons.svg?34404611#unicons") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'unicons';
    src: url('../font/unicons.svg?34404611#unicons') format('svg');
  }
}
*/
[class^="uil-"]:before, [class*=" uil-"]:before {
  font-family: "unicons";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.uil-0-plus:before {
  content: '\e800'; }

/* '' */
.uil-10-plus:before {
  content: '\e801'; }

/* '' */
.uil-12-plus:before {
  content: '\e802'; }

/* '' */
.uil-13-plus:before {
  content: '\e803'; }

/* '' */
.uil-16-plus:before {
  content: '\e804'; }

/* '' */
.uil-17-plus:before {
  content: '\e805'; }

/* '' */
.uil-18-plus:before {
  content: '\e806'; }

/* '' */
.uil-21-plus:before {
  content: '\e807'; }

/* '' */
.uil-3-plus:before {
  content: '\e808'; }

/* '' */
.uil-500px:before {
  content: '\e809'; }

/* '' */
.uil-6-plus:before {
  content: '\e80a'; }

/* '' */
.uil-abacus:before {
  content: '\e80b'; }

/* '' */
.uil-accessible-icon-alt:before {
  content: '\e80c'; }

/* '' */
.uil-adjust-alt:before {
  content: '\e80d'; }

/* '' */
.uil-adjust-circle:before {
  content: '\e80e'; }

/* '' */
.uil-adjust-half:before {
  content: '\e80f'; }

/* '' */
.uil-adjust:before {
  content: '\e810'; }

/* '' */
.uil-adobe:before {
  content: '\e811'; }

/* '' */
.uil-airplay:before {
  content: '\e812'; }

/* '' */
.uil-align-alt:before {
  content: '\e813'; }

/* '' */
.uil-align-center-alt:before {
  content: '\e814'; }

/* '' */
.uil-align-center-h:before {
  content: '\e815'; }

/* '' */
.uil-align-center-justify:before {
  content: '\e816'; }

/* '' */
.uil-align-center-v:before {
  content: '\e817'; }

/* '' */
.uil-align-center:before {
  content: '\e818'; }

/* '' */
.uil-align-justify:before {
  content: '\e819'; }

/* '' */
.uil-align-left-justify:before {
  content: '\e81a'; }

/* '' */
.uil-align-left:before {
  content: '\e81b'; }

/* '' */
.uil-align-letter-right:before {
  content: '\e81c'; }

/* '' */
.uil-align-right-justify:before {
  content: '\e81d'; }

/* '' */
.uil-align-right:before {
  content: '\e81e'; }

/* '' */
.uil-align:before {
  content: '\e81f'; }

/* '' */
.uil-ambulance:before {
  content: '\e820'; }

/* '' */
.uil-analysis:before {
  content: '\e821'; }

/* '' */
.uil-analytics:before {
  content: '\e822'; }

/* '' */
.uil-anchor:before {
  content: '\e823'; }

/* '' */
.uil-android-phone-slash:before {
  content: '\e824'; }

/* '' */
.uil-angle-double-down:before {
  content: '\e825'; }

/* '' */
.uil-angle-double-left:before {
  content: '\e826'; }

/* '' */
.uil-angle-double-right:before {
  content: '\e827'; }

/* '' */
.uil-angle-double-up:before {
  content: '\e828'; }

/* '' */
.uil-angle-down:before {
  content: '\e829'; }

/* '' */
.uil-angle-left:before {
  content: '\e82a'; }

/* '' */
.uil-angle-right-b:before {
  content: '\e82b'; }

/* '' */
.uil-angle-right:before {
  content: '\e82c'; }

/* '' */
.uil-angle-up:before {
  content: '\e82d'; }

/* '' */
.uil-angry:before {
  content: '\e82e'; }

/* '' */
.uil-ankh:before {
  content: '\e82f'; }

/* '' */
.uil-annoyed-alt:before {
  content: '\e830'; }

/* '' */
.uil-annoyed:before {
  content: '\e831'; }

/* '' */
.uil-apple:before {
  content: '\e832'; }

/* '' */
.uil-apps:before {
  content: '\e833'; }

/* '' */
.uil-archive-alt:before {
  content: '\e834'; }

/* '' */
.uil-archive:before {
  content: '\e835'; }

/* '' */
.uil-archway:before {
  content: '\e836'; }

/* '' */
.uil-arrow-break:before {
  content: '\e837'; }

/* '' */
.uil-arrow-circle-down:before {
  content: '\e838'; }

/* '' */
.uil-arrow-circle-left:before {
  content: '\e839'; }

/* '' */
.uil-arrow-circle-right:before {
  content: '\e83a'; }

/* '' */
.uil-arrow-circle-up:before {
  content: '\e83b'; }

/* '' */
.uil-arrow-compress-h:before {
  content: '\e83c'; }

/* '' */
.uil-arrow-down-left:before {
  content: '\e83d'; }

/* '' */
.uil-arrow-down-right:before {
  content: '\e83e'; }

/* '' */
.uil-arrow-down:before {
  content: '\e83f'; }

/* '' */
.uil-arrow-from-right:before {
  content: '\e840'; }

/* '' */
.uil-arrow-from-top:before {
  content: '\e841'; }

/* '' */
.uil-arrow-growth:before {
  content: '\e842'; }

/* '' */
.uil-arrow-left:before {
  content: '\e843'; }

/* '' */
.uil-arrow-random:before {
  content: '\e844'; }

/* '' */
.uil-arrow-resize-diagonal:before {
  content: '\e845'; }

/* '' */
.uil-arrow-right:before {
  content: '\e846'; }

/* '' */
.uil-arrow-to-bottom:before {
  content: '\e847'; }

/* '' */
.uil-arrow-to-right:before {
  content: '\e848'; }

/* '' */
.uil-arrow-up-left:before {
  content: '\e849'; }

/* '' */
.uil-arrow-up-right:before {
  content: '\e84a'; }

/* '' */
.uil-arrow-up:before {
  content: '\e84b'; }

/* '' */
.uil-arrows-h-alt:before {
  content: '\e84c'; }

/* '' */
.uil-arrows-h:before {
  content: '\e84d'; }

/* '' */
.uil-arrows-left-down:before {
  content: '\e84e'; }

/* '' */
.uil-arrows-maximize:before {
  content: '\e84f'; }

/* '' */
.uil-arrows-merge:before {
  content: '\e850'; }

/* '' */
.uil-arrows-resize-h:before {
  content: '\e851'; }

/* '' */
.uil-arrows-resize-v:before {
  content: '\e852'; }

/* '' */
.uil-arrows-resize:before {
  content: '\e853'; }

/* '' */
.uil-arrows-right-down:before {
  content: '\e854'; }

/* '' */
.uil-arrows-shrink-h:before {
  content: '\e855'; }

/* '' */
.uil-arrows-shrink-v:before {
  content: '\e856'; }

/* '' */
.uil-arrows-up-right:before {
  content: '\e857'; }

/* '' */
.uil-arrows-v-alt:before {
  content: '\e858'; }

/* '' */
.uil-arrows-v:before {
  content: '\e859'; }

/* '' */
.uil-assistive-listening-systems:before {
  content: '\e85a'; }

/* '' */
.uil-asterisk:before {
  content: '\e85b'; }

/* '' */
.uil-at:before {
  content: '\e85c'; }

/* '' */
.uil-atm-card:before {
  content: '\e85d'; }

/* '' */
.uil-atom:before {
  content: '\e85e'; }

/* '' */
.uil-auto-flash:before {
  content: '\e85f'; }

/* '' */
.uil-award-alt:before {
  content: '\e860'; }

/* '' */
.uil-award:before {
  content: '\e861'; }

/* '' */
.uil-baby-carriage:before {
  content: '\e862'; }

/* '' */
.uil-backpack:before {
  content: '\e863'; }

/* '' */
.uil-backspace:before {
  content: '\e864'; }

/* '' */
.uil-backward:before {
  content: '\e865'; }

/* '' */
.uil-bag-alt:before {
  content: '\e866'; }

/* '' */
.uil-bag-slash:before {
  content: '\e867'; }

/* '' */
.uil-bag:before {
  content: '\e868'; }

/* '' */
.uil-balance-scale:before {
  content: '\e869'; }

/* '' */
.uil-ball:before {
  content: '\e86a'; }

/* '' */
.uil-ban:before {
  content: '\e86b'; }

/* '' */
.uil-bars:before {
  content: '\e86c'; }

/* '' */
.uil-basketball-hoop:before {
  content: '\e86d'; }

/* '' */
.uil-basketball:before {
  content: '\e86e'; }

/* '' */
.uil-battery-bolt:before {
  content: '\e86f'; }

/* '' */
.uil-battery-empty:before {
  content: '\e870'; }

/* '' */
.uil-bed-double:before {
  content: '\e871'; }

/* '' */
.uil-bed:before {
  content: '\e872'; }

/* '' */
.uil-behance:before {
  content: '\e873'; }

/* '' */
.uil-bell-school:before {
  content: '\e874'; }

/* '' */
.uil-bell-slash:before {
  content: '\e875'; }

/* '' */
.uil-bell:before {
  content: '\e876'; }

/* '' */
.uil-bill:before {
  content: '\e877'; }

/* '' */
.uil-bitcoin-circle:before {
  content: '\e878'; }

/* '' */
.uil-bitcoin:before {
  content: '\e879'; }

/* '' */
.uil-black-berry:before {
  content: '\e87a'; }

/* '' */
.uil-bluetooth-b:before {
  content: '\e87b'; }

/* '' */
.uil-bold:before {
  content: '\e87c'; }

/* '' */
.uil-bolt-alt:before {
  content: '\e87d'; }

/* '' */
.uil-bolt-slash:before {
  content: '\e87e'; }

/* '' */
.uil-bolt:before {
  content: '\e87f'; }

/* '' */
.uil-book-alt:before {
  content: '\e880'; }

/* '' */
.uil-book-medical:before {
  content: '\e881'; }

/* '' */
.uil-book-open:before {
  content: '\e882'; }

/* '' */
.uil-book-reader:before {
  content: '\e883'; }

/* '' */
.uil-book:before {
  content: '\e884'; }

/* '' */
.uil-bookmark-full:before {
  content: '\e885'; }

/* '' */
.uil-bookmark:before {
  content: '\e886'; }

/* '' */
.uil-books:before {
  content: '\e887'; }

/* '' */
.uil-boombox:before {
  content: '\e888'; }

/* '' */
.uil-border-alt:before {
  content: '\e889'; }

/* '' */
.uil-border-bottom:before {
  content: '\e88a'; }

/* '' */
.uil-border-clear:before {
  content: '\e88b'; }

/* '' */
.uil-border-horizontal:before {
  content: '\e88c'; }

/* '' */
.uil-border-inner:before {
  content: '\e88d'; }

/* '' */
.uil-border-left:before {
  content: '\e88e'; }

/* '' */
.uil-border-out:before {
  content: '\e88f'; }

/* '' */
.uil-border-right:before {
  content: '\e890'; }

/* '' */
.uil-border-top:before {
  content: '\e891'; }

/* '' */
.uil-border-vertical:before {
  content: '\e892'; }

/* '' */
.uil-box:before {
  content: '\e893'; }

/* '' */
.uil-briefcase-alt:before {
  content: '\e894'; }

/* '' */
.uil-briefcase:before {
  content: '\e895'; }

/* '' */
.uil-bright:before {
  content: '\e896'; }

/* '' */
.uil-brightness-empty:before {
  content: '\e897'; }

/* '' */
.uil-brightness-half:before {
  content: '\e898'; }

/* '' */
.uil-brightness-low:before {
  content: '\e899'; }

/* '' */
.uil-brightness-minus:before {
  content: '\e89a'; }

/* '' */
.uil-brightness-plus:before {
  content: '\e89b'; }

/* '' */
.uil-brightness:before {
  content: '\e89c'; }

/* '' */
.uil-bring-bottom:before {
  content: '\e89d'; }

/* '' */
.uil-bring-front:before {
  content: '\e89e'; }

/* '' */
.uil-brush-alt:before {
  content: '\e89f'; }

/* '' */
.uil-bug:before {
  content: '\e8a0'; }

/* '' */
.uil-building:before {
  content: '\e8a1'; }

/* '' */
.uil-bullseye:before {
  content: '\e8a2'; }

/* '' */
.uil-bus-alt:before {
  content: '\e8a3'; }

/* '' */
.uil-bus-school:before {
  content: '\e8a4'; }

/* '' */
.uil-bus:before {
  content: '\e8a5'; }

/* '' */
.uil-calcualtor:before {
  content: '\e8a6'; }

/* '' */
.uil-calculator-alt:before {
  content: '\e8a7'; }

/* '' */
.uil-calendar-alt:before {
  content: '\e8a8'; }

/* '' */
.uil-calendar-slash:before {
  content: '\e8a9'; }

/* '' */
.uil-calender:before {
  content: '\e8aa'; }

/* '' */
.uil-calling:before {
  content: '\e8ab'; }

/* '' */
.uil-camera-change:before {
  content: '\e8ac'; }

/* '' */
.uil-camera-plus:before {
  content: '\e8ad'; }

/* '' */
.uil-camera-slash:before {
  content: '\e8ae'; }

/* '' */
.uil-camera:before {
  content: '\e8af'; }

/* '' */
.uil-cancel:before {
  content: '\e8b0'; }

/* '' */
.uil-capsule:before {
  content: '\e8b1'; }

/* '' */
.uil-capture:before {
  content: '\e8b2'; }

/* '' */
.uil-car-sideview:before {
  content: '\e8b3'; }

/* '' */
.uil-car-slash:before {
  content: '\e8b4'; }

/* '' */
.uil-car-wash:before {
  content: '\e8b5'; }

/* '' */
.uil-car:before {
  content: '\e8b6'; }

/* '' */
.uil-card-atm:before {
  content: '\e8b7'; }

/* '' */
.uil-caret-right:before {
  content: '\e8b8'; }

/* '' */
.uil-cart:before {
  content: '\e8b9'; }

/* '' */
.uil-cell:before {
  content: '\e8ba'; }

/* '' */
.uil-celsius:before {
  content: '\e8bb'; }

/* '' */
.uil-chart-bar-alt:before {
  content: '\e8bc'; }

/* '' */
.uil-chart-bar:before {
  content: '\e8bd'; }

/* '' */
.uil-chart-down:before {
  content: '\e8be'; }

/* '' */
.uil-chart-growth-alt:before {
  content: '\e8bf'; }

/* '' */
.uil-chart-growth:before {
  content: '\e8c0'; }

/* '' */
.uil-chart-line:before {
  content: '\e8c1'; }

/* '' */
.uil-chart-pie-alt:before {
  content: '\e8c2'; }

/* '' */
.uil-chart-pie:before {
  content: '\e8c3'; }

/* '' */
.uil-chart:before {
  content: '\e8c4'; }

/* '' */
.uil-chat-bubble-user:before {
  content: '\e8c5'; }

/* '' */
.uil-chat-info:before {
  content: '\e8c6'; }

/* '' */
.uil-chat:before {
  content: '\e8c7'; }

/* '' */
.uil-check-circle:before {
  content: '\e8c8'; }

/* '' */
.uil-check-square:before {
  content: '\e8c9'; }

/* '' */
.uil-check:before {
  content: '\e8ca'; }

/* '' */
.uil-circle-layer:before {
  content: '\e8cb'; }

/* '' */
.uil-circle:before {
  content: '\e8cc'; }

/* '' */
.uil-circuit:before {
  content: '\e8cd'; }

/* '' */
.uil-clapper-board:before {
  content: '\e8ce'; }

/* '' */
.uil-clipboard-alt:before {
  content: '\e8cf'; }

/* '' */
.uil-clipboard-blank:before {
  content: '\e8d0'; }

/* '' */
.uil-clipboard-notes:before {
  content: '\e8d1'; }

/* '' */
.uil-clipboard:before {
  content: '\e8d2'; }

/* '' */
.uil-clock-eight:before {
  content: '\e8d3'; }

/* '' */
.uil-clock-five:before {
  content: '\e8d4'; }

/* '' */
.uil-clock-nine:before {
  content: '\e8d5'; }

/* '' */
.uil-clock-seven:before {
  content: '\e8d6'; }

/* '' */
.uil-clock-ten:before {
  content: '\e8d7'; }

/* '' */
.uil-clock-three:before {
  content: '\e8d8'; }

/* '' */
.uil-clock-two:before {
  content: '\e8d9'; }

/* '' */
.uil-clock:before {
  content: '\e8da'; }

/* '' */
.uil-closed-captioning:before {
  content: '\e8db'; }

/* '' */
.uil-cloud-block:before {
  content: '\e8dc'; }

/* '' */
.uil-cloud-bookmark:before {
  content: '\e8dd'; }

/* '' */
.uil-cloud-check:before {
  content: '\e8de'; }

/* '' */
.uil-cloud-computing:before {
  content: '\e8df'; }

/* '' */
.uil-cloud-data-connection:before {
  content: '\e8e0'; }

/* '' */
.uil-cloud-database-tree:before {
  content: '\e8e1'; }

/* '' */
.uil-cloud-download:before {
  content: '\e8e2'; }

/* '' */
.uil-cloud-drizzle:before {
  content: '\e8e3'; }

/* '' */
.uil-cloud-exclamation:before {
  content: '\e8e4'; }

/* '' */
.uil-cloud-hail:before {
  content: '\e8e5'; }

/* '' */
.uil-cloud-heart:before {
  content: '\e8e6'; }

/* '' */
.uil-cloud-info:before {
  content: '\e8e7'; }

/* '' */
.uil-cloud-lock:before {
  content: '\e8e8'; }

/* '' */
.uil-cloud-meatball:before {
  content: '\e8e9'; }

/* '' */
.uil-cloud-moon-hail:before {
  content: '\e8ea'; }

/* '' */
.uil-cloud-moon-meatball:before {
  content: '\e8eb'; }

/* '' */
.uil-cloud-moon-rain:before {
  content: '\e8ec'; }

/* '' */
.uil-cloud-moon-showers:before {
  content: '\e8ed'; }

/* '' */
.uil-cloud-moon:before {
  content: '\e8ee'; }

/* '' */
.uil-cloud-question:before {
  content: '\e8ef'; }

/* '' */
.uil-cloud-rain-sun:before {
  content: '\e8f0'; }

/* '' */
.uil-cloud-rain:before {
  content: '\e8f1'; }

/* '' */
.uil-cloud-redo:before {
  content: '\e8f2'; }

/* '' */
.uil-cloud-set:before {
  content: '\e8f3'; }

/* '' */
.uil-cloud-share:before {
  content: '\e8f4'; }

/* '' */
.uil-cloud-shield:before {
  content: '\e8f5'; }

/* '' */
.uil-cloud-showers-alt:before {
  content: '\e8f6'; }

/* '' */
.uil-cloud-showers-heavy:before {
  content: '\e8f7'; }

/* '' */
.uil-cloud-showers:before {
  content: '\e8f8'; }

/* '' */
.uil-cloud-slash:before {
  content: '\e8f9'; }

/* '' */
.uil-cloud-sun-hail:before {
  content: '\e8fa'; }

/* '' */
.uil-cloud-sun-meatball:before {
  content: '\e8fb'; }

/* '' */
.uil-cloud-sun-rain-alt:before {
  content: '\e8fc'; }

/* '' */
.uil-cloud-sun-rain:before {
  content: '\e8fd'; }

/* '' */
.uil-cloud-sun-tear:before {
  content: '\e8fe'; }

/* '' */
.uil-cloud-sun:before {
  content: '\e8ff'; }

/* '' */
.uil-cloud-times:before {
  content: '\e900'; }

/* '' */
.uil-cloud-unlock:before {
  content: '\e901'; }

/* '' */
.uil-cloud-upload:before {
  content: '\e902'; }

/* '' */
.uil-cloud-wifi:before {
  content: '\e903'; }

/* '' */
.uil-cloud-wind:before {
  content: '\e904'; }

/* '' */
.uil-cloud:before {
  content: '\e905'; }

/* '' */
.uil-clouds:before {
  content: '\e906'; }

/* '' */
.uil-club:before {
  content: '\e907'; }

/* '' */
.uil-code:before {
  content: '\e908'; }

/* '' */
.uil-coffee:before {
  content: '\e909'; }

/* '' */
.uil-cog:before {
  content: '\e90a'; }

/* '' */
.uil-coins:before {
  content: '\e90b'; }

/* '' */
.uil-columns:before {
  content: '\e90c'; }

/* '' */
.uil-comment-alt-block:before {
  content: '\e90d'; }

/* '' */
.uil-comment-alt-chart-lines:before {
  content: '\e90e'; }

/* '' */
.uil-comment-alt-check:before {
  content: '\e90f'; }

/* '' */
.uil-comment-alt-dots:before {
  content: '\e910'; }

/* '' */
.uil-comment-alt-download:before {
  content: '\e911'; }

/* '' */
.uil-comment-alt-edit:before {
  content: '\e912'; }

/* '' */
.uil-comment-alt-exclamation:before {
  content: '\e913'; }

/* '' */
.uil-comment-alt-heart:before {
  content: '\e914'; }

/* '' */
.uil-comment-alt-image:before {
  content: '\e915'; }

/* '' */
.uil-comment-alt-info:before {
  content: '\e916'; }

/* '' */
.uil-comment-alt-lines:before {
  content: '\e917'; }

/* '' */
.uil-comment-alt-lock:before {
  content: '\e918'; }

/* '' */
.uil-comment-alt-medical:before {
  content: '\e919'; }

/* '' */
.uil-comment-alt-message:before {
  content: '\e91a'; }

/* '' */
.uil-comment-alt-notes:before {
  content: '\e91b'; }

/* '' */
.uil-comment-alt-plus:before {
  content: '\e91c'; }

/* '' */
.uil-comment-alt-question:before {
  content: '\e91d'; }

/* '' */
.uil-comment-alt-redo:before {
  content: '\e91e'; }

/* '' */
.uil-comment-alt-search:before {
  content: '\e91f'; }

/* '' */
.uil-comment-alt-share:before {
  content: '\e920'; }

/* '' */
.uil-comment-alt-shield:before {
  content: '\e921'; }

/* '' */
.uil-comment-alt-slash:before {
  content: '\e922'; }

/* '' */
.uil-comment-alt-upload:before {
  content: '\e923'; }

/* '' */
.uil-comment-alt-verify:before {
  content: '\e924'; }

/* '' */
.uil-comment-alt:before {
  content: '\e925'; }

/* '' */
.uil-comment-block:before {
  content: '\e926'; }

/* '' */
.uil-comment-chart-line:before {
  content: '\e927'; }

/* '' */
.uil-comment-check:before {
  content: '\e928'; }

/* '' */
.uil-comment-dots:before {
  content: '\e929'; }

/* '' */
.uil-comment-download:before {
  content: '\e92a'; }

/* '' */
.uil-comment-edit:before {
  content: '\e92b'; }

/* '' */
.uil-comment-exclamation:before {
  content: '\e92c'; }

/* '' */
.uil-comment-heart:before {
  content: '\e92d'; }

/* '' */
.uil-comment-image:before {
  content: '\e92e'; }

/* '' */
.uil-comment-info-alt:before {
  content: '\e92f'; }

/* '' */
.uil-comment-info:before {
  content: '\e930'; }

/* '' */
.uil-comment-lines:before {
  content: '\e931'; }

/* '' */
.uil-comment-lock:before {
  content: '\e932'; }

/* '' */
.uil-comment-medical:before {
  content: '\e933'; }

/* '' */
.uil-comment-message:before {
  content: '\e934'; }

/* '' */
.uil-comment-notes:before {
  content: '\e935'; }

/* '' */
.uil-comment-plus:before {
  content: '\e936'; }

/* '' */
.uil-comment-question:before {
  content: '\e937'; }

/* '' */
.uil-comment-redo:before {
  content: '\e938'; }

/* '' */
.uil-comment-search:before {
  content: '\e939'; }

/* '' */
.uil-comment-share:before {
  content: '\e93a'; }

/* '' */
.uil-comment-shield:before {
  content: '\e93b'; }

/* '' */
.uil-comment-slash:before {
  content: '\e93c'; }

/* '' */
.uil-comment-upload:before {
  content: '\e93d'; }

/* '' */
.uil-comment-verify:before {
  content: '\e93e'; }

/* '' */
.uil-comment:before {
  content: '\e93f'; }

/* '' */
.uil-comments-alt:before {
  content: '\e940'; }

/* '' */
.uil-comments:before {
  content: '\e941'; }

/* '' */
.uil-commnet-alt-slash:before {
  content: '\e942'; }

/* '' */
.uil-compact-disc:before {
  content: '\e943'; }

/* '' */
.uil-compass:before {
  content: '\e944'; }

/* '' */
.uil-compress-alt-left:before {
  content: '\e945'; }

/* '' */
.uil-compress-alt:before {
  content: '\e946'; }

/* '' */
.uil-compress-arrows:before {
  content: '\e947'; }

/* '' */
.uil-compress-lines:before {
  content: '\e948'; }

/* '' */
.uil-compress-point:before {
  content: '\e949'; }

/* '' */
.uil-compress-v:before {
  content: '\e94a'; }

/* '' */
.uil-compress:before {
  content: '\e94b'; }

/* '' */
.uil-computer-mouse:before {
  content: '\e94c'; }

/* '' */
.uil-confused:before {
  content: '\e94d'; }

/* '' */
.uil-constructor:before {
  content: '\e94e'; }

/* '' */
.uil-copy-alt:before {
  content: '\e94f'; }

/* '' */
.uil-copy-landscape:before {
  content: '\e950'; }

/* '' */
.uil-copy:before {
  content: '\e951'; }

/* '' */
.uil-copyright:before {
  content: '\e952'; }

/* '' */
.uil-corner-down-left:before {
  content: '\e953'; }

/* '' */
.uil-corner-down-right-alt:before {
  content: '\e954'; }

/* '' */
.uil-corner-down-right:before {
  content: '\e955'; }

/* '' */
.uil-corner-left-down:before {
  content: '\e956'; }

/* '' */
.uil-corner-right-down:before {
  content: '\e957'; }

/* '' */
.uil-corner-up-left-alt:before {
  content: '\e958'; }

/* '' */
.uil-corner-up-left:before {
  content: '\e959'; }

/* '' */
.uil-corner-up-right-alt:before {
  content: '\e95a'; }

/* '' */
.uil-corner-up-right:before {
  content: '\e95b'; }

/* '' */
.uil-creative-commons-pd-alt:before {
  content: '\e95c'; }

/* '' */
.uil-creative-commons-pd:before {
  content: '\e95d'; }

/* '' */
.uil-crockery:before {
  content: '\e95e'; }

/* '' */
.uil-crop-alt-rotate-left:before {
  content: '\e95f'; }

/* '' */
.uil-crop-alt-rotate-right:before {
  content: '\e960'; }

/* '' */
.uil-crop-alt:before {
  content: '\e961'; }

/* '' */
.uil-crosshair-alt:before {
  content: '\e962'; }

/* '' */
.uil-crosshair:before {
  content: '\e963'; }

/* '' */
.uil-crosshairs:before {
  content: '\e964'; }

/* '' */
.uil-cube:before {
  content: '\e965'; }

/* '' */
.uil-dashboard:before {
  content: '\e966'; }

/* '' */
.uil-data-sharing:before {
  content: '\e967'; }

/* '' */
.uil-database-alt:before {
  content: '\e968'; }

/* '' */
.uil-database:before {
  content: '\e969'; }

/* '' */
.uil-desert:before {
  content: '\e96a'; }

/* '' */
.uil-desktop-alt-slash:before {
  content: '\e96b'; }

/* '' */
.uil-desktop-alt:before {
  content: '\e96c'; }

/* '' */
.uil-desktop-cloud-alt:before {
  content: '\e96d'; }

/* '' */
.uil-desktop-slash:before {
  content: '\e96e'; }

/* '' */
.uil-desktop:before {
  content: '\e96f'; }

/* '' */
.uil-dialpad-alt:before {
  content: '\e970'; }

/* '' */
.uil-dialpad:before {
  content: '\e971'; }

/* '' */
.uil-diamond:before {
  content: '\e972'; }

/* '' */
.uil-diary-alt:before {
  content: '\e973'; }

/* '' */
.uil-diary:before {
  content: '\e974'; }

/* '' */
.uil-dice-five:before {
  content: '\e975'; }

/* '' */
.uil-dice-four:before {
  content: '\e976'; }

/* '' */
.uil-dice-one:before {
  content: '\e977'; }

/* '' */
.uil-dice-six:before {
  content: '\e978'; }

/* '' */
.uil-dice-three:before {
  content: '\e979'; }

/* '' */
.uil-dice-two:before {
  content: '\e97a'; }

/* '' */
.uil-direction:before {
  content: '\e97b'; }

/* '' */
.uil-directions:before {
  content: '\e97c'; }

/* '' */
.uil-dizzy-meh:before {
  content: '\e97d'; }

/* '' */
.uil-dna:before {
  content: '\e97e'; }

/* '' */
.uil-document-layout-center:before {
  content: '\e97f'; }

/* '' */
.uil-document-layout-left:before {
  content: '\e980'; }

/* '' */
.uil-document-layout-right:before {
  content: '\e981'; }

/* '' */
.uil-document:before {
  content: '\e982'; }

/* '' */
.uil-dollar-alt:before {
  content: '\e983'; }

/* '' */
.uil-dollar-sign-alt:before {
  content: '\e984'; }

/* '' */
.uil-dollar-sign:before {
  content: '\e985'; }

/* '' */
.uil-down-arrow:before {
  content: '\e986'; }

/* '' */
.uil-download-alt:before {
  content: '\e987'; }

/* '' */
.uil-dribbble:before {
  content: '\e988'; }

/* '' */
.uil-drill:before {
  content: '\e989'; }

/* '' */
.uil-dropbox:before {
  content: '\e98a'; }

/* '' */
.uil-dumbbell:before {
  content: '\e98b'; }

/* '' */
.uil-ear:before {
  content: '\e98c'; }

/* '' */
.uil-edit-alt:before {
  content: '\e98d'; }

/* '' */
.uil-edit:before {
  content: '\e98e'; }

/* '' */
.uil-ellipsis-h:before {
  content: '\e98f'; }

/* '' */
.uil-ellipsis-v:before {
  content: '\e990'; }

/* '' */
.uil-emoji:before {
  content: '\e991'; }

/* '' */
.uil-enter:before {
  content: '\e992'; }

/* '' */
.uil-entry:before {
  content: '\e993'; }

/* '' */
.uil-envelope-add:before {
  content: '\e994'; }

/* '' */
.uil-envelope-alt:before {
  content: '\e995'; }

/* '' */
.uil-envelope-block:before {
  content: '\e996'; }

/* '' */
.uil-envelope-bookmark:before {
  content: '\e997'; }

/* '' */
.uil-envelope-check:before {
  content: '\e998'; }

/* '' */
.uil-envelope-download-alt:before {
  content: '\e999'; }

/* '' */
.uil-envelope-download:before {
  content: '\e99a'; }

/* '' */
.uil-envelope-edit:before {
  content: '\e99b'; }

/* '' */
.uil-envelope-exclamation:before {
  content: '\e99c'; }

/* '' */
.uil-envelope-heart:before {
  content: '\e99d'; }

/* '' */
.uil-envelope-info:before {
  content: '\e99e'; }

/* '' */
.uil-envelope-lock:before {
  content: '\e99f'; }

/* '' */
.uil-envelope-minus:before {
  content: '\e9a0'; }

/* '' */
.uil-envelope-open:before {
  content: '\e9a1'; }

/* '' */
.uil-envelope-question:before {
  content: '\e9a2'; }

/* '' */
.uil-envelope-receive:before {
  content: '\e9a3'; }

/* '' */
.uil-envelope-redo:before {
  content: '\e9a4'; }

/* '' */
.uil-envelope-search:before {
  content: '\e9a5'; }

/* '' */
.uil-envelope-send:before {
  content: '\e9a6'; }

/* '' */
.uil-envelope-share:before {
  content: '\e9a7'; }

/* '' */
.uil-envelope-shield:before {
  content: '\e9a8'; }

/* '' */
.uil-envelope-star:before {
  content: '\e9a9'; }

/* '' */
.uil-envelope-times:before {
  content: '\e9aa'; }

/* '' */
.uil-envelope-upload-alt:before {
  content: '\e9ab'; }

/* '' */
.uil-envelope-upload:before {
  content: '\e9ac'; }

/* '' */
.uil-envelope:before {
  content: '\e9ad'; }

/* '' */
.uil-envelopes:before {
  content: '\e9ae'; }

/* '' */
.uil-equal-circle:before {
  content: '\e9af'; }

/* '' */
.uil-euro-circle:before {
  content: '\e9b0'; }

/* '' */
.uil-euro:before {
  content: '\e9b1'; }

/* '' */
.uil-exchange-alt:before {
  content: '\e9b2'; }

/* '' */
.uil-exchange:before {
  content: '\e9b3'; }

/* '' */
.uil-exclamation-circle:before {
  content: '\e9b4'; }

/* '' */
.uil-exclamation-octagon:before {
  content: '\e9b5'; }

/* '' */
.uil-exclamation-triangle:before {
  content: '\e9b6'; }

/* '' */
.uil-exclude:before {
  content: '\e9b7'; }

/* '' */
.uil-exit:before {
  content: '\e9b8'; }

/* '' */
.uil-expand-alt:before {
  content: '\e9b9'; }

/* '' */
.uil-expand-arrows-alt:before {
  content: '\e9ba'; }

/* '' */
.uil-expand-arrows:before {
  content: '\e9bb'; }

/* '' */
.uil-expand-from-corner:before {
  content: '\e9bc'; }

/* '' */
.uil-expand-left:before {
  content: '\e9bd'; }

/* '' */
.uil-expand-right:before {
  content: '\e9be'; }

/* '' */
.uil-export:before {
  content: '\e9bf'; }

/* '' */
.uil-exposure-alt:before {
  content: '\e9c0'; }

/* '' */
.uil-exposure-increase:before {
  content: '\e9c1'; }

/* '' */
.uil-external-link-alt:before {
  content: '\e9c2'; }

/* '' */
.uil-eye-slash:before {
  content: '\e9c3'; }

/* '' */
.uil-eye:before {
  content: '\e9c4'; }

/* '' */
.uil-facebook-f:before {
  content: '\e9c5'; }

/* '' */
.uil-facebook-messenger:before {
  content: '\e9c6'; }

/* '' */
.uil-facebook:before {
  content: '\e9c7'; }

/* '' */
.uil-fahrenheit:before {
  content: '\e9c8'; }

/* '' */
.uil-fast-mail-alt:before {
  content: '\e9c9'; }

/* '' */
.uil-fast-mail:before {
  content: '\e9ca'; }

/* '' */
.uil-favorite:before {
  content: '\e9cb'; }

/* '' */
.uil-feedback:before {
  content: '\e9cc'; }

/* '' */
.uil-file-alt:before {
  content: '\e9cd'; }

/* '' */
.uil-file-blank:before {
  content: '\e9ce'; }

/* '' */
.uil-file-block-alt:before {
  content: '\e9cf'; }

/* '' */
.uil-file-bookmark-alt:before {
  content: '\e9d0'; }

/* '' */
.uil-file-check-alt:before {
  content: '\e9d1'; }

/* '' */
.uil-file-check:before {
  content: '\e9d2'; }

/* '' */
.uil-file-contract-dollar:before {
  content: '\e9d3'; }

/* '' */
.uil-file-copy-alt:before {
  content: '\e9d4'; }

/* '' */
.uil-file-download-alt:before {
  content: '\e9d5'; }

/* '' */
.uil-file-download:before {
  content: '\e9d6'; }

/* '' */
.uil-file-edit-alt:before {
  content: '\e9d7'; }

/* '' */
.uil-file-exclamation-alt:before {
  content: '\e9d8'; }

/* '' */
.uil-file-exclamation:before {
  content: '\e9d9'; }

/* '' */
.uil-file-heart:before {
  content: '\e9da'; }

/* '' */
.uil-file-info-alt:before {
  content: '\e9db'; }

/* '' */
.uil-file-landscape-alt:before {
  content: '\e9dc'; }

/* '' */
.uil-file-landscape:before {
  content: '\e9dd'; }

/* '' */
.uil-file-lanscape-slash:before {
  content: '\e9de'; }

/* '' */
.uil-file-lock-alt:before {
  content: '\e9df'; }

/* '' */
.uil-file-medical-alt:before {
  content: '\e9e0'; }

/* '' */
.uil-file-medical:before {
  content: '\e9e1'; }

/* '' */
.uil-file-minus-alt:before {
  content: '\e9e2'; }

/* '' */
.uil-file-minus:before {
  content: '\e9e3'; }

/* '' */
.uil-file-network:before {
  content: '\e9e4'; }

/* '' */
.uil-file-plus-alt:before {
  content: '\e9e5'; }

/* '' */
.uil-file-plus:before {
  content: '\e9e6'; }

/* '' */
.uil-file-question-alt:before {
  content: '\e9e7'; }

/* '' */
.uil-file-question:before {
  content: '\e9e8'; }

/* '' */
.uil-file-redo-alt:before {
  content: '\e9e9'; }

/* '' */
.uil-file-search-alt:before {
  content: '\e9ea'; }

/* '' */
.uil-file-share-alt:before {
  content: '\e9eb'; }

/* '' */
.uil-file-shield-alt:before {
  content: '\e9ec'; }

/* '' */
.uil-file-slash:before {
  content: '\e9ed'; }

/* '' */
.uil-file-times-alt:before {
  content: '\e9ee'; }

/* '' */
.uil-file-times:before {
  content: '\e9ef'; }

/* '' */
.uil-file-upload-alt:before {
  content: '\e9f0'; }

/* '' */
.uil-file-upload:before {
  content: '\e9f1'; }

/* '' */
.uil-file:before {
  content: '\e9f2'; }

/* '' */
.uil-files-landscapes-alt:before {
  content: '\e9f3'; }

/* '' */
.uil-files-landscapes:before {
  content: '\e9f4'; }

/* '' */
.uil-film:before {
  content: '\e9f5'; }

/* '' */
.uil-filter-slash:before {
  content: '\e9f6'; }

/* '' */
.uil-filter:before {
  content: '\e9f7'; }

/* '' */
.uil-flask-potion:before {
  content: '\e9f8'; }

/* '' */
.uil-flask:before {
  content: '\e9f9'; }

/* '' */
.uil-flip-h-alt:before {
  content: '\e9fa'; }

/* '' */
.uil-flip-h:before {
  content: '\e9fb'; }

/* '' */
.uil-flip-v-alt:before {
  content: '\e9fc'; }

/* '' */
.uil-flip-v:before {
  content: '\e9fd'; }

/* '' */
.uil-flower:before {
  content: '\e9fe'; }

/* '' */
.uil-focus-add:before {
  content: '\e9ff'; }

/* '' */
.uil-focus-target:before {
  content: '\ea00'; }

/* '' */
.uil-focus:before {
  content: '\ea01'; }

/* '' */
.uil-folder-check:before {
  content: '\ea02'; }

/* '' */
.uil-folder-download:before {
  content: '\ea03'; }

/* '' */
.uil-folder-exclamation:before {
  content: '\ea04'; }

/* '' */
.uil-folder-heart:before {
  content: '\ea05'; }

/* '' */
.uil-folder-info:before {
  content: '\ea06'; }

/* '' */
.uil-folder-lock:before {
  content: '\ea07'; }

/* '' */
.uil-folder-medical:before {
  content: '\ea08'; }

/* '' */
.uil-folder-minus:before {
  content: '\ea09'; }

/* '' */
.uil-folder-network:before {
  content: '\ea0a'; }

/* '' */
.uil-folder-plus:before {
  content: '\ea0b'; }

/* '' */
.uil-folder-question:before {
  content: '\ea0c'; }

/* '' */
.uil-folder-slash:before {
  content: '\ea0d'; }

/* '' */
.uil-folder-times:before {
  content: '\ea0e'; }

/* '' */
.uil-folder-upload:before {
  content: '\ea0f'; }

/* '' */
.uil-folder:before {
  content: '\ea10'; }

/* '' */
.uil-food:before {
  content: '\ea11'; }

/* '' */
.uil-football-american:before {
  content: '\ea12'; }

/* '' */
.uil-football-ball:before {
  content: '\ea13'; }

/* '' */
.uil-football:before {
  content: '\ea14'; }

/* '' */
.uil-forecastcloud-moon-tear:before {
  content: '\ea15'; }

/* '' */
.uil-forwaded-call:before {
  content: '\ea16'; }

/* '' */
.uil-forward:before {
  content: '\ea17'; }

/* '' */
.uil-frown:before {
  content: '\ea18'; }

/* '' */
.uil-game-structure:before {
  content: '\ea19'; }

/* '' */
.uil-game:before {
  content: '\ea1a'; }

/* '' */
.uil-gift:before {
  content: '\ea1b'; }

/* '' */
.uil-github-alt:before {
  content: '\ea1c'; }

/* '' */
.uil-github:before {
  content: '\ea1d'; }

/* '' */
.uil-glass-martini-alt-slash:before {
  content: '\ea1e'; }

/* '' */
.uil-glass-martini-alt:before {
  content: '\ea1f'; }

/* '' */
.uil-glass-martini:before {
  content: '\ea20'; }

/* '' */
.uil-glass-tea:before {
  content: '\ea21'; }

/* '' */
.uil-glass:before {
  content: '\ea22'; }

/* '' */
.uil-globe:before {
  content: '\ea23'; }

/* '' */
.uil-gold:before {
  content: '\ea24'; }

/* '' */
.uil-google-drive-alt:before {
  content: '\ea25'; }

/* '' */
.uil-google-drive:before {
  content: '\ea26'; }

/* '' */
.uil-google-hangouts:before {
  content: '\ea27'; }

/* '' */
.uil-google-play:before {
  content: '\ea28'; }

/* '' */
.uil-google:before {
  content: '\ea29'; }

/* '' */
.uil-graduation-hat:before {
  content: '\ea2a'; }

/* '' */
.uil-graph-bar:before {
  content: '\ea2b'; }

/* '' */
.uil-grid:before {
  content: '\ea2c'; }

/* '' */
.uil-grids:before {
  content: '\ea2d'; }

/* '' */
.uil-grin-tongue-wink-alt:before {
  content: '\ea2e'; }

/* '' */
.uil-grin-tongue-wink:before {
  content: '\ea2f'; }

/* '' */
.uil-grin:before {
  content: '\ea30'; }

/* '' */
.uil-grip-horizontal-line:before {
  content: '\ea31'; }

/* '' */
.uil-hdd:before {
  content: '\ea32'; }

/* '' */
.uil-headphones-alt:before {
  content: '\ea33'; }

/* '' */
.uil-headphones:before {
  content: '\ea34'; }

/* '' */
.uil-heart-alt:before {
  content: '\ea35'; }

/* '' */
.uil-heart-medical:before {
  content: '\ea36'; }

/* '' */
.uil-heart-rate:before {
  content: '\ea37'; }

/* '' */
.uil-heart-sign:before {
  content: '\ea38'; }

/* '' */
.uil-heart:before {
  content: '\ea39'; }

/* '' */
.uil-heartbeat:before {
  content: '\ea3a'; }

/* '' */
.uil-history-alt:before {
  content: '\ea3b'; }

/* '' */
.uil-history:before {
  content: '\ea3c'; }

/* '' */
.uil-home-alt:before {
  content: '\ea3d'; }

/* '' */
.uil-home:before {
  content: '\ea3e'; }

/* '' */
.uil-horizontal-align-center:before {
  content: '\ea3f'; }

/* '' */
.uil-horizontal-align-left:before {
  content: '\ea40'; }

/* '' */
.uil-horizontal-align-right:before {
  content: '\ea41'; }

/* '' */
.uil-horizontal-distribution-center:before {
  content: '\ea42'; }

/* '' */
.uil-horizontal-distribution-left:before {
  content: '\ea43'; }

/* '' */
.uil-horizontal-distribution-right:before {
  content: '\ea44'; }

/* '' */
.uil-hunting:before {
  content: '\ea45'; }

/* '' */
.uil-image-alt-slash:before {
  content: '\ea46'; }

/* '' */
.uil-image-block:before {
  content: '\ea47'; }

/* '' */
.uil-image-broken:before {
  content: '\ea48'; }

/* '' */
.uil-image-check:before {
  content: '\ea49'; }

/* '' */
.uil-image-download:before {
  content: '\ea4a'; }

/* '' */
.uil-image-edit:before {
  content: '\ea4b'; }

/* '' */
.uil-image-lock:before {
  content: '\ea4c'; }

/* '' */
.uil-image-minus:before {
  content: '\ea4d'; }

/* '' */
.uil-image-plus:before {
  content: '\ea4e'; }

/* '' */
.uil-image-question:before {
  content: '\ea4f'; }

/* '' */
.uil-image-redo:before {
  content: '\ea50'; }

/* '' */
.uil-image-resize-landscape:before {
  content: '\ea51'; }

/* '' */
.uil-image-resize-square:before {
  content: '\ea52'; }

/* '' */
.uil-image-search:before {
  content: '\ea53'; }

/* '' */
.uil-image-share:before {
  content: '\ea54'; }

/* '' */
.uil-image-shield:before {
  content: '\ea55'; }

/* '' */
.uil-image-slash:before {
  content: '\ea56'; }

/* '' */
.uil-image-times:before {
  content: '\ea57'; }

/* '' */
.uil-image-upload:before {
  content: '\ea58'; }

/* '' */
.uil-image-v:before {
  content: '\ea59'; }

/* '' */
.uil-image:before {
  content: '\ea5a'; }

/* '' */
.uil-images:before {
  content: '\ea5b'; }

/* '' */
.uil-incoming-call:before {
  content: '\ea5c'; }

/* '' */
.uil-info-circle:before {
  content: '\ea5d'; }

/* '' */
.uil-instagram-alt:before {
  content: '\ea5e'; }

/* '' */
.uil-instagram:before {
  content: '\ea5f'; }

/* '' */
.uil-intercom:before {
  content: '\ea60'; }

/* '' */
.uil-invoice:before {
  content: '\ea61'; }

/* '' */
.uil-italic:before {
  content: '\ea62'; }

/* '' */
.uil-jackhammer:before {
  content: '\ea63'; }

/* '' */
.uil-java-script:before {
  content: '\ea64'; }

/* '' */
.uil-kayak:before {
  content: '\ea65'; }

/* '' */
.uil-key-skeleton-alt:before {
  content: '\ea66'; }

/* '' */
.uil-key-skeleton:before {
  content: '\ea67'; }

/* '' */
.uil-keyboard-alt:before {
  content: '\ea68'; }

/* '' */
.uil-keyboard-hide:before {
  content: '\ea69'; }

/* '' */
.uil-keyboard-show:before {
  content: '\ea6a'; }

/* '' */
.uil-keyboard:before {
  content: '\ea6b'; }

/* '' */
.uil-keyhole-circle:before {
  content: '\ea6c'; }

/* '' */
.uil-keyhole-square-full:before {
  content: '\ea6d'; }

/* '' */
.uil-keyhole-square:before {
  content: '\ea6e'; }

/* '' */
.uil-kid:before {
  content: '\ea6f'; }

/* '' */
.uil-label-alt:before {
  content: '\ea70'; }

/* '' */
.uil-label:before {
  content: '\ea71'; }

/* '' */
.uil-lamp:before {
  content: '\ea72'; }

/* '' */
.uil-laptop-cloud:before {
  content: '\ea73'; }

/* '' */
.uil-laptop:before {
  content: '\ea74'; }

/* '' */
.uil-laughing:before {
  content: '\ea75'; }

/* '' */
.uil-layer-group-slash:before {
  content: '\ea76'; }

/* '' */
.uil-layer-group:before {
  content: '\ea77'; }

/* '' */
.uil-layers-alt:before {
  content: '\ea78'; }

/* '' */
.uil-layers-slash:before {
  content: '\ea79'; }

/* '' */
.uil-layers:before {
  content: '\ea7a'; }

/* '' */
.uil-left-arrow-from-left:before {
  content: '\ea7b'; }

/* '' */
.uil-left-arrow-to-left:before {
  content: '\ea7c'; }

/* '' */
.uil-left-indent-alt:before {
  content: '\ea7d'; }

/* '' */
.uil-left-indent:before {
  content: '\ea7e'; }

/* '' */
.uil-left-to-right-text-direction:before {
  content: '\ea7f'; }

/* '' */
.uil-life-ring:before {
  content: '\ea80'; }

/* '' */
.uil-lightbulb-alt:before {
  content: '\ea81'; }

/* '' */
.uil-lightbulb:before {
  content: '\ea82'; }

/* '' */
.uil-line-alt:before {
  content: '\ea83'; }

/* '' */
.uil-line-spacing:before {
  content: '\ea84'; }

/* '' */
.uil-line:before {
  content: '\ea85'; }

/* '' */
.uil-link-alt:before {
  content: '\ea86'; }

/* '' */
.uil-link-broken:before {
  content: '\ea87'; }

/* '' */
.uil-link-h:before {
  content: '\ea88'; }

/* '' */
.uil-link:before {
  content: '\ea89'; }

/* '' */
.uil-linkedin-alt:before {
  content: '\ea8a'; }

/* '' */
.uil-linkedin:before {
  content: '\ea8b'; }

/* '' */
.uil-list-ui-alt:before {
  content: '\ea8c'; }

/* '' */
.uil-list-ul:before {
  content: '\ea8d'; }

/* '' */
.uil-location-arrow-alt:before {
  content: '\ea8e'; }

/* '' */
.uil-location-arrow:before {
  content: '\ea8f'; }

/* '' */
.uil-location-pin-alt:before {
  content: '\ea90'; }

/* '' */
.uil-location-point:before {
  content: '\ea91'; }

/* '' */
.uil-location:before {
  content: '\ea92'; }

/* '' */
.uil-lock-access:before {
  content: '\ea93'; }

/* '' */
.uil-lock-alt:before {
  content: '\ea94'; }

/* '' */
.uil-lock-open-alt:before {
  content: '\ea95'; }

/* '' */
.uil-lock-slash:before {
  content: '\ea96'; }

/* '' */
.uil-lock:before {
  content: '\ea97'; }

/* '' */
.uil-mailbox-alt:before {
  content: '\ea98'; }

/* '' */
.uil-mailbox:before {
  content: '\ea99'; }

/* '' */
.uil-map-marker-alt:before {
  content: '\ea9a'; }

/* '' */
.uil-map-marker-edit:before {
  content: '\ea9b'; }

/* '' */
.uil-map-marker-info:before {
  content: '\ea9c'; }

/* '' */
.uil-map-marker-minus:before {
  content: '\ea9d'; }

/* '' */
.uil-map-marker-plus:before {
  content: '\ea9e'; }

/* '' */
.uil-map-marker-question:before {
  content: '\ea9f'; }

/* '' */
.uil-map-marker-shield:before {
  content: '\eaa0'; }

/* '' */
.uil-map-marker-slash:before {
  content: '\eaa1'; }

/* '' */
.uil-map-marker:before {
  content: '\eaa2'; }

/* '' */
.uil-map-pin-alt:before {
  content: '\eaa3'; }

/* '' */
.uil-map-pin:before {
  content: '\eaa4'; }

/* '' */
.uil-map:before {
  content: '\eaa5'; }

/* '' */
.uil-mars:before {
  content: '\eaa6'; }

/* '' */
.uil-maximize-left:before {
  content: '\eaa7'; }

/* '' */
.uil-medal:before {
  content: '\eaa8'; }

/* '' */
.uil-medical-drip:before {
  content: '\eaa9'; }

/* '' */
.uil-medical-square-full:before {
  content: '\eaaa'; }

/* '' */
.uil-medical-square:before {
  content: '\eaab'; }

/* '' */
.uil-medical:before {
  content: '\eaac'; }

/* '' */
.uil-medium-m:before {
  content: '\eaad'; }

/* '' */
.uil-medkit:before {
  content: '\eaae'; }

/* '' */
.uil-meeting-board:before {
  content: '\eaaf'; }

/* '' */
.uil-meh-alt:before {
  content: '\eab0'; }

/* '' */
.uil-meh-closed-eye:before {
  content: '\eab1'; }

/* '' */
.uil-meh:before {
  content: '\eab2'; }

/* '' */
.uil-message:before {
  content: '\eab3'; }

/* '' */
.uil-microphone-slash:before {
  content: '\eab4'; }

/* '' */
.uil-microphone:before {
  content: '\eab5'; }

/* '' */
.uil-minus-circle:before {
  content: '\eab6'; }

/* '' */
.uil-minus-path:before {
  content: '\eab7'; }

/* '' */
.uil-minus-square-full:before {
  content: '\eab8'; }

/* '' */
.uil-minus-square:before {
  content: '\eab9'; }

/* '' */
.uil-minus:before {
  content: '\eaba'; }

/* '' */
.uil-missed-call:before {
  content: '\eabb'; }

/* '' */
.uil-mobey-bill-slash:before {
  content: '\eabc'; }

/* '' */
.uil-mobile-android-alt:before {
  content: '\eabd'; }

/* '' */
.uil-mobile-android:before {
  content: '\eabe'; }

/* '' */
.uil-mobile-vibrate:before {
  content: '\eabf'; }

/* '' */
.uil-modem:before {
  content: '\eac0'; }

/* '' */
.uil-money-bill-stack:before {
  content: '\eac1'; }

/* '' */
.uil-money-bill:before {
  content: '\eac2'; }

/* '' */
.uil-money-insert:before {
  content: '\eac3'; }

/* '' */
.uil-money-stack:before {
  content: '\eac4'; }

/* '' */
.uil-money-withdraw:before {
  content: '\eac5'; }

/* '' */
.uil-money-withdrawal:before {
  content: '\eac6'; }

/* '' */
.uil-moneybag-alt:before {
  content: '\eac7'; }

/* '' */
.uil-moneybag:before {
  content: '\eac8'; }

/* '' */
.uil-monitor-heart-rate:before {
  content: '\eac9'; }

/* '' */
.uil-monitor:before {
  content: '\eaca'; }

/* '' */
.uil-moon-eclipse:before {
  content: '\eacb'; }

/* '' */
.uil-moon:before {
  content: '\eacc'; }

/* '' */
.uil-moonset:before {
  content: '\eacd'; }

/* '' */
.uil-mountains-sun:before {
  content: '\eace'; }

/* '' */
.uil-mountains:before {
  content: '\eacf'; }

/* '' */
.uil-mouse-alt:before {
  content: '\ead0'; }

/* '' */
.uil-mouse:before {
  content: '\ead1'; }

/* '' */
.uil-multiply:before {
  content: '\ead2'; }

/* '' */
.uil-music-note:before {
  content: '\ead3'; }

/* '' */
.uil-music-tune-slash:before {
  content: '\ead4'; }

/* '' */
.uil-music:before {
  content: '\ead5'; }

/* '' */
.uil-n-a:before {
  content: '\ead6'; }

/* '' */
.uil-navigator:before {
  content: '\ead7'; }

/* '' */
.uil-nerd:before {
  content: '\ead8'; }

/* '' */
.uil-newspaper:before {
  content: '\ead9'; }

/* '' */
.uil-ninja:before {
  content: '\eada'; }

/* '' */
.uil-no-entry:before {
  content: '\eadb'; }

/* '' */
.uil-notebooks:before {
  content: '\eadc'; }

/* '' */
.uil-notes:before {
  content: '\eadd'; }

/* '' */
.uil-object-group:before {
  content: '\eade'; }

/* '' */
.uil-object-ungroup:before {
  content: '\eadf'; }

/* '' */
.uil-octagon:before {
  content: '\eae0'; }

/* '' */
.uil-outgoing-call:before {
  content: '\eae1'; }

/* '' */
.uil-package:before {
  content: '\eae2'; }

/* '' */
.uil-padlock:before {
  content: '\eae3'; }

/* '' */
.uil-paint-tool:before {
  content: '\eae4'; }

/* '' */
.uil-palette:before {
  content: '\eae5'; }

/* '' */
.uil-panorama-h-alt:before {
  content: '\eae6'; }

/* '' */
.uil-panorama-h:before {
  content: '\eae7'; }

/* '' */
.uil-panorama-v:before {
  content: '\eae8'; }

/* '' */
.uil-paperclip:before {
  content: '\eae9'; }

/* '' */
.uil-paragraph:before {
  content: '\eaea'; }

/* '' */
.uil-parcel:before {
  content: '\eaeb'; }

/* '' */
.uil-parking-square:before {
  content: '\eaec'; }

/* '' */
.uil-pathfinder-unite:before {
  content: '\eaed'; }

/* '' */
.uil-pathfinder:before {
  content: '\eaee'; }

/* '' */
.uil-pause-circle:before {
  content: '\eaef'; }

/* '' */
.uil-pause:before {
  content: '\eaf0'; }

/* '' */
.uil-paypal:before {
  content: '\eaf1'; }

/* '' */
.uil-pen:before {
  content: '\eaf2'; }

/* '' */
.uil-pentagon:before {
  content: '\eaf3'; }

/* '' */
.uil-phone-alt:before {
  content: '\eaf4'; }

/* '' */
.uil-phone-pause:before {
  content: '\eaf5'; }

/* '' */
.uil-phone-slash:before {
  content: '\eaf6'; }

/* '' */
.uil-phone-times:before {
  content: '\eaf7'; }

/* '' */
.uil-phone-volume:before {
  content: '\eaf8'; }

/* '' */
.uil-phone:before {
  content: '\eaf9'; }

/* '' */
.uil-picture:before {
  content: '\eafa'; }

/* '' */
.uil-plane-arrival:before {
  content: '\eafb'; }

/* '' */
.uil-plane-departure:before {
  content: '\eafc'; }

/* '' */
.uil-plane-fly:before {
  content: '\eafd'; }

/* '' */
.uil-plane:before {
  content: '\eafe'; }

/* '' */
.uil-play-circle:before {
  content: '\eaff'; }

/* '' */
.uil-play:before {
  content: '\eb00'; }

/* '' */
.uil-plug:before {
  content: '\eb01'; }

/* '' */
.uil-plus-circle:before {
  content: '\eb02'; }

/* '' */
.uil-plus-square:before {
  content: '\eb03'; }

/* '' */
.uil-plus:before {
  content: '\eb04'; }

/* '' */
.uil-podium:before {
  content: '\eb05'; }

/* '' */
.uil-polygon:before {
  content: '\eb06'; }

/* '' */
.uil-post-stamp:before {
  content: '\eb07'; }

/* '' */
.uil-postcard:before {
  content: '\eb08'; }

/* '' */
.uil-pound-circle:before {
  content: '\eb09'; }

/* '' */
.uil-pound:before {
  content: '\eb0a'; }

/* '' */
.uil-power:before {
  content: '\eb0b'; }

/* '' */
.uil-prescription-bottle:before {
  content: '\eb0c'; }

/* '' */
.uil-presentation-check:before {
  content: '\eb0d'; }

/* '' */
.uil-presentation-edit:before {
  content: '\eb0e'; }

/* '' */
.uil-presentation-line:before {
  content: '\eb0f'; }

/* '' */
.uil-presentation-lines-alt:before {
  content: '\eb10'; }

/* '' */
.uil-presentation-minus:before {
  content: '\eb11'; }

/* '' */
.uil-presentation-play:before {
  content: '\eb12'; }

/* '' */
.uil-presentation-plus:before {
  content: '\eb13'; }

/* '' */
.uil-presentation-times:before {
  content: '\eb14'; }

/* '' */
.uil-presentation:before {
  content: '\eb15'; }

/* '' */
.uil-previous:before {
  content: '\eb16'; }

/* '' */
.uil-pricetag-alt:before {
  content: '\eb17'; }

/* '' */
.uil-print-slash:before {
  content: '\eb18'; }

/* '' */
.uil-print:before {
  content: '\eb19'; }

/* '' */
.uil-processor:before {
  content: '\eb1a'; }

/* '' */
.uil-pump:before {
  content: '\eb1b'; }

/* '' */
.uil-puzzle-piece:before {
  content: '\eb1c'; }

/* '' */
.uil-question-circle:before {
  content: '\eb1d'; }

/* '' */
.uil-raddit-alien-alt:before {
  content: '\eb1e'; }

/* '' */
.uil-rainbow:before {
  content: '\eb1f'; }

/* '' */
.uil-raindrops-alt:before {
  content: '\eb20'; }

/* '' */
.uil-raindrops:before {
  content: '\eb21'; }

/* '' */
.uil-receipt-alt:before {
  content: '\eb22'; }

/* '' */
.uil-receipt:before {
  content: '\eb23'; }

/* '' */
.uil-record-audio:before {
  content: '\eb24'; }

/* '' */
.uil-redo:before {
  content: '\eb25'; }

/* '' */
.uil-refresh:before {
  content: '\eb26'; }

/* '' */
.uil-registered:before {
  content: '\eb27'; }

/* '' */
.uil-repeat:before {
  content: '\eb28'; }

/* '' */
.uil-restaurant:before {
  content: '\eb29'; }

/* '' */
.uil-right-indent-alt:before {
  content: '\eb2a'; }

/* '' */
.uil-right-to-left-text-direction:before {
  content: '\eb2b'; }

/* '' */
.uil-robot:before {
  content: '\eb2c'; }

/* '' */
.uil-rope-way:before {
  content: '\eb2d'; }

/* '' */
.uil-rotate-360:before {
  content: '\eb2e'; }

/* '' */
.uil-rss-alt:before {
  content: '\eb2f'; }

/* '' */
.uil-rss-interface:before {
  content: '\eb30'; }

/* '' */
.uil-rss:before {
  content: '\eb31'; }

/* '' */
.uil-ruler-combined:before {
  content: '\eb32'; }

/* '' */
.uil-ruler:before {
  content: '\eb33'; }

/* '' */
.uil-sad-cry:before {
  content: '\eb34'; }

/* '' */
.uil-sad-crying:before {
  content: '\eb35'; }

/* '' */
.uil-sad-dizzy:before {
  content: '\eb36'; }

/* '' */
.uil-sad-squint:before {
  content: '\eb37'; }

/* '' */
.uil-sad:before {
  content: '\eb38'; }

/* '' */
.uil-scaling-left:before {
  content: '\eb39'; }

/* '' */
.uil-scaling-right:before {
  content: '\eb3a'; }

/* '' */
.uil-scenery:before {
  content: '\eb3b'; }

/* '' */
.uil-schedule:before {
  content: '\eb3c'; }

/* '' */
.uil-science:before {
  content: '\eb3d'; }

/* '' */
.uil-screw:before {
  content: '\eb3e'; }

/* '' */
.uil-scroll-h:before {
  content: '\eb3f'; }

/* '' */
.uil-scroll:before {
  content: '\eb40'; }

/* '' */
.uil-search-alt:before {
  content: '\eb41'; }

/* '' */
.uil-search-minus:before {
  content: '\eb42'; }

/* '' */
.uil-search-plus:before {
  content: '\eb43'; }

/* '' */
.uil-search:before {
  content: '\eb44'; }

/* '' */
.uil-selfie:before {
  content: '\eb45'; }

/* '' */
.uil-server-alt:before {
  content: '\eb46'; }

/* '' */
.uil-server-connection:before {
  content: '\eb47'; }

/* '' */
.uil-server-network-alt:before {
  content: '\eb48'; }

/* '' */
.uil-server-network:before {
  content: '\eb49'; }

/* '' */
.uil-server:before {
  content: '\eb4a'; }

/* '' */
.uil-servers:before {
  content: '\eb4b'; }

/* '' */
.uil-servicemark:before {
  content: '\eb4c'; }

/* '' */
.uil-share-alt:before {
  content: '\eb4d'; }

/* '' */
.uil-shield-check:before {
  content: '\eb4e'; }

/* '' */
.uil-shield-exclamation:before {
  content: '\eb4f'; }

/* '' */
.uil-shield-question:before {
  content: '\eb50'; }

/* '' */
.uil-shield-slash:before {
  content: '\eb51'; }

/* '' */
.uil-shield:before {
  content: '\eb52'; }

/* '' */
.uil-ship:before {
  content: '\eb53'; }

/* '' */
.uil-shop:before {
  content: '\eb54'; }

/* '' */
.uil-shopping-basket:before {
  content: '\eb55'; }

/* '' */
.uil-shopping-cart-alt:before {
  content: '\eb56'; }

/* '' */
.uil-shopping-trolley:before {
  content: '\eb57'; }

/* '' */
.uil-shovel:before {
  content: '\eb58'; }

/* '' */
.uil-shrink:before {
  content: '\eb59'; }

/* '' */
.uil-shuffle:before {
  content: '\eb5a'; }

/* '' */
.uil-shutter-alt:before {
  content: '\eb5b'; }

/* '' */
.uil-shutter:before {
  content: '\eb5c'; }

/* '' */
.uil-sick:before {
  content: '\eb5d'; }

/* '' */
.uil-sigma:before {
  content: '\eb5e'; }

/* '' */
.uil-sign-alt:before {
  content: '\eb5f'; }

/* '' */
.uil-sign-in-alt:before {
  content: '\eb60'; }

/* '' */
.uil-sign-left:before {
  content: '\eb61'; }

/* '' */
.uil-sign-out-alt:before {
  content: '\eb62'; }

/* '' */
.uil-sign-right:before {
  content: '\eb63'; }

/* '' */
.uil-signal-alt-3:before {
  content: '\eb64'; }

/* '' */
.uil-signal-alt:before {
  content: '\eb65'; }

/* '' */
.uil-signal:before {
  content: '\eb66'; }

/* '' */
.uil-silence:before {
  content: '\eb67'; }

/* '' */
.uil-silent-squint:before {
  content: '\eb68'; }

/* '' */
.uil-sim-card:before {
  content: '\eb69'; }

/* '' */
.uil-sitemap:before {
  content: '\eb6a'; }

/* '' */
.uil-skip-forward-alt:before {
  content: '\eb6b'; }

/* '' */
.uil-skip-forward-circle:before {
  content: '\eb6c'; }

/* '' */
.uil-skip-forward:before {
  content: '\eb6d'; }

/* '' */
.uil-slack-alt:before {
  content: '\eb6e'; }

/* '' */
.uil-slack:before {
  content: '\eb6f'; }

/* '' */
.uil-sliders-v-alt:before {
  content: '\eb70'; }

/* '' */
.uil-sliders-v:before {
  content: '\eb71'; }

/* '' */
.uil-smile-beam:before {
  content: '\eb72'; }

/* '' */
.uil-smile-dizzy:before {
  content: '\eb73'; }

/* '' */
.uil-smile-squint-wink-alt:before {
  content: '\eb74'; }

/* '' */
.uil-smile-squint-wink:before {
  content: '\eb75'; }

/* '' */
.uil-smile-wink-alt:before {
  content: '\eb76'; }

/* '' */
.uil-smile-wink:before {
  content: '\eb77'; }

/* '' */
.uil-smile:before {
  content: '\eb78'; }

/* '' */
.uil-snapchat-ghost:before {
  content: '\eb79'; }

/* '' */
.uil-snapchat-square:before {
  content: '\eb7a'; }

/* '' */
.uil-snow-flake:before {
  content: '\eb7b'; }

/* '' */
.uil-snowflake-alt:before {
  content: '\eb7c'; }

/* '' */
.uil-snowflake:before {
  content: '\eb7d'; }

/* '' */
.uil-sort-amount-down:before {
  content: '\eb7e'; }

/* '' */
.uil-sort-amount-up:before {
  content: '\eb7f'; }

/* '' */
.uil-sort:before {
  content: '\eb80'; }

/* '' */
.uil-sorting:before {
  content: '\eb81'; }

/* '' */
.uil-space-key:before {
  content: '\eb82'; }

/* '' */
.uil-spade:before {
  content: '\eb83'; }

/* '' */
.uil-sperms:before {
  content: '\eb84'; }

/* '' */
.uil-spin:before {
  content: '\eb85'; }

/* '' */
.uil-sport:before {
  content: '\eb86'; }

/* '' */
.uil-square-full:before {
  content: '\eb87'; }

/* '' */
.uil-square-shape:before {
  content: '\eb88'; }

/* '' */
.uil-square:before {
  content: '\eb89'; }

/* '' */
.uil-squint:before {
  content: '\eb8a'; }

/* '' */
.uil-star-half-alt:before {
  content: '\eb8b'; }

/* '' */
.uil-star:before {
  content: '\eb8c'; }

/* '' */
.uil-step-backward-alt:before {
  content: '\eb8d'; }

/* '' */
.uil-step-backward-circle:before {
  content: '\eb8e'; }

/* '' */
.uil-step-backward:before {
  content: '\eb8f'; }

/* '' */
.uil-step-forward:before {
  content: '\eb90'; }

/* '' */
.uil-stop-circle:before {
  content: '\eb91'; }

/* '' */
.uil-stopwatch-slash:before {
  content: '\eb92'; }

/* '' */
.uil-stopwatch:before {
  content: '\eb93'; }

/* '' */
.uil-store-alt:before {
  content: '\eb94'; }

/* '' */
.uil-store:before {
  content: '\eb95'; }

/* '' */
.uil-streering:before {
  content: '\eb96'; }

/* '' */
.uil-stretcher:before {
  content: '\eb97'; }

/* '' */
.uil-subject:before {
  content: '\eb98'; }

/* '' */
.uil-subway-alt:before {
  content: '\eb99'; }

/* '' */
.uil-subway:before {
  content: '\eb9a'; }

/* '' */
.uil-suitcase-alt:before {
  content: '\eb9b'; }

/* '' */
.uil-suitcase:before {
  content: '\eb9c'; }

/* '' */
.uil-sun:before {
  content: '\eb9d'; }

/* '' */
.uil-sunset:before {
  content: '\eb9e'; }

/* '' */
.uil-surprise:before {
  content: '\eb9f'; }

/* '' */
.uil-swatchbook:before {
  content: '\eba0'; }

/* '' */
.uil-swimmer:before {
  content: '\eba1'; }

/* '' */
.uil-symbol:before {
  content: '\eba2'; }

/* '' */
.uil-sync-exclamation:before {
  content: '\eba3'; }

/* '' */
.uil-sync-slash:before {
  content: '\eba4'; }

/* '' */
.uil-sync:before {
  content: '\eba5'; }

/* '' */
.uil-syringe:before {
  content: '\eba6'; }

/* '' */
.uil-table:before {
  content: '\eba7'; }

/* '' */
.uil-tablet:before {
  content: '\eba8'; }

/* '' */
.uil-tablets:before {
  content: '\eba9'; }

/* '' */
.uil-tachometer-fast:before {
  content: '\ebaa'; }

/* '' */
.uil-tag-alt:before {
  content: '\ebab'; }

/* '' */
.uil-tag:before {
  content: '\ebac'; }

/* '' */
.uil-tape:before {
  content: '\ebad'; }

/* '' */
.uil-taxi:before {
  content: '\ebae'; }

/* '' */
.uil-tear:before {
  content: '\ebaf'; }

/* '' */
.uil-technology:before {
  content: '\ebb0'; }

/* '' */
.uil-telescope:before {
  content: '\ebb1'; }

/* '' */
.uil-temperature-empty:before {
  content: '\ebb2'; }

/* '' */
.uil-temperature-half:before {
  content: '\ebb3'; }

/* '' */
.uil-temperature-minus:before {
  content: '\ebb4'; }

/* '' */
.uil-temperature-plus:before {
  content: '\ebb5'; }

/* '' */
.uil-temperature-quarter:before {
  content: '\ebb6'; }

/* '' */
.uil-temperature-three-quarter:before {
  content: '\ebb7'; }

/* '' */
.uil-temperature:before {
  content: '\ebb8'; }

/* '' */
.uil-text-fields:before {
  content: '\ebb9'; }

/* '' */
.uil-text-size:before {
  content: '\ebba'; }

/* '' */
.uil-text-strike-through:before {
  content: '\ebbb'; }

/* '' */
.uil-text:before {
  content: '\ebbc'; }

/* '' */
.uil-th-large:before {
  content: '\ebbd'; }

/* '' */
.uil-th-slash:before {
  content: '\ebbe'; }

/* '' */
.uil-th:before {
  content: '\ebbf'; }

/* '' */
.uil-thermometer:before {
  content: '\ebc0'; }

/* '' */
.uil-thumbs-down:before {
  content: '\ebc1'; }

/* '' */
.uil-thumbs-up:before {
  content: '\ebc2'; }

/* '' */
.uil-thunderstorm-moon:before {
  content: '\ebc3'; }

/* '' */
.uil-thunderstorm-sun:before {
  content: '\ebc4'; }

/* '' */
.uil-thunderstorm:before {
  content: '\ebc5'; }

/* '' */
.uil-ticket:before {
  content: '\ebc6'; }

/* '' */
.uil-times-circle:before {
  content: '\ebc7'; }

/* '' */
.uil-times-square:before {
  content: '\ebc8'; }

/* '' */
.uil-times:before {
  content: '\ebc9'; }

/* '' */
.uil-toggle-off:before {
  content: '\ebca'; }

/* '' */
.uil-toggle-on:before {
  content: '\ebcb'; }

/* '' */
.uil-top-arrow-from-top:before {
  content: '\ebcc'; }

/* '' */
.uil-top-arrow-to-top:before {
  content: '\ebcd'; }

/* '' */
.uil-tornado:before {
  content: '\ebce'; }

/* '' */
.uil-trademark-circle:before {
  content: '\ebcf'; }

/* '' */
.uil-trademark:before {
  content: '\ebd0'; }

/* '' */
.uil-traffic-barrier:before {
  content: '\ebd1'; }

/* '' */
.uil-trash-alt:before {
  content: '\ebd2'; }

/* '' */
.uil-trash:before {
  content: '\ebd3'; }

/* '' */
.uil-trees:before {
  content: '\ebd4'; }

/* '' */
.uil-triangle:before {
  content: '\ebd5'; }

/* '' */
.uil-trophy:before {
  content: '\ebd6'; }

/* '' */
.uil-trowel:before {
  content: '\ebd7'; }

/* '' */
.uil-truck-case:before {
  content: '\ebd8'; }

/* '' */
.uil-truck-loading:before {
  content: '\ebd9'; }

/* '' */
.uil-truck:before {
  content: '\ebda'; }

/* '' */
.uil-tumblr-square:before {
  content: '\ebdb'; }

/* '' */
.uil-tumblr:before {
  content: '\ebdc'; }

/* '' */
.uil-tv-retro-slash:before {
  content: '\ebdd'; }

/* '' */
.uil-tv-retro:before {
  content: '\ebde'; }

/* '' */
.uil-twitter:before {
  content: '\ebdf'; }

/* '' */
.uil-umbrella:before {
  content: '\ebe0'; }

/* '' */
.uil-unamused:before {
  content: '\ebe1'; }

/* '' */
.uil-underline:before {
  content: '\ebe2'; }

/* '' */
.uil-unlock-alt:before {
  content: '\ebe3'; }

/* '' */
.uil-unlock:before {
  content: '\ebe4'; }

/* '' */
.uil-upload-alt:before {
  content: '\ebe5'; }

/* '' */
.uil-upload:before {
  content: '\ebe6'; }

/* '' */
.uil-usd-circle:before {
  content: '\ebe7'; }

/* '' */
.uil-usd-square:before {
  content: '\ebe8'; }

/* '' */
.uil-user-check:before {
  content: '\ebe9'; }

/* '' */
.uil-user-circle:before {
  content: '\ebea'; }

/* '' */
.uil-user-exclamation:before {
  content: '\ebeb'; }

/* '' */
.uil-user-hard-hat:before {
  content: '\ebec'; }

/* '' */
.uil-user-minus:before {
  content: '\ebed'; }

/* '' */
.uil-user-plus:before {
  content: '\ebee'; }

/* '' */
.uil-user-square:before {
  content: '\ebef'; }

/* '' */
.uil-user-times:before {
  content: '\ebf0'; }

/* '' */
.uil-user:before {
  content: '\ebf1'; }

/* '' */
.uil-users-alt:before {
  content: '\ebf2'; }

/* '' */
.uil-utensils-alt:before {
  content: '\ebf3'; }

/* '' */
.uil-utensils:before {
  content: '\ebf4'; }

/* '' */
.uil-vector-square-alt:before {
  content: '\ebf5'; }

/* '' */
.uil-vector-square:before {
  content: '\ebf6'; }

/* '' */
.uil-venus:before {
  content: '\ebf7'; }

/* '' */
.uil-vertical-align-bottom:before {
  content: '\ebf8'; }

/* '' */
.uil-vertical-align-center:before {
  content: '\ebf9'; }

/* '' */
.uil-vertical-align-top:before {
  content: '\ebfa'; }

/* '' */
.uil-vertical-distribute-bottom:before {
  content: '\ebfb'; }

/* '' */
.uil-vertical-distribution-center:before {
  content: '\ebfc'; }

/* '' */
.uil-vertical-distribution-top:before {
  content: '\ebfd'; }

/* '' */
.uil-video-slash:before {
  content: '\ebfe'; }

/* '' */
.uil-video:before {
  content: '\ebff'; }

/* '' */
.uil-visual-studio:before {
  content: '\ec00'; }

/* '' */
.uil-voicemail-rectangle:before {
  content: '\ec01'; }

/* '' */
.uil-voicemail:before {
  content: '\ec02'; }

/* '' */
.uil-volleyball:before {
  content: '\ec03'; }

/* '' */
.uil-volume-down:before {
  content: '\ec04'; }

/* '' */
.uil-volume-mute:before {
  content: '\ec05'; }

/* '' */
.uil-volume-off:before {
  content: '\ec06'; }

/* '' */
.uil-volume-up:before {
  content: '\ec07'; }

/* '' */
.uil-volume:before {
  content: '\ec08'; }

/* '' */
.uil-vuejs-alt:before {
  content: '\ec09'; }

/* '' */
.uil-vuejs:before {
  content: '\ec0a'; }

/* '' */
.uil-wall:before {
  content: '\ec0b'; }

/* '' */
.uil-wallet:before {
  content: '\ec0c'; }

/* '' */
.uil-watch-alt:before {
  content: '\ec0d'; }

/* '' */
.uil-watch:before {
  content: '\ec0e'; }

/* '' */
.uil-water-drop-slash:before {
  content: '\ec0f'; }

/* '' */
.uil-water-glass:before {
  content: '\ec10'; }

/* '' */
.uil-water:before {
  content: '\ec11'; }

/* '' */
.uil-web-grid-alt:before {
  content: '\ec12'; }

/* '' */
.uil-web-grid:before {
  content: '\ec13'; }

/* '' */
.uil-web-section-alt:before {
  content: '\ec14'; }

/* '' */
.uil-web-section:before {
  content: '\ec15'; }

/* '' */
.uil-webcam:before {
  content: '\ec16'; }

/* '' */
.uil-weight:before {
  content: '\ec17'; }

/* '' */
.uil-whatsapp:before {
  content: '\ec18'; }

/* '' */
.uil-wheel-barrow:before {
  content: '\ec19'; }

/* '' */
.uil-wheelchair-alt:before {
  content: '\ec1a'; }

/* '' */
.uil-wheelchair:before {
  content: '\ec1b'; }

/* '' */
.uil-wifi-router:before {
  content: '\ec1c'; }

/* '' */
.uil-wifi-slash:before {
  content: '\ec1d'; }

/* '' */
.uil-wifi:before {
  content: '\ec1e'; }

/* '' */
.uil-wind:before {
  content: '\ec1f'; }

/* '' */
.uil-window-grid:before {
  content: '\ec20'; }

/* '' */
.uil-window-maximize:before {
  content: '\ec21'; }

/* '' */
.uil-window-restore:before {
  content: '\ec22'; }

/* '' */
.uil-window-section:before {
  content: '\ec23'; }

/* '' */
.uil-window:before {
  content: '\ec24'; }

/* '' */
.uil-windsock:before {
  content: '\ec25'; }

/* '' */
.uil-wrap-text:before {
  content: '\ec26'; }

/* '' */
.uil-wrench:before {
  content: '\ec27'; }

/* '' */
.uil-yellow:before {
  content: '\ec28'; }

/* '' */
.uil-yen-circle:before {
  content: '\ec29'; }

/* '' */
.uil-yen:before {
  content: '\ec2a'; }

/* '' */
.uil-youtube-alt:before {
  content: '\ec2b'; }

/* '' */
.uil-youtube:before {
  content: '\ec2c'; }

/* '' */
@font-face {
  font-family: "dripicons-v2";
  src: url("../fonts/dripicons-v2.eot");
  src: url("../fonts/dripicons-v2.eot?#iefix") format("embedded-opentype"), url("../fonts/dripicons-v2.woff") format("woff"), url("../fonts/dripicons-v2.ttf") format("truetype"), url("../fonts/dripicons-v2.svg#dripicons-v2") format("svg");
  font-weight: normal;
  font-style: normal; }

[data-icon]:before {
  font-family: "dripicons-v2" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

[class^="dripicons-"]:before,
[class*=" dripicons-"]:before {
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: middle;
  display: inline-block; }

.dripicons-alarm:before {
  content: "\61"; }

.dripicons-align-center:before {
  content: "\62"; }

.dripicons-align-justify:before {
  content: "\63"; }

.dripicons-align-left:before {
  content: "\64"; }

.dripicons-align-right:before {
  content: "\65"; }

.dripicons-anchor:before {
  content: "\66"; }

.dripicons-archive:before {
  content: "\67"; }

.dripicons-arrow-down:before {
  content: "\68"; }

.dripicons-arrow-left:before {
  content: "\69"; }

.dripicons-arrow-right:before {
  content: "\6a"; }

.dripicons-arrow-thin-down:before {
  content: "\6b"; }

.dripicons-arrow-thin-left:before {
  content: "\6c"; }

.dripicons-arrow-thin-right:before {
  content: "\6d"; }

.dripicons-arrow-thin-up:before {
  content: "\6e"; }

.dripicons-arrow-up:before {
  content: "\6f"; }

.dripicons-article:before {
  content: "\70"; }

.dripicons-backspace:before {
  content: "\71"; }

.dripicons-basket:before {
  content: "\72"; }

.dripicons-basketball:before {
  content: "\73"; }

.dripicons-battery-empty:before {
  content: "\74"; }

.dripicons-battery-full:before {
  content: "\75"; }

.dripicons-battery-low:before {
  content: "\76"; }

.dripicons-battery-medium:before {
  content: "\77"; }

.dripicons-bell:before {
  content: "\78"; }

.dripicons-blog:before {
  content: "\79"; }

.dripicons-bluetooth:before {
  content: "\7a"; }

.dripicons-bold:before {
  content: "\41"; }

.dripicons-bookmark:before {
  content: "\42"; }

.dripicons-bookmarks:before {
  content: "\43"; }

.dripicons-box:before {
  content: "\44"; }

.dripicons-briefcase:before {
  content: "\45"; }

.dripicons-brightness-low:before {
  content: "\46"; }

.dripicons-brightness-max:before {
  content: "\47"; }

.dripicons-brightness-medium:before {
  content: "\48"; }

.dripicons-broadcast:before {
  content: "\49"; }

.dripicons-browser:before {
  content: "\4a"; }

.dripicons-browser-upload:before {
  content: "\4b"; }

.dripicons-brush:before {
  content: "\4c"; }

.dripicons-calendar:before {
  content: "\4d"; }

.dripicons-camcorder:before {
  content: "\4e"; }

.dripicons-camera:before {
  content: "\4f"; }

.dripicons-card:before {
  content: "\50"; }

.dripicons-cart:before {
  content: "\51"; }

.dripicons-checklist:before {
  content: "\52"; }

.dripicons-checkmark:before {
  content: "\53"; }

.dripicons-chevron-down:before {
  content: "\54"; }

.dripicons-chevron-left:before {
  content: "\55"; }

.dripicons-chevron-right:before {
  content: "\56"; }

.dripicons-chevron-up:before {
  content: "\57"; }

.dripicons-clipboard:before {
  content: "\58"; }

.dripicons-clock:before {
  content: "\59"; }

.dripicons-clockwise:before {
  content: "\5a"; }

.dripicons-cloud:before {
  content: "\30"; }

.dripicons-cloud-download:before {
  content: "\31"; }

.dripicons-cloud-upload:before {
  content: "\32"; }

.dripicons-code:before {
  content: "\33"; }

.dripicons-contract:before {
  content: "\34"; }

.dripicons-contract-2:before {
  content: "\35"; }

.dripicons-conversation:before {
  content: "\36"; }

.dripicons-copy:before {
  content: "\37"; }

.dripicons-crop:before {
  content: "\38"; }

.dripicons-cross:before {
  content: "\39"; }

.dripicons-crosshair:before {
  content: "\21"; }

.dripicons-cutlery:before {
  content: "\22"; }

.dripicons-device-desktop:before {
  content: "\23"; }

.dripicons-device-mobile:before {
  content: "\24"; }

.dripicons-device-tablet:before {
  content: "\25"; }

.dripicons-direction:before {
  content: "\26"; }

.dripicons-disc:before {
  content: "\27"; }

.dripicons-document:before {
  content: "\28"; }

.dripicons-document-delete:before {
  content: "\29"; }

.dripicons-document-edit:before {
  content: "\2a"; }

.dripicons-document-new:before {
  content: "\2b"; }

.dripicons-document-remove:before {
  content: "\2c"; }

.dripicons-dot:before {
  content: "\2d"; }

.dripicons-dots-2:before {
  content: "\2e"; }

.dripicons-dots-3:before {
  content: "\2f"; }

.dripicons-download:before {
  content: "\3a"; }

.dripicons-duplicate:before {
  content: "\3b"; }

.dripicons-enter:before {
  content: "\3c"; }

.dripicons-exit:before {
  content: "\3d"; }

.dripicons-expand:before {
  content: "\3e"; }

.dripicons-expand-2:before {
  content: "\3f"; }

.dripicons-experiment:before {
  content: "\40"; }

.dripicons-export:before {
  content: "\5b"; }

.dripicons-feed:before {
  content: "\5d"; }

.dripicons-flag:before {
  content: "\5e"; }

.dripicons-flashlight:before {
  content: "\5f"; }

.dripicons-folder:before {
  content: "\60"; }

.dripicons-folder-open:before {
  content: "\7b"; }

.dripicons-forward:before {
  content: "\7c"; }

.dripicons-gaming:before {
  content: "\7d"; }

.dripicons-gear:before {
  content: "\7e"; }

.dripicons-graduation:before {
  content: "\5c"; }

.dripicons-graph-bar:before {
  content: "\e000"; }

.dripicons-graph-line:before {
  content: "\e001"; }

.dripicons-graph-pie:before {
  content: "\e002"; }

.dripicons-headset:before {
  content: "\e003"; }

.dripicons-heart:before {
  content: "\e004"; }

.dripicons-help:before {
  content: "\e005"; }

.dripicons-home:before {
  content: "\e006"; }

.dripicons-hourglass:before {
  content: "\e007"; }

.dripicons-inbox:before {
  content: "\e008"; }

.dripicons-information:before {
  content: "\e009"; }

.dripicons-italic:before {
  content: "\e00a"; }

.dripicons-jewel:before {
  content: "\e00b"; }

.dripicons-lifting:before {
  content: "\e00c"; }

.dripicons-lightbulb:before {
  content: "\e00d"; }

.dripicons-link:before {
  content: "\e00e"; }

.dripicons-link-broken:before {
  content: "\e00f"; }

.dripicons-list:before {
  content: "\e010"; }

.dripicons-loading:before {
  content: "\e011"; }

.dripicons-location:before {
  content: "\e012"; }

.dripicons-lock:before {
  content: "\e013"; }

.dripicons-lock-open:before {
  content: "\e014"; }

.dripicons-mail:before {
  content: "\e015"; }

.dripicons-map:before {
  content: "\e016"; }

.dripicons-media-loop:before {
  content: "\e017"; }

.dripicons-media-next:before {
  content: "\e018"; }

.dripicons-media-pause:before {
  content: "\e019"; }

.dripicons-media-play:before {
  content: "\e01a"; }

.dripicons-media-previous:before {
  content: "\e01b"; }

.dripicons-media-record:before {
  content: "\e01c"; }

.dripicons-media-shuffle:before {
  content: "\e01d"; }

.dripicons-media-stop:before {
  content: "\e01e"; }

.dripicons-medical:before {
  content: "\e01f"; }

.dripicons-menu:before {
  content: "\e020"; }

.dripicons-message:before {
  content: "\e021"; }

.dripicons-meter:before {
  content: "\e022"; }

.dripicons-microphone:before {
  content: "\e023"; }

.dripicons-minus:before {
  content: "\e024"; }

.dripicons-monitor:before {
  content: "\e025"; }

.dripicons-move:before {
  content: "\e026"; }

.dripicons-music:before {
  content: "\e027"; }

.dripicons-network-1:before {
  content: "\e028"; }

.dripicons-network-2:before {
  content: "\e029"; }

.dripicons-network-3:before {
  content: "\e02a"; }

.dripicons-network-4:before {
  content: "\e02b"; }

.dripicons-network-5:before {
  content: "\e02c"; }

.dripicons-pamphlet:before {
  content: "\e02d"; }

.dripicons-paperclip:before {
  content: "\e02e"; }

.dripicons-pencil:before {
  content: "\e02f"; }

.dripicons-phone:before {
  content: "\e030"; }

.dripicons-photo:before {
  content: "\e031"; }

.dripicons-photo-group:before {
  content: "\e032"; }

.dripicons-pill:before {
  content: "\e033"; }

.dripicons-pin:before {
  content: "\e034"; }

.dripicons-plus:before {
  content: "\e035"; }

.dripicons-power:before {
  content: "\e036"; }

.dripicons-preview:before {
  content: "\e037"; }

.dripicons-print:before {
  content: "\e038"; }

.dripicons-pulse:before {
  content: "\e039"; }

.dripicons-question:before {
  content: "\e03a"; }

.dripicons-reply:before {
  content: "\e03b"; }

.dripicons-reply-all:before {
  content: "\e03c"; }

.dripicons-return:before {
  content: "\e03d"; }

.dripicons-retweet:before {
  content: "\e03e"; }

.dripicons-rocket:before {
  content: "\e03f"; }

.dripicons-scale:before {
  content: "\e040"; }

.dripicons-search:before {
  content: "\e041"; }

.dripicons-shopping-bag:before {
  content: "\e042"; }

.dripicons-skip:before {
  content: "\e043"; }

.dripicons-stack:before {
  content: "\e044"; }

.dripicons-star:before {
  content: "\e045"; }

.dripicons-stopwatch:before {
  content: "\e046"; }

.dripicons-store:before {
  content: "\e047"; }

.dripicons-suitcase:before {
  content: "\e048"; }

.dripicons-swap:before {
  content: "\e049"; }

.dripicons-tag:before {
  content: "\e04a"; }

.dripicons-tag-delete:before {
  content: "\e04b"; }

.dripicons-tags:before {
  content: "\e04c"; }

.dripicons-thumbs-down:before {
  content: "\e04d"; }

.dripicons-thumbs-up:before {
  content: "\e04e"; }

.dripicons-ticket:before {
  content: "\e04f"; }

.dripicons-time-reverse:before {
  content: "\e050"; }

.dripicons-to-do:before {
  content: "\e051"; }

.dripicons-toggles:before {
  content: "\e052"; }

.dripicons-trash:before {
  content: "\e053"; }

.dripicons-trophy:before {
  content: "\e054"; }

.dripicons-upload:before {
  content: "\e055"; }

.dripicons-user:before {
  content: "\e056"; }

.dripicons-user-group:before {
  content: "\e057"; }

.dripicons-user-id:before {
  content: "\e058"; }

.dripicons-vibrate:before {
  content: "\e059"; }

.dripicons-view-apps:before {
  content: "\e05a"; }

.dripicons-view-list:before {
  content: "\e05b"; }

.dripicons-view-list-large:before {
  content: "\e05c"; }

.dripicons-view-thumb:before {
  content: "\e05d"; }

.dripicons-volume-full:before {
  content: "\e05e"; }

.dripicons-volume-low:before {
  content: "\e05f"; }

.dripicons-volume-medium:before {
  content: "\e060"; }

.dripicons-volume-off:before {
  content: "\e061"; }

.dripicons-wallet:before {
  content: "\e062"; }

.dripicons-warning:before {
  content: "\e063"; }

.dripicons-web:before {
  content: "\e064"; }

.dripicons-weight:before {
  content: "\e065"; }

.dripicons-wifi:before {
  content: "\e066"; }

.dripicons-wrong:before {
  content: "\e067"; }

.dripicons-zoom-in:before {
  content: "\e068"; }

.dripicons-zoom-out:before {
  content: "\e069"; }

/* MaterialDesignIcons.com */
@font-face {
  font-family: "Material Design Icons";
  src: url("../fonts/materialdesignicons-webfont.eot?v=5.8.55");
  src: url("../fonts/materialdesignicons-webfont.eot?#iefix&v=5.8.55") format("embedded-opentype"), url("../fonts/materialdesignicons-webfont.woff2?v=5.8.55") format("woff2"), url("../fonts/materialdesignicons-webfont.woff?v=5.8.55") format("woff"), url("../fonts/materialdesignicons-webfont.ttf?v=5.8.55") format("truetype");
  font-weight: normal;
  font-style: normal; }

.mdi:before,
.mdi-set {
  display: inline-block;
  font: normal normal normal 24px/1 "Material Design Icons";
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.mdi-ab-testing::before {
  content: "\F01C9"; }

.mdi-abjad-arabic::before {
  content: "\F1328"; }

.mdi-abjad-hebrew::before {
  content: "\F1329"; }

.mdi-abugida-devanagari::before {
  content: "\F132A"; }

.mdi-abugida-thai::before {
  content: "\F132B"; }

.mdi-access-point::before {
  content: "\F0003"; }

.mdi-access-point-check::before {
  content: "\F1538"; }

.mdi-access-point-minus::before {
  content: "\F1539"; }

.mdi-access-point-network::before {
  content: "\F0002"; }

.mdi-access-point-network-off::before {
  content: "\F0BE1"; }

.mdi-access-point-off::before {
  content: "\F1511"; }

.mdi-access-point-plus::before {
  content: "\F153A"; }

.mdi-access-point-remove::before {
  content: "\F153B"; }

.mdi-account::before {
  content: "\F0004"; }

.mdi-account-alert::before {
  content: "\F0005"; }

.mdi-account-alert-outline::before {
  content: "\F0B50"; }

.mdi-account-arrow-left::before {
  content: "\F0B51"; }

.mdi-account-arrow-left-outline::before {
  content: "\F0B52"; }

.mdi-account-arrow-right::before {
  content: "\F0B53"; }

.mdi-account-arrow-right-outline::before {
  content: "\F0B54"; }

.mdi-account-box::before {
  content: "\F0006"; }

.mdi-account-box-multiple::before {
  content: "\F0934"; }

.mdi-account-box-multiple-outline::before {
  content: "\F100A"; }

.mdi-account-box-outline::before {
  content: "\F0007"; }

.mdi-account-cancel::before {
  content: "\F12DF"; }

.mdi-account-cancel-outline::before {
  content: "\F12E0"; }

.mdi-account-cash::before {
  content: "\F1097"; }

.mdi-account-cash-outline::before {
  content: "\F1098"; }

.mdi-account-check::before {
  content: "\F0008"; }

.mdi-account-check-outline::before {
  content: "\F0BE2"; }

.mdi-account-child::before {
  content: "\F0A89"; }

.mdi-account-child-circle::before {
  content: "\F0A8A"; }

.mdi-account-child-outline::before {
  content: "\F10C8"; }

.mdi-account-circle::before {
  content: "\F0009"; }

.mdi-account-circle-outline::before {
  content: "\F0B55"; }

.mdi-account-clock::before {
  content: "\F0B56"; }

.mdi-account-clock-outline::before {
  content: "\F0B57"; }

.mdi-account-cog::before {
  content: "\F1370"; }

.mdi-account-cog-outline::before {
  content: "\F1371"; }

.mdi-account-convert::before {
  content: "\F000A"; }

.mdi-account-convert-outline::before {
  content: "\F1301"; }

.mdi-account-cowboy-hat::before {
  content: "\F0E9B"; }

.mdi-account-details::before {
  content: "\F0631"; }

.mdi-account-details-outline::before {
  content: "\F1372"; }

.mdi-account-edit::before {
  content: "\F06BC"; }

.mdi-account-edit-outline::before {
  content: "\F0FFB"; }

.mdi-account-group::before {
  content: "\F0849"; }

.mdi-account-group-outline::before {
  content: "\F0B58"; }

.mdi-account-hard-hat::before {
  content: "\F05B5"; }

.mdi-account-heart::before {
  content: "\F0899"; }

.mdi-account-heart-outline::before {
  content: "\F0BE3"; }

.mdi-account-key::before {
  content: "\F000B"; }

.mdi-account-key-outline::before {
  content: "\F0BE4"; }

.mdi-account-lock::before {
  content: "\F115E"; }

.mdi-account-lock-outline::before {
  content: "\F115F"; }

.mdi-account-minus::before {
  content: "\F000D"; }

.mdi-account-minus-outline::before {
  content: "\F0AEC"; }

.mdi-account-multiple::before {
  content: "\F000E"; }

.mdi-account-multiple-check::before {
  content: "\F08C5"; }

.mdi-account-multiple-check-outline::before {
  content: "\F11FE"; }

.mdi-account-multiple-minus::before {
  content: "\F05D3"; }

.mdi-account-multiple-minus-outline::before {
  content: "\F0BE5"; }

.mdi-account-multiple-outline::before {
  content: "\F000F"; }

.mdi-account-multiple-plus::before {
  content: "\F0010"; }

.mdi-account-multiple-plus-outline::before {
  content: "\F0800"; }

.mdi-account-multiple-remove::before {
  content: "\F120A"; }

.mdi-account-multiple-remove-outline::before {
  content: "\F120B"; }

.mdi-account-music::before {
  content: "\F0803"; }

.mdi-account-music-outline::before {
  content: "\F0CE9"; }

.mdi-account-network::before {
  content: "\F0011"; }

.mdi-account-network-outline::before {
  content: "\F0BE6"; }

.mdi-account-off::before {
  content: "\F0012"; }

.mdi-account-off-outline::before {
  content: "\F0BE7"; }

.mdi-account-outline::before {
  content: "\F0013"; }

.mdi-account-plus::before {
  content: "\F0014"; }

.mdi-account-plus-outline::before {
  content: "\F0801"; }

.mdi-account-question::before {
  content: "\F0B59"; }

.mdi-account-question-outline::before {
  content: "\F0B5A"; }

.mdi-account-reactivate::before {
  content: "\F152B"; }

.mdi-account-reactivate-outline::before {
  content: "\F152C"; }

.mdi-account-remove::before {
  content: "\F0015"; }

.mdi-account-remove-outline::before {
  content: "\F0AED"; }

.mdi-account-search::before {
  content: "\F0016"; }

.mdi-account-search-outline::before {
  content: "\F0935"; }

.mdi-account-settings::before {
  content: "\F0630"; }

.mdi-account-settings-outline::before {
  content: "\F10C9"; }

.mdi-account-star::before {
  content: "\F0017"; }

.mdi-account-star-outline::before {
  content: "\F0BE8"; }

.mdi-account-supervisor::before {
  content: "\F0A8B"; }

.mdi-account-supervisor-circle::before {
  content: "\F0A8C"; }

.mdi-account-supervisor-circle-outline::before {
  content: "\F14EC"; }

.mdi-account-supervisor-outline::before {
  content: "\F112D"; }

.mdi-account-switch::before {
  content: "\F0019"; }

.mdi-account-switch-outline::before {
  content: "\F04CB"; }

.mdi-account-tie::before {
  content: "\F0CE3"; }

.mdi-account-tie-outline::before {
  content: "\F10CA"; }

.mdi-account-tie-voice::before {
  content: "\F1308"; }

.mdi-account-tie-voice-off::before {
  content: "\F130A"; }

.mdi-account-tie-voice-off-outline::before {
  content: "\F130B"; }

.mdi-account-tie-voice-outline::before {
  content: "\F1309"; }

.mdi-account-voice::before {
  content: "\F05CB"; }

.mdi-adjust::before {
  content: "\F001A"; }

.mdi-adobe::before {
  content: "\F0936"; }

.mdi-adobe-acrobat::before {
  content: "\F0F9D"; }

.mdi-air-conditioner::before {
  content: "\F001B"; }

.mdi-air-filter::before {
  content: "\F0D43"; }

.mdi-air-horn::before {
  content: "\F0DAC"; }

.mdi-air-humidifier::before {
  content: "\F1099"; }

.mdi-air-humidifier-off::before {
  content: "\F1466"; }

.mdi-air-purifier::before {
  content: "\F0D44"; }

.mdi-airbag::before {
  content: "\F0BE9"; }

.mdi-airballoon::before {
  content: "\F001C"; }

.mdi-airballoon-outline::before {
  content: "\F100B"; }

.mdi-airplane::before {
  content: "\F001D"; }

.mdi-airplane-landing::before {
  content: "\F05D4"; }

.mdi-airplane-off::before {
  content: "\F001E"; }

.mdi-airplane-takeoff::before {
  content: "\F05D5"; }

.mdi-airport::before {
  content: "\F084B"; }

.mdi-alarm::before {
  content: "\F0020"; }

.mdi-alarm-bell::before {
  content: "\F078E"; }

.mdi-alarm-check::before {
  content: "\F0021"; }

.mdi-alarm-light::before {
  content: "\F078F"; }

.mdi-alarm-light-outline::before {
  content: "\F0BEA"; }

.mdi-alarm-multiple::before {
  content: "\F0022"; }

.mdi-alarm-note::before {
  content: "\F0E71"; }

.mdi-alarm-note-off::before {
  content: "\F0E72"; }

.mdi-alarm-off::before {
  content: "\F0023"; }

.mdi-alarm-panel::before {
  content: "\F15C4"; }

.mdi-alarm-panel-outline::before {
  content: "\F15C5"; }

.mdi-alarm-plus::before {
  content: "\F0024"; }

.mdi-alarm-snooze::before {
  content: "\F068E"; }

.mdi-album::before {
  content: "\F0025"; }

.mdi-alert::before {
  content: "\F0026"; }

.mdi-alert-box::before {
  content: "\F0027"; }

.mdi-alert-box-outline::before {
  content: "\F0CE4"; }

.mdi-alert-circle::before {
  content: "\F0028"; }

.mdi-alert-circle-check::before {
  content: "\F11ED"; }

.mdi-alert-circle-check-outline::before {
  content: "\F11EE"; }

.mdi-alert-circle-outline::before {
  content: "\F05D6"; }

.mdi-alert-decagram::before {
  content: "\F06BD"; }

.mdi-alert-decagram-outline::before {
  content: "\F0CE5"; }

.mdi-alert-minus::before {
  content: "\F14BB"; }

.mdi-alert-minus-outline::before {
  content: "\F14BE"; }

.mdi-alert-octagon::before {
  content: "\F0029"; }

.mdi-alert-octagon-outline::before {
  content: "\F0CE6"; }

.mdi-alert-octagram::before {
  content: "\F0767"; }

.mdi-alert-octagram-outline::before {
  content: "\F0CE7"; }

.mdi-alert-outline::before {
  content: "\F002A"; }

.mdi-alert-plus::before {
  content: "\F14BA"; }

.mdi-alert-plus-outline::before {
  content: "\F14BD"; }

.mdi-alert-remove::before {
  content: "\F14BC"; }

.mdi-alert-remove-outline::before {
  content: "\F14BF"; }

.mdi-alert-rhombus::before {
  content: "\F11CE"; }

.mdi-alert-rhombus-outline::before {
  content: "\F11CF"; }

.mdi-alien::before {
  content: "\F089A"; }

.mdi-alien-outline::before {
  content: "\F10CB"; }

.mdi-align-horizontal-center::before {
  content: "\F11C3"; }

.mdi-align-horizontal-left::before {
  content: "\F11C2"; }

.mdi-align-horizontal-right::before {
  content: "\F11C4"; }

.mdi-align-vertical-bottom::before {
  content: "\F11C5"; }

.mdi-align-vertical-center::before {
  content: "\F11C6"; }

.mdi-align-vertical-top::before {
  content: "\F11C7"; }

.mdi-all-inclusive::before {
  content: "\F06BE"; }

.mdi-allergy::before {
  content: "\F1258"; }

.mdi-alpha::before {
  content: "\F002B"; }

.mdi-alpha-a::before {
  content: "\F0AEE"; }

.mdi-alpha-a-box::before {
  content: "\F0B08"; }

.mdi-alpha-a-box-outline::before {
  content: "\F0BEB"; }

.mdi-alpha-a-circle::before {
  content: "\F0BEC"; }

.mdi-alpha-a-circle-outline::before {
  content: "\F0BED"; }

.mdi-alpha-b::before {
  content: "\F0AEF"; }

.mdi-alpha-b-box::before {
  content: "\F0B09"; }

.mdi-alpha-b-box-outline::before {
  content: "\F0BEE"; }

.mdi-alpha-b-circle::before {
  content: "\F0BEF"; }

.mdi-alpha-b-circle-outline::before {
  content: "\F0BF0"; }

.mdi-alpha-c::before {
  content: "\F0AF0"; }

.mdi-alpha-c-box::before {
  content: "\F0B0A"; }

.mdi-alpha-c-box-outline::before {
  content: "\F0BF1"; }

.mdi-alpha-c-circle::before {
  content: "\F0BF2"; }

.mdi-alpha-c-circle-outline::before {
  content: "\F0BF3"; }

.mdi-alpha-d::before {
  content: "\F0AF1"; }

.mdi-alpha-d-box::before {
  content: "\F0B0B"; }

.mdi-alpha-d-box-outline::before {
  content: "\F0BF4"; }

.mdi-alpha-d-circle::before {
  content: "\F0BF5"; }

.mdi-alpha-d-circle-outline::before {
  content: "\F0BF6"; }

.mdi-alpha-e::before {
  content: "\F0AF2"; }

.mdi-alpha-e-box::before {
  content: "\F0B0C"; }

.mdi-alpha-e-box-outline::before {
  content: "\F0BF7"; }

.mdi-alpha-e-circle::before {
  content: "\F0BF8"; }

.mdi-alpha-e-circle-outline::before {
  content: "\F0BF9"; }

.mdi-alpha-f::before {
  content: "\F0AF3"; }

.mdi-alpha-f-box::before {
  content: "\F0B0D"; }

.mdi-alpha-f-box-outline::before {
  content: "\F0BFA"; }

.mdi-alpha-f-circle::before {
  content: "\F0BFB"; }

.mdi-alpha-f-circle-outline::before {
  content: "\F0BFC"; }

.mdi-alpha-g::before {
  content: "\F0AF4"; }

.mdi-alpha-g-box::before {
  content: "\F0B0E"; }

.mdi-alpha-g-box-outline::before {
  content: "\F0BFD"; }

.mdi-alpha-g-circle::before {
  content: "\F0BFE"; }

.mdi-alpha-g-circle-outline::before {
  content: "\F0BFF"; }

.mdi-alpha-h::before {
  content: "\F0AF5"; }

.mdi-alpha-h-box::before {
  content: "\F0B0F"; }

.mdi-alpha-h-box-outline::before {
  content: "\F0C00"; }

.mdi-alpha-h-circle::before {
  content: "\F0C01"; }

.mdi-alpha-h-circle-outline::before {
  content: "\F0C02"; }

.mdi-alpha-i::before {
  content: "\F0AF6"; }

.mdi-alpha-i-box::before {
  content: "\F0B10"; }

.mdi-alpha-i-box-outline::before {
  content: "\F0C03"; }

.mdi-alpha-i-circle::before {
  content: "\F0C04"; }

.mdi-alpha-i-circle-outline::before {
  content: "\F0C05"; }

.mdi-alpha-j::before {
  content: "\F0AF7"; }

.mdi-alpha-j-box::before {
  content: "\F0B11"; }

.mdi-alpha-j-box-outline::before {
  content: "\F0C06"; }

.mdi-alpha-j-circle::before {
  content: "\F0C07"; }

.mdi-alpha-j-circle-outline::before {
  content: "\F0C08"; }

.mdi-alpha-k::before {
  content: "\F0AF8"; }

.mdi-alpha-k-box::before {
  content: "\F0B12"; }

.mdi-alpha-k-box-outline::before {
  content: "\F0C09"; }

.mdi-alpha-k-circle::before {
  content: "\F0C0A"; }

.mdi-alpha-k-circle-outline::before {
  content: "\F0C0B"; }

.mdi-alpha-l::before {
  content: "\F0AF9"; }

.mdi-alpha-l-box::before {
  content: "\F0B13"; }

.mdi-alpha-l-box-outline::before {
  content: "\F0C0C"; }

.mdi-alpha-l-circle::before {
  content: "\F0C0D"; }

.mdi-alpha-l-circle-outline::before {
  content: "\F0C0E"; }

.mdi-alpha-m::before {
  content: "\F0AFA"; }

.mdi-alpha-m-box::before {
  content: "\F0B14"; }

.mdi-alpha-m-box-outline::before {
  content: "\F0C0F"; }

.mdi-alpha-m-circle::before {
  content: "\F0C10"; }

.mdi-alpha-m-circle-outline::before {
  content: "\F0C11"; }

.mdi-alpha-n::before {
  content: "\F0AFB"; }

.mdi-alpha-n-box::before {
  content: "\F0B15"; }

.mdi-alpha-n-box-outline::before {
  content: "\F0C12"; }

.mdi-alpha-n-circle::before {
  content: "\F0C13"; }

.mdi-alpha-n-circle-outline::before {
  content: "\F0C14"; }

.mdi-alpha-o::before {
  content: "\F0AFC"; }

.mdi-alpha-o-box::before {
  content: "\F0B16"; }

.mdi-alpha-o-box-outline::before {
  content: "\F0C15"; }

.mdi-alpha-o-circle::before {
  content: "\F0C16"; }

.mdi-alpha-o-circle-outline::before {
  content: "\F0C17"; }

.mdi-alpha-p::before {
  content: "\F0AFD"; }

.mdi-alpha-p-box::before {
  content: "\F0B17"; }

.mdi-alpha-p-box-outline::before {
  content: "\F0C18"; }

.mdi-alpha-p-circle::before {
  content: "\F0C19"; }

.mdi-alpha-p-circle-outline::before {
  content: "\F0C1A"; }

.mdi-alpha-q::before {
  content: "\F0AFE"; }

.mdi-alpha-q-box::before {
  content: "\F0B18"; }

.mdi-alpha-q-box-outline::before {
  content: "\F0C1B"; }

.mdi-alpha-q-circle::before {
  content: "\F0C1C"; }

.mdi-alpha-q-circle-outline::before {
  content: "\F0C1D"; }

.mdi-alpha-r::before {
  content: "\F0AFF"; }

.mdi-alpha-r-box::before {
  content: "\F0B19"; }

.mdi-alpha-r-box-outline::before {
  content: "\F0C1E"; }

.mdi-alpha-r-circle::before {
  content: "\F0C1F"; }

.mdi-alpha-r-circle-outline::before {
  content: "\F0C20"; }

.mdi-alpha-s::before {
  content: "\F0B00"; }

.mdi-alpha-s-box::before {
  content: "\F0B1A"; }

.mdi-alpha-s-box-outline::before {
  content: "\F0C21"; }

.mdi-alpha-s-circle::before {
  content: "\F0C22"; }

.mdi-alpha-s-circle-outline::before {
  content: "\F0C23"; }

.mdi-alpha-t::before {
  content: "\F0B01"; }

.mdi-alpha-t-box::before {
  content: "\F0B1B"; }

.mdi-alpha-t-box-outline::before {
  content: "\F0C24"; }

.mdi-alpha-t-circle::before {
  content: "\F0C25"; }

.mdi-alpha-t-circle-outline::before {
  content: "\F0C26"; }

.mdi-alpha-u::before {
  content: "\F0B02"; }

.mdi-alpha-u-box::before {
  content: "\F0B1C"; }

.mdi-alpha-u-box-outline::before {
  content: "\F0C27"; }

.mdi-alpha-u-circle::before {
  content: "\F0C28"; }

.mdi-alpha-u-circle-outline::before {
  content: "\F0C29"; }

.mdi-alpha-v::before {
  content: "\F0B03"; }

.mdi-alpha-v-box::before {
  content: "\F0B1D"; }

.mdi-alpha-v-box-outline::before {
  content: "\F0C2A"; }

.mdi-alpha-v-circle::before {
  content: "\F0C2B"; }

.mdi-alpha-v-circle-outline::before {
  content: "\F0C2C"; }

.mdi-alpha-w::before {
  content: "\F0B04"; }

.mdi-alpha-w-box::before {
  content: "\F0B1E"; }

.mdi-alpha-w-box-outline::before {
  content: "\F0C2D"; }

.mdi-alpha-w-circle::before {
  content: "\F0C2E"; }

.mdi-alpha-w-circle-outline::before {
  content: "\F0C2F"; }

.mdi-alpha-x::before {
  content: "\F0B05"; }

.mdi-alpha-x-box::before {
  content: "\F0B1F"; }

.mdi-alpha-x-box-outline::before {
  content: "\F0C30"; }

.mdi-alpha-x-circle::before {
  content: "\F0C31"; }

.mdi-alpha-x-circle-outline::before {
  content: "\F0C32"; }

.mdi-alpha-y::before {
  content: "\F0B06"; }

.mdi-alpha-y-box::before {
  content: "\F0B20"; }

.mdi-alpha-y-box-outline::before {
  content: "\F0C33"; }

.mdi-alpha-y-circle::before {
  content: "\F0C34"; }

.mdi-alpha-y-circle-outline::before {
  content: "\F0C35"; }

.mdi-alpha-z::before {
  content: "\F0B07"; }

.mdi-alpha-z-box::before {
  content: "\F0B21"; }

.mdi-alpha-z-box-outline::before {
  content: "\F0C36"; }

.mdi-alpha-z-circle::before {
  content: "\F0C37"; }

.mdi-alpha-z-circle-outline::before {
  content: "\F0C38"; }

.mdi-alphabet-aurebesh::before {
  content: "\F132C"; }

.mdi-alphabet-cyrillic::before {
  content: "\F132D"; }

.mdi-alphabet-greek::before {
  content: "\F132E"; }

.mdi-alphabet-latin::before {
  content: "\F132F"; }

.mdi-alphabet-piqad::before {
  content: "\F1330"; }

.mdi-alphabet-tengwar::before {
  content: "\F1337"; }

.mdi-alphabetical::before {
  content: "\F002C"; }

.mdi-alphabetical-off::before {
  content: "\F100C"; }

.mdi-alphabetical-variant::before {
  content: "\F100D"; }

.mdi-alphabetical-variant-off::before {
  content: "\F100E"; }

.mdi-altimeter::before {
  content: "\F05D7"; }

.mdi-amazon::before {
  content: "\F002D"; }

.mdi-amazon-alexa::before {
  content: "\F08C6"; }

.mdi-ambulance::before {
  content: "\F002F"; }

.mdi-ammunition::before {
  content: "\F0CE8"; }

.mdi-ampersand::before {
  content: "\F0A8D"; }

.mdi-amplifier::before {
  content: "\F0030"; }

.mdi-amplifier-off::before {
  content: "\F11B5"; }

.mdi-anchor::before {
  content: "\F0031"; }

.mdi-android::before {
  content: "\F0032"; }

.mdi-android-auto::before {
  content: "\F0A8E"; }

.mdi-android-debug-bridge::before {
  content: "\F0033"; }

.mdi-android-messages::before {
  content: "\F0D45"; }

.mdi-android-studio::before {
  content: "\F0034"; }

.mdi-angle-acute::before {
  content: "\F0937"; }

.mdi-angle-obtuse::before {
  content: "\F0938"; }

.mdi-angle-right::before {
  content: "\F0939"; }

.mdi-angular::before {
  content: "\F06B2"; }

.mdi-angularjs::before {
  content: "\F06BF"; }

.mdi-animation::before {
  content: "\F05D8"; }

.mdi-animation-outline::before {
  content: "\F0A8F"; }

.mdi-animation-play::before {
  content: "\F093A"; }

.mdi-animation-play-outline::before {
  content: "\F0A90"; }

.mdi-ansible::before {
  content: "\F109A"; }

.mdi-antenna::before {
  content: "\F1119"; }

.mdi-anvil::before {
  content: "\F089B"; }

.mdi-apache-kafka::before {
  content: "\F100F"; }

.mdi-api::before {
  content: "\F109B"; }

.mdi-api-off::before {
  content: "\F1257"; }

.mdi-apple::before {
  content: "\F0035"; }

.mdi-apple-airplay::before {
  content: "\F001F"; }

.mdi-apple-finder::before {
  content: "\F0036"; }

.mdi-apple-icloud::before {
  content: "\F0038"; }

.mdi-apple-ios::before {
  content: "\F0037"; }

.mdi-apple-keyboard-caps::before {
  content: "\F0632"; }

.mdi-apple-keyboard-command::before {
  content: "\F0633"; }

.mdi-apple-keyboard-control::before {
  content: "\F0634"; }

.mdi-apple-keyboard-option::before {
  content: "\F0635"; }

.mdi-apple-keyboard-shift::before {
  content: "\F0636"; }

.mdi-apple-safari::before {
  content: "\F0039"; }

.mdi-application::before {
  content: "\F0614"; }

.mdi-application-cog::before {
  content: "\F1577"; }

.mdi-application-export::before {
  content: "\F0DAD"; }

.mdi-application-import::before {
  content: "\F0DAE"; }

.mdi-application-settings::before {
  content: "\F1555"; }

.mdi-approximately-equal::before {
  content: "\F0F9E"; }

.mdi-approximately-equal-box::before {
  content: "\F0F9F"; }

.mdi-apps::before {
  content: "\F003B"; }

.mdi-apps-box::before {
  content: "\F0D46"; }

.mdi-arch::before {
  content: "\F08C7"; }

.mdi-archive::before {
  content: "\F003C"; }

.mdi-archive-alert::before {
  content: "\F14FD"; }

.mdi-archive-alert-outline::before {
  content: "\F14FE"; }

.mdi-archive-arrow-down::before {
  content: "\F1259"; }

.mdi-archive-arrow-down-outline::before {
  content: "\F125A"; }

.mdi-archive-arrow-up::before {
  content: "\F125B"; }

.mdi-archive-arrow-up-outline::before {
  content: "\F125C"; }

.mdi-archive-outline::before {
  content: "\F120E"; }

.mdi-arm-flex::before {
  content: "\F0FD7"; }

.mdi-arm-flex-outline::before {
  content: "\F0FD6"; }

.mdi-arrange-bring-forward::before {
  content: "\F003D"; }

.mdi-arrange-bring-to-front::before {
  content: "\F003E"; }

.mdi-arrange-send-backward::before {
  content: "\F003F"; }

.mdi-arrange-send-to-back::before {
  content: "\F0040"; }

.mdi-arrow-all::before {
  content: "\F0041"; }

.mdi-arrow-bottom-left::before {
  content: "\F0042"; }

.mdi-arrow-bottom-left-bold-outline::before {
  content: "\F09B7"; }

.mdi-arrow-bottom-left-thick::before {
  content: "\F09B8"; }

.mdi-arrow-bottom-left-thin-circle-outline::before {
  content: "\F1596"; }

.mdi-arrow-bottom-right::before {
  content: "\F0043"; }

.mdi-arrow-bottom-right-bold-outline::before {
  content: "\F09B9"; }

.mdi-arrow-bottom-right-thick::before {
  content: "\F09BA"; }

.mdi-arrow-bottom-right-thin-circle-outline::before {
  content: "\F1595"; }

.mdi-arrow-collapse::before {
  content: "\F0615"; }

.mdi-arrow-collapse-all::before {
  content: "\F0044"; }

.mdi-arrow-collapse-down::before {
  content: "\F0792"; }

.mdi-arrow-collapse-horizontal::before {
  content: "\F084C"; }

.mdi-arrow-collapse-left::before {
  content: "\F0793"; }

.mdi-arrow-collapse-right::before {
  content: "\F0794"; }

.mdi-arrow-collapse-up::before {
  content: "\F0795"; }

.mdi-arrow-collapse-vertical::before {
  content: "\F084D"; }

.mdi-arrow-decision::before {
  content: "\F09BB"; }

.mdi-arrow-decision-auto::before {
  content: "\F09BC"; }

.mdi-arrow-decision-auto-outline::before {
  content: "\F09BD"; }

.mdi-arrow-decision-outline::before {
  content: "\F09BE"; }

.mdi-arrow-down::before {
  content: "\F0045"; }

.mdi-arrow-down-bold::before {
  content: "\F072E"; }

.mdi-arrow-down-bold-box::before {
  content: "\F072F"; }

.mdi-arrow-down-bold-box-outline::before {
  content: "\F0730"; }

.mdi-arrow-down-bold-circle::before {
  content: "\F0047"; }

.mdi-arrow-down-bold-circle-outline::before {
  content: "\F0048"; }

.mdi-arrow-down-bold-hexagon-outline::before {
  content: "\F0049"; }

.mdi-arrow-down-bold-outline::before {
  content: "\F09BF"; }

.mdi-arrow-down-box::before {
  content: "\F06C0"; }

.mdi-arrow-down-circle::before {
  content: "\F0CDB"; }

.mdi-arrow-down-circle-outline::before {
  content: "\F0CDC"; }

.mdi-arrow-down-drop-circle::before {
  content: "\F004A"; }

.mdi-arrow-down-drop-circle-outline::before {
  content: "\F004B"; }

.mdi-arrow-down-thick::before {
  content: "\F0046"; }

.mdi-arrow-down-thin-circle-outline::before {
  content: "\F1599"; }

.mdi-arrow-expand::before {
  content: "\F0616"; }

.mdi-arrow-expand-all::before {
  content: "\F004C"; }

.mdi-arrow-expand-down::before {
  content: "\F0796"; }

.mdi-arrow-expand-horizontal::before {
  content: "\F084E"; }

.mdi-arrow-expand-left::before {
  content: "\F0797"; }

.mdi-arrow-expand-right::before {
  content: "\F0798"; }

.mdi-arrow-expand-up::before {
  content: "\F0799"; }

.mdi-arrow-expand-vertical::before {
  content: "\F084F"; }

.mdi-arrow-horizontal-lock::before {
  content: "\F115B"; }

.mdi-arrow-left::before {
  content: "\F004D"; }

.mdi-arrow-left-bold::before {
  content: "\F0731"; }

.mdi-arrow-left-bold-box::before {
  content: "\F0732"; }

.mdi-arrow-left-bold-box-outline::before {
  content: "\F0733"; }

.mdi-arrow-left-bold-circle::before {
  content: "\F004F"; }

.mdi-arrow-left-bold-circle-outline::before {
  content: "\F0050"; }

.mdi-arrow-left-bold-hexagon-outline::before {
  content: "\F0051"; }

.mdi-arrow-left-bold-outline::before {
  content: "\F09C0"; }

.mdi-arrow-left-box::before {
  content: "\F06C1"; }

.mdi-arrow-left-circle::before {
  content: "\F0CDD"; }

.mdi-arrow-left-circle-outline::before {
  content: "\F0CDE"; }

.mdi-arrow-left-drop-circle::before {
  content: "\F0052"; }

.mdi-arrow-left-drop-circle-outline::before {
  content: "\F0053"; }

.mdi-arrow-left-right::before {
  content: "\F0E73"; }

.mdi-arrow-left-right-bold::before {
  content: "\F0E74"; }

.mdi-arrow-left-right-bold-outline::before {
  content: "\F09C1"; }

.mdi-arrow-left-thick::before {
  content: "\F004E"; }

.mdi-arrow-left-thin-circle-outline::before {
  content: "\F159A"; }

.mdi-arrow-right::before {
  content: "\F0054"; }

.mdi-arrow-right-bold::before {
  content: "\F0734"; }

.mdi-arrow-right-bold-box::before {
  content: "\F0735"; }

.mdi-arrow-right-bold-box-outline::before {
  content: "\F0736"; }

.mdi-arrow-right-bold-circle::before {
  content: "\F0056"; }

.mdi-arrow-right-bold-circle-outline::before {
  content: "\F0057"; }

.mdi-arrow-right-bold-hexagon-outline::before {
  content: "\F0058"; }

.mdi-arrow-right-bold-outline::before {
  content: "\F09C2"; }

.mdi-arrow-right-box::before {
  content: "\F06C2"; }

.mdi-arrow-right-circle::before {
  content: "\F0CDF"; }

.mdi-arrow-right-circle-outline::before {
  content: "\F0CE0"; }

.mdi-arrow-right-drop-circle::before {
  content: "\F0059"; }

.mdi-arrow-right-drop-circle-outline::before {
  content: "\F005A"; }

.mdi-arrow-right-thick::before {
  content: "\F0055"; }

.mdi-arrow-right-thin-circle-outline::before {
  content: "\F1598"; }

.mdi-arrow-split-horizontal::before {
  content: "\F093B"; }

.mdi-arrow-split-vertical::before {
  content: "\F093C"; }

.mdi-arrow-top-left::before {
  content: "\F005B"; }

.mdi-arrow-top-left-bold-outline::before {
  content: "\F09C3"; }

.mdi-arrow-top-left-bottom-right::before {
  content: "\F0E75"; }

.mdi-arrow-top-left-bottom-right-bold::before {
  content: "\F0E76"; }

.mdi-arrow-top-left-thick::before {
  content: "\F09C4"; }

.mdi-arrow-top-left-thin-circle-outline::before {
  content: "\F1593"; }

.mdi-arrow-top-right::before {
  content: "\F005C"; }

.mdi-arrow-top-right-bold-outline::before {
  content: "\F09C5"; }

.mdi-arrow-top-right-bottom-left::before {
  content: "\F0E77"; }

.mdi-arrow-top-right-bottom-left-bold::before {
  content: "\F0E78"; }

.mdi-arrow-top-right-thick::before {
  content: "\F09C6"; }

.mdi-arrow-top-right-thin-circle-outline::before {
  content: "\F1594"; }

.mdi-arrow-up::before {
  content: "\F005D"; }

.mdi-arrow-up-bold::before {
  content: "\F0737"; }

.mdi-arrow-up-bold-box::before {
  content: "\F0738"; }

.mdi-arrow-up-bold-box-outline::before {
  content: "\F0739"; }

.mdi-arrow-up-bold-circle::before {
  content: "\F005F"; }

.mdi-arrow-up-bold-circle-outline::before {
  content: "\F0060"; }

.mdi-arrow-up-bold-hexagon-outline::before {
  content: "\F0061"; }

.mdi-arrow-up-bold-outline::before {
  content: "\F09C7"; }

.mdi-arrow-up-box::before {
  content: "\F06C3"; }

.mdi-arrow-up-circle::before {
  content: "\F0CE1"; }

.mdi-arrow-up-circle-outline::before {
  content: "\F0CE2"; }

.mdi-arrow-up-down::before {
  content: "\F0E79"; }

.mdi-arrow-up-down-bold::before {
  content: "\F0E7A"; }

.mdi-arrow-up-down-bold-outline::before {
  content: "\F09C8"; }

.mdi-arrow-up-drop-circle::before {
  content: "\F0062"; }

.mdi-arrow-up-drop-circle-outline::before {
  content: "\F0063"; }

.mdi-arrow-up-thick::before {
  content: "\F005E"; }

.mdi-arrow-up-thin-circle-outline::before {
  content: "\F1597"; }

.mdi-arrow-vertical-lock::before {
  content: "\F115C"; }

.mdi-artstation::before {
  content: "\F0B5B"; }

.mdi-aspect-ratio::before {
  content: "\F0A24"; }

.mdi-assistant::before {
  content: "\F0064"; }

.mdi-asterisk::before {
  content: "\F06C4"; }

.mdi-at::before {
  content: "\F0065"; }

.mdi-atlassian::before {
  content: "\F0804"; }

.mdi-atm::before {
  content: "\F0D47"; }

.mdi-atom::before {
  content: "\F0768"; }

.mdi-atom-variant::before {
  content: "\F0E7B"; }

.mdi-attachment::before {
  content: "\F0066"; }

.mdi-audio-video::before {
  content: "\F093D"; }

.mdi-audio-video-off::before {
  content: "\F11B6"; }

.mdi-augmented-reality::before {
  content: "\F0850"; }

.mdi-auto-download::before {
  content: "\F137E"; }

.mdi-auto-fix::before {
  content: "\F0068"; }

.mdi-auto-upload::before {
  content: "\F0069"; }

.mdi-autorenew::before {
  content: "\F006A"; }

.mdi-av-timer::before {
  content: "\F006B"; }

.mdi-aws::before {
  content: "\F0E0F"; }

.mdi-axe::before {
  content: "\F08C8"; }

.mdi-axis::before {
  content: "\F0D48"; }

.mdi-axis-arrow::before {
  content: "\F0D49"; }

.mdi-axis-arrow-info::before {
  content: "\F140E"; }

.mdi-axis-arrow-lock::before {
  content: "\F0D4A"; }

.mdi-axis-lock::before {
  content: "\F0D4B"; }

.mdi-axis-x-arrow::before {
  content: "\F0D4C"; }

.mdi-axis-x-arrow-lock::before {
  content: "\F0D4D"; }

.mdi-axis-x-rotate-clockwise::before {
  content: "\F0D4E"; }

.mdi-axis-x-rotate-counterclockwise::before {
  content: "\F0D4F"; }

.mdi-axis-x-y-arrow-lock::before {
  content: "\F0D50"; }

.mdi-axis-y-arrow::before {
  content: "\F0D51"; }

.mdi-axis-y-arrow-lock::before {
  content: "\F0D52"; }

.mdi-axis-y-rotate-clockwise::before {
  content: "\F0D53"; }

.mdi-axis-y-rotate-counterclockwise::before {
  content: "\F0D54"; }

.mdi-axis-z-arrow::before {
  content: "\F0D55"; }

.mdi-axis-z-arrow-lock::before {
  content: "\F0D56"; }

.mdi-axis-z-rotate-clockwise::before {
  content: "\F0D57"; }

.mdi-axis-z-rotate-counterclockwise::before {
  content: "\F0D58"; }

.mdi-babel::before {
  content: "\F0A25"; }

.mdi-baby::before {
  content: "\F006C"; }

.mdi-baby-bottle::before {
  content: "\F0F39"; }

.mdi-baby-bottle-outline::before {
  content: "\F0F3A"; }

.mdi-baby-buggy::before {
  content: "\F13E0"; }

.mdi-baby-carriage::before {
  content: "\F068F"; }

.mdi-baby-carriage-off::before {
  content: "\F0FA0"; }

.mdi-baby-face::before {
  content: "\F0E7C"; }

.mdi-baby-face-outline::before {
  content: "\F0E7D"; }

.mdi-backburger::before {
  content: "\F006D"; }

.mdi-backspace::before {
  content: "\F006E"; }

.mdi-backspace-outline::before {
  content: "\F0B5C"; }

.mdi-backspace-reverse::before {
  content: "\F0E7E"; }

.mdi-backspace-reverse-outline::before {
  content: "\F0E7F"; }

.mdi-backup-restore::before {
  content: "\F006F"; }

.mdi-bacteria::before {
  content: "\F0ED5"; }

.mdi-bacteria-outline::before {
  content: "\F0ED6"; }

.mdi-badge-account::before {
  content: "\F0DA7"; }

.mdi-badge-account-alert::before {
  content: "\F0DA8"; }

.mdi-badge-account-alert-outline::before {
  content: "\F0DA9"; }

.mdi-badge-account-horizontal::before {
  content: "\F0E0D"; }

.mdi-badge-account-horizontal-outline::before {
  content: "\F0E0E"; }

.mdi-badge-account-outline::before {
  content: "\F0DAA"; }

.mdi-badminton::before {
  content: "\F0851"; }

.mdi-bag-carry-on::before {
  content: "\F0F3B"; }

.mdi-bag-carry-on-check::before {
  content: "\F0D65"; }

.mdi-bag-carry-on-off::before {
  content: "\F0F3C"; }

.mdi-bag-checked::before {
  content: "\F0F3D"; }

.mdi-bag-personal::before {
  content: "\F0E10"; }

.mdi-bag-personal-off::before {
  content: "\F0E11"; }

.mdi-bag-personal-off-outline::before {
  content: "\F0E12"; }

.mdi-bag-personal-outline::before {
  content: "\F0E13"; }

.mdi-bag-suitcase::before {
  content: "\F158B"; }

.mdi-bag-suitcase-off::before {
  content: "\F158D"; }

.mdi-bag-suitcase-off-outline::before {
  content: "\F158E"; }

.mdi-bag-suitcase-outline::before {
  content: "\F158C"; }

.mdi-baguette::before {
  content: "\F0F3E"; }

.mdi-balloon::before {
  content: "\F0A26"; }

.mdi-ballot::before {
  content: "\F09C9"; }

.mdi-ballot-outline::before {
  content: "\F09CA"; }

.mdi-ballot-recount::before {
  content: "\F0C39"; }

.mdi-ballot-recount-outline::before {
  content: "\F0C3A"; }

.mdi-bandage::before {
  content: "\F0DAF"; }

.mdi-bandcamp::before {
  content: "\F0675"; }

.mdi-bank::before {
  content: "\F0070"; }

.mdi-bank-check::before {
  content: "\F1655"; }

.mdi-bank-minus::before {
  content: "\F0DB0"; }

.mdi-bank-off::before {
  content: "\F1656"; }

.mdi-bank-off-outline::before {
  content: "\F1657"; }

.mdi-bank-outline::before {
  content: "\F0E80"; }

.mdi-bank-plus::before {
  content: "\F0DB1"; }

.mdi-bank-remove::before {
  content: "\F0DB2"; }

.mdi-bank-transfer::before {
  content: "\F0A27"; }

.mdi-bank-transfer-in::before {
  content: "\F0A28"; }

.mdi-bank-transfer-out::before {
  content: "\F0A29"; }

.mdi-barcode::before {
  content: "\F0071"; }

.mdi-barcode-off::before {
  content: "\F1236"; }

.mdi-barcode-scan::before {
  content: "\F0072"; }

.mdi-barley::before {
  content: "\F0073"; }

.mdi-barley-off::before {
  content: "\F0B5D"; }

.mdi-barn::before {
  content: "\F0B5E"; }

.mdi-barrel::before {
  content: "\F0074"; }

.mdi-baseball::before {
  content: "\F0852"; }

.mdi-baseball-bat::before {
  content: "\F0853"; }

.mdi-baseball-diamond::before {
  content: "\F15EC"; }

.mdi-baseball-diamond-outline::before {
  content: "\F15ED"; }

.mdi-bash::before {
  content: "\F1183"; }

.mdi-basket::before {
  content: "\F0076"; }

.mdi-basket-fill::before {
  content: "\F0077"; }

.mdi-basket-minus::before {
  content: "\F1523"; }

.mdi-basket-minus-outline::before {
  content: "\F1524"; }

.mdi-basket-off::before {
  content: "\F1525"; }

.mdi-basket-off-outline::before {
  content: "\F1526"; }

.mdi-basket-outline::before {
  content: "\F1181"; }

.mdi-basket-plus::before {
  content: "\F1527"; }

.mdi-basket-plus-outline::before {
  content: "\F1528"; }

.mdi-basket-remove::before {
  content: "\F1529"; }

.mdi-basket-remove-outline::before {
  content: "\F152A"; }

.mdi-basket-unfill::before {
  content: "\F0078"; }

.mdi-basketball::before {
  content: "\F0806"; }

.mdi-basketball-hoop::before {
  content: "\F0C3B"; }

.mdi-basketball-hoop-outline::before {
  content: "\F0C3C"; }

.mdi-bat::before {
  content: "\F0B5F"; }

.mdi-battery::before {
  content: "\F0079"; }

.mdi-battery-10::before {
  content: "\F007A"; }

.mdi-battery-10-bluetooth::before {
  content: "\F093E"; }

.mdi-battery-20::before {
  content: "\F007B"; }

.mdi-battery-20-bluetooth::before {
  content: "\F093F"; }

.mdi-battery-30::before {
  content: "\F007C"; }

.mdi-battery-30-bluetooth::before {
  content: "\F0940"; }

.mdi-battery-40::before {
  content: "\F007D"; }

.mdi-battery-40-bluetooth::before {
  content: "\F0941"; }

.mdi-battery-50::before {
  content: "\F007E"; }

.mdi-battery-50-bluetooth::before {
  content: "\F0942"; }

.mdi-battery-60::before {
  content: "\F007F"; }

.mdi-battery-60-bluetooth::before {
  content: "\F0943"; }

.mdi-battery-70::before {
  content: "\F0080"; }

.mdi-battery-70-bluetooth::before {
  content: "\F0944"; }

.mdi-battery-80::before {
  content: "\F0081"; }

.mdi-battery-80-bluetooth::before {
  content: "\F0945"; }

.mdi-battery-90::before {
  content: "\F0082"; }

.mdi-battery-90-bluetooth::before {
  content: "\F0946"; }

.mdi-battery-alert::before {
  content: "\F0083"; }

.mdi-battery-alert-bluetooth::before {
  content: "\F0947"; }

.mdi-battery-alert-variant::before {
  content: "\F10CC"; }

.mdi-battery-alert-variant-outline::before {
  content: "\F10CD"; }

.mdi-battery-bluetooth::before {
  content: "\F0948"; }

.mdi-battery-bluetooth-variant::before {
  content: "\F0949"; }

.mdi-battery-charging::before {
  content: "\F0084"; }

.mdi-battery-charging-10::before {
  content: "\F089C"; }

.mdi-battery-charging-100::before {
  content: "\F0085"; }

.mdi-battery-charging-20::before {
  content: "\F0086"; }

.mdi-battery-charging-30::before {
  content: "\F0087"; }

.mdi-battery-charging-40::before {
  content: "\F0088"; }

.mdi-battery-charging-50::before {
  content: "\F089D"; }

.mdi-battery-charging-60::before {
  content: "\F0089"; }

.mdi-battery-charging-70::before {
  content: "\F089E"; }

.mdi-battery-charging-80::before {
  content: "\F008A"; }

.mdi-battery-charging-90::before {
  content: "\F008B"; }

.mdi-battery-charging-high::before {
  content: "\F12A6"; }

.mdi-battery-charging-low::before {
  content: "\F12A4"; }

.mdi-battery-charging-medium::before {
  content: "\F12A5"; }

.mdi-battery-charging-outline::before {
  content: "\F089F"; }

.mdi-battery-charging-wireless::before {
  content: "\F0807"; }

.mdi-battery-charging-wireless-10::before {
  content: "\F0808"; }

.mdi-battery-charging-wireless-20::before {
  content: "\F0809"; }

.mdi-battery-charging-wireless-30::before {
  content: "\F080A"; }

.mdi-battery-charging-wireless-40::before {
  content: "\F080B"; }

.mdi-battery-charging-wireless-50::before {
  content: "\F080C"; }

.mdi-battery-charging-wireless-60::before {
  content: "\F080D"; }

.mdi-battery-charging-wireless-70::before {
  content: "\F080E"; }

.mdi-battery-charging-wireless-80::before {
  content: "\F080F"; }

.mdi-battery-charging-wireless-90::before {
  content: "\F0810"; }

.mdi-battery-charging-wireless-alert::before {
  content: "\F0811"; }

.mdi-battery-charging-wireless-outline::before {
  content: "\F0812"; }

.mdi-battery-heart::before {
  content: "\F120F"; }

.mdi-battery-heart-outline::before {
  content: "\F1210"; }

.mdi-battery-heart-variant::before {
  content: "\F1211"; }

.mdi-battery-high::before {
  content: "\F12A3"; }

.mdi-battery-low::before {
  content: "\F12A1"; }

.mdi-battery-medium::before {
  content: "\F12A2"; }

.mdi-battery-minus::before {
  content: "\F008C"; }

.mdi-battery-negative::before {
  content: "\F008D"; }

.mdi-battery-off::before {
  content: "\F125D"; }

.mdi-battery-off-outline::before {
  content: "\F125E"; }

.mdi-battery-outline::before {
  content: "\F008E"; }

.mdi-battery-plus::before {
  content: "\F008F"; }

.mdi-battery-positive::before {
  content: "\F0090"; }

.mdi-battery-unknown::before {
  content: "\F0091"; }

.mdi-battery-unknown-bluetooth::before {
  content: "\F094A"; }

.mdi-battlenet::before {
  content: "\F0B60"; }

.mdi-beach::before {
  content: "\F0092"; }

.mdi-beaker::before {
  content: "\F0CEA"; }

.mdi-beaker-alert::before {
  content: "\F1229"; }

.mdi-beaker-alert-outline::before {
  content: "\F122A"; }

.mdi-beaker-check::before {
  content: "\F122B"; }

.mdi-beaker-check-outline::before {
  content: "\F122C"; }

.mdi-beaker-minus::before {
  content: "\F122D"; }

.mdi-beaker-minus-outline::before {
  content: "\F122E"; }

.mdi-beaker-outline::before {
  content: "\F0690"; }

.mdi-beaker-plus::before {
  content: "\F122F"; }

.mdi-beaker-plus-outline::before {
  content: "\F1230"; }

.mdi-beaker-question::before {
  content: "\F1231"; }

.mdi-beaker-question-outline::before {
  content: "\F1232"; }

.mdi-beaker-remove::before {
  content: "\F1233"; }

.mdi-beaker-remove-outline::before {
  content: "\F1234"; }

.mdi-bed::before {
  content: "\F02E3"; }

.mdi-bed-double::before {
  content: "\F0FD4"; }

.mdi-bed-double-outline::before {
  content: "\F0FD3"; }

.mdi-bed-empty::before {
  content: "\F08A0"; }

.mdi-bed-king::before {
  content: "\F0FD2"; }

.mdi-bed-king-outline::before {
  content: "\F0FD1"; }

.mdi-bed-outline::before {
  content: "\F0099"; }

.mdi-bed-queen::before {
  content: "\F0FD0"; }

.mdi-bed-queen-outline::before {
  content: "\F0FDB"; }

.mdi-bed-single::before {
  content: "\F106D"; }

.mdi-bed-single-outline::before {
  content: "\F106E"; }

.mdi-bee::before {
  content: "\F0FA1"; }

.mdi-bee-flower::before {
  content: "\F0FA2"; }

.mdi-beehive-off-outline::before {
  content: "\F13ED"; }

.mdi-beehive-outline::before {
  content: "\F10CE"; }

.mdi-beekeeper::before {
  content: "\F14E2"; }

.mdi-beer::before {
  content: "\F0098"; }

.mdi-beer-outline::before {
  content: "\F130C"; }

.mdi-bell::before {
  content: "\F009A"; }

.mdi-bell-alert::before {
  content: "\F0D59"; }

.mdi-bell-alert-outline::before {
  content: "\F0E81"; }

.mdi-bell-cancel::before {
  content: "\F13E7"; }

.mdi-bell-cancel-outline::before {
  content: "\F13E8"; }

.mdi-bell-check::before {
  content: "\F11E5"; }

.mdi-bell-check-outline::before {
  content: "\F11E6"; }

.mdi-bell-circle::before {
  content: "\F0D5A"; }

.mdi-bell-circle-outline::before {
  content: "\F0D5B"; }

.mdi-bell-minus::before {
  content: "\F13E9"; }

.mdi-bell-minus-outline::before {
  content: "\F13EA"; }

.mdi-bell-off::before {
  content: "\F009B"; }

.mdi-bell-off-outline::before {
  content: "\F0A91"; }

.mdi-bell-outline::before {
  content: "\F009C"; }

.mdi-bell-plus::before {
  content: "\F009D"; }

.mdi-bell-plus-outline::before {
  content: "\F0A92"; }

.mdi-bell-remove::before {
  content: "\F13EB"; }

.mdi-bell-remove-outline::before {
  content: "\F13EC"; }

.mdi-bell-ring::before {
  content: "\F009E"; }

.mdi-bell-ring-outline::before {
  content: "\F009F"; }

.mdi-bell-sleep::before {
  content: "\F00A0"; }

.mdi-bell-sleep-outline::before {
  content: "\F0A93"; }

.mdi-beta::before {
  content: "\F00A1"; }

.mdi-betamax::before {
  content: "\F09CB"; }

.mdi-biathlon::before {
  content: "\F0E14"; }

.mdi-bicycle::before {
  content: "\F109C"; }

.mdi-bicycle-basket::before {
  content: "\F1235"; }

.mdi-bicycle-electric::before {
  content: "\F15B4"; }

.mdi-bicycle-penny-farthing::before {
  content: "\F15E9"; }

.mdi-bike::before {
  content: "\F00A3"; }

.mdi-bike-fast::before {
  content: "\F111F"; }

.mdi-billboard::before {
  content: "\F1010"; }

.mdi-billiards::before {
  content: "\F0B61"; }

.mdi-billiards-rack::before {
  content: "\F0B62"; }

.mdi-binoculars::before {
  content: "\F00A5"; }

.mdi-bio::before {
  content: "\F00A6"; }

.mdi-biohazard::before {
  content: "\F00A7"; }

.mdi-bird::before {
  content: "\F15C6"; }

.mdi-bitbucket::before {
  content: "\F00A8"; }

.mdi-bitcoin::before {
  content: "\F0813"; }

.mdi-black-mesa::before {
  content: "\F00A9"; }

.mdi-blender::before {
  content: "\F0CEB"; }

.mdi-blender-software::before {
  content: "\F00AB"; }

.mdi-blinds::before {
  content: "\F00AC"; }

.mdi-blinds-open::before {
  content: "\F1011"; }

.mdi-block-helper::before {
  content: "\F00AD"; }

.mdi-blogger::before {
  content: "\F00AE"; }

.mdi-blood-bag::before {
  content: "\F0CEC"; }

.mdi-bluetooth::before {
  content: "\F00AF"; }

.mdi-bluetooth-audio::before {
  content: "\F00B0"; }

.mdi-bluetooth-connect::before {
  content: "\F00B1"; }

.mdi-bluetooth-off::before {
  content: "\F00B2"; }

.mdi-bluetooth-settings::before {
  content: "\F00B3"; }

.mdi-bluetooth-transfer::before {
  content: "\F00B4"; }

.mdi-blur::before {
  content: "\F00B5"; }

.mdi-blur-linear::before {
  content: "\F00B6"; }

.mdi-blur-off::before {
  content: "\F00B7"; }

.mdi-blur-radial::before {
  content: "\F00B8"; }

.mdi-bolnisi-cross::before {
  content: "\F0CED"; }

.mdi-bolt::before {
  content: "\F0DB3"; }

.mdi-bomb::before {
  content: "\F0691"; }

.mdi-bomb-off::before {
  content: "\F06C5"; }

.mdi-bone::before {
  content: "\F00B9"; }

.mdi-book::before {
  content: "\F00BA"; }

.mdi-book-account::before {
  content: "\F13AD"; }

.mdi-book-account-outline::before {
  content: "\F13AE"; }

.mdi-book-alert::before {
  content: "\F167C"; }

.mdi-book-alert-outline::before {
  content: "\F167D"; }

.mdi-book-alphabet::before {
  content: "\F061D"; }

.mdi-book-arrow-down::before {
  content: "\F167E"; }

.mdi-book-arrow-down-outline::before {
  content: "\F167F"; }

.mdi-book-arrow-left::before {
  content: "\F1680"; }

.mdi-book-arrow-left-outline::before {
  content: "\F1681"; }

.mdi-book-arrow-right::before {
  content: "\F1682"; }

.mdi-book-arrow-right-outline::before {
  content: "\F1683"; }

.mdi-book-arrow-up::before {
  content: "\F1684"; }

.mdi-book-arrow-up-outline::before {
  content: "\F1685"; }

.mdi-book-cancel::before {
  content: "\F1686"; }

.mdi-book-cancel-outline::before {
  content: "\F1687"; }

.mdi-book-check::before {
  content: "\F14F3"; }

.mdi-book-check-outline::before {
  content: "\F14F4"; }

.mdi-book-clock::before {
  content: "\F1688"; }

.mdi-book-clock-outline::before {
  content: "\F1689"; }

.mdi-book-cog::before {
  content: "\F168A"; }

.mdi-book-cog-outline::before {
  content: "\F168B"; }

.mdi-book-cross::before {
  content: "\F00A2"; }

.mdi-book-edit::before {
  content: "\F168C"; }

.mdi-book-edit-outline::before {
  content: "\F168D"; }

.mdi-book-education::before {
  content: "\F16C9"; }

.mdi-book-education-outline::before {
  content: "\F16CA"; }

.mdi-book-information-variant::before {
  content: "\F106F"; }

.mdi-book-lock::before {
  content: "\F079A"; }

.mdi-book-lock-open::before {
  content: "\F079B"; }

.mdi-book-lock-open-outline::before {
  content: "\F168E"; }

.mdi-book-lock-outline::before {
  content: "\F168F"; }

.mdi-book-marker::before {
  content: "\F1690"; }

.mdi-book-marker-outline::before {
  content: "\F1691"; }

.mdi-book-minus::before {
  content: "\F05D9"; }

.mdi-book-minus-multiple::before {
  content: "\F0A94"; }

.mdi-book-minus-multiple-outline::before {
  content: "\F090B"; }

.mdi-book-minus-outline::before {
  content: "\F1692"; }

.mdi-book-multiple::before {
  content: "\F00BB"; }

.mdi-book-multiple-outline::before {
  content: "\F0436"; }

.mdi-book-music::before {
  content: "\F0067"; }

.mdi-book-music-outline::before {
  content: "\F1693"; }

.mdi-book-off::before {
  content: "\F1694"; }

.mdi-book-off-outline::before {
  content: "\F1695"; }

.mdi-book-open::before {
  content: "\F00BD"; }

.mdi-book-open-blank-variant::before {
  content: "\F00BE"; }

.mdi-book-open-outline::before {
  content: "\F0B63"; }

.mdi-book-open-page-variant::before {
  content: "\F05DA"; }

.mdi-book-open-page-variant-outline::before {
  content: "\F15D6"; }

.mdi-book-open-variant::before {
  content: "\F14F7"; }

.mdi-book-outline::before {
  content: "\F0B64"; }

.mdi-book-play::before {
  content: "\F0E82"; }

.mdi-book-play-outline::before {
  content: "\F0E83"; }

.mdi-book-plus::before {
  content: "\F05DB"; }

.mdi-book-plus-multiple::before {
  content: "\F0A95"; }

.mdi-book-plus-multiple-outline::before {
  content: "\F0ADE"; }

.mdi-book-plus-outline::before {
  content: "\F1696"; }

.mdi-book-refresh::before {
  content: "\F1697"; }

.mdi-book-refresh-outline::before {
  content: "\F1698"; }

.mdi-book-remove::before {
  content: "\F0A97"; }

.mdi-book-remove-multiple::before {
  content: "\F0A96"; }

.mdi-book-remove-multiple-outline::before {
  content: "\F04CA"; }

.mdi-book-remove-outline::before {
  content: "\F1699"; }

.mdi-book-search::before {
  content: "\F0E84"; }

.mdi-book-search-outline::before {
  content: "\F0E85"; }

.mdi-book-settings::before {
  content: "\F169A"; }

.mdi-book-settings-outline::before {
  content: "\F169B"; }

.mdi-book-sync::before {
  content: "\F169C"; }

.mdi-book-sync-outline::before {
  content: "\F16C8"; }

.mdi-book-variant::before {
  content: "\F00BF"; }

.mdi-book-variant-multiple::before {
  content: "\F00BC"; }

.mdi-bookmark::before {
  content: "\F00C0"; }

.mdi-bookmark-check::before {
  content: "\F00C1"; }

.mdi-bookmark-check-outline::before {
  content: "\F137B"; }

.mdi-bookmark-minus::before {
  content: "\F09CC"; }

.mdi-bookmark-minus-outline::before {
  content: "\F09CD"; }

.mdi-bookmark-multiple::before {
  content: "\F0E15"; }

.mdi-bookmark-multiple-outline::before {
  content: "\F0E16"; }

.mdi-bookmark-music::before {
  content: "\F00C2"; }

.mdi-bookmark-music-outline::before {
  content: "\F1379"; }

.mdi-bookmark-off::before {
  content: "\F09CE"; }

.mdi-bookmark-off-outline::before {
  content: "\F09CF"; }

.mdi-bookmark-outline::before {
  content: "\F00C3"; }

.mdi-bookmark-plus::before {
  content: "\F00C5"; }

.mdi-bookmark-plus-outline::before {
  content: "\F00C4"; }

.mdi-bookmark-remove::before {
  content: "\F00C6"; }

.mdi-bookmark-remove-outline::before {
  content: "\F137A"; }

.mdi-bookshelf::before {
  content: "\F125F"; }

.mdi-boom-gate::before {
  content: "\F0E86"; }

.mdi-boom-gate-alert::before {
  content: "\F0E87"; }

.mdi-boom-gate-alert-outline::before {
  content: "\F0E88"; }

.mdi-boom-gate-down::before {
  content: "\F0E89"; }

.mdi-boom-gate-down-outline::before {
  content: "\F0E8A"; }

.mdi-boom-gate-outline::before {
  content: "\F0E8B"; }

.mdi-boom-gate-up::before {
  content: "\F0E8C"; }

.mdi-boom-gate-up-outline::before {
  content: "\F0E8D"; }

.mdi-boombox::before {
  content: "\F05DC"; }

.mdi-boomerang::before {
  content: "\F10CF"; }

.mdi-bootstrap::before {
  content: "\F06C6"; }

.mdi-border-all::before {
  content: "\F00C7"; }

.mdi-border-all-variant::before {
  content: "\F08A1"; }

.mdi-border-bottom::before {
  content: "\F00C8"; }

.mdi-border-bottom-variant::before {
  content: "\F08A2"; }

.mdi-border-color::before {
  content: "\F00C9"; }

.mdi-border-horizontal::before {
  content: "\F00CA"; }

.mdi-border-inside::before {
  content: "\F00CB"; }

.mdi-border-left::before {
  content: "\F00CC"; }

.mdi-border-left-variant::before {
  content: "\F08A3"; }

.mdi-border-none::before {
  content: "\F00CD"; }

.mdi-border-none-variant::before {
  content: "\F08A4"; }

.mdi-border-outside::before {
  content: "\F00CE"; }

.mdi-border-right::before {
  content: "\F00CF"; }

.mdi-border-right-variant::before {
  content: "\F08A5"; }

.mdi-border-style::before {
  content: "\F00D0"; }

.mdi-border-top::before {
  content: "\F00D1"; }

.mdi-border-top-variant::before {
  content: "\F08A6"; }

.mdi-border-vertical::before {
  content: "\F00D2"; }

.mdi-bottle-soda::before {
  content: "\F1070"; }

.mdi-bottle-soda-classic::before {
  content: "\F1071"; }

.mdi-bottle-soda-classic-outline::before {
  content: "\F1363"; }

.mdi-bottle-soda-outline::before {
  content: "\F1072"; }

.mdi-bottle-tonic::before {
  content: "\F112E"; }

.mdi-bottle-tonic-outline::before {
  content: "\F112F"; }

.mdi-bottle-tonic-plus::before {
  content: "\F1130"; }

.mdi-bottle-tonic-plus-outline::before {
  content: "\F1131"; }

.mdi-bottle-tonic-skull::before {
  content: "\F1132"; }

.mdi-bottle-tonic-skull-outline::before {
  content: "\F1133"; }

.mdi-bottle-wine::before {
  content: "\F0854"; }

.mdi-bottle-wine-outline::before {
  content: "\F1310"; }

.mdi-bow-tie::before {
  content: "\F0678"; }

.mdi-bowl::before {
  content: "\F028E"; }

.mdi-bowl-mix::before {
  content: "\F0617"; }

.mdi-bowl-mix-outline::before {
  content: "\F02E4"; }

.mdi-bowl-outline::before {
  content: "\F02A9"; }

.mdi-bowling::before {
  content: "\F00D3"; }

.mdi-box::before {
  content: "\F00D4"; }

.mdi-box-cutter::before {
  content: "\F00D5"; }

.mdi-box-cutter-off::before {
  content: "\F0B4A"; }

.mdi-box-shadow::before {
  content: "\F0637"; }

.mdi-boxing-glove::before {
  content: "\F0B65"; }

.mdi-braille::before {
  content: "\F09D0"; }

.mdi-brain::before {
  content: "\F09D1"; }

.mdi-bread-slice::before {
  content: "\F0CEE"; }

.mdi-bread-slice-outline::before {
  content: "\F0CEF"; }

.mdi-bridge::before {
  content: "\F0618"; }

.mdi-briefcase::before {
  content: "\F00D6"; }

.mdi-briefcase-account::before {
  content: "\F0CF0"; }

.mdi-briefcase-account-outline::before {
  content: "\F0CF1"; }

.mdi-briefcase-check::before {
  content: "\F00D7"; }

.mdi-briefcase-check-outline::before {
  content: "\F131E"; }

.mdi-briefcase-clock::before {
  content: "\F10D0"; }

.mdi-briefcase-clock-outline::before {
  content: "\F10D1"; }

.mdi-briefcase-download::before {
  content: "\F00D8"; }

.mdi-briefcase-download-outline::before {
  content: "\F0C3D"; }

.mdi-briefcase-edit::before {
  content: "\F0A98"; }

.mdi-briefcase-edit-outline::before {
  content: "\F0C3E"; }

.mdi-briefcase-minus::before {
  content: "\F0A2A"; }

.mdi-briefcase-minus-outline::before {
  content: "\F0C3F"; }

.mdi-briefcase-off::before {
  content: "\F1658"; }

.mdi-briefcase-off-outline::before {
  content: "\F1659"; }

.mdi-briefcase-outline::before {
  content: "\F0814"; }

.mdi-briefcase-plus::before {
  content: "\F0A2B"; }

.mdi-briefcase-plus-outline::before {
  content: "\F0C40"; }

.mdi-briefcase-remove::before {
  content: "\F0A2C"; }

.mdi-briefcase-remove-outline::before {
  content: "\F0C41"; }

.mdi-briefcase-search::before {
  content: "\F0A2D"; }

.mdi-briefcase-search-outline::before {
  content: "\F0C42"; }

.mdi-briefcase-upload::before {
  content: "\F00D9"; }

.mdi-briefcase-upload-outline::before {
  content: "\F0C43"; }

.mdi-briefcase-variant::before {
  content: "\F1494"; }

.mdi-briefcase-variant-off::before {
  content: "\F165A"; }

.mdi-briefcase-variant-off-outline::before {
  content: "\F165B"; }

.mdi-briefcase-variant-outline::before {
  content: "\F1495"; }

.mdi-brightness-1::before {
  content: "\F00DA"; }

.mdi-brightness-2::before {
  content: "\F00DB"; }

.mdi-brightness-3::before {
  content: "\F00DC"; }

.mdi-brightness-4::before {
  content: "\F00DD"; }

.mdi-brightness-5::before {
  content: "\F00DE"; }

.mdi-brightness-6::before {
  content: "\F00DF"; }

.mdi-brightness-7::before {
  content: "\F00E0"; }

.mdi-brightness-auto::before {
  content: "\F00E1"; }

.mdi-brightness-percent::before {
  content: "\F0CF2"; }

.mdi-broom::before {
  content: "\F00E2"; }

.mdi-brush::before {
  content: "\F00E3"; }

.mdi-bucket::before {
  content: "\F1415"; }

.mdi-bucket-outline::before {
  content: "\F1416"; }

.mdi-buddhism::before {
  content: "\F094B"; }

.mdi-buffer::before {
  content: "\F0619"; }

.mdi-buffet::before {
  content: "\F0578"; }

.mdi-bug::before {
  content: "\F00E4"; }

.mdi-bug-check::before {
  content: "\F0A2E"; }

.mdi-bug-check-outline::before {
  content: "\F0A2F"; }

.mdi-bug-outline::before {
  content: "\F0A30"; }

.mdi-bugle::before {
  content: "\F0DB4"; }

.mdi-bulldozer::before {
  content: "\F0B22"; }

.mdi-bullet::before {
  content: "\F0CF3"; }

.mdi-bulletin-board::before {
  content: "\F00E5"; }

.mdi-bullhorn::before {
  content: "\F00E6"; }

.mdi-bullhorn-outline::before {
  content: "\F0B23"; }

.mdi-bullseye::before {
  content: "\F05DD"; }

.mdi-bullseye-arrow::before {
  content: "\F08C9"; }

.mdi-bulma::before {
  content: "\F12E7"; }

.mdi-bunk-bed::before {
  content: "\F1302"; }

.mdi-bunk-bed-outline::before {
  content: "\F0097"; }

.mdi-bus::before {
  content: "\F00E7"; }

.mdi-bus-alert::before {
  content: "\F0A99"; }

.mdi-bus-articulated-end::before {
  content: "\F079C"; }

.mdi-bus-articulated-front::before {
  content: "\F079D"; }

.mdi-bus-clock::before {
  content: "\F08CA"; }

.mdi-bus-double-decker::before {
  content: "\F079E"; }

.mdi-bus-marker::before {
  content: "\F1212"; }

.mdi-bus-multiple::before {
  content: "\F0F3F"; }

.mdi-bus-school::before {
  content: "\F079F"; }

.mdi-bus-side::before {
  content: "\F07A0"; }

.mdi-bus-stop::before {
  content: "\F1012"; }

.mdi-bus-stop-covered::before {
  content: "\F1013"; }

.mdi-bus-stop-uncovered::before {
  content: "\F1014"; }

.mdi-butterfly::before {
  content: "\F1589"; }

.mdi-butterfly-outline::before {
  content: "\F158A"; }

.mdi-cable-data::before {
  content: "\F1394"; }

.mdi-cached::before {
  content: "\F00E8"; }

.mdi-cactus::before {
  content: "\F0DB5"; }

.mdi-cake::before {
  content: "\F00E9"; }

.mdi-cake-layered::before {
  content: "\F00EA"; }

.mdi-cake-variant::before {
  content: "\F00EB"; }

.mdi-calculator::before {
  content: "\F00EC"; }

.mdi-calculator-variant::before {
  content: "\F0A9A"; }

.mdi-calculator-variant-outline::before {
  content: "\F15A6"; }

.mdi-calendar::before {
  content: "\F00ED"; }

.mdi-calendar-account::before {
  content: "\F0ED7"; }

.mdi-calendar-account-outline::before {
  content: "\F0ED8"; }

.mdi-calendar-alert::before {
  content: "\F0A31"; }

.mdi-calendar-arrow-left::before {
  content: "\F1134"; }

.mdi-calendar-arrow-right::before {
  content: "\F1135"; }

.mdi-calendar-blank::before {
  content: "\F00EE"; }

.mdi-calendar-blank-multiple::before {
  content: "\F1073"; }

.mdi-calendar-blank-outline::before {
  content: "\F0B66"; }

.mdi-calendar-check::before {
  content: "\F00EF"; }

.mdi-calendar-check-outline::before {
  content: "\F0C44"; }

.mdi-calendar-clock::before {
  content: "\F00F0"; }

.mdi-calendar-cursor::before {
  content: "\F157B"; }

.mdi-calendar-edit::before {
  content: "\F08A7"; }

.mdi-calendar-end::before {
  content: "\F166C"; }

.mdi-calendar-export::before {
  content: "\F0B24"; }

.mdi-calendar-heart::before {
  content: "\F09D2"; }

.mdi-calendar-import::before {
  content: "\F0B25"; }

.mdi-calendar-lock::before {
  content: "\F1641"; }

.mdi-calendar-lock-outline::before {
  content: "\F1642"; }

.mdi-calendar-minus::before {
  content: "\F0D5C"; }

.mdi-calendar-month::before {
  content: "\F0E17"; }

.mdi-calendar-month-outline::before {
  content: "\F0E18"; }

.mdi-calendar-multiple::before {
  content: "\F00F1"; }

.mdi-calendar-multiple-check::before {
  content: "\F00F2"; }

.mdi-calendar-multiselect::before {
  content: "\F0A32"; }

.mdi-calendar-outline::before {
  content: "\F0B67"; }

.mdi-calendar-plus::before {
  content: "\F00F3"; }

.mdi-calendar-question::before {
  content: "\F0692"; }

.mdi-calendar-range::before {
  content: "\F0679"; }

.mdi-calendar-range-outline::before {
  content: "\F0B68"; }

.mdi-calendar-refresh::before {
  content: "\F01E1"; }

.mdi-calendar-refresh-outline::before {
  content: "\F0203"; }

.mdi-calendar-remove::before {
  content: "\F00F4"; }

.mdi-calendar-remove-outline::before {
  content: "\F0C45"; }

.mdi-calendar-search::before {
  content: "\F094C"; }

.mdi-calendar-star::before {
  content: "\F09D3"; }

.mdi-calendar-start::before {
  content: "\F166D"; }

.mdi-calendar-sync::before {
  content: "\F0E8E"; }

.mdi-calendar-sync-outline::before {
  content: "\F0E8F"; }

.mdi-calendar-text::before {
  content: "\F00F5"; }

.mdi-calendar-text-outline::before {
  content: "\F0C46"; }

.mdi-calendar-today::before {
  content: "\F00F6"; }

.mdi-calendar-week::before {
  content: "\F0A33"; }

.mdi-calendar-week-begin::before {
  content: "\F0A34"; }

.mdi-calendar-weekend::before {
  content: "\F0ED9"; }

.mdi-calendar-weekend-outline::before {
  content: "\F0EDA"; }

.mdi-call-made::before {
  content: "\F00F7"; }

.mdi-call-merge::before {
  content: "\F00F8"; }

.mdi-call-missed::before {
  content: "\F00F9"; }

.mdi-call-received::before {
  content: "\F00FA"; }

.mdi-call-split::before {
  content: "\F00FB"; }

.mdi-camcorder::before {
  content: "\F00FC"; }

.mdi-camcorder-off::before {
  content: "\F00FF"; }

.mdi-camera::before {
  content: "\F0100"; }

.mdi-camera-account::before {
  content: "\F08CB"; }

.mdi-camera-burst::before {
  content: "\F0693"; }

.mdi-camera-control::before {
  content: "\F0B69"; }

.mdi-camera-enhance::before {
  content: "\F0101"; }

.mdi-camera-enhance-outline::before {
  content: "\F0B6A"; }

.mdi-camera-flip::before {
  content: "\F15D9"; }

.mdi-camera-flip-outline::before {
  content: "\F15DA"; }

.mdi-camera-front::before {
  content: "\F0102"; }

.mdi-camera-front-variant::before {
  content: "\F0103"; }

.mdi-camera-gopro::before {
  content: "\F07A1"; }

.mdi-camera-image::before {
  content: "\F08CC"; }

.mdi-camera-iris::before {
  content: "\F0104"; }

.mdi-camera-metering-center::before {
  content: "\F07A2"; }

.mdi-camera-metering-matrix::before {
  content: "\F07A3"; }

.mdi-camera-metering-partial::before {
  content: "\F07A4"; }

.mdi-camera-metering-spot::before {
  content: "\F07A5"; }

.mdi-camera-off::before {
  content: "\F05DF"; }

.mdi-camera-outline::before {
  content: "\F0D5D"; }

.mdi-camera-party-mode::before {
  content: "\F0105"; }

.mdi-camera-plus::before {
  content: "\F0EDB"; }

.mdi-camera-plus-outline::before {
  content: "\F0EDC"; }

.mdi-camera-rear::before {
  content: "\F0106"; }

.mdi-camera-rear-variant::before {
  content: "\F0107"; }

.mdi-camera-retake::before {
  content: "\F0E19"; }

.mdi-camera-retake-outline::before {
  content: "\F0E1A"; }

.mdi-camera-switch::before {
  content: "\F0108"; }

.mdi-camera-switch-outline::before {
  content: "\F084A"; }

.mdi-camera-timer::before {
  content: "\F0109"; }

.mdi-camera-wireless::before {
  content: "\F0DB6"; }

.mdi-camera-wireless-outline::before {
  content: "\F0DB7"; }

.mdi-campfire::before {
  content: "\F0EDD"; }

.mdi-cancel::before {
  content: "\F073A"; }

.mdi-candle::before {
  content: "\F05E2"; }

.mdi-candycane::before {
  content: "\F010A"; }

.mdi-cannabis::before {
  content: "\F07A6"; }

.mdi-cannabis-off::before {
  content: "\F166E"; }

.mdi-caps-lock::before {
  content: "\F0A9B"; }

.mdi-car::before {
  content: "\F010B"; }

.mdi-car-2-plus::before {
  content: "\F1015"; }

.mdi-car-3-plus::before {
  content: "\F1016"; }

.mdi-car-arrow-left::before {
  content: "\F13B2"; }

.mdi-car-arrow-right::before {
  content: "\F13B3"; }

.mdi-car-back::before {
  content: "\F0E1B"; }

.mdi-car-battery::before {
  content: "\F010C"; }

.mdi-car-brake-abs::before {
  content: "\F0C47"; }

.mdi-car-brake-alert::before {
  content: "\F0C48"; }

.mdi-car-brake-hold::before {
  content: "\F0D5E"; }

.mdi-car-brake-parking::before {
  content: "\F0D5F"; }

.mdi-car-brake-retarder::before {
  content: "\F1017"; }

.mdi-car-child-seat::before {
  content: "\F0FA3"; }

.mdi-car-clutch::before {
  content: "\F1018"; }

.mdi-car-cog::before {
  content: "\F13CC"; }

.mdi-car-connected::before {
  content: "\F010D"; }

.mdi-car-convertible::before {
  content: "\F07A7"; }

.mdi-car-coolant-level::before {
  content: "\F1019"; }

.mdi-car-cruise-control::before {
  content: "\F0D60"; }

.mdi-car-defrost-front::before {
  content: "\F0D61"; }

.mdi-car-defrost-rear::before {
  content: "\F0D62"; }

.mdi-car-door::before {
  content: "\F0B6B"; }

.mdi-car-door-lock::before {
  content: "\F109D"; }

.mdi-car-electric::before {
  content: "\F0B6C"; }

.mdi-car-electric-outline::before {
  content: "\F15B5"; }

.mdi-car-emergency::before {
  content: "\F160F"; }

.mdi-car-esp::before {
  content: "\F0C49"; }

.mdi-car-estate::before {
  content: "\F07A8"; }

.mdi-car-hatchback::before {
  content: "\F07A9"; }

.mdi-car-info::before {
  content: "\F11BE"; }

.mdi-car-key::before {
  content: "\F0B6D"; }

.mdi-car-lifted-pickup::before {
  content: "\F152D"; }

.mdi-car-light-dimmed::before {
  content: "\F0C4A"; }

.mdi-car-light-fog::before {
  content: "\F0C4B"; }

.mdi-car-light-high::before {
  content: "\F0C4C"; }

.mdi-car-limousine::before {
  content: "\F08CD"; }

.mdi-car-multiple::before {
  content: "\F0B6E"; }

.mdi-car-off::before {
  content: "\F0E1C"; }

.mdi-car-outline::before {
  content: "\F14ED"; }

.mdi-car-parking-lights::before {
  content: "\F0D63"; }

.mdi-car-pickup::before {
  content: "\F07AA"; }

.mdi-car-seat::before {
  content: "\F0FA4"; }

.mdi-car-seat-cooler::before {
  content: "\F0FA5"; }

.mdi-car-seat-heater::before {
  content: "\F0FA6"; }

.mdi-car-settings::before {
  content: "\F13CD"; }

.mdi-car-shift-pattern::before {
  content: "\F0F40"; }

.mdi-car-side::before {
  content: "\F07AB"; }

.mdi-car-sports::before {
  content: "\F07AC"; }

.mdi-car-tire-alert::before {
  content: "\F0C4D"; }

.mdi-car-traction-control::before {
  content: "\F0D64"; }

.mdi-car-turbocharger::before {
  content: "\F101A"; }

.mdi-car-wash::before {
  content: "\F010E"; }

.mdi-car-windshield::before {
  content: "\F101B"; }

.mdi-car-windshield-outline::before {
  content: "\F101C"; }

.mdi-carabiner::before {
  content: "\F14C0"; }

.mdi-caravan::before {
  content: "\F07AD"; }

.mdi-card::before {
  content: "\F0B6F"; }

.mdi-card-account-details::before {
  content: "\F05D2"; }

.mdi-card-account-details-outline::before {
  content: "\F0DAB"; }

.mdi-card-account-details-star::before {
  content: "\F02A3"; }

.mdi-card-account-details-star-outline::before {
  content: "\F06DB"; }

.mdi-card-account-mail::before {
  content: "\F018E"; }

.mdi-card-account-mail-outline::before {
  content: "\F0E98"; }

.mdi-card-account-phone::before {
  content: "\F0E99"; }

.mdi-card-account-phone-outline::before {
  content: "\F0E9A"; }

.mdi-card-bulleted::before {
  content: "\F0B70"; }

.mdi-card-bulleted-off::before {
  content: "\F0B71"; }

.mdi-card-bulleted-off-outline::before {
  content: "\F0B72"; }

.mdi-card-bulleted-outline::before {
  content: "\F0B73"; }

.mdi-card-bulleted-settings::before {
  content: "\F0B74"; }

.mdi-card-bulleted-settings-outline::before {
  content: "\F0B75"; }

.mdi-card-minus::before {
  content: "\F1600"; }

.mdi-card-minus-outline::before {
  content: "\F1601"; }

.mdi-card-off::before {
  content: "\F1602"; }

.mdi-card-off-outline::before {
  content: "\F1603"; }

.mdi-card-outline::before {
  content: "\F0B76"; }

.mdi-card-plus::before {
  content: "\F11FF"; }

.mdi-card-plus-outline::before {
  content: "\F1200"; }

.mdi-card-remove::before {
  content: "\F1604"; }

.mdi-card-remove-outline::before {
  content: "\F1605"; }

.mdi-card-search::before {
  content: "\F1074"; }

.mdi-card-search-outline::before {
  content: "\F1075"; }

.mdi-card-text::before {
  content: "\F0B77"; }

.mdi-card-text-outline::before {
  content: "\F0B78"; }

.mdi-cards::before {
  content: "\F0638"; }

.mdi-cards-club::before {
  content: "\F08CE"; }

.mdi-cards-diamond::before {
  content: "\F08CF"; }

.mdi-cards-diamond-outline::before {
  content: "\F101D"; }

.mdi-cards-heart::before {
  content: "\F08D0"; }

.mdi-cards-outline::before {
  content: "\F0639"; }

.mdi-cards-playing-outline::before {
  content: "\F063A"; }

.mdi-cards-spade::before {
  content: "\F08D1"; }

.mdi-cards-variant::before {
  content: "\F06C7"; }

.mdi-carrot::before {
  content: "\F010F"; }

.mdi-cart::before {
  content: "\F0110"; }

.mdi-cart-arrow-down::before {
  content: "\F0D66"; }

.mdi-cart-arrow-right::before {
  content: "\F0C4E"; }

.mdi-cart-arrow-up::before {
  content: "\F0D67"; }

.mdi-cart-check::before {
  content: "\F15EA"; }

.mdi-cart-minus::before {
  content: "\F0D68"; }

.mdi-cart-off::before {
  content: "\F066B"; }

.mdi-cart-outline::before {
  content: "\F0111"; }

.mdi-cart-plus::before {
  content: "\F0112"; }

.mdi-cart-remove::before {
  content: "\F0D69"; }

.mdi-cart-variant::before {
  content: "\F15EB"; }

.mdi-case-sensitive-alt::before {
  content: "\F0113"; }

.mdi-cash::before {
  content: "\F0114"; }

.mdi-cash-100::before {
  content: "\F0115"; }

.mdi-cash-check::before {
  content: "\F14EE"; }

.mdi-cash-lock::before {
  content: "\F14EA"; }

.mdi-cash-lock-open::before {
  content: "\F14EB"; }

.mdi-cash-marker::before {
  content: "\F0DB8"; }

.mdi-cash-minus::before {
  content: "\F1260"; }

.mdi-cash-multiple::before {
  content: "\F0116"; }

.mdi-cash-plus::before {
  content: "\F1261"; }

.mdi-cash-refund::before {
  content: "\F0A9C"; }

.mdi-cash-register::before {
  content: "\F0CF4"; }

.mdi-cash-remove::before {
  content: "\F1262"; }

.mdi-cash-usd::before {
  content: "\F1176"; }

.mdi-cash-usd-outline::before {
  content: "\F0117"; }

.mdi-cassette::before {
  content: "\F09D4"; }

.mdi-cast::before {
  content: "\F0118"; }

.mdi-cast-audio::before {
  content: "\F101E"; }

.mdi-cast-connected::before {
  content: "\F0119"; }

.mdi-cast-education::before {
  content: "\F0E1D"; }

.mdi-cast-off::before {
  content: "\F078A"; }

.mdi-castle::before {
  content: "\F011A"; }

.mdi-cat::before {
  content: "\F011B"; }

.mdi-cctv::before {
  content: "\F07AE"; }

.mdi-ceiling-light::before {
  content: "\F0769"; }

.mdi-cellphone::before {
  content: "\F011C"; }

.mdi-cellphone-android::before {
  content: "\F011D"; }

.mdi-cellphone-arrow-down::before {
  content: "\F09D5"; }

.mdi-cellphone-basic::before {
  content: "\F011E"; }

.mdi-cellphone-charging::before {
  content: "\F1397"; }

.mdi-cellphone-cog::before {
  content: "\F0951"; }

.mdi-cellphone-dock::before {
  content: "\F011F"; }

.mdi-cellphone-erase::before {
  content: "\F094D"; }

.mdi-cellphone-information::before {
  content: "\F0F41"; }

.mdi-cellphone-iphone::before {
  content: "\F0120"; }

.mdi-cellphone-key::before {
  content: "\F094E"; }

.mdi-cellphone-link::before {
  content: "\F0121"; }

.mdi-cellphone-link-off::before {
  content: "\F0122"; }

.mdi-cellphone-lock::before {
  content: "\F094F"; }

.mdi-cellphone-message::before {
  content: "\F08D3"; }

.mdi-cellphone-message-off::before {
  content: "\F10D2"; }

.mdi-cellphone-nfc::before {
  content: "\F0E90"; }

.mdi-cellphone-nfc-off::before {
  content: "\F12D8"; }

.mdi-cellphone-off::before {
  content: "\F0950"; }

.mdi-cellphone-play::before {
  content: "\F101F"; }

.mdi-cellphone-screenshot::before {
  content: "\F0A35"; }

.mdi-cellphone-settings::before {
  content: "\F0123"; }

.mdi-cellphone-sound::before {
  content: "\F0952"; }

.mdi-cellphone-text::before {
  content: "\F08D2"; }

.mdi-cellphone-wireless::before {
  content: "\F0815"; }

.mdi-celtic-cross::before {
  content: "\F0CF5"; }

.mdi-centos::before {
  content: "\F111A"; }

.mdi-certificate::before {
  content: "\F0124"; }

.mdi-certificate-outline::before {
  content: "\F1188"; }

.mdi-chair-rolling::before {
  content: "\F0F48"; }

.mdi-chair-school::before {
  content: "\F0125"; }

.mdi-charity::before {
  content: "\F0C4F"; }

.mdi-chart-arc::before {
  content: "\F0126"; }

.mdi-chart-areaspline::before {
  content: "\F0127"; }

.mdi-chart-areaspline-variant::before {
  content: "\F0E91"; }

.mdi-chart-bar::before {
  content: "\F0128"; }

.mdi-chart-bar-stacked::before {
  content: "\F076A"; }

.mdi-chart-bell-curve::before {
  content: "\F0C50"; }

.mdi-chart-bell-curve-cumulative::before {
  content: "\F0FA7"; }

.mdi-chart-box::before {
  content: "\F154D"; }

.mdi-chart-box-outline::before {
  content: "\F154E"; }

.mdi-chart-box-plus-outline::before {
  content: "\F154F"; }

.mdi-chart-bubble::before {
  content: "\F05E3"; }

.mdi-chart-donut::before {
  content: "\F07AF"; }

.mdi-chart-donut-variant::before {
  content: "\F07B0"; }

.mdi-chart-gantt::before {
  content: "\F066C"; }

.mdi-chart-histogram::before {
  content: "\F0129"; }

.mdi-chart-line::before {
  content: "\F012A"; }

.mdi-chart-line-stacked::before {
  content: "\F076B"; }

.mdi-chart-line-variant::before {
  content: "\F07B1"; }

.mdi-chart-multiline::before {
  content: "\F08D4"; }

.mdi-chart-multiple::before {
  content: "\F1213"; }

.mdi-chart-pie::before {
  content: "\F012B"; }

.mdi-chart-ppf::before {
  content: "\F1380"; }

.mdi-chart-sankey::before {
  content: "\F11DF"; }

.mdi-chart-sankey-variant::before {
  content: "\F11E0"; }

.mdi-chart-scatter-plot::before {
  content: "\F0E92"; }

.mdi-chart-scatter-plot-hexbin::before {
  content: "\F066D"; }

.mdi-chart-timeline::before {
  content: "\F066E"; }

.mdi-chart-timeline-variant::before {
  content: "\F0E93"; }

.mdi-chart-timeline-variant-shimmer::before {
  content: "\F15B6"; }

.mdi-chart-tree::before {
  content: "\F0E94"; }

.mdi-chat::before {
  content: "\F0B79"; }

.mdi-chat-alert::before {
  content: "\F0B7A"; }

.mdi-chat-alert-outline::before {
  content: "\F12C9"; }

.mdi-chat-minus::before {
  content: "\F1410"; }

.mdi-chat-minus-outline::before {
  content: "\F1413"; }

.mdi-chat-outline::before {
  content: "\F0EDE"; }

.mdi-chat-plus::before {
  content: "\F140F"; }

.mdi-chat-plus-outline::before {
  content: "\F1412"; }

.mdi-chat-processing::before {
  content: "\F0B7B"; }

.mdi-chat-processing-outline::before {
  content: "\F12CA"; }

.mdi-chat-remove::before {
  content: "\F1411"; }

.mdi-chat-remove-outline::before {
  content: "\F1414"; }

.mdi-chat-sleep::before {
  content: "\F12D1"; }

.mdi-chat-sleep-outline::before {
  content: "\F12D2"; }

.mdi-check::before {
  content: "\F012C"; }

.mdi-check-all::before {
  content: "\F012D"; }

.mdi-check-bold::before {
  content: "\F0E1E"; }

.mdi-check-box-multiple-outline::before {
  content: "\F0C51"; }

.mdi-check-box-outline::before {
  content: "\F0C52"; }

.mdi-check-circle::before {
  content: "\F05E0"; }

.mdi-check-circle-outline::before {
  content: "\F05E1"; }

.mdi-check-decagram::before {
  content: "\F0791"; }

.mdi-check-network::before {
  content: "\F0C53"; }

.mdi-check-network-outline::before {
  content: "\F0C54"; }

.mdi-check-outline::before {
  content: "\F0855"; }

.mdi-check-underline::before {
  content: "\F0E1F"; }

.mdi-check-underline-circle::before {
  content: "\F0E20"; }

.mdi-check-underline-circle-outline::before {
  content: "\F0E21"; }

.mdi-checkbook::before {
  content: "\F0A9D"; }

.mdi-checkbox-blank::before {
  content: "\F012E"; }

.mdi-checkbox-blank-circle::before {
  content: "\F012F"; }

.mdi-checkbox-blank-circle-outline::before {
  content: "\F0130"; }

.mdi-checkbox-blank-off::before {
  content: "\F12EC"; }

.mdi-checkbox-blank-off-outline::before {
  content: "\F12ED"; }

.mdi-checkbox-blank-outline::before {
  content: "\F0131"; }

.mdi-checkbox-intermediate::before {
  content: "\F0856"; }

.mdi-checkbox-marked::before {
  content: "\F0132"; }

.mdi-checkbox-marked-circle::before {
  content: "\F0133"; }

.mdi-checkbox-marked-circle-outline::before {
  content: "\F0134"; }

.mdi-checkbox-marked-outline::before {
  content: "\F0135"; }

.mdi-checkbox-multiple-blank::before {
  content: "\F0136"; }

.mdi-checkbox-multiple-blank-circle::before {
  content: "\F063B"; }

.mdi-checkbox-multiple-blank-circle-outline::before {
  content: "\F063C"; }

.mdi-checkbox-multiple-blank-outline::before {
  content: "\F0137"; }

.mdi-checkbox-multiple-marked::before {
  content: "\F0138"; }

.mdi-checkbox-multiple-marked-circle::before {
  content: "\F063D"; }

.mdi-checkbox-multiple-marked-circle-outline::before {
  content: "\F063E"; }

.mdi-checkbox-multiple-marked-outline::before {
  content: "\F0139"; }

.mdi-checkerboard::before {
  content: "\F013A"; }

.mdi-checkerboard-minus::before {
  content: "\F1202"; }

.mdi-checkerboard-plus::before {
  content: "\F1201"; }

.mdi-checkerboard-remove::before {
  content: "\F1203"; }

.mdi-cheese::before {
  content: "\F12B9"; }

.mdi-cheese-off::before {
  content: "\F13EE"; }

.mdi-chef-hat::before {
  content: "\F0B7C"; }

.mdi-chemical-weapon::before {
  content: "\F013B"; }

.mdi-chess-bishop::before {
  content: "\F085C"; }

.mdi-chess-king::before {
  content: "\F0857"; }

.mdi-chess-knight::before {
  content: "\F0858"; }

.mdi-chess-pawn::before {
  content: "\F0859"; }

.mdi-chess-queen::before {
  content: "\F085A"; }

.mdi-chess-rook::before {
  content: "\F085B"; }

.mdi-chevron-double-down::before {
  content: "\F013C"; }

.mdi-chevron-double-left::before {
  content: "\F013D"; }

.mdi-chevron-double-right::before {
  content: "\F013E"; }

.mdi-chevron-double-up::before {
  content: "\F013F"; }

.mdi-chevron-down::before {
  content: "\F0140"; }

.mdi-chevron-down-box::before {
  content: "\F09D6"; }

.mdi-chevron-down-box-outline::before {
  content: "\F09D7"; }

.mdi-chevron-down-circle::before {
  content: "\F0B26"; }

.mdi-chevron-down-circle-outline::before {
  content: "\F0B27"; }

.mdi-chevron-left::before {
  content: "\F0141"; }

.mdi-chevron-left-box::before {
  content: "\F09D8"; }

.mdi-chevron-left-box-outline::before {
  content: "\F09D9"; }

.mdi-chevron-left-circle::before {
  content: "\F0B28"; }

.mdi-chevron-left-circle-outline::before {
  content: "\F0B29"; }

.mdi-chevron-right::before {
  content: "\F0142"; }

.mdi-chevron-right-box::before {
  content: "\F09DA"; }

.mdi-chevron-right-box-outline::before {
  content: "\F09DB"; }

.mdi-chevron-right-circle::before {
  content: "\F0B2A"; }

.mdi-chevron-right-circle-outline::before {
  content: "\F0B2B"; }

.mdi-chevron-triple-down::before {
  content: "\F0DB9"; }

.mdi-chevron-triple-left::before {
  content: "\F0DBA"; }

.mdi-chevron-triple-right::before {
  content: "\F0DBB"; }

.mdi-chevron-triple-up::before {
  content: "\F0DBC"; }

.mdi-chevron-up::before {
  content: "\F0143"; }

.mdi-chevron-up-box::before {
  content: "\F09DC"; }

.mdi-chevron-up-box-outline::before {
  content: "\F09DD"; }

.mdi-chevron-up-circle::before {
  content: "\F0B2C"; }

.mdi-chevron-up-circle-outline::before {
  content: "\F0B2D"; }

.mdi-chili-hot::before {
  content: "\F07B2"; }

.mdi-chili-medium::before {
  content: "\F07B3"; }

.mdi-chili-mild::before {
  content: "\F07B4"; }

.mdi-chili-off::before {
  content: "\F1467"; }

.mdi-chip::before {
  content: "\F061A"; }

.mdi-christianity::before {
  content: "\F0953"; }

.mdi-christianity-outline::before {
  content: "\F0CF6"; }

.mdi-church::before {
  content: "\F0144"; }

.mdi-cigar::before {
  content: "\F1189"; }

.mdi-cigar-off::before {
  content: "\F141B"; }

.mdi-circle::before {
  content: "\F0765"; }

.mdi-circle-box::before {
  content: "\F15DC"; }

.mdi-circle-box-outline::before {
  content: "\F15DD"; }

.mdi-circle-double::before {
  content: "\F0E95"; }

.mdi-circle-edit-outline::before {
  content: "\F08D5"; }

.mdi-circle-expand::before {
  content: "\F0E96"; }

.mdi-circle-half::before {
  content: "\F1395"; }

.mdi-circle-half-full::before {
  content: "\F1396"; }

.mdi-circle-medium::before {
  content: "\F09DE"; }

.mdi-circle-multiple::before {
  content: "\F0B38"; }

.mdi-circle-multiple-outline::before {
  content: "\F0695"; }

.mdi-circle-off-outline::before {
  content: "\F10D3"; }

.mdi-circle-outline::before {
  content: "\F0766"; }

.mdi-circle-slice-1::before {
  content: "\F0A9E"; }

.mdi-circle-slice-2::before {
  content: "\F0A9F"; }

.mdi-circle-slice-3::before {
  content: "\F0AA0"; }

.mdi-circle-slice-4::before {
  content: "\F0AA1"; }

.mdi-circle-slice-5::before {
  content: "\F0AA2"; }

.mdi-circle-slice-6::before {
  content: "\F0AA3"; }

.mdi-circle-slice-7::before {
  content: "\F0AA4"; }

.mdi-circle-slice-8::before {
  content: "\F0AA5"; }

.mdi-circle-small::before {
  content: "\F09DF"; }

.mdi-circular-saw::before {
  content: "\F0E22"; }

.mdi-city::before {
  content: "\F0146"; }

.mdi-city-variant::before {
  content: "\F0A36"; }

.mdi-city-variant-outline::before {
  content: "\F0A37"; }

.mdi-clipboard::before {
  content: "\F0147"; }

.mdi-clipboard-account::before {
  content: "\F0148"; }

.mdi-clipboard-account-outline::before {
  content: "\F0C55"; }

.mdi-clipboard-alert::before {
  content: "\F0149"; }

.mdi-clipboard-alert-outline::before {
  content: "\F0CF7"; }

.mdi-clipboard-arrow-down::before {
  content: "\F014A"; }

.mdi-clipboard-arrow-down-outline::before {
  content: "\F0C56"; }

.mdi-clipboard-arrow-left::before {
  content: "\F014B"; }

.mdi-clipboard-arrow-left-outline::before {
  content: "\F0CF8"; }

.mdi-clipboard-arrow-right::before {
  content: "\F0CF9"; }

.mdi-clipboard-arrow-right-outline::before {
  content: "\F0CFA"; }

.mdi-clipboard-arrow-up::before {
  content: "\F0C57"; }

.mdi-clipboard-arrow-up-outline::before {
  content: "\F0C58"; }

.mdi-clipboard-check::before {
  content: "\F014E"; }

.mdi-clipboard-check-multiple::before {
  content: "\F1263"; }

.mdi-clipboard-check-multiple-outline::before {
  content: "\F1264"; }

.mdi-clipboard-check-outline::before {
  content: "\F08A8"; }

.mdi-clipboard-edit::before {
  content: "\F14E5"; }

.mdi-clipboard-edit-outline::before {
  content: "\F14E6"; }

.mdi-clipboard-file::before {
  content: "\F1265"; }

.mdi-clipboard-file-outline::before {
  content: "\F1266"; }

.mdi-clipboard-flow::before {
  content: "\F06C8"; }

.mdi-clipboard-flow-outline::before {
  content: "\F1117"; }

.mdi-clipboard-list::before {
  content: "\F10D4"; }

.mdi-clipboard-list-outline::before {
  content: "\F10D5"; }

.mdi-clipboard-minus::before {
  content: "\F1618"; }

.mdi-clipboard-minus-outline::before {
  content: "\F1619"; }

.mdi-clipboard-multiple::before {
  content: "\F1267"; }

.mdi-clipboard-multiple-outline::before {
  content: "\F1268"; }

.mdi-clipboard-off::before {
  content: "\F161A"; }

.mdi-clipboard-off-outline::before {
  content: "\F161B"; }

.mdi-clipboard-outline::before {
  content: "\F014C"; }

.mdi-clipboard-play::before {
  content: "\F0C59"; }

.mdi-clipboard-play-multiple::before {
  content: "\F1269"; }

.mdi-clipboard-play-multiple-outline::before {
  content: "\F126A"; }

.mdi-clipboard-play-outline::before {
  content: "\F0C5A"; }

.mdi-clipboard-plus::before {
  content: "\F0751"; }

.mdi-clipboard-plus-outline::before {
  content: "\F131F"; }

.mdi-clipboard-pulse::before {
  content: "\F085D"; }

.mdi-clipboard-pulse-outline::before {
  content: "\F085E"; }

.mdi-clipboard-remove::before {
  content: "\F161C"; }

.mdi-clipboard-remove-outline::before {
  content: "\F161D"; }

.mdi-clipboard-search::before {
  content: "\F161E"; }

.mdi-clipboard-search-outline::before {
  content: "\F161F"; }

.mdi-clipboard-text::before {
  content: "\F014D"; }

.mdi-clipboard-text-multiple::before {
  content: "\F126B"; }

.mdi-clipboard-text-multiple-outline::before {
  content: "\F126C"; }

.mdi-clipboard-text-off::before {
  content: "\F1620"; }

.mdi-clipboard-text-off-outline::before {
  content: "\F1621"; }

.mdi-clipboard-text-outline::before {
  content: "\F0A38"; }

.mdi-clipboard-text-play::before {
  content: "\F0C5B"; }

.mdi-clipboard-text-play-outline::before {
  content: "\F0C5C"; }

.mdi-clipboard-text-search::before {
  content: "\F1622"; }

.mdi-clipboard-text-search-outline::before {
  content: "\F1623"; }

.mdi-clippy::before {
  content: "\F014F"; }

.mdi-clock::before {
  content: "\F0954"; }

.mdi-clock-alert::before {
  content: "\F0955"; }

.mdi-clock-alert-outline::before {
  content: "\F05CE"; }

.mdi-clock-check::before {
  content: "\F0FA8"; }

.mdi-clock-check-outline::before {
  content: "\F0FA9"; }

.mdi-clock-digital::before {
  content: "\F0E97"; }

.mdi-clock-end::before {
  content: "\F0151"; }

.mdi-clock-fast::before {
  content: "\F0152"; }

.mdi-clock-in::before {
  content: "\F0153"; }

.mdi-clock-out::before {
  content: "\F0154"; }

.mdi-clock-outline::before {
  content: "\F0150"; }

.mdi-clock-start::before {
  content: "\F0155"; }

.mdi-clock-time-eight::before {
  content: "\F1446"; }

.mdi-clock-time-eight-outline::before {
  content: "\F1452"; }

.mdi-clock-time-eleven::before {
  content: "\F1449"; }

.mdi-clock-time-eleven-outline::before {
  content: "\F1455"; }

.mdi-clock-time-five::before {
  content: "\F1443"; }

.mdi-clock-time-five-outline::before {
  content: "\F144F"; }

.mdi-clock-time-four::before {
  content: "\F1442"; }

.mdi-clock-time-four-outline::before {
  content: "\F144E"; }

.mdi-clock-time-nine::before {
  content: "\F1447"; }

.mdi-clock-time-nine-outline::before {
  content: "\F1453"; }

.mdi-clock-time-one::before {
  content: "\F143F"; }

.mdi-clock-time-one-outline::before {
  content: "\F144B"; }

.mdi-clock-time-seven::before {
  content: "\F1445"; }

.mdi-clock-time-seven-outline::before {
  content: "\F1451"; }

.mdi-clock-time-six::before {
  content: "\F1444"; }

.mdi-clock-time-six-outline::before {
  content: "\F1450"; }

.mdi-clock-time-ten::before {
  content: "\F1448"; }

.mdi-clock-time-ten-outline::before {
  content: "\F1454"; }

.mdi-clock-time-three::before {
  content: "\F1441"; }

.mdi-clock-time-three-outline::before {
  content: "\F144D"; }

.mdi-clock-time-twelve::before {
  content: "\F144A"; }

.mdi-clock-time-twelve-outline::before {
  content: "\F1456"; }

.mdi-clock-time-two::before {
  content: "\F1440"; }

.mdi-clock-time-two-outline::before {
  content: "\F144C"; }

.mdi-close::before {
  content: "\F0156"; }

.mdi-close-box::before {
  content: "\F0157"; }

.mdi-close-box-multiple::before {
  content: "\F0C5D"; }

.mdi-close-box-multiple-outline::before {
  content: "\F0C5E"; }

.mdi-close-box-outline::before {
  content: "\F0158"; }

.mdi-close-circle::before {
  content: "\F0159"; }

.mdi-close-circle-multiple::before {
  content: "\F062A"; }

.mdi-close-circle-multiple-outline::before {
  content: "\F0883"; }

.mdi-close-circle-outline::before {
  content: "\F015A"; }

.mdi-close-network::before {
  content: "\F015B"; }

.mdi-close-network-outline::before {
  content: "\F0C5F"; }

.mdi-close-octagon::before {
  content: "\F015C"; }

.mdi-close-octagon-outline::before {
  content: "\F015D"; }

.mdi-close-outline::before {
  content: "\F06C9"; }

.mdi-close-thick::before {
  content: "\F1398"; }

.mdi-closed-caption::before {
  content: "\F015E"; }

.mdi-closed-caption-outline::before {
  content: "\F0DBD"; }

.mdi-cloud::before {
  content: "\F015F"; }

.mdi-cloud-alert::before {
  content: "\F09E0"; }

.mdi-cloud-braces::before {
  content: "\F07B5"; }

.mdi-cloud-check::before {
  content: "\F0160"; }

.mdi-cloud-check-outline::before {
  content: "\F12CC"; }

.mdi-cloud-circle::before {
  content: "\F0161"; }

.mdi-cloud-download::before {
  content: "\F0162"; }

.mdi-cloud-download-outline::before {
  content: "\F0B7D"; }

.mdi-cloud-lock::before {
  content: "\F11F1"; }

.mdi-cloud-lock-outline::before {
  content: "\F11F2"; }

.mdi-cloud-off-outline::before {
  content: "\F0164"; }

.mdi-cloud-outline::before {
  content: "\F0163"; }

.mdi-cloud-print::before {
  content: "\F0165"; }

.mdi-cloud-print-outline::before {
  content: "\F0166"; }

.mdi-cloud-question::before {
  content: "\F0A39"; }

.mdi-cloud-refresh::before {
  content: "\F052A"; }

.mdi-cloud-search::before {
  content: "\F0956"; }

.mdi-cloud-search-outline::before {
  content: "\F0957"; }

.mdi-cloud-sync::before {
  content: "\F063F"; }

.mdi-cloud-sync-outline::before {
  content: "\F12D6"; }

.mdi-cloud-tags::before {
  content: "\F07B6"; }

.mdi-cloud-upload::before {
  content: "\F0167"; }

.mdi-cloud-upload-outline::before {
  content: "\F0B7E"; }

.mdi-clover::before {
  content: "\F0816"; }

.mdi-coach-lamp::before {
  content: "\F1020"; }

.mdi-coat-rack::before {
  content: "\F109E"; }

.mdi-code-array::before {
  content: "\F0168"; }

.mdi-code-braces::before {
  content: "\F0169"; }

.mdi-code-braces-box::before {
  content: "\F10D6"; }

.mdi-code-brackets::before {
  content: "\F016A"; }

.mdi-code-equal::before {
  content: "\F016B"; }

.mdi-code-greater-than::before {
  content: "\F016C"; }

.mdi-code-greater-than-or-equal::before {
  content: "\F016D"; }

.mdi-code-json::before {
  content: "\F0626"; }

.mdi-code-less-than::before {
  content: "\F016E"; }

.mdi-code-less-than-or-equal::before {
  content: "\F016F"; }

.mdi-code-not-equal::before {
  content: "\F0170"; }

.mdi-code-not-equal-variant::before {
  content: "\F0171"; }

.mdi-code-parentheses::before {
  content: "\F0172"; }

.mdi-code-parentheses-box::before {
  content: "\F10D7"; }

.mdi-code-string::before {
  content: "\F0173"; }

.mdi-code-tags::before {
  content: "\F0174"; }

.mdi-code-tags-check::before {
  content: "\F0694"; }

.mdi-codepen::before {
  content: "\F0175"; }

.mdi-coffee::before {
  content: "\F0176"; }

.mdi-coffee-maker::before {
  content: "\F109F"; }

.mdi-coffee-off::before {
  content: "\F0FAA"; }

.mdi-coffee-off-outline::before {
  content: "\F0FAB"; }

.mdi-coffee-outline::before {
  content: "\F06CA"; }

.mdi-coffee-to-go::before {
  content: "\F0177"; }

.mdi-coffee-to-go-outline::before {
  content: "\F130E"; }

.mdi-coffin::before {
  content: "\F0B7F"; }

.mdi-cog::before {
  content: "\F0493"; }

.mdi-cog-box::before {
  content: "\F0494"; }

.mdi-cog-clockwise::before {
  content: "\F11DD"; }

.mdi-cog-counterclockwise::before {
  content: "\F11DE"; }

.mdi-cog-off::before {
  content: "\F13CE"; }

.mdi-cog-off-outline::before {
  content: "\F13CF"; }

.mdi-cog-outline::before {
  content: "\F08BB"; }

.mdi-cog-refresh::before {
  content: "\F145E"; }

.mdi-cog-refresh-outline::before {
  content: "\F145F"; }

.mdi-cog-sync::before {
  content: "\F1460"; }

.mdi-cog-sync-outline::before {
  content: "\F1461"; }

.mdi-cog-transfer::before {
  content: "\F105B"; }

.mdi-cog-transfer-outline::before {
  content: "\F105C"; }

.mdi-cogs::before {
  content: "\F08D6"; }

.mdi-collage::before {
  content: "\F0640"; }

.mdi-collapse-all::before {
  content: "\F0AA6"; }

.mdi-collapse-all-outline::before {
  content: "\F0AA7"; }

.mdi-color-helper::before {
  content: "\F0179"; }

.mdi-comma::before {
  content: "\F0E23"; }

.mdi-comma-box::before {
  content: "\F0E2B"; }

.mdi-comma-box-outline::before {
  content: "\F0E24"; }

.mdi-comma-circle::before {
  content: "\F0E25"; }

.mdi-comma-circle-outline::before {
  content: "\F0E26"; }

.mdi-comment::before {
  content: "\F017A"; }

.mdi-comment-account::before {
  content: "\F017B"; }

.mdi-comment-account-outline::before {
  content: "\F017C"; }

.mdi-comment-alert::before {
  content: "\F017D"; }

.mdi-comment-alert-outline::before {
  content: "\F017E"; }

.mdi-comment-arrow-left::before {
  content: "\F09E1"; }

.mdi-comment-arrow-left-outline::before {
  content: "\F09E2"; }

.mdi-comment-arrow-right::before {
  content: "\F09E3"; }

.mdi-comment-arrow-right-outline::before {
  content: "\F09E4"; }

.mdi-comment-bookmark::before {
  content: "\F15AE"; }

.mdi-comment-bookmark-outline::before {
  content: "\F15AF"; }

.mdi-comment-check::before {
  content: "\F017F"; }

.mdi-comment-check-outline::before {
  content: "\F0180"; }

.mdi-comment-edit::before {
  content: "\F11BF"; }

.mdi-comment-edit-outline::before {
  content: "\F12C4"; }

.mdi-comment-eye::before {
  content: "\F0A3A"; }

.mdi-comment-eye-outline::before {
  content: "\F0A3B"; }

.mdi-comment-flash::before {
  content: "\F15B0"; }

.mdi-comment-flash-outline::before {
  content: "\F15B1"; }

.mdi-comment-minus::before {
  content: "\F15DF"; }

.mdi-comment-minus-outline::before {
  content: "\F15E0"; }

.mdi-comment-multiple::before {
  content: "\F085F"; }

.mdi-comment-multiple-outline::before {
  content: "\F0181"; }

.mdi-comment-off::before {
  content: "\F15E1"; }

.mdi-comment-off-outline::before {
  content: "\F15E2"; }

.mdi-comment-outline::before {
  content: "\F0182"; }

.mdi-comment-plus::before {
  content: "\F09E5"; }

.mdi-comment-plus-outline::before {
  content: "\F0183"; }

.mdi-comment-processing::before {
  content: "\F0184"; }

.mdi-comment-processing-outline::before {
  content: "\F0185"; }

.mdi-comment-question::before {
  content: "\F0817"; }

.mdi-comment-question-outline::before {
  content: "\F0186"; }

.mdi-comment-quote::before {
  content: "\F1021"; }

.mdi-comment-quote-outline::before {
  content: "\F1022"; }

.mdi-comment-remove::before {
  content: "\F05DE"; }

.mdi-comment-remove-outline::before {
  content: "\F0187"; }

.mdi-comment-search::before {
  content: "\F0A3C"; }

.mdi-comment-search-outline::before {
  content: "\F0A3D"; }

.mdi-comment-text::before {
  content: "\F0188"; }

.mdi-comment-text-multiple::before {
  content: "\F0860"; }

.mdi-comment-text-multiple-outline::before {
  content: "\F0861"; }

.mdi-comment-text-outline::before {
  content: "\F0189"; }

.mdi-compare::before {
  content: "\F018A"; }

.mdi-compare-horizontal::before {
  content: "\F1492"; }

.mdi-compare-vertical::before {
  content: "\F1493"; }

.mdi-compass::before {
  content: "\F018B"; }

.mdi-compass-off::before {
  content: "\F0B80"; }

.mdi-compass-off-outline::before {
  content: "\F0B81"; }

.mdi-compass-outline::before {
  content: "\F018C"; }

.mdi-compass-rose::before {
  content: "\F1382"; }

.mdi-concourse-ci::before {
  content: "\F10A0"; }

.mdi-connection::before {
  content: "\F1616"; }

.mdi-console::before {
  content: "\F018D"; }

.mdi-console-line::before {
  content: "\F07B7"; }

.mdi-console-network::before {
  content: "\F08A9"; }

.mdi-console-network-outline::before {
  content: "\F0C60"; }

.mdi-consolidate::before {
  content: "\F10D8"; }

.mdi-contactless-payment::before {
  content: "\F0D6A"; }

.mdi-contactless-payment-circle::before {
  content: "\F0321"; }

.mdi-contactless-payment-circle-outline::before {
  content: "\F0408"; }

.mdi-contacts::before {
  content: "\F06CB"; }

.mdi-contacts-outline::before {
  content: "\F05B8"; }

.mdi-contain::before {
  content: "\F0A3E"; }

.mdi-contain-end::before {
  content: "\F0A3F"; }

.mdi-contain-start::before {
  content: "\F0A40"; }

.mdi-content-copy::before {
  content: "\F018F"; }

.mdi-content-cut::before {
  content: "\F0190"; }

.mdi-content-duplicate::before {
  content: "\F0191"; }

.mdi-content-paste::before {
  content: "\F0192"; }

.mdi-content-save::before {
  content: "\F0193"; }

.mdi-content-save-alert::before {
  content: "\F0F42"; }

.mdi-content-save-alert-outline::before {
  content: "\F0F43"; }

.mdi-content-save-all::before {
  content: "\F0194"; }

.mdi-content-save-all-outline::before {
  content: "\F0F44"; }

.mdi-content-save-cog::before {
  content: "\F145B"; }

.mdi-content-save-cog-outline::before {
  content: "\F145C"; }

.mdi-content-save-edit::before {
  content: "\F0CFB"; }

.mdi-content-save-edit-outline::before {
  content: "\F0CFC"; }

.mdi-content-save-move::before {
  content: "\F0E27"; }

.mdi-content-save-move-outline::before {
  content: "\F0E28"; }

.mdi-content-save-off::before {
  content: "\F1643"; }

.mdi-content-save-off-outline::before {
  content: "\F1644"; }

.mdi-content-save-outline::before {
  content: "\F0818"; }

.mdi-content-save-settings::before {
  content: "\F061B"; }

.mdi-content-save-settings-outline::before {
  content: "\F0B2E"; }

.mdi-contrast::before {
  content: "\F0195"; }

.mdi-contrast-box::before {
  content: "\F0196"; }

.mdi-contrast-circle::before {
  content: "\F0197"; }

.mdi-controller-classic::before {
  content: "\F0B82"; }

.mdi-controller-classic-outline::before {
  content: "\F0B83"; }

.mdi-cookie::before {
  content: "\F0198"; }

.mdi-cookie-alert::before {
  content: "\F16D0"; }

.mdi-cookie-alert-outline::before {
  content: "\F16D1"; }

.mdi-cookie-check::before {
  content: "\F16D2"; }

.mdi-cookie-check-outline::before {
  content: "\F16D3"; }

.mdi-cookie-cog::before {
  content: "\F16D4"; }

.mdi-cookie-cog-outline::before {
  content: "\F16D5"; }

.mdi-cookie-minus::before {
  content: "\F16DA"; }

.mdi-cookie-minus-outline::before {
  content: "\F16DB"; }

.mdi-cookie-outline::before {
  content: "\F16DE"; }

.mdi-cookie-plus::before {
  content: "\F16D6"; }

.mdi-cookie-plus-outline::before {
  content: "\F16D7"; }

.mdi-cookie-remove::before {
  content: "\F16D8"; }

.mdi-cookie-remove-outline::before {
  content: "\F16D9"; }

.mdi-cookie-settings::before {
  content: "\F16DC"; }

.mdi-cookie-settings-outline::before {
  content: "\F16DD"; }

.mdi-coolant-temperature::before {
  content: "\F03C8"; }

.mdi-copyright::before {
  content: "\F05E6"; }

.mdi-cordova::before {
  content: "\F0958"; }

.mdi-corn::before {
  content: "\F07B8"; }

.mdi-corn-off::before {
  content: "\F13EF"; }

.mdi-cosine-wave::before {
  content: "\F1479"; }

.mdi-counter::before {
  content: "\F0199"; }

.mdi-cow::before {
  content: "\F019A"; }

.mdi-cpu-32-bit::before {
  content: "\F0EDF"; }

.mdi-cpu-64-bit::before {
  content: "\F0EE0"; }

.mdi-crane::before {
  content: "\F0862"; }

.mdi-creation::before {
  content: "\F0674"; }

.mdi-creative-commons::before {
  content: "\F0D6B"; }

.mdi-credit-card::before {
  content: "\F0FEF"; }

.mdi-credit-card-check::before {
  content: "\F13D0"; }

.mdi-credit-card-check-outline::before {
  content: "\F13D1"; }

.mdi-credit-card-clock::before {
  content: "\F0EE1"; }

.mdi-credit-card-clock-outline::before {
  content: "\F0EE2"; }

.mdi-credit-card-marker::before {
  content: "\F06A8"; }

.mdi-credit-card-marker-outline::before {
  content: "\F0DBE"; }

.mdi-credit-card-minus::before {
  content: "\F0FAC"; }

.mdi-credit-card-minus-outline::before {
  content: "\F0FAD"; }

.mdi-credit-card-multiple::before {
  content: "\F0FF0"; }

.mdi-credit-card-multiple-outline::before {
  content: "\F019C"; }

.mdi-credit-card-off::before {
  content: "\F0FF1"; }

.mdi-credit-card-off-outline::before {
  content: "\F05E4"; }

.mdi-credit-card-outline::before {
  content: "\F019B"; }

.mdi-credit-card-plus::before {
  content: "\F0FF2"; }

.mdi-credit-card-plus-outline::before {
  content: "\F0676"; }

.mdi-credit-card-refresh::before {
  content: "\F1645"; }

.mdi-credit-card-refresh-outline::before {
  content: "\F1646"; }

.mdi-credit-card-refund::before {
  content: "\F0FF3"; }

.mdi-credit-card-refund-outline::before {
  content: "\F0AA8"; }

.mdi-credit-card-remove::before {
  content: "\F0FAE"; }

.mdi-credit-card-remove-outline::before {
  content: "\F0FAF"; }

.mdi-credit-card-scan::before {
  content: "\F0FF4"; }

.mdi-credit-card-scan-outline::before {
  content: "\F019D"; }

.mdi-credit-card-search::before {
  content: "\F1647"; }

.mdi-credit-card-search-outline::before {
  content: "\F1648"; }

.mdi-credit-card-settings::before {
  content: "\F0FF5"; }

.mdi-credit-card-settings-outline::before {
  content: "\F08D7"; }

.mdi-credit-card-sync::before {
  content: "\F1649"; }

.mdi-credit-card-sync-outline::before {
  content: "\F164A"; }

.mdi-credit-card-wireless::before {
  content: "\F0802"; }

.mdi-credit-card-wireless-off::before {
  content: "\F057A"; }

.mdi-credit-card-wireless-off-outline::before {
  content: "\F057B"; }

.mdi-credit-card-wireless-outline::before {
  content: "\F0D6C"; }

.mdi-cricket::before {
  content: "\F0D6D"; }

.mdi-crop::before {
  content: "\F019E"; }

.mdi-crop-free::before {
  content: "\F019F"; }

.mdi-crop-landscape::before {
  content: "\F01A0"; }

.mdi-crop-portrait::before {
  content: "\F01A1"; }

.mdi-crop-rotate::before {
  content: "\F0696"; }

.mdi-crop-square::before {
  content: "\F01A2"; }

.mdi-crosshairs::before {
  content: "\F01A3"; }

.mdi-crosshairs-gps::before {
  content: "\F01A4"; }

.mdi-crosshairs-off::before {
  content: "\F0F45"; }

.mdi-crosshairs-question::before {
  content: "\F1136"; }

.mdi-crown::before {
  content: "\F01A5"; }

.mdi-crown-outline::before {
  content: "\F11D0"; }

.mdi-cryengine::before {
  content: "\F0959"; }

.mdi-crystal-ball::before {
  content: "\F0B2F"; }

.mdi-cube::before {
  content: "\F01A6"; }

.mdi-cube-off::before {
  content: "\F141C"; }

.mdi-cube-off-outline::before {
  content: "\F141D"; }

.mdi-cube-outline::before {
  content: "\F01A7"; }

.mdi-cube-scan::before {
  content: "\F0B84"; }

.mdi-cube-send::before {
  content: "\F01A8"; }

.mdi-cube-unfolded::before {
  content: "\F01A9"; }

.mdi-cup::before {
  content: "\F01AA"; }

.mdi-cup-off::before {
  content: "\F05E5"; }

.mdi-cup-off-outline::before {
  content: "\F137D"; }

.mdi-cup-outline::before {
  content: "\F130F"; }

.mdi-cup-water::before {
  content: "\F01AB"; }

.mdi-cupboard::before {
  content: "\F0F46"; }

.mdi-cupboard-outline::before {
  content: "\F0F47"; }

.mdi-cupcake::before {
  content: "\F095A"; }

.mdi-curling::before {
  content: "\F0863"; }

.mdi-currency-bdt::before {
  content: "\F0864"; }

.mdi-currency-brl::before {
  content: "\F0B85"; }

.mdi-currency-btc::before {
  content: "\F01AC"; }

.mdi-currency-cny::before {
  content: "\F07BA"; }

.mdi-currency-eth::before {
  content: "\F07BB"; }

.mdi-currency-eur::before {
  content: "\F01AD"; }

.mdi-currency-eur-off::before {
  content: "\F1315"; }

.mdi-currency-gbp::before {
  content: "\F01AE"; }

.mdi-currency-ils::before {
  content: "\F0C61"; }

.mdi-currency-inr::before {
  content: "\F01AF"; }

.mdi-currency-jpy::before {
  content: "\F07BC"; }

.mdi-currency-krw::before {
  content: "\F07BD"; }

.mdi-currency-kzt::before {
  content: "\F0865"; }

.mdi-currency-mnt::before {
  content: "\F1512"; }

.mdi-currency-ngn::before {
  content: "\F01B0"; }

.mdi-currency-php::before {
  content: "\F09E6"; }

.mdi-currency-rial::before {
  content: "\F0E9C"; }

.mdi-currency-rub::before {
  content: "\F01B1"; }

.mdi-currency-sign::before {
  content: "\F07BE"; }

.mdi-currency-try::before {
  content: "\F01B2"; }

.mdi-currency-twd::before {
  content: "\F07BF"; }

.mdi-currency-usd::before {
  content: "\F01C1"; }

.mdi-currency-usd-circle::before {
  content: "\F116B"; }

.mdi-currency-usd-circle-outline::before {
  content: "\F0178"; }

.mdi-currency-usd-off::before {
  content: "\F067A"; }

.mdi-current-ac::before {
  content: "\F1480"; }

.mdi-current-dc::before {
  content: "\F095C"; }

.mdi-cursor-default::before {
  content: "\F01C0"; }

.mdi-cursor-default-click::before {
  content: "\F0CFD"; }

.mdi-cursor-default-click-outline::before {
  content: "\F0CFE"; }

.mdi-cursor-default-gesture::before {
  content: "\F1127"; }

.mdi-cursor-default-gesture-outline::before {
  content: "\F1128"; }

.mdi-cursor-default-outline::before {
  content: "\F01BF"; }

.mdi-cursor-move::before {
  content: "\F01BE"; }

.mdi-cursor-pointer::before {
  content: "\F01BD"; }

.mdi-cursor-text::before {
  content: "\F05E7"; }

.mdi-dance-ballroom::before {
  content: "\F15FB"; }

.mdi-dance-pole::before {
  content: "\F1578"; }

.mdi-data-matrix::before {
  content: "\F153C"; }

.mdi-data-matrix-edit::before {
  content: "\F153D"; }

.mdi-data-matrix-minus::before {
  content: "\F153E"; }

.mdi-data-matrix-plus::before {
  content: "\F153F"; }

.mdi-data-matrix-remove::before {
  content: "\F1540"; }

.mdi-data-matrix-scan::before {
  content: "\F1541"; }

.mdi-database::before {
  content: "\F01BC"; }

.mdi-database-alert::before {
  content: "\F163A"; }

.mdi-database-alert-outline::before {
  content: "\F1624"; }

.mdi-database-arrow-down::before {
  content: "\F163B"; }

.mdi-database-arrow-down-outline::before {
  content: "\F1625"; }

.mdi-database-arrow-left::before {
  content: "\F163C"; }

.mdi-database-arrow-left-outline::before {
  content: "\F1626"; }

.mdi-database-arrow-right::before {
  content: "\F163D"; }

.mdi-database-arrow-right-outline::before {
  content: "\F1627"; }

.mdi-database-arrow-up::before {
  content: "\F163E"; }

.mdi-database-arrow-up-outline::before {
  content: "\F1628"; }

.mdi-database-check::before {
  content: "\F0AA9"; }

.mdi-database-check-outline::before {
  content: "\F1629"; }

.mdi-database-clock::before {
  content: "\F163F"; }

.mdi-database-clock-outline::before {
  content: "\F162A"; }

.mdi-database-cog::before {
  content: "\F164B"; }

.mdi-database-cog-outline::before {
  content: "\F164C"; }

.mdi-database-edit::before {
  content: "\F0B86"; }

.mdi-database-edit-outline::before {
  content: "\F162B"; }

.mdi-database-export::before {
  content: "\F095E"; }

.mdi-database-export-outline::before {
  content: "\F162C"; }

.mdi-database-import::before {
  content: "\F095D"; }

.mdi-database-import-outline::before {
  content: "\F162D"; }

.mdi-database-lock::before {
  content: "\F0AAA"; }

.mdi-database-lock-outline::before {
  content: "\F162E"; }

.mdi-database-marker::before {
  content: "\F12F6"; }

.mdi-database-marker-outline::before {
  content: "\F162F"; }

.mdi-database-minus::before {
  content: "\F01BB"; }

.mdi-database-minus-outline::before {
  content: "\F1630"; }

.mdi-database-off::before {
  content: "\F1640"; }

.mdi-database-off-outline::before {
  content: "\F1631"; }

.mdi-database-outline::before {
  content: "\F1632"; }

.mdi-database-plus::before {
  content: "\F01BA"; }

.mdi-database-plus-outline::before {
  content: "\F1633"; }

.mdi-database-refresh::before {
  content: "\F05C2"; }

.mdi-database-refresh-outline::before {
  content: "\F1634"; }

.mdi-database-remove::before {
  content: "\F0D00"; }

.mdi-database-remove-outline::before {
  content: "\F1635"; }

.mdi-database-search::before {
  content: "\F0866"; }

.mdi-database-search-outline::before {
  content: "\F1636"; }

.mdi-database-settings::before {
  content: "\F0D01"; }

.mdi-database-settings-outline::before {
  content: "\F1637"; }

.mdi-database-sync::before {
  content: "\F0CFF"; }

.mdi-database-sync-outline::before {
  content: "\F1638"; }

.mdi-death-star::before {
  content: "\F08D8"; }

.mdi-death-star-variant::before {
  content: "\F08D9"; }

.mdi-deathly-hallows::before {
  content: "\F0B87"; }

.mdi-debian::before {
  content: "\F08DA"; }

.mdi-debug-step-into::before {
  content: "\F01B9"; }

.mdi-debug-step-out::before {
  content: "\F01B8"; }

.mdi-debug-step-over::before {
  content: "\F01B7"; }

.mdi-decagram::before {
  content: "\F076C"; }

.mdi-decagram-outline::before {
  content: "\F076D"; }

.mdi-decimal::before {
  content: "\F10A1"; }

.mdi-decimal-comma::before {
  content: "\F10A2"; }

.mdi-decimal-comma-decrease::before {
  content: "\F10A3"; }

.mdi-decimal-comma-increase::before {
  content: "\F10A4"; }

.mdi-decimal-decrease::before {
  content: "\F01B6"; }

.mdi-decimal-increase::before {
  content: "\F01B5"; }

.mdi-delete::before {
  content: "\F01B4"; }

.mdi-delete-alert::before {
  content: "\F10A5"; }

.mdi-delete-alert-outline::before {
  content: "\F10A6"; }

.mdi-delete-circle::before {
  content: "\F0683"; }

.mdi-delete-circle-outline::before {
  content: "\F0B88"; }

.mdi-delete-clock::before {
  content: "\F1556"; }

.mdi-delete-clock-outline::before {
  content: "\F1557"; }

.mdi-delete-empty::before {
  content: "\F06CC"; }

.mdi-delete-empty-outline::before {
  content: "\F0E9D"; }

.mdi-delete-forever::before {
  content: "\F05E8"; }

.mdi-delete-forever-outline::before {
  content: "\F0B89"; }

.mdi-delete-off::before {
  content: "\F10A7"; }

.mdi-delete-off-outline::before {
  content: "\F10A8"; }

.mdi-delete-outline::before {
  content: "\F09E7"; }

.mdi-delete-restore::before {
  content: "\F0819"; }

.mdi-delete-sweep::before {
  content: "\F05E9"; }

.mdi-delete-sweep-outline::before {
  content: "\F0C62"; }

.mdi-delete-variant::before {
  content: "\F01B3"; }

.mdi-delta::before {
  content: "\F01C2"; }

.mdi-desk::before {
  content: "\F1239"; }

.mdi-desk-lamp::before {
  content: "\F095F"; }

.mdi-deskphone::before {
  content: "\F01C3"; }

.mdi-desktop-classic::before {
  content: "\F07C0"; }

.mdi-desktop-mac::before {
  content: "\F01C4"; }

.mdi-desktop-mac-dashboard::before {
  content: "\F09E8"; }

.mdi-desktop-tower::before {
  content: "\F01C5"; }

.mdi-desktop-tower-monitor::before {
  content: "\F0AAB"; }

.mdi-details::before {
  content: "\F01C6"; }

.mdi-dev-to::before {
  content: "\F0D6E"; }

.mdi-developer-board::before {
  content: "\F0697"; }

.mdi-deviantart::before {
  content: "\F01C7"; }

.mdi-devices::before {
  content: "\F0FB0"; }

.mdi-diabetes::before {
  content: "\F1126"; }

.mdi-dialpad::before {
  content: "\F061C"; }

.mdi-diameter::before {
  content: "\F0C63"; }

.mdi-diameter-outline::before {
  content: "\F0C64"; }

.mdi-diameter-variant::before {
  content: "\F0C65"; }

.mdi-diamond::before {
  content: "\F0B8A"; }

.mdi-diamond-outline::before {
  content: "\F0B8B"; }

.mdi-diamond-stone::before {
  content: "\F01C8"; }

.mdi-dice-1::before {
  content: "\F01CA"; }

.mdi-dice-1-outline::before {
  content: "\F114A"; }

.mdi-dice-2::before {
  content: "\F01CB"; }

.mdi-dice-2-outline::before {
  content: "\F114B"; }

.mdi-dice-3::before {
  content: "\F01CC"; }

.mdi-dice-3-outline::before {
  content: "\F114C"; }

.mdi-dice-4::before {
  content: "\F01CD"; }

.mdi-dice-4-outline::before {
  content: "\F114D"; }

.mdi-dice-5::before {
  content: "\F01CE"; }

.mdi-dice-5-outline::before {
  content: "\F114E"; }

.mdi-dice-6::before {
  content: "\F01CF"; }

.mdi-dice-6-outline::before {
  content: "\F114F"; }

.mdi-dice-d10::before {
  content: "\F1153"; }

.mdi-dice-d10-outline::before {
  content: "\F076F"; }

.mdi-dice-d12::before {
  content: "\F1154"; }

.mdi-dice-d12-outline::before {
  content: "\F0867"; }

.mdi-dice-d20::before {
  content: "\F1155"; }

.mdi-dice-d20-outline::before {
  content: "\F05EA"; }

.mdi-dice-d4::before {
  content: "\F1150"; }

.mdi-dice-d4-outline::before {
  content: "\F05EB"; }

.mdi-dice-d6::before {
  content: "\F1151"; }

.mdi-dice-d6-outline::before {
  content: "\F05ED"; }

.mdi-dice-d8::before {
  content: "\F1152"; }

.mdi-dice-d8-outline::before {
  content: "\F05EC"; }

.mdi-dice-multiple::before {
  content: "\F076E"; }

.mdi-dice-multiple-outline::before {
  content: "\F1156"; }

.mdi-digital-ocean::before {
  content: "\F1237"; }

.mdi-dip-switch::before {
  content: "\F07C1"; }

.mdi-directions::before {
  content: "\F01D0"; }

.mdi-directions-fork::before {
  content: "\F0641"; }

.mdi-disc::before {
  content: "\F05EE"; }

.mdi-disc-alert::before {
  content: "\F01D1"; }

.mdi-disc-player::before {
  content: "\F0960"; }

.mdi-discord::before {
  content: "\F066F"; }

.mdi-dishwasher::before {
  content: "\F0AAC"; }

.mdi-dishwasher-alert::before {
  content: "\F11B8"; }

.mdi-dishwasher-off::before {
  content: "\F11B9"; }

.mdi-disqus::before {
  content: "\F01D2"; }

.mdi-distribute-horizontal-center::before {
  content: "\F11C9"; }

.mdi-distribute-horizontal-left::before {
  content: "\F11C8"; }

.mdi-distribute-horizontal-right::before {
  content: "\F11CA"; }

.mdi-distribute-vertical-bottom::before {
  content: "\F11CB"; }

.mdi-distribute-vertical-center::before {
  content: "\F11CC"; }

.mdi-distribute-vertical-top::before {
  content: "\F11CD"; }

.mdi-diving-flippers::before {
  content: "\F0DBF"; }

.mdi-diving-helmet::before {
  content: "\F0DC0"; }

.mdi-diving-scuba::before {
  content: "\F0DC1"; }

.mdi-diving-scuba-flag::before {
  content: "\F0DC2"; }

.mdi-diving-scuba-tank::before {
  content: "\F0DC3"; }

.mdi-diving-scuba-tank-multiple::before {
  content: "\F0DC4"; }

.mdi-diving-snorkel::before {
  content: "\F0DC5"; }

.mdi-division::before {
  content: "\F01D4"; }

.mdi-division-box::before {
  content: "\F01D5"; }

.mdi-dlna::before {
  content: "\F0A41"; }

.mdi-dna::before {
  content: "\F0684"; }

.mdi-dns::before {
  content: "\F01D6"; }

.mdi-dns-outline::before {
  content: "\F0B8C"; }

.mdi-do-not-disturb::before {
  content: "\F0698"; }

.mdi-do-not-disturb-off::before {
  content: "\F0699"; }

.mdi-dock-bottom::before {
  content: "\F10A9"; }

.mdi-dock-left::before {
  content: "\F10AA"; }

.mdi-dock-right::before {
  content: "\F10AB"; }

.mdi-dock-top::before {
  content: "\F1513"; }

.mdi-dock-window::before {
  content: "\F10AC"; }

.mdi-docker::before {
  content: "\F0868"; }

.mdi-doctor::before {
  content: "\F0A42"; }

.mdi-dog::before {
  content: "\F0A43"; }

.mdi-dog-service::before {
  content: "\F0AAD"; }

.mdi-dog-side::before {
  content: "\F0A44"; }

.mdi-dolby::before {
  content: "\F06B3"; }

.mdi-dolly::before {
  content: "\F0E9E"; }

.mdi-domain::before {
  content: "\F01D7"; }

.mdi-domain-off::before {
  content: "\F0D6F"; }

.mdi-domain-plus::before {
  content: "\F10AD"; }

.mdi-domain-remove::before {
  content: "\F10AE"; }

.mdi-dome-light::before {
  content: "\F141E"; }

.mdi-domino-mask::before {
  content: "\F1023"; }

.mdi-donkey::before {
  content: "\F07C2"; }

.mdi-door::before {
  content: "\F081A"; }

.mdi-door-closed::before {
  content: "\F081B"; }

.mdi-door-closed-lock::before {
  content: "\F10AF"; }

.mdi-door-open::before {
  content: "\F081C"; }

.mdi-doorbell::before {
  content: "\F12E6"; }

.mdi-doorbell-video::before {
  content: "\F0869"; }

.mdi-dot-net::before {
  content: "\F0AAE"; }

.mdi-dots-grid::before {
  content: "\F15FC"; }

.mdi-dots-hexagon::before {
  content: "\F15FF"; }

.mdi-dots-horizontal::before {
  content: "\F01D8"; }

.mdi-dots-horizontal-circle::before {
  content: "\F07C3"; }

.mdi-dots-horizontal-circle-outline::before {
  content: "\F0B8D"; }

.mdi-dots-square::before {
  content: "\F15FD"; }

.mdi-dots-triangle::before {
  content: "\F15FE"; }

.mdi-dots-vertical::before {
  content: "\F01D9"; }

.mdi-dots-vertical-circle::before {
  content: "\F07C4"; }

.mdi-dots-vertical-circle-outline::before {
  content: "\F0B8E"; }

.mdi-douban::before {
  content: "\F069A"; }

.mdi-download::before {
  content: "\F01DA"; }

.mdi-download-box::before {
  content: "\F1462"; }

.mdi-download-box-outline::before {
  content: "\F1463"; }

.mdi-download-circle::before {
  content: "\F1464"; }

.mdi-download-circle-outline::before {
  content: "\F1465"; }

.mdi-download-lock::before {
  content: "\F1320"; }

.mdi-download-lock-outline::before {
  content: "\F1321"; }

.mdi-download-multiple::before {
  content: "\F09E9"; }

.mdi-download-network::before {
  content: "\F06F4"; }

.mdi-download-network-outline::before {
  content: "\F0C66"; }

.mdi-download-off::before {
  content: "\F10B0"; }

.mdi-download-off-outline::before {
  content: "\F10B1"; }

.mdi-download-outline::before {
  content: "\F0B8F"; }

.mdi-drag::before {
  content: "\F01DB"; }

.mdi-drag-horizontal::before {
  content: "\F01DC"; }

.mdi-drag-horizontal-variant::before {
  content: "\F12F0"; }

.mdi-drag-variant::before {
  content: "\F0B90"; }

.mdi-drag-vertical::before {
  content: "\F01DD"; }

.mdi-drag-vertical-variant::before {
  content: "\F12F1"; }

.mdi-drama-masks::before {
  content: "\F0D02"; }

.mdi-draw::before {
  content: "\F0F49"; }

.mdi-drawing::before {
  content: "\F01DE"; }

.mdi-drawing-box::before {
  content: "\F01DF"; }

.mdi-dresser::before {
  content: "\F0F4A"; }

.mdi-dresser-outline::before {
  content: "\F0F4B"; }

.mdi-drone::before {
  content: "\F01E2"; }

.mdi-dropbox::before {
  content: "\F01E3"; }

.mdi-drupal::before {
  content: "\F01E4"; }

.mdi-duck::before {
  content: "\F01E5"; }

.mdi-dumbbell::before {
  content: "\F01E6"; }

.mdi-dump-truck::before {
  content: "\F0C67"; }

.mdi-ear-hearing::before {
  content: "\F07C5"; }

.mdi-ear-hearing-off::before {
  content: "\F0A45"; }

.mdi-earth::before {
  content: "\F01E7"; }

.mdi-earth-arrow-right::before {
  content: "\F1311"; }

.mdi-earth-box::before {
  content: "\F06CD"; }

.mdi-earth-box-minus::before {
  content: "\F1407"; }

.mdi-earth-box-off::before {
  content: "\F06CE"; }

.mdi-earth-box-plus::before {
  content: "\F1406"; }

.mdi-earth-box-remove::before {
  content: "\F1408"; }

.mdi-earth-minus::before {
  content: "\F1404"; }

.mdi-earth-off::before {
  content: "\F01E8"; }

.mdi-earth-plus::before {
  content: "\F1403"; }

.mdi-earth-remove::before {
  content: "\F1405"; }

.mdi-egg::before {
  content: "\F0AAF"; }

.mdi-egg-easter::before {
  content: "\F0AB0"; }

.mdi-egg-off::before {
  content: "\F13F0"; }

.mdi-egg-off-outline::before {
  content: "\F13F1"; }

.mdi-egg-outline::before {
  content: "\F13F2"; }

.mdi-eiffel-tower::before {
  content: "\F156B"; }

.mdi-eight-track::before {
  content: "\F09EA"; }

.mdi-eject::before {
  content: "\F01EA"; }

.mdi-eject-outline::before {
  content: "\F0B91"; }

.mdi-electric-switch::before {
  content: "\F0E9F"; }

.mdi-electric-switch-closed::before {
  content: "\F10D9"; }

.mdi-electron-framework::before {
  content: "\F1024"; }

.mdi-elephant::before {
  content: "\F07C6"; }

.mdi-elevation-decline::before {
  content: "\F01EB"; }

.mdi-elevation-rise::before {
  content: "\F01EC"; }

.mdi-elevator::before {
  content: "\F01ED"; }

.mdi-elevator-down::before {
  content: "\F12C2"; }

.mdi-elevator-passenger::before {
  content: "\F1381"; }

.mdi-elevator-up::before {
  content: "\F12C1"; }

.mdi-ellipse::before {
  content: "\F0EA0"; }

.mdi-ellipse-outline::before {
  content: "\F0EA1"; }

.mdi-email::before {
  content: "\F01EE"; }

.mdi-email-alert::before {
  content: "\F06CF"; }

.mdi-email-alert-outline::before {
  content: "\F0D42"; }

.mdi-email-box::before {
  content: "\F0D03"; }

.mdi-email-check::before {
  content: "\F0AB1"; }

.mdi-email-check-outline::before {
  content: "\F0AB2"; }

.mdi-email-edit::before {
  content: "\F0EE3"; }

.mdi-email-edit-outline::before {
  content: "\F0EE4"; }

.mdi-email-lock::before {
  content: "\F01F1"; }

.mdi-email-mark-as-unread::before {
  content: "\F0B92"; }

.mdi-email-minus::before {
  content: "\F0EE5"; }

.mdi-email-minus-outline::before {
  content: "\F0EE6"; }

.mdi-email-multiple::before {
  content: "\F0EE7"; }

.mdi-email-multiple-outline::before {
  content: "\F0EE8"; }

.mdi-email-newsletter::before {
  content: "\F0FB1"; }

.mdi-email-off::before {
  content: "\F13E3"; }

.mdi-email-off-outline::before {
  content: "\F13E4"; }

.mdi-email-open::before {
  content: "\F01EF"; }

.mdi-email-open-multiple::before {
  content: "\F0EE9"; }

.mdi-email-open-multiple-outline::before {
  content: "\F0EEA"; }

.mdi-email-open-outline::before {
  content: "\F05EF"; }

.mdi-email-outline::before {
  content: "\F01F0"; }

.mdi-email-plus::before {
  content: "\F09EB"; }

.mdi-email-plus-outline::before {
  content: "\F09EC"; }

.mdi-email-receive::before {
  content: "\F10DA"; }

.mdi-email-receive-outline::before {
  content: "\F10DB"; }

.mdi-email-remove::before {
  content: "\F1661"; }

.mdi-email-remove-outline::before {
  content: "\F1662"; }

.mdi-email-search::before {
  content: "\F0961"; }

.mdi-email-search-outline::before {
  content: "\F0962"; }

.mdi-email-send::before {
  content: "\F10DC"; }

.mdi-email-send-outline::before {
  content: "\F10DD"; }

.mdi-email-sync::before {
  content: "\F12C7"; }

.mdi-email-sync-outline::before {
  content: "\F12C8"; }

.mdi-email-variant::before {
  content: "\F05F0"; }

.mdi-ember::before {
  content: "\F0B30"; }

.mdi-emby::before {
  content: "\F06B4"; }

.mdi-emoticon::before {
  content: "\F0C68"; }

.mdi-emoticon-angry::before {
  content: "\F0C69"; }

.mdi-emoticon-angry-outline::before {
  content: "\F0C6A"; }

.mdi-emoticon-confused::before {
  content: "\F10DE"; }

.mdi-emoticon-confused-outline::before {
  content: "\F10DF"; }

.mdi-emoticon-cool::before {
  content: "\F0C6B"; }

.mdi-emoticon-cool-outline::before {
  content: "\F01F3"; }

.mdi-emoticon-cry::before {
  content: "\F0C6C"; }

.mdi-emoticon-cry-outline::before {
  content: "\F0C6D"; }

.mdi-emoticon-dead::before {
  content: "\F0C6E"; }

.mdi-emoticon-dead-outline::before {
  content: "\F069B"; }

.mdi-emoticon-devil::before {
  content: "\F0C6F"; }

.mdi-emoticon-devil-outline::before {
  content: "\F01F4"; }

.mdi-emoticon-excited::before {
  content: "\F0C70"; }

.mdi-emoticon-excited-outline::before {
  content: "\F069C"; }

.mdi-emoticon-frown::before {
  content: "\F0F4C"; }

.mdi-emoticon-frown-outline::before {
  content: "\F0F4D"; }

.mdi-emoticon-happy::before {
  content: "\F0C71"; }

.mdi-emoticon-happy-outline::before {
  content: "\F01F5"; }

.mdi-emoticon-kiss::before {
  content: "\F0C72"; }

.mdi-emoticon-kiss-outline::before {
  content: "\F0C73"; }

.mdi-emoticon-lol::before {
  content: "\F1214"; }

.mdi-emoticon-lol-outline::before {
  content: "\F1215"; }

.mdi-emoticon-neutral::before {
  content: "\F0C74"; }

.mdi-emoticon-neutral-outline::before {
  content: "\F01F6"; }

.mdi-emoticon-outline::before {
  content: "\F01F2"; }

.mdi-emoticon-poop::before {
  content: "\F01F7"; }

.mdi-emoticon-poop-outline::before {
  content: "\F0C75"; }

.mdi-emoticon-sad::before {
  content: "\F0C76"; }

.mdi-emoticon-sad-outline::before {
  content: "\F01F8"; }

.mdi-emoticon-sick::before {
  content: "\F157C"; }

.mdi-emoticon-sick-outline::before {
  content: "\F157D"; }

.mdi-emoticon-tongue::before {
  content: "\F01F9"; }

.mdi-emoticon-tongue-outline::before {
  content: "\F0C77"; }

.mdi-emoticon-wink::before {
  content: "\F0C78"; }

.mdi-emoticon-wink-outline::before {
  content: "\F0C79"; }

.mdi-engine::before {
  content: "\F01FA"; }

.mdi-engine-off::before {
  content: "\F0A46"; }

.mdi-engine-off-outline::before {
  content: "\F0A47"; }

.mdi-engine-outline::before {
  content: "\F01FB"; }

.mdi-epsilon::before {
  content: "\F10E0"; }

.mdi-equal::before {
  content: "\F01FC"; }

.mdi-equal-box::before {
  content: "\F01FD"; }

.mdi-equalizer::before {
  content: "\F0EA2"; }

.mdi-equalizer-outline::before {
  content: "\F0EA3"; }

.mdi-eraser::before {
  content: "\F01FE"; }

.mdi-eraser-variant::before {
  content: "\F0642"; }

.mdi-escalator::before {
  content: "\F01FF"; }

.mdi-escalator-box::before {
  content: "\F1399"; }

.mdi-escalator-down::before {
  content: "\F12C0"; }

.mdi-escalator-up::before {
  content: "\F12BF"; }

.mdi-eslint::before {
  content: "\F0C7A"; }

.mdi-et::before {
  content: "\F0AB3"; }

.mdi-ethereum::before {
  content: "\F086A"; }

.mdi-ethernet::before {
  content: "\F0200"; }

.mdi-ethernet-cable::before {
  content: "\F0201"; }

.mdi-ethernet-cable-off::before {
  content: "\F0202"; }

.mdi-ev-plug-ccs1::before {
  content: "\F1519"; }

.mdi-ev-plug-ccs2::before {
  content: "\F151A"; }

.mdi-ev-plug-chademo::before {
  content: "\F151B"; }

.mdi-ev-plug-tesla::before {
  content: "\F151C"; }

.mdi-ev-plug-type1::before {
  content: "\F151D"; }

.mdi-ev-plug-type2::before {
  content: "\F151E"; }

.mdi-ev-station::before {
  content: "\F05F1"; }

.mdi-evernote::before {
  content: "\F0204"; }

.mdi-excavator::before {
  content: "\F1025"; }

.mdi-exclamation::before {
  content: "\F0205"; }

.mdi-exclamation-thick::before {
  content: "\F1238"; }

.mdi-exit-run::before {
  content: "\F0A48"; }

.mdi-exit-to-app::before {
  content: "\F0206"; }

.mdi-expand-all::before {
  content: "\F0AB4"; }

.mdi-expand-all-outline::before {
  content: "\F0AB5"; }

.mdi-expansion-card::before {
  content: "\F08AE"; }

.mdi-expansion-card-variant::before {
  content: "\F0FB2"; }

.mdi-exponent::before {
  content: "\F0963"; }

.mdi-exponent-box::before {
  content: "\F0964"; }

.mdi-export::before {
  content: "\F0207"; }

.mdi-export-variant::before {
  content: "\F0B93"; }

.mdi-eye::before {
  content: "\F0208"; }

.mdi-eye-check::before {
  content: "\F0D04"; }

.mdi-eye-check-outline::before {
  content: "\F0D05"; }

.mdi-eye-circle::before {
  content: "\F0B94"; }

.mdi-eye-circle-outline::before {
  content: "\F0B95"; }

.mdi-eye-minus::before {
  content: "\F1026"; }

.mdi-eye-minus-outline::before {
  content: "\F1027"; }

.mdi-eye-off::before {
  content: "\F0209"; }

.mdi-eye-off-outline::before {
  content: "\F06D1"; }

.mdi-eye-outline::before {
  content: "\F06D0"; }

.mdi-eye-plus::before {
  content: "\F086B"; }

.mdi-eye-plus-outline::before {
  content: "\F086C"; }

.mdi-eye-remove::before {
  content: "\F15E3"; }

.mdi-eye-remove-outline::before {
  content: "\F15E4"; }

.mdi-eye-settings::before {
  content: "\F086D"; }

.mdi-eye-settings-outline::before {
  content: "\F086E"; }

.mdi-eyedropper::before {
  content: "\F020A"; }

.mdi-eyedropper-minus::before {
  content: "\F13DD"; }

.mdi-eyedropper-off::before {
  content: "\F13DF"; }

.mdi-eyedropper-plus::before {
  content: "\F13DC"; }

.mdi-eyedropper-remove::before {
  content: "\F13DE"; }

.mdi-eyedropper-variant::before {
  content: "\F020B"; }

.mdi-face::before {
  content: "\F0643"; }

.mdi-face-agent::before {
  content: "\F0D70"; }

.mdi-face-mask::before {
  content: "\F1586"; }

.mdi-face-mask-outline::before {
  content: "\F1587"; }

.mdi-face-outline::before {
  content: "\F0B96"; }

.mdi-face-profile::before {
  content: "\F0644"; }

.mdi-face-profile-woman::before {
  content: "\F1076"; }

.mdi-face-recognition::before {
  content: "\F0C7B"; }

.mdi-face-shimmer::before {
  content: "\F15CC"; }

.mdi-face-shimmer-outline::before {
  content: "\F15CD"; }

.mdi-face-woman::before {
  content: "\F1077"; }

.mdi-face-woman-outline::before {
  content: "\F1078"; }

.mdi-face-woman-shimmer::before {
  content: "\F15CE"; }

.mdi-face-woman-shimmer-outline::before {
  content: "\F15CF"; }

.mdi-facebook::before {
  content: "\F020C"; }

.mdi-facebook-gaming::before {
  content: "\F07DD"; }

.mdi-facebook-messenger::before {
  content: "\F020E"; }

.mdi-facebook-workplace::before {
  content: "\F0B31"; }

.mdi-factory::before {
  content: "\F020F"; }

.mdi-family-tree::before {
  content: "\F160E"; }

.mdi-fan::before {
  content: "\F0210"; }

.mdi-fan-alert::before {
  content: "\F146C"; }

.mdi-fan-chevron-down::before {
  content: "\F146D"; }

.mdi-fan-chevron-up::before {
  content: "\F146E"; }

.mdi-fan-minus::before {
  content: "\F1470"; }

.mdi-fan-off::before {
  content: "\F081D"; }

.mdi-fan-plus::before {
  content: "\F146F"; }

.mdi-fan-remove::before {
  content: "\F1471"; }

.mdi-fan-speed-1::before {
  content: "\F1472"; }

.mdi-fan-speed-2::before {
  content: "\F1473"; }

.mdi-fan-speed-3::before {
  content: "\F1474"; }

.mdi-fast-forward::before {
  content: "\F0211"; }

.mdi-fast-forward-10::before {
  content: "\F0D71"; }

.mdi-fast-forward-30::before {
  content: "\F0D06"; }

.mdi-fast-forward-5::before {
  content: "\F11F8"; }

.mdi-fast-forward-60::before {
  content: "\F160B"; }

.mdi-fast-forward-outline::before {
  content: "\F06D2"; }

.mdi-fax::before {
  content: "\F0212"; }

.mdi-feather::before {
  content: "\F06D3"; }

.mdi-feature-search::before {
  content: "\F0A49"; }

.mdi-feature-search-outline::before {
  content: "\F0A4A"; }

.mdi-fedora::before {
  content: "\F08DB"; }

.mdi-fencing::before {
  content: "\F14C1"; }

.mdi-ferris-wheel::before {
  content: "\F0EA4"; }

.mdi-ferry::before {
  content: "\F0213"; }

.mdi-file::before {
  content: "\F0214"; }

.mdi-file-account::before {
  content: "\F073B"; }

.mdi-file-account-outline::before {
  content: "\F1028"; }

.mdi-file-alert::before {
  content: "\F0A4B"; }

.mdi-file-alert-outline::before {
  content: "\F0A4C"; }

.mdi-file-cabinet::before {
  content: "\F0AB6"; }

.mdi-file-cad::before {
  content: "\F0EEB"; }

.mdi-file-cad-box::before {
  content: "\F0EEC"; }

.mdi-file-cancel::before {
  content: "\F0DC6"; }

.mdi-file-cancel-outline::before {
  content: "\F0DC7"; }

.mdi-file-certificate::before {
  content: "\F1186"; }

.mdi-file-certificate-outline::before {
  content: "\F1187"; }

.mdi-file-chart::before {
  content: "\F0215"; }

.mdi-file-chart-outline::before {
  content: "\F1029"; }

.mdi-file-check::before {
  content: "\F0216"; }

.mdi-file-check-outline::before {
  content: "\F0E29"; }

.mdi-file-clock::before {
  content: "\F12E1"; }

.mdi-file-clock-outline::before {
  content: "\F12E2"; }

.mdi-file-cloud::before {
  content: "\F0217"; }

.mdi-file-cloud-outline::before {
  content: "\F102A"; }

.mdi-file-code::before {
  content: "\F022E"; }

.mdi-file-code-outline::before {
  content: "\F102B"; }

.mdi-file-cog::before {
  content: "\F107B"; }

.mdi-file-cog-outline::before {
  content: "\F107C"; }

.mdi-file-compare::before {
  content: "\F08AA"; }

.mdi-file-delimited::before {
  content: "\F0218"; }

.mdi-file-delimited-outline::before {
  content: "\F0EA5"; }

.mdi-file-document::before {
  content: "\F0219"; }

.mdi-file-document-edit::before {
  content: "\F0DC8"; }

.mdi-file-document-edit-outline::before {
  content: "\F0DC9"; }

.mdi-file-document-multiple::before {
  content: "\F1517"; }

.mdi-file-document-multiple-outline::before {
  content: "\F1518"; }

.mdi-file-document-outline::before {
  content: "\F09EE"; }

.mdi-file-download::before {
  content: "\F0965"; }

.mdi-file-download-outline::before {
  content: "\F0966"; }

.mdi-file-edit::before {
  content: "\F11E7"; }

.mdi-file-edit-outline::before {
  content: "\F11E8"; }

.mdi-file-excel::before {
  content: "\F021B"; }

.mdi-file-excel-box::before {
  content: "\F021C"; }

.mdi-file-excel-box-outline::before {
  content: "\F102C"; }

.mdi-file-excel-outline::before {
  content: "\F102D"; }

.mdi-file-export::before {
  content: "\F021D"; }

.mdi-file-export-outline::before {
  content: "\F102E"; }

.mdi-file-eye::before {
  content: "\F0DCA"; }

.mdi-file-eye-outline::before {
  content: "\F0DCB"; }

.mdi-file-find::before {
  content: "\F021E"; }

.mdi-file-find-outline::before {
  content: "\F0B97"; }

.mdi-file-hidden::before {
  content: "\F0613"; }

.mdi-file-image::before {
  content: "\F021F"; }

.mdi-file-image-outline::before {
  content: "\F0EB0"; }

.mdi-file-import::before {
  content: "\F0220"; }

.mdi-file-import-outline::before {
  content: "\F102F"; }

.mdi-file-key::before {
  content: "\F1184"; }

.mdi-file-key-outline::before {
  content: "\F1185"; }

.mdi-file-link::before {
  content: "\F1177"; }

.mdi-file-link-outline::before {
  content: "\F1178"; }

.mdi-file-lock::before {
  content: "\F0221"; }

.mdi-file-lock-outline::before {
  content: "\F1030"; }

.mdi-file-move::before {
  content: "\F0AB9"; }

.mdi-file-move-outline::before {
  content: "\F1031"; }

.mdi-file-multiple::before {
  content: "\F0222"; }

.mdi-file-multiple-outline::before {
  content: "\F1032"; }

.mdi-file-music::before {
  content: "\F0223"; }

.mdi-file-music-outline::before {
  content: "\F0E2A"; }

.mdi-file-outline::before {
  content: "\F0224"; }

.mdi-file-pdf::before {
  content: "\F0225"; }

.mdi-file-pdf-box::before {
  content: "\F0226"; }

.mdi-file-pdf-box-outline::before {
  content: "\F0FB3"; }

.mdi-file-pdf-outline::before {
  content: "\F0E2D"; }

.mdi-file-percent::before {
  content: "\F081E"; }

.mdi-file-percent-outline::before {
  content: "\F1033"; }

.mdi-file-phone::before {
  content: "\F1179"; }

.mdi-file-phone-outline::before {
  content: "\F117A"; }

.mdi-file-plus::before {
  content: "\F0752"; }

.mdi-file-plus-outline::before {
  content: "\F0EED"; }

.mdi-file-powerpoint::before {
  content: "\F0227"; }

.mdi-file-powerpoint-box::before {
  content: "\F0228"; }

.mdi-file-powerpoint-box-outline::before {
  content: "\F1034"; }

.mdi-file-powerpoint-outline::before {
  content: "\F1035"; }

.mdi-file-presentation-box::before {
  content: "\F0229"; }

.mdi-file-question::before {
  content: "\F086F"; }

.mdi-file-question-outline::before {
  content: "\F1036"; }

.mdi-file-refresh::before {
  content: "\F0918"; }

.mdi-file-refresh-outline::before {
  content: "\F0541"; }

.mdi-file-remove::before {
  content: "\F0B98"; }

.mdi-file-remove-outline::before {
  content: "\F1037"; }

.mdi-file-replace::before {
  content: "\F0B32"; }

.mdi-file-replace-outline::before {
  content: "\F0B33"; }

.mdi-file-restore::before {
  content: "\F0670"; }

.mdi-file-restore-outline::before {
  content: "\F1038"; }

.mdi-file-search::before {
  content: "\F0C7C"; }

.mdi-file-search-outline::before {
  content: "\F0C7D"; }

.mdi-file-send::before {
  content: "\F022A"; }

.mdi-file-send-outline::before {
  content: "\F1039"; }

.mdi-file-settings::before {
  content: "\F1079"; }

.mdi-file-settings-outline::before {
  content: "\F107A"; }

.mdi-file-star::before {
  content: "\F103A"; }

.mdi-file-star-outline::before {
  content: "\F103B"; }

.mdi-file-swap::before {
  content: "\F0FB4"; }

.mdi-file-swap-outline::before {
  content: "\F0FB5"; }

.mdi-file-sync::before {
  content: "\F1216"; }

.mdi-file-sync-outline::before {
  content: "\F1217"; }

.mdi-file-table::before {
  content: "\F0C7E"; }

.mdi-file-table-box::before {
  content: "\F10E1"; }

.mdi-file-table-box-multiple::before {
  content: "\F10E2"; }

.mdi-file-table-box-multiple-outline::before {
  content: "\F10E3"; }

.mdi-file-table-box-outline::before {
  content: "\F10E4"; }

.mdi-file-table-outline::before {
  content: "\F0C7F"; }

.mdi-file-tree::before {
  content: "\F0645"; }

.mdi-file-tree-outline::before {
  content: "\F13D2"; }

.mdi-file-undo::before {
  content: "\F08DC"; }

.mdi-file-undo-outline::before {
  content: "\F103C"; }

.mdi-file-upload::before {
  content: "\F0A4D"; }

.mdi-file-upload-outline::before {
  content: "\F0A4E"; }

.mdi-file-video::before {
  content: "\F022B"; }

.mdi-file-video-outline::before {
  content: "\F0E2C"; }

.mdi-file-word::before {
  content: "\F022C"; }

.mdi-file-word-box::before {
  content: "\F022D"; }

.mdi-file-word-box-outline::before {
  content: "\F103D"; }

.mdi-file-word-outline::before {
  content: "\F103E"; }

.mdi-film::before {
  content: "\F022F"; }

.mdi-filmstrip::before {
  content: "\F0230"; }

.mdi-filmstrip-box::before {
  content: "\F0332"; }

.mdi-filmstrip-box-multiple::before {
  content: "\F0D18"; }

.mdi-filmstrip-off::before {
  content: "\F0231"; }

.mdi-filter::before {
  content: "\F0232"; }

.mdi-filter-menu::before {
  content: "\F10E5"; }

.mdi-filter-menu-outline::before {
  content: "\F10E6"; }

.mdi-filter-minus::before {
  content: "\F0EEE"; }

.mdi-filter-minus-outline::before {
  content: "\F0EEF"; }

.mdi-filter-off::before {
  content: "\F14EF"; }

.mdi-filter-off-outline::before {
  content: "\F14F0"; }

.mdi-filter-outline::before {
  content: "\F0233"; }

.mdi-filter-plus::before {
  content: "\F0EF0"; }

.mdi-filter-plus-outline::before {
  content: "\F0EF1"; }

.mdi-filter-remove::before {
  content: "\F0234"; }

.mdi-filter-remove-outline::before {
  content: "\F0235"; }

.mdi-filter-variant::before {
  content: "\F0236"; }

.mdi-filter-variant-minus::before {
  content: "\F1112"; }

.mdi-filter-variant-plus::before {
  content: "\F1113"; }

.mdi-filter-variant-remove::before {
  content: "\F103F"; }

.mdi-finance::before {
  content: "\F081F"; }

.mdi-find-replace::before {
  content: "\F06D4"; }

.mdi-fingerprint::before {
  content: "\F0237"; }

.mdi-fingerprint-off::before {
  content: "\F0EB1"; }

.mdi-fire::before {
  content: "\F0238"; }

.mdi-fire-alert::before {
  content: "\F15D7"; }

.mdi-fire-extinguisher::before {
  content: "\F0EF2"; }

.mdi-fire-hydrant::before {
  content: "\F1137"; }

.mdi-fire-hydrant-alert::before {
  content: "\F1138"; }

.mdi-fire-hydrant-off::before {
  content: "\F1139"; }

.mdi-fire-truck::before {
  content: "\F08AB"; }

.mdi-firebase::before {
  content: "\F0967"; }

.mdi-firefox::before {
  content: "\F0239"; }

.mdi-fireplace::before {
  content: "\F0E2E"; }

.mdi-fireplace-off::before {
  content: "\F0E2F"; }

.mdi-firework::before {
  content: "\F0E30"; }

.mdi-fish::before {
  content: "\F023A"; }

.mdi-fish-off::before {
  content: "\F13F3"; }

.mdi-fishbowl::before {
  content: "\F0EF3"; }

.mdi-fishbowl-outline::before {
  content: "\F0EF4"; }

.mdi-fit-to-page::before {
  content: "\F0EF5"; }

.mdi-fit-to-page-outline::before {
  content: "\F0EF6"; }

.mdi-flag::before {
  content: "\F023B"; }

.mdi-flag-checkered::before {
  content: "\F023C"; }

.mdi-flag-minus::before {
  content: "\F0B99"; }

.mdi-flag-minus-outline::before {
  content: "\F10B2"; }

.mdi-flag-outline::before {
  content: "\F023D"; }

.mdi-flag-plus::before {
  content: "\F0B9A"; }

.mdi-flag-plus-outline::before {
  content: "\F10B3"; }

.mdi-flag-remove::before {
  content: "\F0B9B"; }

.mdi-flag-remove-outline::before {
  content: "\F10B4"; }

.mdi-flag-triangle::before {
  content: "\F023F"; }

.mdi-flag-variant::before {
  content: "\F0240"; }

.mdi-flag-variant-outline::before {
  content: "\F023E"; }

.mdi-flare::before {
  content: "\F0D72"; }

.mdi-flash::before {
  content: "\F0241"; }

.mdi-flash-alert::before {
  content: "\F0EF7"; }

.mdi-flash-alert-outline::before {
  content: "\F0EF8"; }

.mdi-flash-auto::before {
  content: "\F0242"; }

.mdi-flash-circle::before {
  content: "\F0820"; }

.mdi-flash-off::before {
  content: "\F0243"; }

.mdi-flash-outline::before {
  content: "\F06D5"; }

.mdi-flash-red-eye::before {
  content: "\F067B"; }

.mdi-flashlight::before {
  content: "\F0244"; }

.mdi-flashlight-off::before {
  content: "\F0245"; }

.mdi-flask::before {
  content: "\F0093"; }

.mdi-flask-empty::before {
  content: "\F0094"; }

.mdi-flask-empty-minus::before {
  content: "\F123A"; }

.mdi-flask-empty-minus-outline::before {
  content: "\F123B"; }

.mdi-flask-empty-off::before {
  content: "\F13F4"; }

.mdi-flask-empty-off-outline::before {
  content: "\F13F5"; }

.mdi-flask-empty-outline::before {
  content: "\F0095"; }

.mdi-flask-empty-plus::before {
  content: "\F123C"; }

.mdi-flask-empty-plus-outline::before {
  content: "\F123D"; }

.mdi-flask-empty-remove::before {
  content: "\F123E"; }

.mdi-flask-empty-remove-outline::before {
  content: "\F123F"; }

.mdi-flask-minus::before {
  content: "\F1240"; }

.mdi-flask-minus-outline::before {
  content: "\F1241"; }

.mdi-flask-off::before {
  content: "\F13F6"; }

.mdi-flask-off-outline::before {
  content: "\F13F7"; }

.mdi-flask-outline::before {
  content: "\F0096"; }

.mdi-flask-plus::before {
  content: "\F1242"; }

.mdi-flask-plus-outline::before {
  content: "\F1243"; }

.mdi-flask-remove::before {
  content: "\F1244"; }

.mdi-flask-remove-outline::before {
  content: "\F1245"; }

.mdi-flask-round-bottom::before {
  content: "\F124B"; }

.mdi-flask-round-bottom-empty::before {
  content: "\F124C"; }

.mdi-flask-round-bottom-empty-outline::before {
  content: "\F124D"; }

.mdi-flask-round-bottom-outline::before {
  content: "\F124E"; }

.mdi-fleur-de-lis::before {
  content: "\F1303"; }

.mdi-flip-horizontal::before {
  content: "\F10E7"; }

.mdi-flip-to-back::before {
  content: "\F0247"; }

.mdi-flip-to-front::before {
  content: "\F0248"; }

.mdi-flip-vertical::before {
  content: "\F10E8"; }

.mdi-floor-lamp::before {
  content: "\F08DD"; }

.mdi-floor-lamp-dual::before {
  content: "\F1040"; }

.mdi-floor-lamp-variant::before {
  content: "\F1041"; }

.mdi-floor-plan::before {
  content: "\F0821"; }

.mdi-floppy::before {
  content: "\F0249"; }

.mdi-floppy-variant::before {
  content: "\F09EF"; }

.mdi-flower::before {
  content: "\F024A"; }

.mdi-flower-outline::before {
  content: "\F09F0"; }

.mdi-flower-poppy::before {
  content: "\F0D08"; }

.mdi-flower-tulip::before {
  content: "\F09F1"; }

.mdi-flower-tulip-outline::before {
  content: "\F09F2"; }

.mdi-focus-auto::before {
  content: "\F0F4E"; }

.mdi-focus-field::before {
  content: "\F0F4F"; }

.mdi-focus-field-horizontal::before {
  content: "\F0F50"; }

.mdi-focus-field-vertical::before {
  content: "\F0F51"; }

.mdi-folder::before {
  content: "\F024B"; }

.mdi-folder-account::before {
  content: "\F024C"; }

.mdi-folder-account-outline::before {
  content: "\F0B9C"; }

.mdi-folder-alert::before {
  content: "\F0DCC"; }

.mdi-folder-alert-outline::before {
  content: "\F0DCD"; }

.mdi-folder-clock::before {
  content: "\F0ABA"; }

.mdi-folder-clock-outline::before {
  content: "\F0ABB"; }

.mdi-folder-cog::before {
  content: "\F107F"; }

.mdi-folder-cog-outline::before {
  content: "\F1080"; }

.mdi-folder-download::before {
  content: "\F024D"; }

.mdi-folder-download-outline::before {
  content: "\F10E9"; }

.mdi-folder-edit::before {
  content: "\F08DE"; }

.mdi-folder-edit-outline::before {
  content: "\F0DCE"; }

.mdi-folder-google-drive::before {
  content: "\F024E"; }

.mdi-folder-heart::before {
  content: "\F10EA"; }

.mdi-folder-heart-outline::before {
  content: "\F10EB"; }

.mdi-folder-home::before {
  content: "\F10B5"; }

.mdi-folder-home-outline::before {
  content: "\F10B6"; }

.mdi-folder-image::before {
  content: "\F024F"; }

.mdi-folder-information::before {
  content: "\F10B7"; }

.mdi-folder-information-outline::before {
  content: "\F10B8"; }

.mdi-folder-key::before {
  content: "\F08AC"; }

.mdi-folder-key-network::before {
  content: "\F08AD"; }

.mdi-folder-key-network-outline::before {
  content: "\F0C80"; }

.mdi-folder-key-outline::before {
  content: "\F10EC"; }

.mdi-folder-lock::before {
  content: "\F0250"; }

.mdi-folder-lock-open::before {
  content: "\F0251"; }

.mdi-folder-marker::before {
  content: "\F126D"; }

.mdi-folder-marker-outline::before {
  content: "\F126E"; }

.mdi-folder-move::before {
  content: "\F0252"; }

.mdi-folder-move-outline::before {
  content: "\F1246"; }

.mdi-folder-multiple::before {
  content: "\F0253"; }

.mdi-folder-multiple-image::before {
  content: "\F0254"; }

.mdi-folder-multiple-outline::before {
  content: "\F0255"; }

.mdi-folder-multiple-plus::before {
  content: "\F147E"; }

.mdi-folder-multiple-plus-outline::before {
  content: "\F147F"; }

.mdi-folder-music::before {
  content: "\F1359"; }

.mdi-folder-music-outline::before {
  content: "\F135A"; }

.mdi-folder-network::before {
  content: "\F0870"; }

.mdi-folder-network-outline::before {
  content: "\F0C81"; }

.mdi-folder-open::before {
  content: "\F0770"; }

.mdi-folder-open-outline::before {
  content: "\F0DCF"; }

.mdi-folder-outline::before {
  content: "\F0256"; }

.mdi-folder-plus::before {
  content: "\F0257"; }

.mdi-folder-plus-outline::before {
  content: "\F0B9D"; }

.mdi-folder-pound::before {
  content: "\F0D09"; }

.mdi-folder-pound-outline::before {
  content: "\F0D0A"; }

.mdi-folder-refresh::before {
  content: "\F0749"; }

.mdi-folder-refresh-outline::before {
  content: "\F0542"; }

.mdi-folder-remove::before {
  content: "\F0258"; }

.mdi-folder-remove-outline::before {
  content: "\F0B9E"; }

.mdi-folder-search::before {
  content: "\F0968"; }

.mdi-folder-search-outline::before {
  content: "\F0969"; }

.mdi-folder-settings::before {
  content: "\F107D"; }

.mdi-folder-settings-outline::before {
  content: "\F107E"; }

.mdi-folder-star::before {
  content: "\F069D"; }

.mdi-folder-star-multiple::before {
  content: "\F13D3"; }

.mdi-folder-star-multiple-outline::before {
  content: "\F13D4"; }

.mdi-folder-star-outline::before {
  content: "\F0B9F"; }

.mdi-folder-swap::before {
  content: "\F0FB6"; }

.mdi-folder-swap-outline::before {
  content: "\F0FB7"; }

.mdi-folder-sync::before {
  content: "\F0D0B"; }

.mdi-folder-sync-outline::before {
  content: "\F0D0C"; }

.mdi-folder-table::before {
  content: "\F12E3"; }

.mdi-folder-table-outline::before {
  content: "\F12E4"; }

.mdi-folder-text::before {
  content: "\F0C82"; }

.mdi-folder-text-outline::before {
  content: "\F0C83"; }

.mdi-folder-upload::before {
  content: "\F0259"; }

.mdi-folder-upload-outline::before {
  content: "\F10ED"; }

.mdi-folder-zip::before {
  content: "\F06EB"; }

.mdi-folder-zip-outline::before {
  content: "\F07B9"; }

.mdi-font-awesome::before {
  content: "\F003A"; }

.mdi-food::before {
  content: "\F025A"; }

.mdi-food-apple::before {
  content: "\F025B"; }

.mdi-food-apple-outline::before {
  content: "\F0C84"; }

.mdi-food-croissant::before {
  content: "\F07C8"; }

.mdi-food-drumstick::before {
  content: "\F141F"; }

.mdi-food-drumstick-off::before {
  content: "\F1468"; }

.mdi-food-drumstick-off-outline::before {
  content: "\F1469"; }

.mdi-food-drumstick-outline::before {
  content: "\F1420"; }

.mdi-food-fork-drink::before {
  content: "\F05F2"; }

.mdi-food-halal::before {
  content: "\F1572"; }

.mdi-food-kosher::before {
  content: "\F1573"; }

.mdi-food-off::before {
  content: "\F05F3"; }

.mdi-food-steak::before {
  content: "\F146A"; }

.mdi-food-steak-off::before {
  content: "\F146B"; }

.mdi-food-variant::before {
  content: "\F025C"; }

.mdi-food-variant-off::before {
  content: "\F13E5"; }

.mdi-foot-print::before {
  content: "\F0F52"; }

.mdi-football::before {
  content: "\F025D"; }

.mdi-football-australian::before {
  content: "\F025E"; }

.mdi-football-helmet::before {
  content: "\F025F"; }

.mdi-forklift::before {
  content: "\F07C9"; }

.mdi-form-dropdown::before {
  content: "\F1400"; }

.mdi-form-select::before {
  content: "\F1401"; }

.mdi-form-textarea::before {
  content: "\F1095"; }

.mdi-form-textbox::before {
  content: "\F060E"; }

.mdi-form-textbox-lock::before {
  content: "\F135D"; }

.mdi-form-textbox-password::before {
  content: "\F07F5"; }

.mdi-format-align-bottom::before {
  content: "\F0753"; }

.mdi-format-align-center::before {
  content: "\F0260"; }

.mdi-format-align-justify::before {
  content: "\F0261"; }

.mdi-format-align-left::before {
  content: "\F0262"; }

.mdi-format-align-middle::before {
  content: "\F0754"; }

.mdi-format-align-right::before {
  content: "\F0263"; }

.mdi-format-align-top::before {
  content: "\F0755"; }

.mdi-format-annotation-minus::before {
  content: "\F0ABC"; }

.mdi-format-annotation-plus::before {
  content: "\F0646"; }

.mdi-format-bold::before {
  content: "\F0264"; }

.mdi-format-clear::before {
  content: "\F0265"; }

.mdi-format-color-fill::before {
  content: "\F0266"; }

.mdi-format-color-highlight::before {
  content: "\F0E31"; }

.mdi-format-color-marker-cancel::before {
  content: "\F1313"; }

.mdi-format-color-text::before {
  content: "\F069E"; }

.mdi-format-columns::before {
  content: "\F08DF"; }

.mdi-format-float-center::before {
  content: "\F0267"; }

.mdi-format-float-left::before {
  content: "\F0268"; }

.mdi-format-float-none::before {
  content: "\F0269"; }

.mdi-format-float-right::before {
  content: "\F026A"; }

.mdi-format-font::before {
  content: "\F06D6"; }

.mdi-format-font-size-decrease::before {
  content: "\F09F3"; }

.mdi-format-font-size-increase::before {
  content: "\F09F4"; }

.mdi-format-header-1::before {
  content: "\F026B"; }

.mdi-format-header-2::before {
  content: "\F026C"; }

.mdi-format-header-3::before {
  content: "\F026D"; }

.mdi-format-header-4::before {
  content: "\F026E"; }

.mdi-format-header-5::before {
  content: "\F026F"; }

.mdi-format-header-6::before {
  content: "\F0270"; }

.mdi-format-header-decrease::before {
  content: "\F0271"; }

.mdi-format-header-equal::before {
  content: "\F0272"; }

.mdi-format-header-increase::before {
  content: "\F0273"; }

.mdi-format-header-pound::before {
  content: "\F0274"; }

.mdi-format-horizontal-align-center::before {
  content: "\F061E"; }

.mdi-format-horizontal-align-left::before {
  content: "\F061F"; }

.mdi-format-horizontal-align-right::before {
  content: "\F0620"; }

.mdi-format-indent-decrease::before {
  content: "\F0275"; }

.mdi-format-indent-increase::before {
  content: "\F0276"; }

.mdi-format-italic::before {
  content: "\F0277"; }

.mdi-format-letter-case::before {
  content: "\F0B34"; }

.mdi-format-letter-case-lower::before {
  content: "\F0B35"; }

.mdi-format-letter-case-upper::before {
  content: "\F0B36"; }

.mdi-format-letter-ends-with::before {
  content: "\F0FB8"; }

.mdi-format-letter-matches::before {
  content: "\F0FB9"; }

.mdi-format-letter-starts-with::before {
  content: "\F0FBA"; }

.mdi-format-line-spacing::before {
  content: "\F0278"; }

.mdi-format-line-style::before {
  content: "\F05C8"; }

.mdi-format-line-weight::before {
  content: "\F05C9"; }

.mdi-format-list-bulleted::before {
  content: "\F0279"; }

.mdi-format-list-bulleted-square::before {
  content: "\F0DD0"; }

.mdi-format-list-bulleted-triangle::before {
  content: "\F0EB2"; }

.mdi-format-list-bulleted-type::before {
  content: "\F027A"; }

.mdi-format-list-checkbox::before {
  content: "\F096A"; }

.mdi-format-list-checks::before {
  content: "\F0756"; }

.mdi-format-list-numbered::before {
  content: "\F027B"; }

.mdi-format-list-numbered-rtl::before {
  content: "\F0D0D"; }

.mdi-format-list-text::before {
  content: "\F126F"; }

.mdi-format-overline::before {
  content: "\F0EB3"; }

.mdi-format-page-break::before {
  content: "\F06D7"; }

.mdi-format-paint::before {
  content: "\F027C"; }

.mdi-format-paragraph::before {
  content: "\F027D"; }

.mdi-format-pilcrow::before {
  content: "\F06D8"; }

.mdi-format-quote-close::before {
  content: "\F027E"; }

.mdi-format-quote-close-outline::before {
  content: "\F11A8"; }

.mdi-format-quote-open::before {
  content: "\F0757"; }

.mdi-format-quote-open-outline::before {
  content: "\F11A7"; }

.mdi-format-rotate-90::before {
  content: "\F06AA"; }

.mdi-format-section::before {
  content: "\F069F"; }

.mdi-format-size::before {
  content: "\F027F"; }

.mdi-format-strikethrough::before {
  content: "\F0280"; }

.mdi-format-strikethrough-variant::before {
  content: "\F0281"; }

.mdi-format-subscript::before {
  content: "\F0282"; }

.mdi-format-superscript::before {
  content: "\F0283"; }

.mdi-format-text::before {
  content: "\F0284"; }

.mdi-format-text-rotation-angle-down::before {
  content: "\F0FBB"; }

.mdi-format-text-rotation-angle-up::before {
  content: "\F0FBC"; }

.mdi-format-text-rotation-down::before {
  content: "\F0D73"; }

.mdi-format-text-rotation-down-vertical::before {
  content: "\F0FBD"; }

.mdi-format-text-rotation-none::before {
  content: "\F0D74"; }

.mdi-format-text-rotation-up::before {
  content: "\F0FBE"; }

.mdi-format-text-rotation-vertical::before {
  content: "\F0FBF"; }

.mdi-format-text-variant::before {
  content: "\F0E32"; }

.mdi-format-text-variant-outline::before {
  content: "\F150F"; }

.mdi-format-text-wrapping-clip::before {
  content: "\F0D0E"; }

.mdi-format-text-wrapping-overflow::before {
  content: "\F0D0F"; }

.mdi-format-text-wrapping-wrap::before {
  content: "\F0D10"; }

.mdi-format-textbox::before {
  content: "\F0D11"; }

.mdi-format-textdirection-l-to-r::before {
  content: "\F0285"; }

.mdi-format-textdirection-r-to-l::before {
  content: "\F0286"; }

.mdi-format-title::before {
  content: "\F05F4"; }

.mdi-format-underline::before {
  content: "\F0287"; }

.mdi-format-vertical-align-bottom::before {
  content: "\F0621"; }

.mdi-format-vertical-align-center::before {
  content: "\F0622"; }

.mdi-format-vertical-align-top::before {
  content: "\F0623"; }

.mdi-format-wrap-inline::before {
  content: "\F0288"; }

.mdi-format-wrap-square::before {
  content: "\F0289"; }

.mdi-format-wrap-tight::before {
  content: "\F028A"; }

.mdi-format-wrap-top-bottom::before {
  content: "\F028B"; }

.mdi-forum::before {
  content: "\F028C"; }

.mdi-forum-outline::before {
  content: "\F0822"; }

.mdi-forward::before {
  content: "\F028D"; }

.mdi-forwardburger::before {
  content: "\F0D75"; }

.mdi-fountain::before {
  content: "\F096B"; }

.mdi-fountain-pen::before {
  content: "\F0D12"; }

.mdi-fountain-pen-tip::before {
  content: "\F0D13"; }

.mdi-freebsd::before {
  content: "\F08E0"; }

.mdi-frequently-asked-questions::before {
  content: "\F0EB4"; }

.mdi-fridge::before {
  content: "\F0290"; }

.mdi-fridge-alert::before {
  content: "\F11B1"; }

.mdi-fridge-alert-outline::before {
  content: "\F11B2"; }

.mdi-fridge-bottom::before {
  content: "\F0292"; }

.mdi-fridge-industrial::before {
  content: "\F15EE"; }

.mdi-fridge-industrial-alert::before {
  content: "\F15EF"; }

.mdi-fridge-industrial-alert-outline::before {
  content: "\F15F0"; }

.mdi-fridge-industrial-off::before {
  content: "\F15F1"; }

.mdi-fridge-industrial-off-outline::before {
  content: "\F15F2"; }

.mdi-fridge-industrial-outline::before {
  content: "\F15F3"; }

.mdi-fridge-off::before {
  content: "\F11AF"; }

.mdi-fridge-off-outline::before {
  content: "\F11B0"; }

.mdi-fridge-outline::before {
  content: "\F028F"; }

.mdi-fridge-top::before {
  content: "\F0291"; }

.mdi-fridge-variant::before {
  content: "\F15F4"; }

.mdi-fridge-variant-alert::before {
  content: "\F15F5"; }

.mdi-fridge-variant-alert-outline::before {
  content: "\F15F6"; }

.mdi-fridge-variant-off::before {
  content: "\F15F7"; }

.mdi-fridge-variant-off-outline::before {
  content: "\F15F8"; }

.mdi-fridge-variant-outline::before {
  content: "\F15F9"; }

.mdi-fruit-cherries::before {
  content: "\F1042"; }

.mdi-fruit-cherries-off::before {
  content: "\F13F8"; }

.mdi-fruit-citrus::before {
  content: "\F1043"; }

.mdi-fruit-citrus-off::before {
  content: "\F13F9"; }

.mdi-fruit-grapes::before {
  content: "\F1044"; }

.mdi-fruit-grapes-outline::before {
  content: "\F1045"; }

.mdi-fruit-pineapple::before {
  content: "\F1046"; }

.mdi-fruit-watermelon::before {
  content: "\F1047"; }

.mdi-fuel::before {
  content: "\F07CA"; }

.mdi-fullscreen::before {
  content: "\F0293"; }

.mdi-fullscreen-exit::before {
  content: "\F0294"; }

.mdi-function::before {
  content: "\F0295"; }

.mdi-function-variant::before {
  content: "\F0871"; }

.mdi-furigana-horizontal::before {
  content: "\F1081"; }

.mdi-furigana-vertical::before {
  content: "\F1082"; }

.mdi-fuse::before {
  content: "\F0C85"; }

.mdi-fuse-alert::before {
  content: "\F142D"; }

.mdi-fuse-blade::before {
  content: "\F0C86"; }

.mdi-fuse-off::before {
  content: "\F142C"; }

.mdi-gamepad::before {
  content: "\F0296"; }

.mdi-gamepad-circle::before {
  content: "\F0E33"; }

.mdi-gamepad-circle-down::before {
  content: "\F0E34"; }

.mdi-gamepad-circle-left::before {
  content: "\F0E35"; }

.mdi-gamepad-circle-outline::before {
  content: "\F0E36"; }

.mdi-gamepad-circle-right::before {
  content: "\F0E37"; }

.mdi-gamepad-circle-up::before {
  content: "\F0E38"; }

.mdi-gamepad-down::before {
  content: "\F0E39"; }

.mdi-gamepad-left::before {
  content: "\F0E3A"; }

.mdi-gamepad-right::before {
  content: "\F0E3B"; }

.mdi-gamepad-round::before {
  content: "\F0E3C"; }

.mdi-gamepad-round-down::before {
  content: "\F0E3D"; }

.mdi-gamepad-round-left::before {
  content: "\F0E3E"; }

.mdi-gamepad-round-outline::before {
  content: "\F0E3F"; }

.mdi-gamepad-round-right::before {
  content: "\F0E40"; }

.mdi-gamepad-round-up::before {
  content: "\F0E41"; }

.mdi-gamepad-square::before {
  content: "\F0EB5"; }

.mdi-gamepad-square-outline::before {
  content: "\F0EB6"; }

.mdi-gamepad-up::before {
  content: "\F0E42"; }

.mdi-gamepad-variant::before {
  content: "\F0297"; }

.mdi-gamepad-variant-outline::before {
  content: "\F0EB7"; }

.mdi-gamma::before {
  content: "\F10EE"; }

.mdi-gantry-crane::before {
  content: "\F0DD1"; }

.mdi-garage::before {
  content: "\F06D9"; }

.mdi-garage-alert::before {
  content: "\F0872"; }

.mdi-garage-alert-variant::before {
  content: "\F12D5"; }

.mdi-garage-open::before {
  content: "\F06DA"; }

.mdi-garage-open-variant::before {
  content: "\F12D4"; }

.mdi-garage-variant::before {
  content: "\F12D3"; }

.mdi-gas-cylinder::before {
  content: "\F0647"; }

.mdi-gas-station::before {
  content: "\F0298"; }

.mdi-gas-station-off::before {
  content: "\F1409"; }

.mdi-gas-station-off-outline::before {
  content: "\F140A"; }

.mdi-gas-station-outline::before {
  content: "\F0EB8"; }

.mdi-gate::before {
  content: "\F0299"; }

.mdi-gate-and::before {
  content: "\F08E1"; }

.mdi-gate-arrow-right::before {
  content: "\F1169"; }

.mdi-gate-nand::before {
  content: "\F08E2"; }

.mdi-gate-nor::before {
  content: "\F08E3"; }

.mdi-gate-not::before {
  content: "\F08E4"; }

.mdi-gate-open::before {
  content: "\F116A"; }

.mdi-gate-or::before {
  content: "\F08E5"; }

.mdi-gate-xnor::before {
  content: "\F08E6"; }

.mdi-gate-xor::before {
  content: "\F08E7"; }

.mdi-gatsby::before {
  content: "\F0E43"; }

.mdi-gauge::before {
  content: "\F029A"; }

.mdi-gauge-empty::before {
  content: "\F0873"; }

.mdi-gauge-full::before {
  content: "\F0874"; }

.mdi-gauge-low::before {
  content: "\F0875"; }

.mdi-gavel::before {
  content: "\F029B"; }

.mdi-gender-female::before {
  content: "\F029C"; }

.mdi-gender-male::before {
  content: "\F029D"; }

.mdi-gender-male-female::before {
  content: "\F029E"; }

.mdi-gender-male-female-variant::before {
  content: "\F113F"; }

.mdi-gender-non-binary::before {
  content: "\F1140"; }

.mdi-gender-transgender::before {
  content: "\F029F"; }

.mdi-gentoo::before {
  content: "\F08E8"; }

.mdi-gesture::before {
  content: "\F07CB"; }

.mdi-gesture-double-tap::before {
  content: "\F073C"; }

.mdi-gesture-pinch::before {
  content: "\F0ABD"; }

.mdi-gesture-spread::before {
  content: "\F0ABE"; }

.mdi-gesture-swipe::before {
  content: "\F0D76"; }

.mdi-gesture-swipe-down::before {
  content: "\F073D"; }

.mdi-gesture-swipe-horizontal::before {
  content: "\F0ABF"; }

.mdi-gesture-swipe-left::before {
  content: "\F073E"; }

.mdi-gesture-swipe-right::before {
  content: "\F073F"; }

.mdi-gesture-swipe-up::before {
  content: "\F0740"; }

.mdi-gesture-swipe-vertical::before {
  content: "\F0AC0"; }

.mdi-gesture-tap::before {
  content: "\F0741"; }

.mdi-gesture-tap-box::before {
  content: "\F12A9"; }

.mdi-gesture-tap-button::before {
  content: "\F12A8"; }

.mdi-gesture-tap-hold::before {
  content: "\F0D77"; }

.mdi-gesture-two-double-tap::before {
  content: "\F0742"; }

.mdi-gesture-two-tap::before {
  content: "\F0743"; }

.mdi-ghost::before {
  content: "\F02A0"; }

.mdi-ghost-off::before {
  content: "\F09F5"; }

.mdi-ghost-off-outline::before {
  content: "\F165C"; }

.mdi-ghost-outline::before {
  content: "\F165D"; }

.mdi-gif::before {
  content: "\F0D78"; }

.mdi-gift::before {
  content: "\F0E44"; }

.mdi-gift-outline::before {
  content: "\F02A1"; }

.mdi-git::before {
  content: "\F02A2"; }

.mdi-github::before {
  content: "\F02A4"; }

.mdi-gitlab::before {
  content: "\F0BA0"; }

.mdi-glass-cocktail::before {
  content: "\F0356"; }

.mdi-glass-cocktail-off::before {
  content: "\F15E6"; }

.mdi-glass-flute::before {
  content: "\F02A5"; }

.mdi-glass-mug::before {
  content: "\F02A6"; }

.mdi-glass-mug-off::before {
  content: "\F15E7"; }

.mdi-glass-mug-variant::before {
  content: "\F1116"; }

.mdi-glass-mug-variant-off::before {
  content: "\F15E8"; }

.mdi-glass-pint-outline::before {
  content: "\F130D"; }

.mdi-glass-stange::before {
  content: "\F02A7"; }

.mdi-glass-tulip::before {
  content: "\F02A8"; }

.mdi-glass-wine::before {
  content: "\F0876"; }

.mdi-glasses::before {
  content: "\F02AA"; }

.mdi-globe-light::before {
  content: "\F12D7"; }

.mdi-globe-model::before {
  content: "\F08E9"; }

.mdi-gmail::before {
  content: "\F02AB"; }

.mdi-gnome::before {
  content: "\F02AC"; }

.mdi-go-kart::before {
  content: "\F0D79"; }

.mdi-go-kart-track::before {
  content: "\F0D7A"; }

.mdi-gog::before {
  content: "\F0BA1"; }

.mdi-gold::before {
  content: "\F124F"; }

.mdi-golf::before {
  content: "\F0823"; }

.mdi-golf-cart::before {
  content: "\F11A4"; }

.mdi-golf-tee::before {
  content: "\F1083"; }

.mdi-gondola::before {
  content: "\F0686"; }

.mdi-goodreads::before {
  content: "\F0D7B"; }

.mdi-google::before {
  content: "\F02AD"; }

.mdi-google-ads::before {
  content: "\F0C87"; }

.mdi-google-analytics::before {
  content: "\F07CC"; }

.mdi-google-assistant::before {
  content: "\F07CD"; }

.mdi-google-cardboard::before {
  content: "\F02AE"; }

.mdi-google-chrome::before {
  content: "\F02AF"; }

.mdi-google-circles::before {
  content: "\F02B0"; }

.mdi-google-circles-communities::before {
  content: "\F02B1"; }

.mdi-google-circles-extended::before {
  content: "\F02B2"; }

.mdi-google-circles-group::before {
  content: "\F02B3"; }

.mdi-google-classroom::before {
  content: "\F02C0"; }

.mdi-google-cloud::before {
  content: "\F11F6"; }

.mdi-google-controller::before {
  content: "\F02B4"; }

.mdi-google-controller-off::before {
  content: "\F02B5"; }

.mdi-google-downasaur::before {
  content: "\F1362"; }

.mdi-google-drive::before {
  content: "\F02B6"; }

.mdi-google-earth::before {
  content: "\F02B7"; }

.mdi-google-fit::before {
  content: "\F096C"; }

.mdi-google-glass::before {
  content: "\F02B8"; }

.mdi-google-hangouts::before {
  content: "\F02C9"; }

.mdi-google-home::before {
  content: "\F0824"; }

.mdi-google-keep::before {
  content: "\F06DC"; }

.mdi-google-lens::before {
  content: "\F09F6"; }

.mdi-google-maps::before {
  content: "\F05F5"; }

.mdi-google-my-business::before {
  content: "\F1048"; }

.mdi-google-nearby::before {
  content: "\F02B9"; }

.mdi-google-photos::before {
  content: "\F06DD"; }

.mdi-google-play::before {
  content: "\F02BC"; }

.mdi-google-plus::before {
  content: "\F02BD"; }

.mdi-google-podcast::before {
  content: "\F0EB9"; }

.mdi-google-spreadsheet::before {
  content: "\F09F7"; }

.mdi-google-street-view::before {
  content: "\F0C88"; }

.mdi-google-translate::before {
  content: "\F02BF"; }

.mdi-gradient::before {
  content: "\F06A0"; }

.mdi-grain::before {
  content: "\F0D7C"; }

.mdi-graph::before {
  content: "\F1049"; }

.mdi-graph-outline::before {
  content: "\F104A"; }

.mdi-graphql::before {
  content: "\F0877"; }

.mdi-grass::before {
  content: "\F1510"; }

.mdi-grave-stone::before {
  content: "\F0BA2"; }

.mdi-grease-pencil::before {
  content: "\F0648"; }

.mdi-greater-than::before {
  content: "\F096D"; }

.mdi-greater-than-or-equal::before {
  content: "\F096E"; }

.mdi-grid::before {
  content: "\F02C1"; }

.mdi-grid-large::before {
  content: "\F0758"; }

.mdi-grid-off::before {
  content: "\F02C2"; }

.mdi-grill::before {
  content: "\F0E45"; }

.mdi-grill-outline::before {
  content: "\F118A"; }

.mdi-group::before {
  content: "\F02C3"; }

.mdi-guitar-acoustic::before {
  content: "\F0771"; }

.mdi-guitar-electric::before {
  content: "\F02C4"; }

.mdi-guitar-pick::before {
  content: "\F02C5"; }

.mdi-guitar-pick-outline::before {
  content: "\F02C6"; }

.mdi-guy-fawkes-mask::before {
  content: "\F0825"; }

.mdi-hail::before {
  content: "\F0AC1"; }

.mdi-hair-dryer::before {
  content: "\F10EF"; }

.mdi-hair-dryer-outline::before {
  content: "\F10F0"; }

.mdi-halloween::before {
  content: "\F0BA3"; }

.mdi-hamburger::before {
  content: "\F0685"; }

.mdi-hammer::before {
  content: "\F08EA"; }

.mdi-hammer-screwdriver::before {
  content: "\F1322"; }

.mdi-hammer-wrench::before {
  content: "\F1323"; }

.mdi-hand::before {
  content: "\F0A4F"; }

.mdi-hand-heart::before {
  content: "\F10F1"; }

.mdi-hand-heart-outline::before {
  content: "\F157E"; }

.mdi-hand-left::before {
  content: "\F0E46"; }

.mdi-hand-okay::before {
  content: "\F0A50"; }

.mdi-hand-peace::before {
  content: "\F0A51"; }

.mdi-hand-peace-variant::before {
  content: "\F0A52"; }

.mdi-hand-pointing-down::before {
  content: "\F0A53"; }

.mdi-hand-pointing-left::before {
  content: "\F0A54"; }

.mdi-hand-pointing-right::before {
  content: "\F02C7"; }

.mdi-hand-pointing-up::before {
  content: "\F0A55"; }

.mdi-hand-right::before {
  content: "\F0E47"; }

.mdi-hand-saw::before {
  content: "\F0E48"; }

.mdi-hand-wash::before {
  content: "\F157F"; }

.mdi-hand-wash-outline::before {
  content: "\F1580"; }

.mdi-hand-water::before {
  content: "\F139F"; }

.mdi-handball::before {
  content: "\F0F53"; }

.mdi-handcuffs::before {
  content: "\F113E"; }

.mdi-handshake::before {
  content: "\F1218"; }

.mdi-handshake-outline::before {
  content: "\F15A1"; }

.mdi-hanger::before {
  content: "\F02C8"; }

.mdi-hard-hat::before {
  content: "\F096F"; }

.mdi-harddisk::before {
  content: "\F02CA"; }

.mdi-harddisk-plus::before {
  content: "\F104B"; }

.mdi-harddisk-remove::before {
  content: "\F104C"; }

.mdi-hat-fedora::before {
  content: "\F0BA4"; }

.mdi-hazard-lights::before {
  content: "\F0C89"; }

.mdi-hdr::before {
  content: "\F0D7D"; }

.mdi-hdr-off::before {
  content: "\F0D7E"; }

.mdi-head::before {
  content: "\F135E"; }

.mdi-head-alert::before {
  content: "\F1338"; }

.mdi-head-alert-outline::before {
  content: "\F1339"; }

.mdi-head-check::before {
  content: "\F133A"; }

.mdi-head-check-outline::before {
  content: "\F133B"; }

.mdi-head-cog::before {
  content: "\F133C"; }

.mdi-head-cog-outline::before {
  content: "\F133D"; }

.mdi-head-dots-horizontal::before {
  content: "\F133E"; }

.mdi-head-dots-horizontal-outline::before {
  content: "\F133F"; }

.mdi-head-flash::before {
  content: "\F1340"; }

.mdi-head-flash-outline::before {
  content: "\F1341"; }

.mdi-head-heart::before {
  content: "\F1342"; }

.mdi-head-heart-outline::before {
  content: "\F1343"; }

.mdi-head-lightbulb::before {
  content: "\F1344"; }

.mdi-head-lightbulb-outline::before {
  content: "\F1345"; }

.mdi-head-minus::before {
  content: "\F1346"; }

.mdi-head-minus-outline::before {
  content: "\F1347"; }

.mdi-head-outline::before {
  content: "\F135F"; }

.mdi-head-plus::before {
  content: "\F1348"; }

.mdi-head-plus-outline::before {
  content: "\F1349"; }

.mdi-head-question::before {
  content: "\F134A"; }

.mdi-head-question-outline::before {
  content: "\F134B"; }

.mdi-head-remove::before {
  content: "\F134C"; }

.mdi-head-remove-outline::before {
  content: "\F134D"; }

.mdi-head-snowflake::before {
  content: "\F134E"; }

.mdi-head-snowflake-outline::before {
  content: "\F134F"; }

.mdi-head-sync::before {
  content: "\F1350"; }

.mdi-head-sync-outline::before {
  content: "\F1351"; }

.mdi-headphones::before {
  content: "\F02CB"; }

.mdi-headphones-bluetooth::before {
  content: "\F0970"; }

.mdi-headphones-box::before {
  content: "\F02CC"; }

.mdi-headphones-off::before {
  content: "\F07CE"; }

.mdi-headphones-settings::before {
  content: "\F02CD"; }

.mdi-headset::before {
  content: "\F02CE"; }

.mdi-headset-dock::before {
  content: "\F02CF"; }

.mdi-headset-off::before {
  content: "\F02D0"; }

.mdi-heart::before {
  content: "\F02D1"; }

.mdi-heart-box::before {
  content: "\F02D2"; }

.mdi-heart-box-outline::before {
  content: "\F02D3"; }

.mdi-heart-broken::before {
  content: "\F02D4"; }

.mdi-heart-broken-outline::before {
  content: "\F0D14"; }

.mdi-heart-circle::before {
  content: "\F0971"; }

.mdi-heart-circle-outline::before {
  content: "\F0972"; }

.mdi-heart-cog::before {
  content: "\F1663"; }

.mdi-heart-cog-outline::before {
  content: "\F1664"; }

.mdi-heart-flash::before {
  content: "\F0EF9"; }

.mdi-heart-half::before {
  content: "\F06DF"; }

.mdi-heart-half-full::before {
  content: "\F06DE"; }

.mdi-heart-half-outline::before {
  content: "\F06E0"; }

.mdi-heart-minus::before {
  content: "\F142F"; }

.mdi-heart-minus-outline::before {
  content: "\F1432"; }

.mdi-heart-multiple::before {
  content: "\F0A56"; }

.mdi-heart-multiple-outline::before {
  content: "\F0A57"; }

.mdi-heart-off::before {
  content: "\F0759"; }

.mdi-heart-off-outline::before {
  content: "\F1434"; }

.mdi-heart-outline::before {
  content: "\F02D5"; }

.mdi-heart-plus::before {
  content: "\F142E"; }

.mdi-heart-plus-outline::before {
  content: "\F1431"; }

.mdi-heart-pulse::before {
  content: "\F05F6"; }

.mdi-heart-remove::before {
  content: "\F1430"; }

.mdi-heart-remove-outline::before {
  content: "\F1433"; }

.mdi-heart-settings::before {
  content: "\F1665"; }

.mdi-heart-settings-outline::before {
  content: "\F1666"; }

.mdi-helicopter::before {
  content: "\F0AC2"; }

.mdi-help::before {
  content: "\F02D6"; }

.mdi-help-box::before {
  content: "\F078B"; }

.mdi-help-circle::before {
  content: "\F02D7"; }

.mdi-help-circle-outline::before {
  content: "\F0625"; }

.mdi-help-network::before {
  content: "\F06F5"; }

.mdi-help-network-outline::before {
  content: "\F0C8A"; }

.mdi-help-rhombus::before {
  content: "\F0BA5"; }

.mdi-help-rhombus-outline::before {
  content: "\F0BA6"; }

.mdi-hexadecimal::before {
  content: "\F12A7"; }

.mdi-hexagon::before {
  content: "\F02D8"; }

.mdi-hexagon-multiple::before {
  content: "\F06E1"; }

.mdi-hexagon-multiple-outline::before {
  content: "\F10F2"; }

.mdi-hexagon-outline::before {
  content: "\F02D9"; }

.mdi-hexagon-slice-1::before {
  content: "\F0AC3"; }

.mdi-hexagon-slice-2::before {
  content: "\F0AC4"; }

.mdi-hexagon-slice-3::before {
  content: "\F0AC5"; }

.mdi-hexagon-slice-4::before {
  content: "\F0AC6"; }

.mdi-hexagon-slice-5::before {
  content: "\F0AC7"; }

.mdi-hexagon-slice-6::before {
  content: "\F0AC8"; }

.mdi-hexagram::before {
  content: "\F0AC9"; }

.mdi-hexagram-outline::before {
  content: "\F0ACA"; }

.mdi-high-definition::before {
  content: "\F07CF"; }

.mdi-high-definition-box::before {
  content: "\F0878"; }

.mdi-highway::before {
  content: "\F05F7"; }

.mdi-hiking::before {
  content: "\F0D7F"; }

.mdi-hinduism::before {
  content: "\F0973"; }

.mdi-history::before {
  content: "\F02DA"; }

.mdi-hockey-puck::before {
  content: "\F0879"; }

.mdi-hockey-sticks::before {
  content: "\F087A"; }

.mdi-hololens::before {
  content: "\F02DB"; }

.mdi-home::before {
  content: "\F02DC"; }

.mdi-home-account::before {
  content: "\F0826"; }

.mdi-home-alert::before {
  content: "\F087B"; }

.mdi-home-alert-outline::before {
  content: "\F15D0"; }

.mdi-home-analytics::before {
  content: "\F0EBA"; }

.mdi-home-assistant::before {
  content: "\F07D0"; }

.mdi-home-automation::before {
  content: "\F07D1"; }

.mdi-home-circle::before {
  content: "\F07D2"; }

.mdi-home-circle-outline::before {
  content: "\F104D"; }

.mdi-home-city::before {
  content: "\F0D15"; }

.mdi-home-city-outline::before {
  content: "\F0D16"; }

.mdi-home-currency-usd::before {
  content: "\F08AF"; }

.mdi-home-edit::before {
  content: "\F1159"; }

.mdi-home-edit-outline::before {
  content: "\F115A"; }

.mdi-home-export-outline::before {
  content: "\F0F9B"; }

.mdi-home-flood::before {
  content: "\F0EFA"; }

.mdi-home-floor-0::before {
  content: "\F0DD2"; }

.mdi-home-floor-1::before {
  content: "\F0D80"; }

.mdi-home-floor-2::before {
  content: "\F0D81"; }

.mdi-home-floor-3::before {
  content: "\F0D82"; }

.mdi-home-floor-a::before {
  content: "\F0D83"; }

.mdi-home-floor-b::before {
  content: "\F0D84"; }

.mdi-home-floor-g::before {
  content: "\F0D85"; }

.mdi-home-floor-l::before {
  content: "\F0D86"; }

.mdi-home-floor-negative-1::before {
  content: "\F0DD3"; }

.mdi-home-group::before {
  content: "\F0DD4"; }

.mdi-home-heart::before {
  content: "\F0827"; }

.mdi-home-import-outline::before {
  content: "\F0F9C"; }

.mdi-home-lightbulb::before {
  content: "\F1251"; }

.mdi-home-lightbulb-outline::before {
  content: "\F1252"; }

.mdi-home-lock::before {
  content: "\F08EB"; }

.mdi-home-lock-open::before {
  content: "\F08EC"; }

.mdi-home-map-marker::before {
  content: "\F05F8"; }

.mdi-home-minus::before {
  content: "\F0974"; }

.mdi-home-minus-outline::before {
  content: "\F13D5"; }

.mdi-home-modern::before {
  content: "\F02DD"; }

.mdi-home-outline::before {
  content: "\F06A1"; }

.mdi-home-plus::before {
  content: "\F0975"; }

.mdi-home-plus-outline::before {
  content: "\F13D6"; }

.mdi-home-remove::before {
  content: "\F1247"; }

.mdi-home-remove-outline::before {
  content: "\F13D7"; }

.mdi-home-roof::before {
  content: "\F112B"; }

.mdi-home-search::before {
  content: "\F13B0"; }

.mdi-home-search-outline::before {
  content: "\F13B1"; }

.mdi-home-thermometer::before {
  content: "\F0F54"; }

.mdi-home-thermometer-outline::before {
  content: "\F0F55"; }

.mdi-home-variant::before {
  content: "\F02DE"; }

.mdi-home-variant-outline::before {
  content: "\F0BA7"; }

.mdi-hook::before {
  content: "\F06E2"; }

.mdi-hook-off::before {
  content: "\F06E3"; }

.mdi-hops::before {
  content: "\F02DF"; }

.mdi-horizontal-rotate-clockwise::before {
  content: "\F10F3"; }

.mdi-horizontal-rotate-counterclockwise::before {
  content: "\F10F4"; }

.mdi-horse::before {
  content: "\F15BF"; }

.mdi-horse-human::before {
  content: "\F15C0"; }

.mdi-horse-variant::before {
  content: "\F15C1"; }

.mdi-horseshoe::before {
  content: "\F0A58"; }

.mdi-hospital::before {
  content: "\F0FF6"; }

.mdi-hospital-box::before {
  content: "\F02E0"; }

.mdi-hospital-box-outline::before {
  content: "\F0FF7"; }

.mdi-hospital-building::before {
  content: "\F02E1"; }

.mdi-hospital-marker::before {
  content: "\F02E2"; }

.mdi-hot-tub::before {
  content: "\F0828"; }

.mdi-hours-24::before {
  content: "\F1478"; }

.mdi-hubspot::before {
  content: "\F0D17"; }

.mdi-hulu::before {
  content: "\F0829"; }

.mdi-human::before {
  content: "\F02E6"; }

.mdi-human-baby-changing-table::before {
  content: "\F138B"; }

.mdi-human-cane::before {
  content: "\F1581"; }

.mdi-human-capacity-decrease::before {
  content: "\F159B"; }

.mdi-human-capacity-increase::before {
  content: "\F159C"; }

.mdi-human-child::before {
  content: "\F02E7"; }

.mdi-human-edit::before {
  content: "\F14E8"; }

.mdi-human-female::before {
  content: "\F0649"; }

.mdi-human-female-boy::before {
  content: "\F0A59"; }

.mdi-human-female-dance::before {
  content: "\F15C9"; }

.mdi-human-female-female::before {
  content: "\F0A5A"; }

.mdi-human-female-girl::before {
  content: "\F0A5B"; }

.mdi-human-greeting::before {
  content: "\F064A"; }

.mdi-human-greeting-proximity::before {
  content: "\F159D"; }

.mdi-human-handsdown::before {
  content: "\F064B"; }

.mdi-human-handsup::before {
  content: "\F064C"; }

.mdi-human-male::before {
  content: "\F064D"; }

.mdi-human-male-boy::before {
  content: "\F0A5C"; }

.mdi-human-male-child::before {
  content: "\F138C"; }

.mdi-human-male-female::before {
  content: "\F02E8"; }

.mdi-human-male-girl::before {
  content: "\F0A5D"; }

.mdi-human-male-height::before {
  content: "\F0EFB"; }

.mdi-human-male-height-variant::before {
  content: "\F0EFC"; }

.mdi-human-male-male::before {
  content: "\F0A5E"; }

.mdi-human-pregnant::before {
  content: "\F05CF"; }

.mdi-human-queue::before {
  content: "\F1571"; }

.mdi-human-scooter::before {
  content: "\F11E9"; }

.mdi-human-wheelchair::before {
  content: "\F138D"; }

.mdi-humble-bundle::before {
  content: "\F0744"; }

.mdi-hvac::before {
  content: "\F1352"; }

.mdi-hvac-off::before {
  content: "\F159E"; }

.mdi-hydraulic-oil-level::before {
  content: "\F1324"; }

.mdi-hydraulic-oil-temperature::before {
  content: "\F1325"; }

.mdi-hydro-power::before {
  content: "\F12E5"; }

.mdi-ice-cream::before {
  content: "\F082A"; }

.mdi-ice-cream-off::before {
  content: "\F0E52"; }

.mdi-ice-pop::before {
  content: "\F0EFD"; }

.mdi-id-card::before {
  content: "\F0FC0"; }

.mdi-identifier::before {
  content: "\F0EFE"; }

.mdi-ideogram-cjk::before {
  content: "\F1331"; }

.mdi-ideogram-cjk-variant::before {
  content: "\F1332"; }

.mdi-iframe::before {
  content: "\F0C8B"; }

.mdi-iframe-array::before {
  content: "\F10F5"; }

.mdi-iframe-array-outline::before {
  content: "\F10F6"; }

.mdi-iframe-braces::before {
  content: "\F10F7"; }

.mdi-iframe-braces-outline::before {
  content: "\F10F8"; }

.mdi-iframe-outline::before {
  content: "\F0C8C"; }

.mdi-iframe-parentheses::before {
  content: "\F10F9"; }

.mdi-iframe-parentheses-outline::before {
  content: "\F10FA"; }

.mdi-iframe-variable::before {
  content: "\F10FB"; }

.mdi-iframe-variable-outline::before {
  content: "\F10FC"; }

.mdi-image::before {
  content: "\F02E9"; }

.mdi-image-album::before {
  content: "\F02EA"; }

.mdi-image-area::before {
  content: "\F02EB"; }

.mdi-image-area-close::before {
  content: "\F02EC"; }

.mdi-image-auto-adjust::before {
  content: "\F0FC1"; }

.mdi-image-broken::before {
  content: "\F02ED"; }

.mdi-image-broken-variant::before {
  content: "\F02EE"; }

.mdi-image-edit::before {
  content: "\F11E3"; }

.mdi-image-edit-outline::before {
  content: "\F11E4"; }

.mdi-image-filter-black-white::before {
  content: "\F02F0"; }

.mdi-image-filter-center-focus::before {
  content: "\F02F1"; }

.mdi-image-filter-center-focus-strong::before {
  content: "\F0EFF"; }

.mdi-image-filter-center-focus-strong-outline::before {
  content: "\F0F00"; }

.mdi-image-filter-center-focus-weak::before {
  content: "\F02F2"; }

.mdi-image-filter-drama::before {
  content: "\F02F3"; }

.mdi-image-filter-frames::before {
  content: "\F02F4"; }

.mdi-image-filter-hdr::before {
  content: "\F02F5"; }

.mdi-image-filter-none::before {
  content: "\F02F6"; }

.mdi-image-filter-tilt-shift::before {
  content: "\F02F7"; }

.mdi-image-filter-vintage::before {
  content: "\F02F8"; }

.mdi-image-frame::before {
  content: "\F0E49"; }

.mdi-image-minus::before {
  content: "\F1419"; }

.mdi-image-move::before {
  content: "\F09F8"; }

.mdi-image-multiple::before {
  content: "\F02F9"; }

.mdi-image-multiple-outline::before {
  content: "\F02EF"; }

.mdi-image-off::before {
  content: "\F082B"; }

.mdi-image-off-outline::before {
  content: "\F11D1"; }

.mdi-image-outline::before {
  content: "\F0976"; }

.mdi-image-plus::before {
  content: "\F087C"; }

.mdi-image-remove::before {
  content: "\F1418"; }

.mdi-image-search::before {
  content: "\F0977"; }

.mdi-image-search-outline::before {
  content: "\F0978"; }

.mdi-image-size-select-actual::before {
  content: "\F0C8D"; }

.mdi-image-size-select-large::before {
  content: "\F0C8E"; }

.mdi-image-size-select-small::before {
  content: "\F0C8F"; }

.mdi-image-text::before {
  content: "\F160D"; }

.mdi-import::before {
  content: "\F02FA"; }

.mdi-inbox::before {
  content: "\F0687"; }

.mdi-inbox-arrow-down::before {
  content: "\F02FB"; }

.mdi-inbox-arrow-down-outline::before {
  content: "\F1270"; }

.mdi-inbox-arrow-up::before {
  content: "\F03D1"; }

.mdi-inbox-arrow-up-outline::before {
  content: "\F1271"; }

.mdi-inbox-full::before {
  content: "\F1272"; }

.mdi-inbox-full-outline::before {
  content: "\F1273"; }

.mdi-inbox-multiple::before {
  content: "\F08B0"; }

.mdi-inbox-multiple-outline::before {
  content: "\F0BA8"; }

.mdi-inbox-outline::before {
  content: "\F1274"; }

.mdi-inbox-remove::before {
  content: "\F159F"; }

.mdi-inbox-remove-outline::before {
  content: "\F15A0"; }

.mdi-incognito::before {
  content: "\F05F9"; }

.mdi-incognito-circle::before {
  content: "\F1421"; }

.mdi-incognito-circle-off::before {
  content: "\F1422"; }

.mdi-incognito-off::before {
  content: "\F0075"; }

.mdi-infinity::before {
  content: "\F06E4"; }

.mdi-information::before {
  content: "\F02FC"; }

.mdi-information-outline::before {
  content: "\F02FD"; }

.mdi-information-variant::before {
  content: "\F064E"; }

.mdi-instagram::before {
  content: "\F02FE"; }

.mdi-instrument-triangle::before {
  content: "\F104E"; }

.mdi-invert-colors::before {
  content: "\F0301"; }

.mdi-invert-colors-off::before {
  content: "\F0E4A"; }

.mdi-iobroker::before {
  content: "\F12E8"; }

.mdi-ip::before {
  content: "\F0A5F"; }

.mdi-ip-network::before {
  content: "\F0A60"; }

.mdi-ip-network-outline::before {
  content: "\F0C90"; }

.mdi-ipod::before {
  content: "\F0C91"; }

.mdi-islam::before {
  content: "\F0979"; }

.mdi-island::before {
  content: "\F104F"; }

.mdi-iv-bag::before {
  content: "\F10B9"; }

.mdi-jabber::before {
  content: "\F0DD5"; }

.mdi-jeepney::before {
  content: "\F0302"; }

.mdi-jellyfish::before {
  content: "\F0F01"; }

.mdi-jellyfish-outline::before {
  content: "\F0F02"; }

.mdi-jira::before {
  content: "\F0303"; }

.mdi-jquery::before {
  content: "\F087D"; }

.mdi-jsfiddle::before {
  content: "\F0304"; }

.mdi-judaism::before {
  content: "\F097A"; }

.mdi-jump-rope::before {
  content: "\F12FF"; }

.mdi-kabaddi::before {
  content: "\F0D87"; }

.mdi-kangaroo::before {
  content: "\F1558"; }

.mdi-karate::before {
  content: "\F082C"; }

.mdi-keg::before {
  content: "\F0305"; }

.mdi-kettle::before {
  content: "\F05FA"; }

.mdi-kettle-alert::before {
  content: "\F1317"; }

.mdi-kettle-alert-outline::before {
  content: "\F1318"; }

.mdi-kettle-off::before {
  content: "\F131B"; }

.mdi-kettle-off-outline::before {
  content: "\F131C"; }

.mdi-kettle-outline::before {
  content: "\F0F56"; }

.mdi-kettle-steam::before {
  content: "\F1319"; }

.mdi-kettle-steam-outline::before {
  content: "\F131A"; }

.mdi-kettlebell::before {
  content: "\F1300"; }

.mdi-key::before {
  content: "\F0306"; }

.mdi-key-arrow-right::before {
  content: "\F1312"; }

.mdi-key-chain::before {
  content: "\F1574"; }

.mdi-key-chain-variant::before {
  content: "\F1575"; }

.mdi-key-change::before {
  content: "\F0307"; }

.mdi-key-link::before {
  content: "\F119F"; }

.mdi-key-minus::before {
  content: "\F0308"; }

.mdi-key-outline::before {
  content: "\F0DD6"; }

.mdi-key-plus::before {
  content: "\F0309"; }

.mdi-key-remove::before {
  content: "\F030A"; }

.mdi-key-star::before {
  content: "\F119E"; }

.mdi-key-variant::before {
  content: "\F030B"; }

.mdi-key-wireless::before {
  content: "\F0FC2"; }

.mdi-keyboard::before {
  content: "\F030C"; }

.mdi-keyboard-backspace::before {
  content: "\F030D"; }

.mdi-keyboard-caps::before {
  content: "\F030E"; }

.mdi-keyboard-close::before {
  content: "\F030F"; }

.mdi-keyboard-esc::before {
  content: "\F12B7"; }

.mdi-keyboard-f1::before {
  content: "\F12AB"; }

.mdi-keyboard-f10::before {
  content: "\F12B4"; }

.mdi-keyboard-f11::before {
  content: "\F12B5"; }

.mdi-keyboard-f12::before {
  content: "\F12B6"; }

.mdi-keyboard-f2::before {
  content: "\F12AC"; }

.mdi-keyboard-f3::before {
  content: "\F12AD"; }

.mdi-keyboard-f4::before {
  content: "\F12AE"; }

.mdi-keyboard-f5::before {
  content: "\F12AF"; }

.mdi-keyboard-f6::before {
  content: "\F12B0"; }

.mdi-keyboard-f7::before {
  content: "\F12B1"; }

.mdi-keyboard-f8::before {
  content: "\F12B2"; }

.mdi-keyboard-f9::before {
  content: "\F12B3"; }

.mdi-keyboard-off::before {
  content: "\F0310"; }

.mdi-keyboard-off-outline::before {
  content: "\F0E4B"; }

.mdi-keyboard-outline::before {
  content: "\F097B"; }

.mdi-keyboard-return::before {
  content: "\F0311"; }

.mdi-keyboard-settings::before {
  content: "\F09F9"; }

.mdi-keyboard-settings-outline::before {
  content: "\F09FA"; }

.mdi-keyboard-space::before {
  content: "\F1050"; }

.mdi-keyboard-tab::before {
  content: "\F0312"; }

.mdi-keyboard-variant::before {
  content: "\F0313"; }

.mdi-khanda::before {
  content: "\F10FD"; }

.mdi-kickstarter::before {
  content: "\F0745"; }

.mdi-klingon::before {
  content: "\F135B"; }

.mdi-knife::before {
  content: "\F09FB"; }

.mdi-knife-military::before {
  content: "\F09FC"; }

.mdi-kodi::before {
  content: "\F0314"; }

.mdi-kubernetes::before {
  content: "\F10FE"; }

.mdi-label::before {
  content: "\F0315"; }

.mdi-label-multiple::before {
  content: "\F1375"; }

.mdi-label-multiple-outline::before {
  content: "\F1376"; }

.mdi-label-off::before {
  content: "\F0ACB"; }

.mdi-label-off-outline::before {
  content: "\F0ACC"; }

.mdi-label-outline::before {
  content: "\F0316"; }

.mdi-label-percent::before {
  content: "\F12EA"; }

.mdi-label-percent-outline::before {
  content: "\F12EB"; }

.mdi-label-variant::before {
  content: "\F0ACD"; }

.mdi-label-variant-outline::before {
  content: "\F0ACE"; }

.mdi-ladder::before {
  content: "\F15A2"; }

.mdi-ladybug::before {
  content: "\F082D"; }

.mdi-lambda::before {
  content: "\F0627"; }

.mdi-lamp::before {
  content: "\F06B5"; }

.mdi-lamps::before {
  content: "\F1576"; }

.mdi-lan::before {
  content: "\F0317"; }

.mdi-lan-check::before {
  content: "\F12AA"; }

.mdi-lan-connect::before {
  content: "\F0318"; }

.mdi-lan-disconnect::before {
  content: "\F0319"; }

.mdi-lan-pending::before {
  content: "\F031A"; }

.mdi-language-c::before {
  content: "\F0671"; }

.mdi-language-cpp::before {
  content: "\F0672"; }

.mdi-language-csharp::before {
  content: "\F031B"; }

.mdi-language-css3::before {
  content: "\F031C"; }

.mdi-language-fortran::before {
  content: "\F121A"; }

.mdi-language-go::before {
  content: "\F07D3"; }

.mdi-language-haskell::before {
  content: "\F0C92"; }

.mdi-language-html5::before {
  content: "\F031D"; }

.mdi-language-java::before {
  content: "\F0B37"; }

.mdi-language-javascript::before {
  content: "\F031E"; }

.mdi-language-kotlin::before {
  content: "\F1219"; }

.mdi-language-lua::before {
  content: "\F08B1"; }

.mdi-language-markdown::before {
  content: "\F0354"; }

.mdi-language-markdown-outline::before {
  content: "\F0F5B"; }

.mdi-language-php::before {
  content: "\F031F"; }

.mdi-language-python::before {
  content: "\F0320"; }

.mdi-language-r::before {
  content: "\F07D4"; }

.mdi-language-ruby::before {
  content: "\F0D2D"; }

.mdi-language-ruby-on-rails::before {
  content: "\F0ACF"; }

.mdi-language-rust::before {
  content: "\F1617"; }

.mdi-language-swift::before {
  content: "\F06E5"; }

.mdi-language-typescript::before {
  content: "\F06E6"; }

.mdi-language-xaml::before {
  content: "\F0673"; }

.mdi-laptop::before {
  content: "\F0322"; }

.mdi-laptop-chromebook::before {
  content: "\F0323"; }

.mdi-laptop-mac::before {
  content: "\F0324"; }

.mdi-laptop-off::before {
  content: "\F06E7"; }

.mdi-laptop-windows::before {
  content: "\F0325"; }

.mdi-laravel::before {
  content: "\F0AD0"; }

.mdi-laser-pointer::before {
  content: "\F1484"; }

.mdi-lasso::before {
  content: "\F0F03"; }

.mdi-lastpass::before {
  content: "\F0446"; }

.mdi-latitude::before {
  content: "\F0F57"; }

.mdi-launch::before {
  content: "\F0327"; }

.mdi-lava-lamp::before {
  content: "\F07D5"; }

.mdi-layers::before {
  content: "\F0328"; }

.mdi-layers-minus::before {
  content: "\F0E4C"; }

.mdi-layers-off::before {
  content: "\F0329"; }

.mdi-layers-off-outline::before {
  content: "\F09FD"; }

.mdi-layers-outline::before {
  content: "\F09FE"; }

.mdi-layers-plus::before {
  content: "\F0E4D"; }

.mdi-layers-remove::before {
  content: "\F0E4E"; }

.mdi-layers-search::before {
  content: "\F1206"; }

.mdi-layers-search-outline::before {
  content: "\F1207"; }

.mdi-layers-triple::before {
  content: "\F0F58"; }

.mdi-layers-triple-outline::before {
  content: "\F0F59"; }

.mdi-lead-pencil::before {
  content: "\F064F"; }

.mdi-leaf::before {
  content: "\F032A"; }

.mdi-leaf-maple::before {
  content: "\F0C93"; }

.mdi-leaf-maple-off::before {
  content: "\F12DA"; }

.mdi-leaf-off::before {
  content: "\F12D9"; }

.mdi-leak::before {
  content: "\F0DD7"; }

.mdi-leak-off::before {
  content: "\F0DD8"; }

.mdi-led-off::before {
  content: "\F032B"; }

.mdi-led-on::before {
  content: "\F032C"; }

.mdi-led-outline::before {
  content: "\F032D"; }

.mdi-led-strip::before {
  content: "\F07D6"; }

.mdi-led-strip-variant::before {
  content: "\F1051"; }

.mdi-led-variant-off::before {
  content: "\F032E"; }

.mdi-led-variant-on::before {
  content: "\F032F"; }

.mdi-led-variant-outline::before {
  content: "\F0330"; }

.mdi-leek::before {
  content: "\F117D"; }

.mdi-less-than::before {
  content: "\F097C"; }

.mdi-less-than-or-equal::before {
  content: "\F097D"; }

.mdi-library::before {
  content: "\F0331"; }

.mdi-library-shelves::before {
  content: "\F0BA9"; }

.mdi-license::before {
  content: "\F0FC3"; }

.mdi-lifebuoy::before {
  content: "\F087E"; }

.mdi-light-switch::before {
  content: "\F097E"; }

.mdi-lightbulb::before {
  content: "\F0335"; }

.mdi-lightbulb-cfl::before {
  content: "\F1208"; }

.mdi-lightbulb-cfl-off::before {
  content: "\F1209"; }

.mdi-lightbulb-cfl-spiral::before {
  content: "\F1275"; }

.mdi-lightbulb-cfl-spiral-off::before {
  content: "\F12C3"; }

.mdi-lightbulb-group::before {
  content: "\F1253"; }

.mdi-lightbulb-group-off::before {
  content: "\F12CD"; }

.mdi-lightbulb-group-off-outline::before {
  content: "\F12CE"; }

.mdi-lightbulb-group-outline::before {
  content: "\F1254"; }

.mdi-lightbulb-multiple::before {
  content: "\F1255"; }

.mdi-lightbulb-multiple-off::before {
  content: "\F12CF"; }

.mdi-lightbulb-multiple-off-outline::before {
  content: "\F12D0"; }

.mdi-lightbulb-multiple-outline::before {
  content: "\F1256"; }

.mdi-lightbulb-off::before {
  content: "\F0E4F"; }

.mdi-lightbulb-off-outline::before {
  content: "\F0E50"; }

.mdi-lightbulb-on::before {
  content: "\F06E8"; }

.mdi-lightbulb-on-outline::before {
  content: "\F06E9"; }

.mdi-lightbulb-outline::before {
  content: "\F0336"; }

.mdi-lighthouse::before {
  content: "\F09FF"; }

.mdi-lighthouse-on::before {
  content: "\F0A00"; }

.mdi-lightning-bolt::before {
  content: "\F140B"; }

.mdi-lightning-bolt-outline::before {
  content: "\F140C"; }

.mdi-lingerie::before {
  content: "\F1476"; }

.mdi-link::before {
  content: "\F0337"; }

.mdi-link-box::before {
  content: "\F0D1A"; }

.mdi-link-box-outline::before {
  content: "\F0D1B"; }

.mdi-link-box-variant::before {
  content: "\F0D1C"; }

.mdi-link-box-variant-outline::before {
  content: "\F0D1D"; }

.mdi-link-lock::before {
  content: "\F10BA"; }

.mdi-link-off::before {
  content: "\F0338"; }

.mdi-link-plus::before {
  content: "\F0C94"; }

.mdi-link-variant::before {
  content: "\F0339"; }

.mdi-link-variant-minus::before {
  content: "\F10FF"; }

.mdi-link-variant-off::before {
  content: "\F033A"; }

.mdi-link-variant-plus::before {
  content: "\F1100"; }

.mdi-link-variant-remove::before {
  content: "\F1101"; }

.mdi-linkedin::before {
  content: "\F033B"; }

.mdi-linux::before {
  content: "\F033D"; }

.mdi-linux-mint::before {
  content: "\F08ED"; }

.mdi-lipstick::before {
  content: "\F13B5"; }

.mdi-list-status::before {
  content: "\F15AB"; }

.mdi-litecoin::before {
  content: "\F0A61"; }

.mdi-loading::before {
  content: "\F0772"; }

.mdi-location-enter::before {
  content: "\F0FC4"; }

.mdi-location-exit::before {
  content: "\F0FC5"; }

.mdi-lock::before {
  content: "\F033E"; }

.mdi-lock-alert::before {
  content: "\F08EE"; }

.mdi-lock-alert-outline::before {
  content: "\F15D1"; }

.mdi-lock-check::before {
  content: "\F139A"; }

.mdi-lock-check-outline::before {
  content: "\F16A8"; }

.mdi-lock-clock::before {
  content: "\F097F"; }

.mdi-lock-minus::before {
  content: "\F16A9"; }

.mdi-lock-minus-outline::before {
  content: "\F16AA"; }

.mdi-lock-off::before {
  content: "\F1671"; }

.mdi-lock-off-outline::before {
  content: "\F1672"; }

.mdi-lock-open::before {
  content: "\F033F"; }

.mdi-lock-open-alert::before {
  content: "\F139B"; }

.mdi-lock-open-alert-outline::before {
  content: "\F15D2"; }

.mdi-lock-open-check::before {
  content: "\F139C"; }

.mdi-lock-open-check-outline::before {
  content: "\F16AB"; }

.mdi-lock-open-minus::before {
  content: "\F16AC"; }

.mdi-lock-open-minus-outline::before {
  content: "\F16AD"; }

.mdi-lock-open-outline::before {
  content: "\F0340"; }

.mdi-lock-open-plus::before {
  content: "\F16AE"; }

.mdi-lock-open-plus-outline::before {
  content: "\F16AF"; }

.mdi-lock-open-remove::before {
  content: "\F16B0"; }

.mdi-lock-open-remove-outline::before {
  content: "\F16B1"; }

.mdi-lock-open-variant::before {
  content: "\F0FC6"; }

.mdi-lock-open-variant-outline::before {
  content: "\F0FC7"; }

.mdi-lock-outline::before {
  content: "\F0341"; }

.mdi-lock-pattern::before {
  content: "\F06EA"; }

.mdi-lock-plus::before {
  content: "\F05FB"; }

.mdi-lock-plus-outline::before {
  content: "\F16B2"; }

.mdi-lock-question::before {
  content: "\F08EF"; }

.mdi-lock-remove::before {
  content: "\F16B3"; }

.mdi-lock-remove-outline::before {
  content: "\F16B4"; }

.mdi-lock-reset::before {
  content: "\F0773"; }

.mdi-lock-smart::before {
  content: "\F08B2"; }

.mdi-locker::before {
  content: "\F07D7"; }

.mdi-locker-multiple::before {
  content: "\F07D8"; }

.mdi-login::before {
  content: "\F0342"; }

.mdi-login-variant::before {
  content: "\F05FC"; }

.mdi-logout::before {
  content: "\F0343"; }

.mdi-logout-variant::before {
  content: "\F05FD"; }

.mdi-longitude::before {
  content: "\F0F5A"; }

.mdi-looks::before {
  content: "\F0344"; }

.mdi-lotion::before {
  content: "\F1582"; }

.mdi-lotion-outline::before {
  content: "\F1583"; }

.mdi-lotion-plus::before {
  content: "\F1584"; }

.mdi-lotion-plus-outline::before {
  content: "\F1585"; }

.mdi-loupe::before {
  content: "\F0345"; }

.mdi-lumx::before {
  content: "\F0346"; }

.mdi-lungs::before {
  content: "\F1084"; }

.mdi-magnet::before {
  content: "\F0347"; }

.mdi-magnet-on::before {
  content: "\F0348"; }

.mdi-magnify::before {
  content: "\F0349"; }

.mdi-magnify-close::before {
  content: "\F0980"; }

.mdi-magnify-minus::before {
  content: "\F034A"; }

.mdi-magnify-minus-cursor::before {
  content: "\F0A62"; }

.mdi-magnify-minus-outline::before {
  content: "\F06EC"; }

.mdi-magnify-plus::before {
  content: "\F034B"; }

.mdi-magnify-plus-cursor::before {
  content: "\F0A63"; }

.mdi-magnify-plus-outline::before {
  content: "\F06ED"; }

.mdi-magnify-remove-cursor::before {
  content: "\F120C"; }

.mdi-magnify-remove-outline::before {
  content: "\F120D"; }

.mdi-magnify-scan::before {
  content: "\F1276"; }

.mdi-mail::before {
  content: "\F0EBB"; }

.mdi-mailbox::before {
  content: "\F06EE"; }

.mdi-mailbox-open::before {
  content: "\F0D88"; }

.mdi-mailbox-open-outline::before {
  content: "\F0D89"; }

.mdi-mailbox-open-up::before {
  content: "\F0D8A"; }

.mdi-mailbox-open-up-outline::before {
  content: "\F0D8B"; }

.mdi-mailbox-outline::before {
  content: "\F0D8C"; }

.mdi-mailbox-up::before {
  content: "\F0D8D"; }

.mdi-mailbox-up-outline::before {
  content: "\F0D8E"; }

.mdi-manjaro::before {
  content: "\F160A"; }

.mdi-map::before {
  content: "\F034D"; }

.mdi-map-check::before {
  content: "\F0EBC"; }

.mdi-map-check-outline::before {
  content: "\F0EBD"; }

.mdi-map-clock::before {
  content: "\F0D1E"; }

.mdi-map-clock-outline::before {
  content: "\F0D1F"; }

.mdi-map-legend::before {
  content: "\F0A01"; }

.mdi-map-marker::before {
  content: "\F034E"; }

.mdi-map-marker-alert::before {
  content: "\F0F05"; }

.mdi-map-marker-alert-outline::before {
  content: "\F0F06"; }

.mdi-map-marker-check::before {
  content: "\F0C95"; }

.mdi-map-marker-check-outline::before {
  content: "\F12FB"; }

.mdi-map-marker-circle::before {
  content: "\F034F"; }

.mdi-map-marker-distance::before {
  content: "\F08F0"; }

.mdi-map-marker-down::before {
  content: "\F1102"; }

.mdi-map-marker-left::before {
  content: "\F12DB"; }

.mdi-map-marker-left-outline::before {
  content: "\F12DD"; }

.mdi-map-marker-minus::before {
  content: "\F0650"; }

.mdi-map-marker-minus-outline::before {
  content: "\F12F9"; }

.mdi-map-marker-multiple::before {
  content: "\F0350"; }

.mdi-map-marker-multiple-outline::before {
  content: "\F1277"; }

.mdi-map-marker-off::before {
  content: "\F0351"; }

.mdi-map-marker-off-outline::before {
  content: "\F12FD"; }

.mdi-map-marker-outline::before {
  content: "\F07D9"; }

.mdi-map-marker-path::before {
  content: "\F0D20"; }

.mdi-map-marker-plus::before {
  content: "\F0651"; }

.mdi-map-marker-plus-outline::before {
  content: "\F12F8"; }

.mdi-map-marker-question::before {
  content: "\F0F07"; }

.mdi-map-marker-question-outline::before {
  content: "\F0F08"; }

.mdi-map-marker-radius::before {
  content: "\F0352"; }

.mdi-map-marker-radius-outline::before {
  content: "\F12FC"; }

.mdi-map-marker-remove::before {
  content: "\F0F09"; }

.mdi-map-marker-remove-outline::before {
  content: "\F12FA"; }

.mdi-map-marker-remove-variant::before {
  content: "\F0F0A"; }

.mdi-map-marker-right::before {
  content: "\F12DC"; }

.mdi-map-marker-right-outline::before {
  content: "\F12DE"; }

.mdi-map-marker-star::before {
  content: "\F1608"; }

.mdi-map-marker-star-outline::before {
  content: "\F1609"; }

.mdi-map-marker-up::before {
  content: "\F1103"; }

.mdi-map-minus::before {
  content: "\F0981"; }

.mdi-map-outline::before {
  content: "\F0982"; }

.mdi-map-plus::before {
  content: "\F0983"; }

.mdi-map-search::before {
  content: "\F0984"; }

.mdi-map-search-outline::before {
  content: "\F0985"; }

.mdi-mapbox::before {
  content: "\F0BAA"; }

.mdi-margin::before {
  content: "\F0353"; }

.mdi-marker::before {
  content: "\F0652"; }

.mdi-marker-cancel::before {
  content: "\F0DD9"; }

.mdi-marker-check::before {
  content: "\F0355"; }

.mdi-mastodon::before {
  content: "\F0AD1"; }

.mdi-material-design::before {
  content: "\F0986"; }

.mdi-material-ui::before {
  content: "\F0357"; }

.mdi-math-compass::before {
  content: "\F0358"; }

.mdi-math-cos::before {
  content: "\F0C96"; }

.mdi-math-integral::before {
  content: "\F0FC8"; }

.mdi-math-integral-box::before {
  content: "\F0FC9"; }

.mdi-math-log::before {
  content: "\F1085"; }

.mdi-math-norm::before {
  content: "\F0FCA"; }

.mdi-math-norm-box::before {
  content: "\F0FCB"; }

.mdi-math-sin::before {
  content: "\F0C97"; }

.mdi-math-tan::before {
  content: "\F0C98"; }

.mdi-matrix::before {
  content: "\F0628"; }

.mdi-medal::before {
  content: "\F0987"; }

.mdi-medal-outline::before {
  content: "\F1326"; }

.mdi-medical-bag::before {
  content: "\F06EF"; }

.mdi-meditation::before {
  content: "\F117B"; }

.mdi-memory::before {
  content: "\F035B"; }

.mdi-menu::before {
  content: "\F035C"; }

.mdi-menu-down::before {
  content: "\F035D"; }

.mdi-menu-down-outline::before {
  content: "\F06B6"; }

.mdi-menu-left::before {
  content: "\F035E"; }

.mdi-menu-left-outline::before {
  content: "\F0A02"; }

.mdi-menu-open::before {
  content: "\F0BAB"; }

.mdi-menu-right::before {
  content: "\F035F"; }

.mdi-menu-right-outline::before {
  content: "\F0A03"; }

.mdi-menu-swap::before {
  content: "\F0A64"; }

.mdi-menu-swap-outline::before {
  content: "\F0A65"; }

.mdi-menu-up::before {
  content: "\F0360"; }

.mdi-menu-up-outline::before {
  content: "\F06B7"; }

.mdi-merge::before {
  content: "\F0F5C"; }

.mdi-message::before {
  content: "\F0361"; }

.mdi-message-alert::before {
  content: "\F0362"; }

.mdi-message-alert-outline::before {
  content: "\F0A04"; }

.mdi-message-arrow-left::before {
  content: "\F12F2"; }

.mdi-message-arrow-left-outline::before {
  content: "\F12F3"; }

.mdi-message-arrow-right::before {
  content: "\F12F4"; }

.mdi-message-arrow-right-outline::before {
  content: "\F12F5"; }

.mdi-message-bookmark::before {
  content: "\F15AC"; }

.mdi-message-bookmark-outline::before {
  content: "\F15AD"; }

.mdi-message-bulleted::before {
  content: "\F06A2"; }

.mdi-message-bulleted-off::before {
  content: "\F06A3"; }

.mdi-message-cog::before {
  content: "\F06F1"; }

.mdi-message-cog-outline::before {
  content: "\F1172"; }

.mdi-message-draw::before {
  content: "\F0363"; }

.mdi-message-flash::before {
  content: "\F15A9"; }

.mdi-message-flash-outline::before {
  content: "\F15AA"; }

.mdi-message-image::before {
  content: "\F0364"; }

.mdi-message-image-outline::before {
  content: "\F116C"; }

.mdi-message-lock::before {
  content: "\F0FCC"; }

.mdi-message-lock-outline::before {
  content: "\F116D"; }

.mdi-message-minus::before {
  content: "\F116E"; }

.mdi-message-minus-outline::before {
  content: "\F116F"; }

.mdi-message-off::before {
  content: "\F164D"; }

.mdi-message-off-outline::before {
  content: "\F164E"; }

.mdi-message-outline::before {
  content: "\F0365"; }

.mdi-message-plus::before {
  content: "\F0653"; }

.mdi-message-plus-outline::before {
  content: "\F10BB"; }

.mdi-message-processing::before {
  content: "\F0366"; }

.mdi-message-processing-outline::before {
  content: "\F1170"; }

.mdi-message-reply::before {
  content: "\F0367"; }

.mdi-message-reply-text::before {
  content: "\F0368"; }

.mdi-message-settings::before {
  content: "\F06F0"; }

.mdi-message-settings-outline::before {
  content: "\F1171"; }

.mdi-message-text::before {
  content: "\F0369"; }

.mdi-message-text-clock::before {
  content: "\F1173"; }

.mdi-message-text-clock-outline::before {
  content: "\F1174"; }

.mdi-message-text-lock::before {
  content: "\F0FCD"; }

.mdi-message-text-lock-outline::before {
  content: "\F1175"; }

.mdi-message-text-outline::before {
  content: "\F036A"; }

.mdi-message-video::before {
  content: "\F036B"; }

.mdi-meteor::before {
  content: "\F0629"; }

.mdi-metronome::before {
  content: "\F07DA"; }

.mdi-metronome-tick::before {
  content: "\F07DB"; }

.mdi-micro-sd::before {
  content: "\F07DC"; }

.mdi-microphone::before {
  content: "\F036C"; }

.mdi-microphone-minus::before {
  content: "\F08B3"; }

.mdi-microphone-off::before {
  content: "\F036D"; }

.mdi-microphone-outline::before {
  content: "\F036E"; }

.mdi-microphone-plus::before {
  content: "\F08B4"; }

.mdi-microphone-settings::before {
  content: "\F036F"; }

.mdi-microphone-variant::before {
  content: "\F0370"; }

.mdi-microphone-variant-off::before {
  content: "\F0371"; }

.mdi-microscope::before {
  content: "\F0654"; }

.mdi-microsoft::before {
  content: "\F0372"; }

.mdi-microsoft-access::before {
  content: "\F138E"; }

.mdi-microsoft-azure::before {
  content: "\F0805"; }

.mdi-microsoft-azure-devops::before {
  content: "\F0FD5"; }

.mdi-microsoft-bing::before {
  content: "\F00A4"; }

.mdi-microsoft-dynamics-365::before {
  content: "\F0988"; }

.mdi-microsoft-edge::before {
  content: "\F01E9"; }

.mdi-microsoft-edge-legacy::before {
  content: "\F1250"; }

.mdi-microsoft-excel::before {
  content: "\F138F"; }

.mdi-microsoft-internet-explorer::before {
  content: "\F0300"; }

.mdi-microsoft-office::before {
  content: "\F03C6"; }

.mdi-microsoft-onedrive::before {
  content: "\F03CA"; }

.mdi-microsoft-onenote::before {
  content: "\F0747"; }

.mdi-microsoft-outlook::before {
  content: "\F0D22"; }

.mdi-microsoft-powerpoint::before {
  content: "\F1390"; }

.mdi-microsoft-sharepoint::before {
  content: "\F1391"; }

.mdi-microsoft-teams::before {
  content: "\F02BB"; }

.mdi-microsoft-visual-studio::before {
  content: "\F0610"; }

.mdi-microsoft-visual-studio-code::before {
  content: "\F0A1E"; }

.mdi-microsoft-windows::before {
  content: "\F05B3"; }

.mdi-microsoft-windows-classic::before {
  content: "\F0A21"; }

.mdi-microsoft-word::before {
  content: "\F1392"; }

.mdi-microsoft-xbox::before {
  content: "\F05B9"; }

.mdi-microsoft-xbox-controller::before {
  content: "\F05BA"; }

.mdi-microsoft-xbox-controller-battery-alert::before {
  content: "\F074B"; }

.mdi-microsoft-xbox-controller-battery-charging::before {
  content: "\F0A22"; }

.mdi-microsoft-xbox-controller-battery-empty::before {
  content: "\F074C"; }

.mdi-microsoft-xbox-controller-battery-full::before {
  content: "\F074D"; }

.mdi-microsoft-xbox-controller-battery-low::before {
  content: "\F074E"; }

.mdi-microsoft-xbox-controller-battery-medium::before {
  content: "\F074F"; }

.mdi-microsoft-xbox-controller-battery-unknown::before {
  content: "\F0750"; }

.mdi-microsoft-xbox-controller-menu::before {
  content: "\F0E6F"; }

.mdi-microsoft-xbox-controller-off::before {
  content: "\F05BB"; }

.mdi-microsoft-xbox-controller-view::before {
  content: "\F0E70"; }

.mdi-microsoft-yammer::before {
  content: "\F0789"; }

.mdi-microwave::before {
  content: "\F0C99"; }

.mdi-microwave-off::before {
  content: "\F1423"; }

.mdi-middleware::before {
  content: "\F0F5D"; }

.mdi-middleware-outline::before {
  content: "\F0F5E"; }

.mdi-midi::before {
  content: "\F08F1"; }

.mdi-midi-port::before {
  content: "\F08F2"; }

.mdi-mine::before {
  content: "\F0DDA"; }

.mdi-minecraft::before {
  content: "\F0373"; }

.mdi-mini-sd::before {
  content: "\F0A05"; }

.mdi-minidisc::before {
  content: "\F0A06"; }

.mdi-minus::before {
  content: "\F0374"; }

.mdi-minus-box::before {
  content: "\F0375"; }

.mdi-minus-box-multiple::before {
  content: "\F1141"; }

.mdi-minus-box-multiple-outline::before {
  content: "\F1142"; }

.mdi-minus-box-outline::before {
  content: "\F06F2"; }

.mdi-minus-circle::before {
  content: "\F0376"; }

.mdi-minus-circle-multiple::before {
  content: "\F035A"; }

.mdi-minus-circle-multiple-outline::before {
  content: "\F0AD3"; }

.mdi-minus-circle-off::before {
  content: "\F1459"; }

.mdi-minus-circle-off-outline::before {
  content: "\F145A"; }

.mdi-minus-circle-outline::before {
  content: "\F0377"; }

.mdi-minus-network::before {
  content: "\F0378"; }

.mdi-minus-network-outline::before {
  content: "\F0C9A"; }

.mdi-minus-thick::before {
  content: "\F1639"; }

.mdi-mirror::before {
  content: "\F11FD"; }

.mdi-mixed-martial-arts::before {
  content: "\F0D8F"; }

.mdi-mixed-reality::before {
  content: "\F087F"; }

.mdi-molecule::before {
  content: "\F0BAC"; }

.mdi-molecule-co::before {
  content: "\F12FE"; }

.mdi-molecule-co2::before {
  content: "\F07E4"; }

.mdi-monitor::before {
  content: "\F0379"; }

.mdi-monitor-cellphone::before {
  content: "\F0989"; }

.mdi-monitor-cellphone-star::before {
  content: "\F098A"; }

.mdi-monitor-clean::before {
  content: "\F1104"; }

.mdi-monitor-dashboard::before {
  content: "\F0A07"; }

.mdi-monitor-edit::before {
  content: "\F12C6"; }

.mdi-monitor-eye::before {
  content: "\F13B4"; }

.mdi-monitor-lock::before {
  content: "\F0DDB"; }

.mdi-monitor-multiple::before {
  content: "\F037A"; }

.mdi-monitor-off::before {
  content: "\F0D90"; }

.mdi-monitor-screenshot::before {
  content: "\F0E51"; }

.mdi-monitor-share::before {
  content: "\F1483"; }

.mdi-monitor-speaker::before {
  content: "\F0F5F"; }

.mdi-monitor-speaker-off::before {
  content: "\F0F60"; }

.mdi-monitor-star::before {
  content: "\F0DDC"; }

.mdi-moon-first-quarter::before {
  content: "\F0F61"; }

.mdi-moon-full::before {
  content: "\F0F62"; }

.mdi-moon-last-quarter::before {
  content: "\F0F63"; }

.mdi-moon-new::before {
  content: "\F0F64"; }

.mdi-moon-waning-crescent::before {
  content: "\F0F65"; }

.mdi-moon-waning-gibbous::before {
  content: "\F0F66"; }

.mdi-moon-waxing-crescent::before {
  content: "\F0F67"; }

.mdi-moon-waxing-gibbous::before {
  content: "\F0F68"; }

.mdi-moped::before {
  content: "\F1086"; }

.mdi-moped-electric::before {
  content: "\F15B7"; }

.mdi-moped-electric-outline::before {
  content: "\F15B8"; }

.mdi-moped-outline::before {
  content: "\F15B9"; }

.mdi-more::before {
  content: "\F037B"; }

.mdi-mother-heart::before {
  content: "\F1314"; }

.mdi-mother-nurse::before {
  content: "\F0D21"; }

.mdi-motion::before {
  content: "\F15B2"; }

.mdi-motion-outline::before {
  content: "\F15B3"; }

.mdi-motion-pause::before {
  content: "\F1590"; }

.mdi-motion-pause-outline::before {
  content: "\F1592"; }

.mdi-motion-play::before {
  content: "\F158F"; }

.mdi-motion-play-outline::before {
  content: "\F1591"; }

.mdi-motion-sensor::before {
  content: "\F0D91"; }

.mdi-motion-sensor-off::before {
  content: "\F1435"; }

.mdi-motorbike::before {
  content: "\F037C"; }

.mdi-motorbike-electric::before {
  content: "\F15BA"; }

.mdi-mouse::before {
  content: "\F037D"; }

.mdi-mouse-bluetooth::before {
  content: "\F098B"; }

.mdi-mouse-move-down::before {
  content: "\F1550"; }

.mdi-mouse-move-up::before {
  content: "\F1551"; }

.mdi-mouse-move-vertical::before {
  content: "\F1552"; }

.mdi-mouse-off::before {
  content: "\F037E"; }

.mdi-mouse-variant::before {
  content: "\F037F"; }

.mdi-mouse-variant-off::before {
  content: "\F0380"; }

.mdi-move-resize::before {
  content: "\F0655"; }

.mdi-move-resize-variant::before {
  content: "\F0656"; }

.mdi-movie::before {
  content: "\F0381"; }

.mdi-movie-edit::before {
  content: "\F1122"; }

.mdi-movie-edit-outline::before {
  content: "\F1123"; }

.mdi-movie-filter::before {
  content: "\F1124"; }

.mdi-movie-filter-outline::before {
  content: "\F1125"; }

.mdi-movie-open::before {
  content: "\F0FCE"; }

.mdi-movie-open-outline::before {
  content: "\F0FCF"; }

.mdi-movie-outline::before {
  content: "\F0DDD"; }

.mdi-movie-roll::before {
  content: "\F07DE"; }

.mdi-movie-search::before {
  content: "\F11D2"; }

.mdi-movie-search-outline::before {
  content: "\F11D3"; }

.mdi-mower::before {
  content: "\F166F"; }

.mdi-mower-bag::before {
  content: "\F1670"; }

.mdi-muffin::before {
  content: "\F098C"; }

.mdi-multiplication::before {
  content: "\F0382"; }

.mdi-multiplication-box::before {
  content: "\F0383"; }

.mdi-mushroom::before {
  content: "\F07DF"; }

.mdi-mushroom-off::before {
  content: "\F13FA"; }

.mdi-mushroom-off-outline::before {
  content: "\F13FB"; }

.mdi-mushroom-outline::before {
  content: "\F07E0"; }

.mdi-music::before {
  content: "\F075A"; }

.mdi-music-accidental-double-flat::before {
  content: "\F0F69"; }

.mdi-music-accidental-double-sharp::before {
  content: "\F0F6A"; }

.mdi-music-accidental-flat::before {
  content: "\F0F6B"; }

.mdi-music-accidental-natural::before {
  content: "\F0F6C"; }

.mdi-music-accidental-sharp::before {
  content: "\F0F6D"; }

.mdi-music-box::before {
  content: "\F0384"; }

.mdi-music-box-multiple::before {
  content: "\F0333"; }

.mdi-music-box-multiple-outline::before {
  content: "\F0F04"; }

.mdi-music-box-outline::before {
  content: "\F0385"; }

.mdi-music-circle::before {
  content: "\F0386"; }

.mdi-music-circle-outline::before {
  content: "\F0AD4"; }

.mdi-music-clef-alto::before {
  content: "\F0F6E"; }

.mdi-music-clef-bass::before {
  content: "\F0F6F"; }

.mdi-music-clef-treble::before {
  content: "\F0F70"; }

.mdi-music-note::before {
  content: "\F0387"; }

.mdi-music-note-bluetooth::before {
  content: "\F05FE"; }

.mdi-music-note-bluetooth-off::before {
  content: "\F05FF"; }

.mdi-music-note-eighth::before {
  content: "\F0388"; }

.mdi-music-note-eighth-dotted::before {
  content: "\F0F71"; }

.mdi-music-note-half::before {
  content: "\F0389"; }

.mdi-music-note-half-dotted::before {
  content: "\F0F72"; }

.mdi-music-note-off::before {
  content: "\F038A"; }

.mdi-music-note-off-outline::before {
  content: "\F0F73"; }

.mdi-music-note-outline::before {
  content: "\F0F74"; }

.mdi-music-note-plus::before {
  content: "\F0DDE"; }

.mdi-music-note-quarter::before {
  content: "\F038B"; }

.mdi-music-note-quarter-dotted::before {
  content: "\F0F75"; }

.mdi-music-note-sixteenth::before {
  content: "\F038C"; }

.mdi-music-note-sixteenth-dotted::before {
  content: "\F0F76"; }

.mdi-music-note-whole::before {
  content: "\F038D"; }

.mdi-music-note-whole-dotted::before {
  content: "\F0F77"; }

.mdi-music-off::before {
  content: "\F075B"; }

.mdi-music-rest-eighth::before {
  content: "\F0F78"; }

.mdi-music-rest-half::before {
  content: "\F0F79"; }

.mdi-music-rest-quarter::before {
  content: "\F0F7A"; }

.mdi-music-rest-sixteenth::before {
  content: "\F0F7B"; }

.mdi-music-rest-whole::before {
  content: "\F0F7C"; }

.mdi-mustache::before {
  content: "\F15DE"; }

.mdi-nail::before {
  content: "\F0DDF"; }

.mdi-nas::before {
  content: "\F08F3"; }

.mdi-nativescript::before {
  content: "\F0880"; }

.mdi-nature::before {
  content: "\F038E"; }

.mdi-nature-people::before {
  content: "\F038F"; }

.mdi-navigation::before {
  content: "\F0390"; }

.mdi-navigation-outline::before {
  content: "\F1607"; }

.mdi-near-me::before {
  content: "\F05CD"; }

.mdi-necklace::before {
  content: "\F0F0B"; }

.mdi-needle::before {
  content: "\F0391"; }

.mdi-netflix::before {
  content: "\F0746"; }

.mdi-network::before {
  content: "\F06F3"; }

.mdi-network-off::before {
  content: "\F0C9B"; }

.mdi-network-off-outline::before {
  content: "\F0C9C"; }

.mdi-network-outline::before {
  content: "\F0C9D"; }

.mdi-network-strength-1::before {
  content: "\F08F4"; }

.mdi-network-strength-1-alert::before {
  content: "\F08F5"; }

.mdi-network-strength-2::before {
  content: "\F08F6"; }

.mdi-network-strength-2-alert::before {
  content: "\F08F7"; }

.mdi-network-strength-3::before {
  content: "\F08F8"; }

.mdi-network-strength-3-alert::before {
  content: "\F08F9"; }

.mdi-network-strength-4::before {
  content: "\F08FA"; }

.mdi-network-strength-4-alert::before {
  content: "\F08FB"; }

.mdi-network-strength-off::before {
  content: "\F08FC"; }

.mdi-network-strength-off-outline::before {
  content: "\F08FD"; }

.mdi-network-strength-outline::before {
  content: "\F08FE"; }

.mdi-new-box::before {
  content: "\F0394"; }

.mdi-newspaper::before {
  content: "\F0395"; }

.mdi-newspaper-minus::before {
  content: "\F0F0C"; }

.mdi-newspaper-plus::before {
  content: "\F0F0D"; }

.mdi-newspaper-variant::before {
  content: "\F1001"; }

.mdi-newspaper-variant-multiple::before {
  content: "\F1002"; }

.mdi-newspaper-variant-multiple-outline::before {
  content: "\F1003"; }

.mdi-newspaper-variant-outline::before {
  content: "\F1004"; }

.mdi-nfc::before {
  content: "\F0396"; }

.mdi-nfc-search-variant::before {
  content: "\F0E53"; }

.mdi-nfc-tap::before {
  content: "\F0397"; }

.mdi-nfc-variant::before {
  content: "\F0398"; }

.mdi-nfc-variant-off::before {
  content: "\F0E54"; }

.mdi-ninja::before {
  content: "\F0774"; }

.mdi-nintendo-game-boy::before {
  content: "\F1393"; }

.mdi-nintendo-switch::before {
  content: "\F07E1"; }

.mdi-nintendo-wii::before {
  content: "\F05AB"; }

.mdi-nintendo-wiiu::before {
  content: "\F072D"; }

.mdi-nix::before {
  content: "\F1105"; }

.mdi-nodejs::before {
  content: "\F0399"; }

.mdi-noodles::before {
  content: "\F117E"; }

.mdi-not-equal::before {
  content: "\F098D"; }

.mdi-not-equal-variant::before {
  content: "\F098E"; }

.mdi-note::before {
  content: "\F039A"; }

.mdi-note-minus::before {
  content: "\F164F"; }

.mdi-note-minus-outline::before {
  content: "\F1650"; }

.mdi-note-multiple::before {
  content: "\F06B8"; }

.mdi-note-multiple-outline::before {
  content: "\F06B9"; }

.mdi-note-outline::before {
  content: "\F039B"; }

.mdi-note-plus::before {
  content: "\F039C"; }

.mdi-note-plus-outline::before {
  content: "\F039D"; }

.mdi-note-remove::before {
  content: "\F1651"; }

.mdi-note-remove-outline::before {
  content: "\F1652"; }

.mdi-note-search::before {
  content: "\F1653"; }

.mdi-note-search-outline::before {
  content: "\F1654"; }

.mdi-note-text::before {
  content: "\F039E"; }

.mdi-note-text-outline::before {
  content: "\F11D7"; }

.mdi-notebook::before {
  content: "\F082E"; }

.mdi-notebook-check::before {
  content: "\F14F5"; }

.mdi-notebook-check-outline::before {
  content: "\F14F6"; }

.mdi-notebook-edit::before {
  content: "\F14E7"; }

.mdi-notebook-edit-outline::before {
  content: "\F14E9"; }

.mdi-notebook-minus::before {
  content: "\F1610"; }

.mdi-notebook-minus-outline::before {
  content: "\F1611"; }

.mdi-notebook-multiple::before {
  content: "\F0E55"; }

.mdi-notebook-outline::before {
  content: "\F0EBF"; }

.mdi-notebook-plus::before {
  content: "\F1612"; }

.mdi-notebook-plus-outline::before {
  content: "\F1613"; }

.mdi-notebook-remove::before {
  content: "\F1614"; }

.mdi-notebook-remove-outline::before {
  content: "\F1615"; }

.mdi-notification-clear-all::before {
  content: "\F039F"; }

.mdi-npm::before {
  content: "\F06F7"; }

.mdi-nuke::before {
  content: "\F06A4"; }

.mdi-null::before {
  content: "\F07E2"; }

.mdi-numeric::before {
  content: "\F03A0"; }

.mdi-numeric-0::before {
  content: "\F0B39"; }

.mdi-numeric-0-box::before {
  content: "\F03A1"; }

.mdi-numeric-0-box-multiple::before {
  content: "\F0F0E"; }

.mdi-numeric-0-box-multiple-outline::before {
  content: "\F03A2"; }

.mdi-numeric-0-box-outline::before {
  content: "\F03A3"; }

.mdi-numeric-0-circle::before {
  content: "\F0C9E"; }

.mdi-numeric-0-circle-outline::before {
  content: "\F0C9F"; }

.mdi-numeric-1::before {
  content: "\F0B3A"; }

.mdi-numeric-1-box::before {
  content: "\F03A4"; }

.mdi-numeric-1-box-multiple::before {
  content: "\F0F0F"; }

.mdi-numeric-1-box-multiple-outline::before {
  content: "\F03A5"; }

.mdi-numeric-1-box-outline::before {
  content: "\F03A6"; }

.mdi-numeric-1-circle::before {
  content: "\F0CA0"; }

.mdi-numeric-1-circle-outline::before {
  content: "\F0CA1"; }

.mdi-numeric-10::before {
  content: "\F0FE9"; }

.mdi-numeric-10-box::before {
  content: "\F0F7D"; }

.mdi-numeric-10-box-multiple::before {
  content: "\F0FEA"; }

.mdi-numeric-10-box-multiple-outline::before {
  content: "\F0FEB"; }

.mdi-numeric-10-box-outline::before {
  content: "\F0F7E"; }

.mdi-numeric-10-circle::before {
  content: "\F0FEC"; }

.mdi-numeric-10-circle-outline::before {
  content: "\F0FED"; }

.mdi-numeric-2::before {
  content: "\F0B3B"; }

.mdi-numeric-2-box::before {
  content: "\F03A7"; }

.mdi-numeric-2-box-multiple::before {
  content: "\F0F10"; }

.mdi-numeric-2-box-multiple-outline::before {
  content: "\F03A8"; }

.mdi-numeric-2-box-outline::before {
  content: "\F03A9"; }

.mdi-numeric-2-circle::before {
  content: "\F0CA2"; }

.mdi-numeric-2-circle-outline::before {
  content: "\F0CA3"; }

.mdi-numeric-3::before {
  content: "\F0B3C"; }

.mdi-numeric-3-box::before {
  content: "\F03AA"; }

.mdi-numeric-3-box-multiple::before {
  content: "\F0F11"; }

.mdi-numeric-3-box-multiple-outline::before {
  content: "\F03AB"; }

.mdi-numeric-3-box-outline::before {
  content: "\F03AC"; }

.mdi-numeric-3-circle::before {
  content: "\F0CA4"; }

.mdi-numeric-3-circle-outline::before {
  content: "\F0CA5"; }

.mdi-numeric-4::before {
  content: "\F0B3D"; }

.mdi-numeric-4-box::before {
  content: "\F03AD"; }

.mdi-numeric-4-box-multiple::before {
  content: "\F0F12"; }

.mdi-numeric-4-box-multiple-outline::before {
  content: "\F03B2"; }

.mdi-numeric-4-box-outline::before {
  content: "\F03AE"; }

.mdi-numeric-4-circle::before {
  content: "\F0CA6"; }

.mdi-numeric-4-circle-outline::before {
  content: "\F0CA7"; }

.mdi-numeric-5::before {
  content: "\F0B3E"; }

.mdi-numeric-5-box::before {
  content: "\F03B1"; }

.mdi-numeric-5-box-multiple::before {
  content: "\F0F13"; }

.mdi-numeric-5-box-multiple-outline::before {
  content: "\F03AF"; }

.mdi-numeric-5-box-outline::before {
  content: "\F03B0"; }

.mdi-numeric-5-circle::before {
  content: "\F0CA8"; }

.mdi-numeric-5-circle-outline::before {
  content: "\F0CA9"; }

.mdi-numeric-6::before {
  content: "\F0B3F"; }

.mdi-numeric-6-box::before {
  content: "\F03B3"; }

.mdi-numeric-6-box-multiple::before {
  content: "\F0F14"; }

.mdi-numeric-6-box-multiple-outline::before {
  content: "\F03B4"; }

.mdi-numeric-6-box-outline::before {
  content: "\F03B5"; }

.mdi-numeric-6-circle::before {
  content: "\F0CAA"; }

.mdi-numeric-6-circle-outline::before {
  content: "\F0CAB"; }

.mdi-numeric-7::before {
  content: "\F0B40"; }

.mdi-numeric-7-box::before {
  content: "\F03B6"; }

.mdi-numeric-7-box-multiple::before {
  content: "\F0F15"; }

.mdi-numeric-7-box-multiple-outline::before {
  content: "\F03B7"; }

.mdi-numeric-7-box-outline::before {
  content: "\F03B8"; }

.mdi-numeric-7-circle::before {
  content: "\F0CAC"; }

.mdi-numeric-7-circle-outline::before {
  content: "\F0CAD"; }

.mdi-numeric-8::before {
  content: "\F0B41"; }

.mdi-numeric-8-box::before {
  content: "\F03B9"; }

.mdi-numeric-8-box-multiple::before {
  content: "\F0F16"; }

.mdi-numeric-8-box-multiple-outline::before {
  content: "\F03BA"; }

.mdi-numeric-8-box-outline::before {
  content: "\F03BB"; }

.mdi-numeric-8-circle::before {
  content: "\F0CAE"; }

.mdi-numeric-8-circle-outline::before {
  content: "\F0CAF"; }

.mdi-numeric-9::before {
  content: "\F0B42"; }

.mdi-numeric-9-box::before {
  content: "\F03BC"; }

.mdi-numeric-9-box-multiple::before {
  content: "\F0F17"; }

.mdi-numeric-9-box-multiple-outline::before {
  content: "\F03BD"; }

.mdi-numeric-9-box-outline::before {
  content: "\F03BE"; }

.mdi-numeric-9-circle::before {
  content: "\F0CB0"; }

.mdi-numeric-9-circle-outline::before {
  content: "\F0CB1"; }

.mdi-numeric-9-plus::before {
  content: "\F0FEE"; }

.mdi-numeric-9-plus-box::before {
  content: "\F03BF"; }

.mdi-numeric-9-plus-box-multiple::before {
  content: "\F0F18"; }

.mdi-numeric-9-plus-box-multiple-outline::before {
  content: "\F03C0"; }

.mdi-numeric-9-plus-box-outline::before {
  content: "\F03C1"; }

.mdi-numeric-9-plus-circle::before {
  content: "\F0CB2"; }

.mdi-numeric-9-plus-circle-outline::before {
  content: "\F0CB3"; }

.mdi-numeric-negative-1::before {
  content: "\F1052"; }

.mdi-numeric-positive-1::before {
  content: "\F15CB"; }

.mdi-nut::before {
  content: "\F06F8"; }

.mdi-nutrition::before {
  content: "\F03C2"; }

.mdi-nuxt::before {
  content: "\F1106"; }

.mdi-oar::before {
  content: "\F067C"; }

.mdi-ocarina::before {
  content: "\F0DE0"; }

.mdi-oci::before {
  content: "\F12E9"; }

.mdi-ocr::before {
  content: "\F113A"; }

.mdi-octagon::before {
  content: "\F03C3"; }

.mdi-octagon-outline::before {
  content: "\F03C4"; }

.mdi-octagram::before {
  content: "\F06F9"; }

.mdi-octagram-outline::before {
  content: "\F0775"; }

.mdi-odnoklassniki::before {
  content: "\F03C5"; }

.mdi-offer::before {
  content: "\F121B"; }

.mdi-office-building::before {
  content: "\F0991"; }

.mdi-office-building-marker::before {
  content: "\F1520"; }

.mdi-office-building-marker-outline::before {
  content: "\F1521"; }

.mdi-office-building-outline::before {
  content: "\F151F"; }

.mdi-oil::before {
  content: "\F03C7"; }

.mdi-oil-lamp::before {
  content: "\F0F19"; }

.mdi-oil-level::before {
  content: "\F1053"; }

.mdi-oil-temperature::before {
  content: "\F0FF8"; }

.mdi-omega::before {
  content: "\F03C9"; }

.mdi-one-up::before {
  content: "\F0BAD"; }

.mdi-onepassword::before {
  content: "\F0881"; }

.mdi-opacity::before {
  content: "\F05CC"; }

.mdi-open-in-app::before {
  content: "\F03CB"; }

.mdi-open-in-new::before {
  content: "\F03CC"; }

.mdi-open-source-initiative::before {
  content: "\F0BAE"; }

.mdi-openid::before {
  content: "\F03CD"; }

.mdi-opera::before {
  content: "\F03CE"; }

.mdi-orbit::before {
  content: "\F0018"; }

.mdi-orbit-variant::before {
  content: "\F15DB"; }

.mdi-order-alphabetical-ascending::before {
  content: "\F020D"; }

.mdi-order-alphabetical-descending::before {
  content: "\F0D07"; }

.mdi-order-bool-ascending::before {
  content: "\F02BE"; }

.mdi-order-bool-ascending-variant::before {
  content: "\F098F"; }

.mdi-order-bool-descending::before {
  content: "\F1384"; }

.mdi-order-bool-descending-variant::before {
  content: "\F0990"; }

.mdi-order-numeric-ascending::before {
  content: "\F0545"; }

.mdi-order-numeric-descending::before {
  content: "\F0546"; }

.mdi-origin::before {
  content: "\F0B43"; }

.mdi-ornament::before {
  content: "\F03CF"; }

.mdi-ornament-variant::before {
  content: "\F03D0"; }

.mdi-outdoor-lamp::before {
  content: "\F1054"; }

.mdi-overscan::before {
  content: "\F1005"; }

.mdi-owl::before {
  content: "\F03D2"; }

.mdi-pac-man::before {
  content: "\F0BAF"; }

.mdi-package::before {
  content: "\F03D3"; }

.mdi-package-down::before {
  content: "\F03D4"; }

.mdi-package-up::before {
  content: "\F03D5"; }

.mdi-package-variant::before {
  content: "\F03D6"; }

.mdi-package-variant-closed::before {
  content: "\F03D7"; }

.mdi-page-first::before {
  content: "\F0600"; }

.mdi-page-last::before {
  content: "\F0601"; }

.mdi-page-layout-body::before {
  content: "\F06FA"; }

.mdi-page-layout-footer::before {
  content: "\F06FB"; }

.mdi-page-layout-header::before {
  content: "\F06FC"; }

.mdi-page-layout-header-footer::before {
  content: "\F0F7F"; }

.mdi-page-layout-sidebar-left::before {
  content: "\F06FD"; }

.mdi-page-layout-sidebar-right::before {
  content: "\F06FE"; }

.mdi-page-next::before {
  content: "\F0BB0"; }

.mdi-page-next-outline::before {
  content: "\F0BB1"; }

.mdi-page-previous::before {
  content: "\F0BB2"; }

.mdi-page-previous-outline::before {
  content: "\F0BB3"; }

.mdi-pail::before {
  content: "\F1417"; }

.mdi-pail-minus::before {
  content: "\F1437"; }

.mdi-pail-minus-outline::before {
  content: "\F143C"; }

.mdi-pail-off::before {
  content: "\F1439"; }

.mdi-pail-off-outline::before {
  content: "\F143E"; }

.mdi-pail-outline::before {
  content: "\F143A"; }

.mdi-pail-plus::before {
  content: "\F1436"; }

.mdi-pail-plus-outline::before {
  content: "\F143B"; }

.mdi-pail-remove::before {
  content: "\F1438"; }

.mdi-pail-remove-outline::before {
  content: "\F143D"; }

.mdi-palette::before {
  content: "\F03D8"; }

.mdi-palette-advanced::before {
  content: "\F03D9"; }

.mdi-palette-outline::before {
  content: "\F0E0C"; }

.mdi-palette-swatch::before {
  content: "\F08B5"; }

.mdi-palette-swatch-outline::before {
  content: "\F135C"; }

.mdi-palm-tree::before {
  content: "\F1055"; }

.mdi-pan::before {
  content: "\F0BB4"; }

.mdi-pan-bottom-left::before {
  content: "\F0BB5"; }

.mdi-pan-bottom-right::before {
  content: "\F0BB6"; }

.mdi-pan-down::before {
  content: "\F0BB7"; }

.mdi-pan-horizontal::before {
  content: "\F0BB8"; }

.mdi-pan-left::before {
  content: "\F0BB9"; }

.mdi-pan-right::before {
  content: "\F0BBA"; }

.mdi-pan-top-left::before {
  content: "\F0BBB"; }

.mdi-pan-top-right::before {
  content: "\F0BBC"; }

.mdi-pan-up::before {
  content: "\F0BBD"; }

.mdi-pan-vertical::before {
  content: "\F0BBE"; }

.mdi-panda::before {
  content: "\F03DA"; }

.mdi-pandora::before {
  content: "\F03DB"; }

.mdi-panorama::before {
  content: "\F03DC"; }

.mdi-panorama-fisheye::before {
  content: "\F03DD"; }

.mdi-panorama-horizontal::before {
  content: "\F03DE"; }

.mdi-panorama-vertical::before {
  content: "\F03DF"; }

.mdi-panorama-wide-angle::before {
  content: "\F03E0"; }

.mdi-paper-cut-vertical::before {
  content: "\F03E1"; }

.mdi-paper-roll::before {
  content: "\F1157"; }

.mdi-paper-roll-outline::before {
  content: "\F1158"; }

.mdi-paperclip::before {
  content: "\F03E2"; }

.mdi-parachute::before {
  content: "\F0CB4"; }

.mdi-parachute-outline::before {
  content: "\F0CB5"; }

.mdi-parking::before {
  content: "\F03E3"; }

.mdi-party-popper::before {
  content: "\F1056"; }

.mdi-passport::before {
  content: "\F07E3"; }

.mdi-passport-biometric::before {
  content: "\F0DE1"; }

.mdi-pasta::before {
  content: "\F1160"; }

.mdi-patio-heater::before {
  content: "\F0F80"; }

.mdi-patreon::before {
  content: "\F0882"; }

.mdi-pause::before {
  content: "\F03E4"; }

.mdi-pause-circle::before {
  content: "\F03E5"; }

.mdi-pause-circle-outline::before {
  content: "\F03E6"; }

.mdi-pause-octagon::before {
  content: "\F03E7"; }

.mdi-pause-octagon-outline::before {
  content: "\F03E8"; }

.mdi-paw::before {
  content: "\F03E9"; }

.mdi-paw-off::before {
  content: "\F0657"; }

.mdi-paw-off-outline::before {
  content: "\F1676"; }

.mdi-paw-outline::before {
  content: "\F1675"; }

.mdi-pdf-box::before {
  content: "\F0E56"; }

.mdi-peace::before {
  content: "\F0884"; }

.mdi-peanut::before {
  content: "\F0FFC"; }

.mdi-peanut-off::before {
  content: "\F0FFD"; }

.mdi-peanut-off-outline::before {
  content: "\F0FFF"; }

.mdi-peanut-outline::before {
  content: "\F0FFE"; }

.mdi-pen::before {
  content: "\F03EA"; }

.mdi-pen-lock::before {
  content: "\F0DE2"; }

.mdi-pen-minus::before {
  content: "\F0DE3"; }

.mdi-pen-off::before {
  content: "\F0DE4"; }

.mdi-pen-plus::before {
  content: "\F0DE5"; }

.mdi-pen-remove::before {
  content: "\F0DE6"; }

.mdi-pencil::before {
  content: "\F03EB"; }

.mdi-pencil-box::before {
  content: "\F03EC"; }

.mdi-pencil-box-multiple::before {
  content: "\F1144"; }

.mdi-pencil-box-multiple-outline::before {
  content: "\F1145"; }

.mdi-pencil-box-outline::before {
  content: "\F03ED"; }

.mdi-pencil-circle::before {
  content: "\F06FF"; }

.mdi-pencil-circle-outline::before {
  content: "\F0776"; }

.mdi-pencil-lock::before {
  content: "\F03EE"; }

.mdi-pencil-lock-outline::before {
  content: "\F0DE7"; }

.mdi-pencil-minus::before {
  content: "\F0DE8"; }

.mdi-pencil-minus-outline::before {
  content: "\F0DE9"; }

.mdi-pencil-off::before {
  content: "\F03EF"; }

.mdi-pencil-off-outline::before {
  content: "\F0DEA"; }

.mdi-pencil-outline::before {
  content: "\F0CB6"; }

.mdi-pencil-plus::before {
  content: "\F0DEB"; }

.mdi-pencil-plus-outline::before {
  content: "\F0DEC"; }

.mdi-pencil-remove::before {
  content: "\F0DED"; }

.mdi-pencil-remove-outline::before {
  content: "\F0DEE"; }

.mdi-pencil-ruler::before {
  content: "\F1353"; }

.mdi-penguin::before {
  content: "\F0EC0"; }

.mdi-pentagon::before {
  content: "\F0701"; }

.mdi-pentagon-outline::before {
  content: "\F0700"; }

.mdi-pentagram::before {
  content: "\F1667"; }

.mdi-percent::before {
  content: "\F03F0"; }

.mdi-percent-outline::before {
  content: "\F1278"; }

.mdi-periodic-table::before {
  content: "\F08B6"; }

.mdi-perspective-less::before {
  content: "\F0D23"; }

.mdi-perspective-more::before {
  content: "\F0D24"; }

.mdi-pharmacy::before {
  content: "\F03F1"; }

.mdi-phone::before {
  content: "\F03F2"; }

.mdi-phone-alert::before {
  content: "\F0F1A"; }

.mdi-phone-alert-outline::before {
  content: "\F118E"; }

.mdi-phone-bluetooth::before {
  content: "\F03F3"; }

.mdi-phone-bluetooth-outline::before {
  content: "\F118F"; }

.mdi-phone-cancel::before {
  content: "\F10BC"; }

.mdi-phone-cancel-outline::before {
  content: "\F1190"; }

.mdi-phone-check::before {
  content: "\F11A9"; }

.mdi-phone-check-outline::before {
  content: "\F11AA"; }

.mdi-phone-classic::before {
  content: "\F0602"; }

.mdi-phone-classic-off::before {
  content: "\F1279"; }

.mdi-phone-dial::before {
  content: "\F1559"; }

.mdi-phone-dial-outline::before {
  content: "\F155A"; }

.mdi-phone-forward::before {
  content: "\F03F4"; }

.mdi-phone-forward-outline::before {
  content: "\F1191"; }

.mdi-phone-hangup::before {
  content: "\F03F5"; }

.mdi-phone-hangup-outline::before {
  content: "\F1192"; }

.mdi-phone-in-talk::before {
  content: "\F03F6"; }

.mdi-phone-in-talk-outline::before {
  content: "\F1182"; }

.mdi-phone-incoming::before {
  content: "\F03F7"; }

.mdi-phone-incoming-outline::before {
  content: "\F1193"; }

.mdi-phone-lock::before {
  content: "\F03F8"; }

.mdi-phone-lock-outline::before {
  content: "\F1194"; }

.mdi-phone-log::before {
  content: "\F03F9"; }

.mdi-phone-log-outline::before {
  content: "\F1195"; }

.mdi-phone-message::before {
  content: "\F1196"; }

.mdi-phone-message-outline::before {
  content: "\F1197"; }

.mdi-phone-minus::before {
  content: "\F0658"; }

.mdi-phone-minus-outline::before {
  content: "\F1198"; }

.mdi-phone-missed::before {
  content: "\F03FA"; }

.mdi-phone-missed-outline::before {
  content: "\F11A5"; }

.mdi-phone-off::before {
  content: "\F0DEF"; }

.mdi-phone-off-outline::before {
  content: "\F11A6"; }

.mdi-phone-outgoing::before {
  content: "\F03FB"; }

.mdi-phone-outgoing-outline::before {
  content: "\F1199"; }

.mdi-phone-outline::before {
  content: "\F0DF0"; }

.mdi-phone-paused::before {
  content: "\F03FC"; }

.mdi-phone-paused-outline::before {
  content: "\F119A"; }

.mdi-phone-plus::before {
  content: "\F0659"; }

.mdi-phone-plus-outline::before {
  content: "\F119B"; }

.mdi-phone-remove::before {
  content: "\F152F"; }

.mdi-phone-remove-outline::before {
  content: "\F1530"; }

.mdi-phone-return::before {
  content: "\F082F"; }

.mdi-phone-return-outline::before {
  content: "\F119C"; }

.mdi-phone-ring::before {
  content: "\F11AB"; }

.mdi-phone-ring-outline::before {
  content: "\F11AC"; }

.mdi-phone-rotate-landscape::before {
  content: "\F0885"; }

.mdi-phone-rotate-portrait::before {
  content: "\F0886"; }

.mdi-phone-settings::before {
  content: "\F03FD"; }

.mdi-phone-settings-outline::before {
  content: "\F119D"; }

.mdi-phone-voip::before {
  content: "\F03FE"; }

.mdi-pi::before {
  content: "\F03FF"; }

.mdi-pi-box::before {
  content: "\F0400"; }

.mdi-pi-hole::before {
  content: "\F0DF1"; }

.mdi-piano::before {
  content: "\F067D"; }

.mdi-pickaxe::before {
  content: "\F08B7"; }

.mdi-picture-in-picture-bottom-right::before {
  content: "\F0E57"; }

.mdi-picture-in-picture-bottom-right-outline::before {
  content: "\F0E58"; }

.mdi-picture-in-picture-top-right::before {
  content: "\F0E59"; }

.mdi-picture-in-picture-top-right-outline::before {
  content: "\F0E5A"; }

.mdi-pier::before {
  content: "\F0887"; }

.mdi-pier-crane::before {
  content: "\F0888"; }

.mdi-pig::before {
  content: "\F0401"; }

.mdi-pig-variant::before {
  content: "\F1006"; }

.mdi-pig-variant-outline::before {
  content: "\F1678"; }

.mdi-piggy-bank::before {
  content: "\F1007"; }

.mdi-piggy-bank-outline::before {
  content: "\F1679"; }

.mdi-pill::before {
  content: "\F0402"; }

.mdi-pillar::before {
  content: "\F0702"; }

.mdi-pin::before {
  content: "\F0403"; }

.mdi-pin-off::before {
  content: "\F0404"; }

.mdi-pin-off-outline::before {
  content: "\F0930"; }

.mdi-pin-outline::before {
  content: "\F0931"; }

.mdi-pine-tree::before {
  content: "\F0405"; }

.mdi-pine-tree-box::before {
  content: "\F0406"; }

.mdi-pine-tree-fire::before {
  content: "\F141A"; }

.mdi-pinterest::before {
  content: "\F0407"; }

.mdi-pinwheel::before {
  content: "\F0AD5"; }

.mdi-pinwheel-outline::before {
  content: "\F0AD6"; }

.mdi-pipe::before {
  content: "\F07E5"; }

.mdi-pipe-disconnected::before {
  content: "\F07E6"; }

.mdi-pipe-leak::before {
  content: "\F0889"; }

.mdi-pipe-wrench::before {
  content: "\F1354"; }

.mdi-pirate::before {
  content: "\F0A08"; }

.mdi-pistol::before {
  content: "\F0703"; }

.mdi-piston::before {
  content: "\F088A"; }

.mdi-pitchfork::before {
  content: "\F1553"; }

.mdi-pizza::before {
  content: "\F0409"; }

.mdi-play::before {
  content: "\F040A"; }

.mdi-play-box::before {
  content: "\F127A"; }

.mdi-play-box-multiple::before {
  content: "\F0D19"; }

.mdi-play-box-multiple-outline::before {
  content: "\F13E6"; }

.mdi-play-box-outline::before {
  content: "\F040B"; }

.mdi-play-circle::before {
  content: "\F040C"; }

.mdi-play-circle-outline::before {
  content: "\F040D"; }

.mdi-play-network::before {
  content: "\F088B"; }

.mdi-play-network-outline::before {
  content: "\F0CB7"; }

.mdi-play-outline::before {
  content: "\F0F1B"; }

.mdi-play-pause::before {
  content: "\F040E"; }

.mdi-play-protected-content::before {
  content: "\F040F"; }

.mdi-play-speed::before {
  content: "\F08FF"; }

.mdi-playlist-check::before {
  content: "\F05C7"; }

.mdi-playlist-edit::before {
  content: "\F0900"; }

.mdi-playlist-minus::before {
  content: "\F0410"; }

.mdi-playlist-music::before {
  content: "\F0CB8"; }

.mdi-playlist-music-outline::before {
  content: "\F0CB9"; }

.mdi-playlist-play::before {
  content: "\F0411"; }

.mdi-playlist-plus::before {
  content: "\F0412"; }

.mdi-playlist-remove::before {
  content: "\F0413"; }

.mdi-playlist-star::before {
  content: "\F0DF2"; }

.mdi-plex::before {
  content: "\F06BA"; }

.mdi-plus::before {
  content: "\F0415"; }

.mdi-plus-box::before {
  content: "\F0416"; }

.mdi-plus-box-multiple::before {
  content: "\F0334"; }

.mdi-plus-box-multiple-outline::before {
  content: "\F1143"; }

.mdi-plus-box-outline::before {
  content: "\F0704"; }

.mdi-plus-circle::before {
  content: "\F0417"; }

.mdi-plus-circle-multiple::before {
  content: "\F034C"; }

.mdi-plus-circle-multiple-outline::before {
  content: "\F0418"; }

.mdi-plus-circle-outline::before {
  content: "\F0419"; }

.mdi-plus-minus::before {
  content: "\F0992"; }

.mdi-plus-minus-box::before {
  content: "\F0993"; }

.mdi-plus-minus-variant::before {
  content: "\F14C9"; }

.mdi-plus-network::before {
  content: "\F041A"; }

.mdi-plus-network-outline::before {
  content: "\F0CBA"; }

.mdi-plus-one::before {
  content: "\F041B"; }

.mdi-plus-outline::before {
  content: "\F0705"; }

.mdi-plus-thick::before {
  content: "\F11EC"; }

.mdi-podcast::before {
  content: "\F0994"; }

.mdi-podium::before {
  content: "\F0D25"; }

.mdi-podium-bronze::before {
  content: "\F0D26"; }

.mdi-podium-gold::before {
  content: "\F0D27"; }

.mdi-podium-silver::before {
  content: "\F0D28"; }

.mdi-point-of-sale::before {
  content: "\F0D92"; }

.mdi-pokeball::before {
  content: "\F041D"; }

.mdi-pokemon-go::before {
  content: "\F0A09"; }

.mdi-poker-chip::before {
  content: "\F0830"; }

.mdi-polaroid::before {
  content: "\F041E"; }

.mdi-police-badge::before {
  content: "\F1167"; }

.mdi-police-badge-outline::before {
  content: "\F1168"; }

.mdi-poll::before {
  content: "\F041F"; }

.mdi-poll-box::before {
  content: "\F0420"; }

.mdi-poll-box-outline::before {
  content: "\F127B"; }

.mdi-polo::before {
  content: "\F14C3"; }

.mdi-polymer::before {
  content: "\F0421"; }

.mdi-pool::before {
  content: "\F0606"; }

.mdi-popcorn::before {
  content: "\F0422"; }

.mdi-post::before {
  content: "\F1008"; }

.mdi-post-outline::before {
  content: "\F1009"; }

.mdi-postage-stamp::before {
  content: "\F0CBB"; }

.mdi-pot::before {
  content: "\F02E5"; }

.mdi-pot-mix::before {
  content: "\F065B"; }

.mdi-pot-mix-outline::before {
  content: "\F0677"; }

.mdi-pot-outline::before {
  content: "\F02FF"; }

.mdi-pot-steam::before {
  content: "\F065A"; }

.mdi-pot-steam-outline::before {
  content: "\F0326"; }

.mdi-pound::before {
  content: "\F0423"; }

.mdi-pound-box::before {
  content: "\F0424"; }

.mdi-pound-box-outline::before {
  content: "\F117F"; }

.mdi-power::before {
  content: "\F0425"; }

.mdi-power-cycle::before {
  content: "\F0901"; }

.mdi-power-off::before {
  content: "\F0902"; }

.mdi-power-on::before {
  content: "\F0903"; }

.mdi-power-plug::before {
  content: "\F06A5"; }

.mdi-power-plug-off::before {
  content: "\F06A6"; }

.mdi-power-plug-off-outline::before {
  content: "\F1424"; }

.mdi-power-plug-outline::before {
  content: "\F1425"; }

.mdi-power-settings::before {
  content: "\F0426"; }

.mdi-power-sleep::before {
  content: "\F0904"; }

.mdi-power-socket::before {
  content: "\F0427"; }

.mdi-power-socket-au::before {
  content: "\F0905"; }

.mdi-power-socket-de::before {
  content: "\F1107"; }

.mdi-power-socket-eu::before {
  content: "\F07E7"; }

.mdi-power-socket-fr::before {
  content: "\F1108"; }

.mdi-power-socket-it::before {
  content: "\F14FF"; }

.mdi-power-socket-jp::before {
  content: "\F1109"; }

.mdi-power-socket-uk::before {
  content: "\F07E8"; }

.mdi-power-socket-us::before {
  content: "\F07E9"; }

.mdi-power-standby::before {
  content: "\F0906"; }

.mdi-powershell::before {
  content: "\F0A0A"; }

.mdi-prescription::before {
  content: "\F0706"; }

.mdi-presentation::before {
  content: "\F0428"; }

.mdi-presentation-play::before {
  content: "\F0429"; }

.mdi-pretzel::before {
  content: "\F1562"; }

.mdi-printer::before {
  content: "\F042A"; }

.mdi-printer-3d::before {
  content: "\F042B"; }

.mdi-printer-3d-nozzle::before {
  content: "\F0E5B"; }

.mdi-printer-3d-nozzle-alert::before {
  content: "\F11C0"; }

.mdi-printer-3d-nozzle-alert-outline::before {
  content: "\F11C1"; }

.mdi-printer-3d-nozzle-outline::before {
  content: "\F0E5C"; }

.mdi-printer-alert::before {
  content: "\F042C"; }

.mdi-printer-check::before {
  content: "\F1146"; }

.mdi-printer-eye::before {
  content: "\F1458"; }

.mdi-printer-off::before {
  content: "\F0E5D"; }

.mdi-printer-pos::before {
  content: "\F1057"; }

.mdi-printer-search::before {
  content: "\F1457"; }

.mdi-printer-settings::before {
  content: "\F0707"; }

.mdi-printer-wireless::before {
  content: "\F0A0B"; }

.mdi-priority-high::before {
  content: "\F0603"; }

.mdi-priority-low::before {
  content: "\F0604"; }

.mdi-professional-hexagon::before {
  content: "\F042D"; }

.mdi-progress-alert::before {
  content: "\F0CBC"; }

.mdi-progress-check::before {
  content: "\F0995"; }

.mdi-progress-clock::before {
  content: "\F0996"; }

.mdi-progress-close::before {
  content: "\F110A"; }

.mdi-progress-download::before {
  content: "\F0997"; }

.mdi-progress-question::before {
  content: "\F1522"; }

.mdi-progress-upload::before {
  content: "\F0998"; }

.mdi-progress-wrench::before {
  content: "\F0CBD"; }

.mdi-projector::before {
  content: "\F042E"; }

.mdi-projector-screen::before {
  content: "\F042F"; }

.mdi-propane-tank::before {
  content: "\F1357"; }

.mdi-propane-tank-outline::before {
  content: "\F1358"; }

.mdi-protocol::before {
  content: "\F0FD8"; }

.mdi-publish::before {
  content: "\F06A7"; }

.mdi-pulse::before {
  content: "\F0430"; }

.mdi-pump::before {
  content: "\F1402"; }

.mdi-pumpkin::before {
  content: "\F0BBF"; }

.mdi-purse::before {
  content: "\F0F1C"; }

.mdi-purse-outline::before {
  content: "\F0F1D"; }

.mdi-puzzle::before {
  content: "\F0431"; }

.mdi-puzzle-check::before {
  content: "\F1426"; }

.mdi-puzzle-check-outline::before {
  content: "\F1427"; }

.mdi-puzzle-edit::before {
  content: "\F14D3"; }

.mdi-puzzle-edit-outline::before {
  content: "\F14D9"; }

.mdi-puzzle-heart::before {
  content: "\F14D4"; }

.mdi-puzzle-heart-outline::before {
  content: "\F14DA"; }

.mdi-puzzle-minus::before {
  content: "\F14D1"; }

.mdi-puzzle-minus-outline::before {
  content: "\F14D7"; }

.mdi-puzzle-outline::before {
  content: "\F0A66"; }

.mdi-puzzle-plus::before {
  content: "\F14D0"; }

.mdi-puzzle-plus-outline::before {
  content: "\F14D6"; }

.mdi-puzzle-remove::before {
  content: "\F14D2"; }

.mdi-puzzle-remove-outline::before {
  content: "\F14D8"; }

.mdi-puzzle-star::before {
  content: "\F14D5"; }

.mdi-puzzle-star-outline::before {
  content: "\F14DB"; }

.mdi-qi::before {
  content: "\F0999"; }

.mdi-qqchat::before {
  content: "\F0605"; }

.mdi-qrcode::before {
  content: "\F0432"; }

.mdi-qrcode-edit::before {
  content: "\F08B8"; }

.mdi-qrcode-minus::before {
  content: "\F118C"; }

.mdi-qrcode-plus::before {
  content: "\F118B"; }

.mdi-qrcode-remove::before {
  content: "\F118D"; }

.mdi-qrcode-scan::before {
  content: "\F0433"; }

.mdi-quadcopter::before {
  content: "\F0434"; }

.mdi-quality-high::before {
  content: "\F0435"; }

.mdi-quality-low::before {
  content: "\F0A0C"; }

.mdi-quality-medium::before {
  content: "\F0A0D"; }

.mdi-quora::before {
  content: "\F0D29"; }

.mdi-rabbit::before {
  content: "\F0907"; }

.mdi-racing-helmet::before {
  content: "\F0D93"; }

.mdi-racquetball::before {
  content: "\F0D94"; }

.mdi-radar::before {
  content: "\F0437"; }

.mdi-radiator::before {
  content: "\F0438"; }

.mdi-radiator-disabled::before {
  content: "\F0AD7"; }

.mdi-radiator-off::before {
  content: "\F0AD8"; }

.mdi-radio::before {
  content: "\F0439"; }

.mdi-radio-am::before {
  content: "\F0CBE"; }

.mdi-radio-fm::before {
  content: "\F0CBF"; }

.mdi-radio-handheld::before {
  content: "\F043A"; }

.mdi-radio-off::before {
  content: "\F121C"; }

.mdi-radio-tower::before {
  content: "\F043B"; }

.mdi-radioactive::before {
  content: "\F043C"; }

.mdi-radioactive-off::before {
  content: "\F0EC1"; }

.mdi-radiobox-blank::before {
  content: "\F043D"; }

.mdi-radiobox-marked::before {
  content: "\F043E"; }

.mdi-radiology-box::before {
  content: "\F14C5"; }

.mdi-radiology-box-outline::before {
  content: "\F14C6"; }

.mdi-radius::before {
  content: "\F0CC0"; }

.mdi-radius-outline::before {
  content: "\F0CC1"; }

.mdi-railroad-light::before {
  content: "\F0F1E"; }

.mdi-rake::before {
  content: "\F1544"; }

.mdi-raspberry-pi::before {
  content: "\F043F"; }

.mdi-ray-end::before {
  content: "\F0440"; }

.mdi-ray-end-arrow::before {
  content: "\F0441"; }

.mdi-ray-start::before {
  content: "\F0442"; }

.mdi-ray-start-arrow::before {
  content: "\F0443"; }

.mdi-ray-start-end::before {
  content: "\F0444"; }

.mdi-ray-start-vertex-end::before {
  content: "\F15D8"; }

.mdi-ray-vertex::before {
  content: "\F0445"; }

.mdi-react::before {
  content: "\F0708"; }

.mdi-read::before {
  content: "\F0447"; }

.mdi-receipt::before {
  content: "\F0449"; }

.mdi-record::before {
  content: "\F044A"; }

.mdi-record-circle::before {
  content: "\F0EC2"; }

.mdi-record-circle-outline::before {
  content: "\F0EC3"; }

.mdi-record-player::before {
  content: "\F099A"; }

.mdi-record-rec::before {
  content: "\F044B"; }

.mdi-rectangle::before {
  content: "\F0E5E"; }

.mdi-rectangle-outline::before {
  content: "\F0E5F"; }

.mdi-recycle::before {
  content: "\F044C"; }

.mdi-recycle-variant::before {
  content: "\F139D"; }

.mdi-reddit::before {
  content: "\F044D"; }

.mdi-redhat::before {
  content: "\F111B"; }

.mdi-redo::before {
  content: "\F044E"; }

.mdi-redo-variant::before {
  content: "\F044F"; }

.mdi-reflect-horizontal::before {
  content: "\F0A0E"; }

.mdi-reflect-vertical::before {
  content: "\F0A0F"; }

.mdi-refresh::before {
  content: "\F0450"; }

.mdi-refresh-circle::before {
  content: "\F1377"; }

.mdi-regex::before {
  content: "\F0451"; }

.mdi-registered-trademark::before {
  content: "\F0A67"; }

.mdi-reiterate::before {
  content: "\F1588"; }

.mdi-relation-many-to-many::before {
  content: "\F1496"; }

.mdi-relation-many-to-one::before {
  content: "\F1497"; }

.mdi-relation-many-to-one-or-many::before {
  content: "\F1498"; }

.mdi-relation-many-to-only-one::before {
  content: "\F1499"; }

.mdi-relation-many-to-zero-or-many::before {
  content: "\F149A"; }

.mdi-relation-many-to-zero-or-one::before {
  content: "\F149B"; }

.mdi-relation-one-or-many-to-many::before {
  content: "\F149C"; }

.mdi-relation-one-or-many-to-one::before {
  content: "\F149D"; }

.mdi-relation-one-or-many-to-one-or-many::before {
  content: "\F149E"; }

.mdi-relation-one-or-many-to-only-one::before {
  content: "\F149F"; }

.mdi-relation-one-or-many-to-zero-or-many::before {
  content: "\F14A0"; }

.mdi-relation-one-or-many-to-zero-or-one::before {
  content: "\F14A1"; }

.mdi-relation-one-to-many::before {
  content: "\F14A2"; }

.mdi-relation-one-to-one::before {
  content: "\F14A3"; }

.mdi-relation-one-to-one-or-many::before {
  content: "\F14A4"; }

.mdi-relation-one-to-only-one::before {
  content: "\F14A5"; }

.mdi-relation-one-to-zero-or-many::before {
  content: "\F14A6"; }

.mdi-relation-one-to-zero-or-one::before {
  content: "\F14A7"; }

.mdi-relation-only-one-to-many::before {
  content: "\F14A8"; }

.mdi-relation-only-one-to-one::before {
  content: "\F14A9"; }

.mdi-relation-only-one-to-one-or-many::before {
  content: "\F14AA"; }

.mdi-relation-only-one-to-only-one::before {
  content: "\F14AB"; }

.mdi-relation-only-one-to-zero-or-many::before {
  content: "\F14AC"; }

.mdi-relation-only-one-to-zero-or-one::before {
  content: "\F14AD"; }

.mdi-relation-zero-or-many-to-many::before {
  content: "\F14AE"; }

.mdi-relation-zero-or-many-to-one::before {
  content: "\F14AF"; }

.mdi-relation-zero-or-many-to-one-or-many::before {
  content: "\F14B0"; }

.mdi-relation-zero-or-many-to-only-one::before {
  content: "\F14B1"; }

.mdi-relation-zero-or-many-to-zero-or-many::before {
  content: "\F14B2"; }

.mdi-relation-zero-or-many-to-zero-or-one::before {
  content: "\F14B3"; }

.mdi-relation-zero-or-one-to-many::before {
  content: "\F14B4"; }

.mdi-relation-zero-or-one-to-one::before {
  content: "\F14B5"; }

.mdi-relation-zero-or-one-to-one-or-many::before {
  content: "\F14B6"; }

.mdi-relation-zero-or-one-to-only-one::before {
  content: "\F14B7"; }

.mdi-relation-zero-or-one-to-zero-or-many::before {
  content: "\F14B8"; }

.mdi-relation-zero-or-one-to-zero-or-one::before {
  content: "\F14B9"; }

.mdi-relative-scale::before {
  content: "\F0452"; }

.mdi-reload::before {
  content: "\F0453"; }

.mdi-reload-alert::before {
  content: "\F110B"; }

.mdi-reminder::before {
  content: "\F088C"; }

.mdi-remote::before {
  content: "\F0454"; }

.mdi-remote-desktop::before {
  content: "\F08B9"; }

.mdi-remote-off::before {
  content: "\F0EC4"; }

.mdi-remote-tv::before {
  content: "\F0EC5"; }

.mdi-remote-tv-off::before {
  content: "\F0EC6"; }

.mdi-rename-box::before {
  content: "\F0455"; }

.mdi-reorder-horizontal::before {
  content: "\F0688"; }

.mdi-reorder-vertical::before {
  content: "\F0689"; }

.mdi-repeat::before {
  content: "\F0456"; }

.mdi-repeat-off::before {
  content: "\F0457"; }

.mdi-repeat-once::before {
  content: "\F0458"; }

.mdi-replay::before {
  content: "\F0459"; }

.mdi-reply::before {
  content: "\F045A"; }

.mdi-reply-all::before {
  content: "\F045B"; }

.mdi-reply-all-outline::before {
  content: "\F0F1F"; }

.mdi-reply-circle::before {
  content: "\F11AE"; }

.mdi-reply-outline::before {
  content: "\F0F20"; }

.mdi-reproduction::before {
  content: "\F045C"; }

.mdi-resistor::before {
  content: "\F0B44"; }

.mdi-resistor-nodes::before {
  content: "\F0B45"; }

.mdi-resize::before {
  content: "\F0A68"; }

.mdi-resize-bottom-right::before {
  content: "\F045D"; }

.mdi-responsive::before {
  content: "\F045E"; }

.mdi-restart::before {
  content: "\F0709"; }

.mdi-restart-alert::before {
  content: "\F110C"; }

.mdi-restart-off::before {
  content: "\F0D95"; }

.mdi-restore::before {
  content: "\F099B"; }

.mdi-restore-alert::before {
  content: "\F110D"; }

.mdi-rewind::before {
  content: "\F045F"; }

.mdi-rewind-10::before {
  content: "\F0D2A"; }

.mdi-rewind-30::before {
  content: "\F0D96"; }

.mdi-rewind-5::before {
  content: "\F11F9"; }

.mdi-rewind-60::before {
  content: "\F160C"; }

.mdi-rewind-outline::before {
  content: "\F070A"; }

.mdi-rhombus::before {
  content: "\F070B"; }

.mdi-rhombus-medium::before {
  content: "\F0A10"; }

.mdi-rhombus-medium-outline::before {
  content: "\F14DC"; }

.mdi-rhombus-outline::before {
  content: "\F070C"; }

.mdi-rhombus-split::before {
  content: "\F0A11"; }

.mdi-rhombus-split-outline::before {
  content: "\F14DD"; }

.mdi-ribbon::before {
  content: "\F0460"; }

.mdi-rice::before {
  content: "\F07EA"; }

.mdi-rickshaw::before {
  content: "\F15BB"; }

.mdi-rickshaw-electric::before {
  content: "\F15BC"; }

.mdi-ring::before {
  content: "\F07EB"; }

.mdi-rivet::before {
  content: "\F0E60"; }

.mdi-road::before {
  content: "\F0461"; }

.mdi-road-variant::before {
  content: "\F0462"; }

.mdi-robber::before {
  content: "\F1058"; }

.mdi-robot::before {
  content: "\F06A9"; }

.mdi-robot-angry::before {
  content: "\F169D"; }

.mdi-robot-angry-outline::before {
  content: "\F169E"; }

.mdi-robot-confused::before {
  content: "\F169F"; }

.mdi-robot-confused-outline::before {
  content: "\F16A0"; }

.mdi-robot-dead::before {
  content: "\F16A1"; }

.mdi-robot-dead-outline::before {
  content: "\F16A2"; }

.mdi-robot-excited::before {
  content: "\F16A3"; }

.mdi-robot-excited-outline::before {
  content: "\F16A4"; }

.mdi-robot-industrial::before {
  content: "\F0B46"; }

.mdi-robot-love::before {
  content: "\F16A5"; }

.mdi-robot-love-outline::before {
  content: "\F16A6"; }

.mdi-robot-mower::before {
  content: "\F11F7"; }

.mdi-robot-mower-outline::before {
  content: "\F11F3"; }

.mdi-robot-off::before {
  content: "\F16A7"; }

.mdi-robot-off-outline::before {
  content: "\F167B"; }

.mdi-robot-outline::before {
  content: "\F167A"; }

.mdi-robot-vacuum::before {
  content: "\F070D"; }

.mdi-robot-vacuum-variant::before {
  content: "\F0908"; }

.mdi-rocket::before {
  content: "\F0463"; }

.mdi-rocket-launch::before {
  content: "\F14DE"; }

.mdi-rocket-launch-outline::before {
  content: "\F14DF"; }

.mdi-rocket-outline::before {
  content: "\F13AF"; }

.mdi-rodent::before {
  content: "\F1327"; }

.mdi-roller-skate::before {
  content: "\F0D2B"; }

.mdi-roller-skate-off::before {
  content: "\F0145"; }

.mdi-rollerblade::before {
  content: "\F0D2C"; }

.mdi-rollerblade-off::before {
  content: "\F002E"; }

.mdi-rollupjs::before {
  content: "\F0BC0"; }

.mdi-roman-numeral-1::before {
  content: "\F1088"; }

.mdi-roman-numeral-10::before {
  content: "\F1091"; }

.mdi-roman-numeral-2::before {
  content: "\F1089"; }

.mdi-roman-numeral-3::before {
  content: "\F108A"; }

.mdi-roman-numeral-4::before {
  content: "\F108B"; }

.mdi-roman-numeral-5::before {
  content: "\F108C"; }

.mdi-roman-numeral-6::before {
  content: "\F108D"; }

.mdi-roman-numeral-7::before {
  content: "\F108E"; }

.mdi-roman-numeral-8::before {
  content: "\F108F"; }

.mdi-roman-numeral-9::before {
  content: "\F1090"; }

.mdi-room-service::before {
  content: "\F088D"; }

.mdi-room-service-outline::before {
  content: "\F0D97"; }

.mdi-rotate-3d::before {
  content: "\F0EC7"; }

.mdi-rotate-3d-variant::before {
  content: "\F0464"; }

.mdi-rotate-left::before {
  content: "\F0465"; }

.mdi-rotate-left-variant::before {
  content: "\F0466"; }

.mdi-rotate-orbit::before {
  content: "\F0D98"; }

.mdi-rotate-right::before {
  content: "\F0467"; }

.mdi-rotate-right-variant::before {
  content: "\F0468"; }

.mdi-rounded-corner::before {
  content: "\F0607"; }

.mdi-router::before {
  content: "\F11E2"; }

.mdi-router-network::before {
  content: "\F1087"; }

.mdi-router-wireless::before {
  content: "\F0469"; }

.mdi-router-wireless-off::before {
  content: "\F15A3"; }

.mdi-router-wireless-settings::before {
  content: "\F0A69"; }

.mdi-routes::before {
  content: "\F046A"; }

.mdi-routes-clock::before {
  content: "\F1059"; }

.mdi-rowing::before {
  content: "\F0608"; }

.mdi-rss::before {
  content: "\F046B"; }

.mdi-rss-box::before {
  content: "\F046C"; }

.mdi-rss-off::before {
  content: "\F0F21"; }

.mdi-rug::before {
  content: "\F1475"; }

.mdi-rugby::before {
  content: "\F0D99"; }

.mdi-ruler::before {
  content: "\F046D"; }

.mdi-ruler-square::before {
  content: "\F0CC2"; }

.mdi-ruler-square-compass::before {
  content: "\F0EBE"; }

.mdi-run::before {
  content: "\F070E"; }

.mdi-run-fast::before {
  content: "\F046E"; }

.mdi-rv-truck::before {
  content: "\F11D4"; }

.mdi-sack::before {
  content: "\F0D2E"; }

.mdi-sack-percent::before {
  content: "\F0D2F"; }

.mdi-safe::before {
  content: "\F0A6A"; }

.mdi-safe-square::before {
  content: "\F127C"; }

.mdi-safe-square-outline::before {
  content: "\F127D"; }

.mdi-safety-goggles::before {
  content: "\F0D30"; }

.mdi-sail-boat::before {
  content: "\F0EC8"; }

.mdi-sale::before {
  content: "\F046F"; }

.mdi-salesforce::before {
  content: "\F088E"; }

.mdi-sass::before {
  content: "\F07EC"; }

.mdi-satellite::before {
  content: "\F0470"; }

.mdi-satellite-uplink::before {
  content: "\F0909"; }

.mdi-satellite-variant::before {
  content: "\F0471"; }

.mdi-sausage::before {
  content: "\F08BA"; }

.mdi-saw-blade::before {
  content: "\F0E61"; }

.mdi-sawtooth-wave::before {
  content: "\F147A"; }

.mdi-saxophone::before {
  content: "\F0609"; }

.mdi-scale::before {
  content: "\F0472"; }

.mdi-scale-balance::before {
  content: "\F05D1"; }

.mdi-scale-bathroom::before {
  content: "\F0473"; }

.mdi-scale-off::before {
  content: "\F105A"; }

.mdi-scan-helper::before {
  content: "\F13D8"; }

.mdi-scanner::before {
  content: "\F06AB"; }

.mdi-scanner-off::before {
  content: "\F090A"; }

.mdi-scatter-plot::before {
  content: "\F0EC9"; }

.mdi-scatter-plot-outline::before {
  content: "\F0ECA"; }

.mdi-school::before {
  content: "\F0474"; }

.mdi-school-outline::before {
  content: "\F1180"; }

.mdi-scissors-cutting::before {
  content: "\F0A6B"; }

.mdi-scooter::before {
  content: "\F15BD"; }

.mdi-scooter-electric::before {
  content: "\F15BE"; }

.mdi-scoreboard::before {
  content: "\F127E"; }

.mdi-scoreboard-outline::before {
  content: "\F127F"; }

.mdi-screen-rotation::before {
  content: "\F0475"; }

.mdi-screen-rotation-lock::before {
  content: "\F0478"; }

.mdi-screw-flat-top::before {
  content: "\F0DF3"; }

.mdi-screw-lag::before {
  content: "\F0DF4"; }

.mdi-screw-machine-flat-top::before {
  content: "\F0DF5"; }

.mdi-screw-machine-round-top::before {
  content: "\F0DF6"; }

.mdi-screw-round-top::before {
  content: "\F0DF7"; }

.mdi-screwdriver::before {
  content: "\F0476"; }

.mdi-script::before {
  content: "\F0BC1"; }

.mdi-script-outline::before {
  content: "\F0477"; }

.mdi-script-text::before {
  content: "\F0BC2"; }

.mdi-script-text-outline::before {
  content: "\F0BC3"; }

.mdi-sd::before {
  content: "\F0479"; }

.mdi-seal::before {
  content: "\F047A"; }

.mdi-seal-variant::before {
  content: "\F0FD9"; }

.mdi-search-web::before {
  content: "\F070F"; }

.mdi-seat::before {
  content: "\F0CC3"; }

.mdi-seat-flat::before {
  content: "\F047B"; }

.mdi-seat-flat-angled::before {
  content: "\F047C"; }

.mdi-seat-individual-suite::before {
  content: "\F047D"; }

.mdi-seat-legroom-extra::before {
  content: "\F047E"; }

.mdi-seat-legroom-normal::before {
  content: "\F047F"; }

.mdi-seat-legroom-reduced::before {
  content: "\F0480"; }

.mdi-seat-outline::before {
  content: "\F0CC4"; }

.mdi-seat-passenger::before {
  content: "\F1249"; }

.mdi-seat-recline-extra::before {
  content: "\F0481"; }

.mdi-seat-recline-normal::before {
  content: "\F0482"; }

.mdi-seatbelt::before {
  content: "\F0CC5"; }

.mdi-security::before {
  content: "\F0483"; }

.mdi-security-network::before {
  content: "\F0484"; }

.mdi-seed::before {
  content: "\F0E62"; }

.mdi-seed-off::before {
  content: "\F13FD"; }

.mdi-seed-off-outline::before {
  content: "\F13FE"; }

.mdi-seed-outline::before {
  content: "\F0E63"; }

.mdi-seesaw::before {
  content: "\F15A4"; }

.mdi-segment::before {
  content: "\F0ECB"; }

.mdi-select::before {
  content: "\F0485"; }

.mdi-select-all::before {
  content: "\F0486"; }

.mdi-select-color::before {
  content: "\F0D31"; }

.mdi-select-compare::before {
  content: "\F0AD9"; }

.mdi-select-drag::before {
  content: "\F0A6C"; }

.mdi-select-group::before {
  content: "\F0F82"; }

.mdi-select-inverse::before {
  content: "\F0487"; }

.mdi-select-marker::before {
  content: "\F1280"; }

.mdi-select-multiple::before {
  content: "\F1281"; }

.mdi-select-multiple-marker::before {
  content: "\F1282"; }

.mdi-select-off::before {
  content: "\F0488"; }

.mdi-select-place::before {
  content: "\F0FDA"; }

.mdi-select-search::before {
  content: "\F1204"; }

.mdi-selection::before {
  content: "\F0489"; }

.mdi-selection-drag::before {
  content: "\F0A6D"; }

.mdi-selection-ellipse::before {
  content: "\F0D32"; }

.mdi-selection-ellipse-arrow-inside::before {
  content: "\F0F22"; }

.mdi-selection-marker::before {
  content: "\F1283"; }

.mdi-selection-multiple::before {
  content: "\F1285"; }

.mdi-selection-multiple-marker::before {
  content: "\F1284"; }

.mdi-selection-off::before {
  content: "\F0777"; }

.mdi-selection-search::before {
  content: "\F1205"; }

.mdi-semantic-web::before {
  content: "\F1316"; }

.mdi-send::before {
  content: "\F048A"; }

.mdi-send-check::before {
  content: "\F1161"; }

.mdi-send-check-outline::before {
  content: "\F1162"; }

.mdi-send-circle::before {
  content: "\F0DF8"; }

.mdi-send-circle-outline::before {
  content: "\F0DF9"; }

.mdi-send-clock::before {
  content: "\F1163"; }

.mdi-send-clock-outline::before {
  content: "\F1164"; }

.mdi-send-lock::before {
  content: "\F07ED"; }

.mdi-send-lock-outline::before {
  content: "\F1166"; }

.mdi-send-outline::before {
  content: "\F1165"; }

.mdi-serial-port::before {
  content: "\F065C"; }

.mdi-server::before {
  content: "\F048B"; }

.mdi-server-minus::before {
  content: "\F048C"; }

.mdi-server-network::before {
  content: "\F048D"; }

.mdi-server-network-off::before {
  content: "\F048E"; }

.mdi-server-off::before {
  content: "\F048F"; }

.mdi-server-plus::before {
  content: "\F0490"; }

.mdi-server-remove::before {
  content: "\F0491"; }

.mdi-server-security::before {
  content: "\F0492"; }

.mdi-set-all::before {
  content: "\F0778"; }

.mdi-set-center::before {
  content: "\F0779"; }

.mdi-set-center-right::before {
  content: "\F077A"; }

.mdi-set-left::before {
  content: "\F077B"; }

.mdi-set-left-center::before {
  content: "\F077C"; }

.mdi-set-left-right::before {
  content: "\F077D"; }

.mdi-set-merge::before {
  content: "\F14E0"; }

.mdi-set-none::before {
  content: "\F077E"; }

.mdi-set-right::before {
  content: "\F077F"; }

.mdi-set-split::before {
  content: "\F14E1"; }

.mdi-set-square::before {
  content: "\F145D"; }

.mdi-set-top-box::before {
  content: "\F099F"; }

.mdi-settings-helper::before {
  content: "\F0A6E"; }

.mdi-shaker::before {
  content: "\F110E"; }

.mdi-shaker-outline::before {
  content: "\F110F"; }

.mdi-shape::before {
  content: "\F0831"; }

.mdi-shape-circle-plus::before {
  content: "\F065D"; }

.mdi-shape-outline::before {
  content: "\F0832"; }

.mdi-shape-oval-plus::before {
  content: "\F11FA"; }

.mdi-shape-plus::before {
  content: "\F0495"; }

.mdi-shape-polygon-plus::before {
  content: "\F065E"; }

.mdi-shape-rectangle-plus::before {
  content: "\F065F"; }

.mdi-shape-square-plus::before {
  content: "\F0660"; }

.mdi-shape-square-rounded-plus::before {
  content: "\F14FA"; }

.mdi-share::before {
  content: "\F0496"; }

.mdi-share-all::before {
  content: "\F11F4"; }

.mdi-share-all-outline::before {
  content: "\F11F5"; }

.mdi-share-circle::before {
  content: "\F11AD"; }

.mdi-share-off::before {
  content: "\F0F23"; }

.mdi-share-off-outline::before {
  content: "\F0F24"; }

.mdi-share-outline::before {
  content: "\F0932"; }

.mdi-share-variant::before {
  content: "\F0497"; }

.mdi-share-variant-outline::before {
  content: "\F1514"; }

.mdi-shark-fin::before {
  content: "\F1673"; }

.mdi-shark-fin-outline::before {
  content: "\F1674"; }

.mdi-sheep::before {
  content: "\F0CC6"; }

.mdi-shield::before {
  content: "\F0498"; }

.mdi-shield-account::before {
  content: "\F088F"; }

.mdi-shield-account-outline::before {
  content: "\F0A12"; }

.mdi-shield-account-variant::before {
  content: "\F15A7"; }

.mdi-shield-account-variant-outline::before {
  content: "\F15A8"; }

.mdi-shield-airplane::before {
  content: "\F06BB"; }

.mdi-shield-airplane-outline::before {
  content: "\F0CC7"; }

.mdi-shield-alert::before {
  content: "\F0ECC"; }

.mdi-shield-alert-outline::before {
  content: "\F0ECD"; }

.mdi-shield-bug::before {
  content: "\F13DA"; }

.mdi-shield-bug-outline::before {
  content: "\F13DB"; }

.mdi-shield-car::before {
  content: "\F0F83"; }

.mdi-shield-check::before {
  content: "\F0565"; }

.mdi-shield-check-outline::before {
  content: "\F0CC8"; }

.mdi-shield-cross::before {
  content: "\F0CC9"; }

.mdi-shield-cross-outline::before {
  content: "\F0CCA"; }

.mdi-shield-edit::before {
  content: "\F11A0"; }

.mdi-shield-edit-outline::before {
  content: "\F11A1"; }

.mdi-shield-half::before {
  content: "\F1360"; }

.mdi-shield-half-full::before {
  content: "\F0780"; }

.mdi-shield-home::before {
  content: "\F068A"; }

.mdi-shield-home-outline::before {
  content: "\F0CCB"; }

.mdi-shield-key::before {
  content: "\F0BC4"; }

.mdi-shield-key-outline::before {
  content: "\F0BC5"; }

.mdi-shield-link-variant::before {
  content: "\F0D33"; }

.mdi-shield-link-variant-outline::before {
  content: "\F0D34"; }

.mdi-shield-lock::before {
  content: "\F099D"; }

.mdi-shield-lock-outline::before {
  content: "\F0CCC"; }

.mdi-shield-off::before {
  content: "\F099E"; }

.mdi-shield-off-outline::before {
  content: "\F099C"; }

.mdi-shield-outline::before {
  content: "\F0499"; }

.mdi-shield-plus::before {
  content: "\F0ADA"; }

.mdi-shield-plus-outline::before {
  content: "\F0ADB"; }

.mdi-shield-refresh::before {
  content: "\F00AA"; }

.mdi-shield-refresh-outline::before {
  content: "\F01E0"; }

.mdi-shield-remove::before {
  content: "\F0ADC"; }

.mdi-shield-remove-outline::before {
  content: "\F0ADD"; }

.mdi-shield-search::before {
  content: "\F0D9A"; }

.mdi-shield-star::before {
  content: "\F113B"; }

.mdi-shield-star-outline::before {
  content: "\F113C"; }

.mdi-shield-sun::before {
  content: "\F105D"; }

.mdi-shield-sun-outline::before {
  content: "\F105E"; }

.mdi-shield-sync::before {
  content: "\F11A2"; }

.mdi-shield-sync-outline::before {
  content: "\F11A3"; }

.mdi-ship-wheel::before {
  content: "\F0833"; }

.mdi-shoe-ballet::before {
  content: "\F15CA"; }

.mdi-shoe-cleat::before {
  content: "\F15C7"; }

.mdi-shoe-formal::before {
  content: "\F0B47"; }

.mdi-shoe-heel::before {
  content: "\F0B48"; }

.mdi-shoe-print::before {
  content: "\F0DFA"; }

.mdi-shoe-sneaker::before {
  content: "\F15C8"; }

.mdi-shopping::before {
  content: "\F049A"; }

.mdi-shopping-music::before {
  content: "\F049B"; }

.mdi-shopping-outline::before {
  content: "\F11D5"; }

.mdi-shopping-search::before {
  content: "\F0F84"; }

.mdi-shore::before {
  content: "\F14F9"; }

.mdi-shovel::before {
  content: "\F0710"; }

.mdi-shovel-off::before {
  content: "\F0711"; }

.mdi-shower::before {
  content: "\F09A0"; }

.mdi-shower-head::before {
  content: "\F09A1"; }

.mdi-shredder::before {
  content: "\F049C"; }

.mdi-shuffle::before {
  content: "\F049D"; }

.mdi-shuffle-disabled::before {
  content: "\F049E"; }

.mdi-shuffle-variant::before {
  content: "\F049F"; }

.mdi-shuriken::before {
  content: "\F137F"; }

.mdi-sigma::before {
  content: "\F04A0"; }

.mdi-sigma-lower::before {
  content: "\F062B"; }

.mdi-sign-caution::before {
  content: "\F04A1"; }

.mdi-sign-direction::before {
  content: "\F0781"; }

.mdi-sign-direction-minus::before {
  content: "\F1000"; }

.mdi-sign-direction-plus::before {
  content: "\F0FDC"; }

.mdi-sign-direction-remove::before {
  content: "\F0FDD"; }

.mdi-sign-pole::before {
  content: "\F14F8"; }

.mdi-sign-real-estate::before {
  content: "\F1118"; }

.mdi-sign-text::before {
  content: "\F0782"; }

.mdi-signal::before {
  content: "\F04A2"; }

.mdi-signal-2g::before {
  content: "\F0712"; }

.mdi-signal-3g::before {
  content: "\F0713"; }

.mdi-signal-4g::before {
  content: "\F0714"; }

.mdi-signal-5g::before {
  content: "\F0A6F"; }

.mdi-signal-cellular-1::before {
  content: "\F08BC"; }

.mdi-signal-cellular-2::before {
  content: "\F08BD"; }

.mdi-signal-cellular-3::before {
  content: "\F08BE"; }

.mdi-signal-cellular-outline::before {
  content: "\F08BF"; }

.mdi-signal-distance-variant::before {
  content: "\F0E64"; }

.mdi-signal-hspa::before {
  content: "\F0715"; }

.mdi-signal-hspa-plus::before {
  content: "\F0716"; }

.mdi-signal-off::before {
  content: "\F0783"; }

.mdi-signal-variant::before {
  content: "\F060A"; }

.mdi-signature::before {
  content: "\F0DFB"; }

.mdi-signature-freehand::before {
  content: "\F0DFC"; }

.mdi-signature-image::before {
  content: "\F0DFD"; }

.mdi-signature-text::before {
  content: "\F0DFE"; }

.mdi-silo::before {
  content: "\F0B49"; }

.mdi-silverware::before {
  content: "\F04A3"; }

.mdi-silverware-clean::before {
  content: "\F0FDE"; }

.mdi-silverware-fork::before {
  content: "\F04A4"; }

.mdi-silverware-fork-knife::before {
  content: "\F0A70"; }

.mdi-silverware-spoon::before {
  content: "\F04A5"; }

.mdi-silverware-variant::before {
  content: "\F04A6"; }

.mdi-sim::before {
  content: "\F04A7"; }

.mdi-sim-alert::before {
  content: "\F04A8"; }

.mdi-sim-alert-outline::before {
  content: "\F15D3"; }

.mdi-sim-off::before {
  content: "\F04A9"; }

.mdi-sim-off-outline::before {
  content: "\F15D4"; }

.mdi-sim-outline::before {
  content: "\F15D5"; }

.mdi-simple-icons::before {
  content: "\F131D"; }

.mdi-sina-weibo::before {
  content: "\F0ADF"; }

.mdi-sine-wave::before {
  content: "\F095B"; }

.mdi-sitemap::before {
  content: "\F04AA"; }

.mdi-size-l::before {
  content: "\F13A6"; }

.mdi-size-m::before {
  content: "\F13A5"; }

.mdi-size-s::before {
  content: "\F13A4"; }

.mdi-size-xl::before {
  content: "\F13A7"; }

.mdi-size-xs::before {
  content: "\F13A3"; }

.mdi-size-xxl::before {
  content: "\F13A8"; }

.mdi-size-xxs::before {
  content: "\F13A2"; }

.mdi-size-xxxl::before {
  content: "\F13A9"; }

.mdi-skate::before {
  content: "\F0D35"; }

.mdi-skateboard::before {
  content: "\F14C2"; }

.mdi-skew-less::before {
  content: "\F0D36"; }

.mdi-skew-more::before {
  content: "\F0D37"; }

.mdi-ski::before {
  content: "\F1304"; }

.mdi-ski-cross-country::before {
  content: "\F1305"; }

.mdi-ski-water::before {
  content: "\F1306"; }

.mdi-skip-backward::before {
  content: "\F04AB"; }

.mdi-skip-backward-outline::before {
  content: "\F0F25"; }

.mdi-skip-forward::before {
  content: "\F04AC"; }

.mdi-skip-forward-outline::before {
  content: "\F0F26"; }

.mdi-skip-next::before {
  content: "\F04AD"; }

.mdi-skip-next-circle::before {
  content: "\F0661"; }

.mdi-skip-next-circle-outline::before {
  content: "\F0662"; }

.mdi-skip-next-outline::before {
  content: "\F0F27"; }

.mdi-skip-previous::before {
  content: "\F04AE"; }

.mdi-skip-previous-circle::before {
  content: "\F0663"; }

.mdi-skip-previous-circle-outline::before {
  content: "\F0664"; }

.mdi-skip-previous-outline::before {
  content: "\F0F28"; }

.mdi-skull::before {
  content: "\F068C"; }

.mdi-skull-crossbones::before {
  content: "\F0BC6"; }

.mdi-skull-crossbones-outline::before {
  content: "\F0BC7"; }

.mdi-skull-outline::before {
  content: "\F0BC8"; }

.mdi-skull-scan::before {
  content: "\F14C7"; }

.mdi-skull-scan-outline::before {
  content: "\F14C8"; }

.mdi-skype::before {
  content: "\F04AF"; }

.mdi-skype-business::before {
  content: "\F04B0"; }

.mdi-slack::before {
  content: "\F04B1"; }

.mdi-slash-forward::before {
  content: "\F0FDF"; }

.mdi-slash-forward-box::before {
  content: "\F0FE0"; }

.mdi-sleep::before {
  content: "\F04B2"; }

.mdi-sleep-off::before {
  content: "\F04B3"; }

.mdi-slide::before {
  content: "\F15A5"; }

.mdi-slope-downhill::before {
  content: "\F0DFF"; }

.mdi-slope-uphill::before {
  content: "\F0E00"; }

.mdi-slot-machine::before {
  content: "\F1114"; }

.mdi-slot-machine-outline::before {
  content: "\F1115"; }

.mdi-smart-card::before {
  content: "\F10BD"; }

.mdi-smart-card-outline::before {
  content: "\F10BE"; }

.mdi-smart-card-reader::before {
  content: "\F10BF"; }

.mdi-smart-card-reader-outline::before {
  content: "\F10C0"; }

.mdi-smog::before {
  content: "\F0A71"; }

.mdi-smoke-detector::before {
  content: "\F0392"; }

.mdi-smoking::before {
  content: "\F04B4"; }

.mdi-smoking-off::before {
  content: "\F04B5"; }

.mdi-smoking-pipe::before {
  content: "\F140D"; }

.mdi-smoking-pipe-off::before {
  content: "\F1428"; }

.mdi-snail::before {
  content: "\F1677"; }

.mdi-snake::before {
  content: "\F150E"; }

.mdi-snapchat::before {
  content: "\F04B6"; }

.mdi-snowboard::before {
  content: "\F1307"; }

.mdi-snowflake::before {
  content: "\F0717"; }

.mdi-snowflake-alert::before {
  content: "\F0F29"; }

.mdi-snowflake-melt::before {
  content: "\F12CB"; }

.mdi-snowflake-off::before {
  content: "\F14E3"; }

.mdi-snowflake-variant::before {
  content: "\F0F2A"; }

.mdi-snowman::before {
  content: "\F04B7"; }

.mdi-soccer::before {
  content: "\F04B8"; }

.mdi-soccer-field::before {
  content: "\F0834"; }

.mdi-social-distance-2-meters::before {
  content: "\F1579"; }

.mdi-social-distance-6-feet::before {
  content: "\F157A"; }

.mdi-sofa::before {
  content: "\F04B9"; }

.mdi-sofa-outline::before {
  content: "\F156D"; }

.mdi-sofa-single::before {
  content: "\F156E"; }

.mdi-sofa-single-outline::before {
  content: "\F156F"; }

.mdi-solar-panel::before {
  content: "\F0D9B"; }

.mdi-solar-panel-large::before {
  content: "\F0D9C"; }

.mdi-solar-power::before {
  content: "\F0A72"; }

.mdi-soldering-iron::before {
  content: "\F1092"; }

.mdi-solid::before {
  content: "\F068D"; }

.mdi-sony-playstation::before {
  content: "\F0414"; }

.mdi-sort::before {
  content: "\F04BA"; }

.mdi-sort-alphabetical-ascending::before {
  content: "\F05BD"; }

.mdi-sort-alphabetical-ascending-variant::before {
  content: "\F1148"; }

.mdi-sort-alphabetical-descending::before {
  content: "\F05BF"; }

.mdi-sort-alphabetical-descending-variant::before {
  content: "\F1149"; }

.mdi-sort-alphabetical-variant::before {
  content: "\F04BB"; }

.mdi-sort-ascending::before {
  content: "\F04BC"; }

.mdi-sort-bool-ascending::before {
  content: "\F1385"; }

.mdi-sort-bool-ascending-variant::before {
  content: "\F1386"; }

.mdi-sort-bool-descending::before {
  content: "\F1387"; }

.mdi-sort-bool-descending-variant::before {
  content: "\F1388"; }

.mdi-sort-calendar-ascending::before {
  content: "\F1547"; }

.mdi-sort-calendar-descending::before {
  content: "\F1548"; }

.mdi-sort-clock-ascending::before {
  content: "\F1549"; }

.mdi-sort-clock-ascending-outline::before {
  content: "\F154A"; }

.mdi-sort-clock-descending::before {
  content: "\F154B"; }

.mdi-sort-clock-descending-outline::before {
  content: "\F154C"; }

.mdi-sort-descending::before {
  content: "\F04BD"; }

.mdi-sort-numeric-ascending::before {
  content: "\F1389"; }

.mdi-sort-numeric-ascending-variant::before {
  content: "\F090D"; }

.mdi-sort-numeric-descending::before {
  content: "\F138A"; }

.mdi-sort-numeric-descending-variant::before {
  content: "\F0AD2"; }

.mdi-sort-numeric-variant::before {
  content: "\F04BE"; }

.mdi-sort-reverse-variant::before {
  content: "\F033C"; }

.mdi-sort-variant::before {
  content: "\F04BF"; }

.mdi-sort-variant-lock::before {
  content: "\F0CCD"; }

.mdi-sort-variant-lock-open::before {
  content: "\F0CCE"; }

.mdi-sort-variant-remove::before {
  content: "\F1147"; }

.mdi-soundcloud::before {
  content: "\F04C0"; }

.mdi-source-branch::before {
  content: "\F062C"; }

.mdi-source-branch-check::before {
  content: "\F14CF"; }

.mdi-source-branch-minus::before {
  content: "\F14CB"; }

.mdi-source-branch-plus::before {
  content: "\F14CA"; }

.mdi-source-branch-refresh::before {
  content: "\F14CD"; }

.mdi-source-branch-remove::before {
  content: "\F14CC"; }

.mdi-source-branch-sync::before {
  content: "\F14CE"; }

.mdi-source-commit::before {
  content: "\F0718"; }

.mdi-source-commit-end::before {
  content: "\F0719"; }

.mdi-source-commit-end-local::before {
  content: "\F071A"; }

.mdi-source-commit-local::before {
  content: "\F071B"; }

.mdi-source-commit-next-local::before {
  content: "\F071C"; }

.mdi-source-commit-start::before {
  content: "\F071D"; }

.mdi-source-commit-start-next-local::before {
  content: "\F071E"; }

.mdi-source-fork::before {
  content: "\F04C1"; }

.mdi-source-merge::before {
  content: "\F062D"; }

.mdi-source-pull::before {
  content: "\F04C2"; }

.mdi-source-repository::before {
  content: "\F0CCF"; }

.mdi-source-repository-multiple::before {
  content: "\F0CD0"; }

.mdi-soy-sauce::before {
  content: "\F07EE"; }

.mdi-soy-sauce-off::before {
  content: "\F13FC"; }

.mdi-spa::before {
  content: "\F0CD1"; }

.mdi-spa-outline::before {
  content: "\F0CD2"; }

.mdi-space-invaders::before {
  content: "\F0BC9"; }

.mdi-space-station::before {
  content: "\F1383"; }

.mdi-spade::before {
  content: "\F0E65"; }

.mdi-sparkles::before {
  content: "\F1545"; }

.mdi-speaker::before {
  content: "\F04C3"; }

.mdi-speaker-bluetooth::before {
  content: "\F09A2"; }

.mdi-speaker-multiple::before {
  content: "\F0D38"; }

.mdi-speaker-off::before {
  content: "\F04C4"; }

.mdi-speaker-wireless::before {
  content: "\F071F"; }

.mdi-speedometer::before {
  content: "\F04C5"; }

.mdi-speedometer-medium::before {
  content: "\F0F85"; }

.mdi-speedometer-slow::before {
  content: "\F0F86"; }

.mdi-spellcheck::before {
  content: "\F04C6"; }

.mdi-spider::before {
  content: "\F11EA"; }

.mdi-spider-thread::before {
  content: "\F11EB"; }

.mdi-spider-web::before {
  content: "\F0BCA"; }

.mdi-spirit-level::before {
  content: "\F14F1"; }

.mdi-spoon-sugar::before {
  content: "\F1429"; }

.mdi-spotify::before {
  content: "\F04C7"; }

.mdi-spotlight::before {
  content: "\F04C8"; }

.mdi-spotlight-beam::before {
  content: "\F04C9"; }

.mdi-spray::before {
  content: "\F0665"; }

.mdi-spray-bottle::before {
  content: "\F0AE0"; }

.mdi-sprinkler::before {
  content: "\F105F"; }

.mdi-sprinkler-variant::before {
  content: "\F1060"; }

.mdi-sprout::before {
  content: "\F0E66"; }

.mdi-sprout-outline::before {
  content: "\F0E67"; }

.mdi-square::before {
  content: "\F0764"; }

.mdi-square-circle::before {
  content: "\F1500"; }

.mdi-square-edit-outline::before {
  content: "\F090C"; }

.mdi-square-medium::before {
  content: "\F0A13"; }

.mdi-square-medium-outline::before {
  content: "\F0A14"; }

.mdi-square-off::before {
  content: "\F12EE"; }

.mdi-square-off-outline::before {
  content: "\F12EF"; }

.mdi-square-outline::before {
  content: "\F0763"; }

.mdi-square-root::before {
  content: "\F0784"; }

.mdi-square-root-box::before {
  content: "\F09A3"; }

.mdi-square-rounded::before {
  content: "\F14FB"; }

.mdi-square-rounded-outline::before {
  content: "\F14FC"; }

.mdi-square-small::before {
  content: "\F0A15"; }

.mdi-square-wave::before {
  content: "\F147B"; }

.mdi-squeegee::before {
  content: "\F0AE1"; }

.mdi-ssh::before {
  content: "\F08C0"; }

.mdi-stack-exchange::before {
  content: "\F060B"; }

.mdi-stack-overflow::before {
  content: "\F04CC"; }

.mdi-stackpath::before {
  content: "\F0359"; }

.mdi-stadium::before {
  content: "\F0FF9"; }

.mdi-stadium-variant::before {
  content: "\F0720"; }

.mdi-stairs::before {
  content: "\F04CD"; }

.mdi-stairs-box::before {
  content: "\F139E"; }

.mdi-stairs-down::before {
  content: "\F12BE"; }

.mdi-stairs-up::before {
  content: "\F12BD"; }

.mdi-stamper::before {
  content: "\F0D39"; }

.mdi-standard-definition::before {
  content: "\F07EF"; }

.mdi-star::before {
  content: "\F04CE"; }

.mdi-star-box::before {
  content: "\F0A73"; }

.mdi-star-box-multiple::before {
  content: "\F1286"; }

.mdi-star-box-multiple-outline::before {
  content: "\F1287"; }

.mdi-star-box-outline::before {
  content: "\F0A74"; }

.mdi-star-check::before {
  content: "\F1566"; }

.mdi-star-check-outline::before {
  content: "\F156A"; }

.mdi-star-circle::before {
  content: "\F04CF"; }

.mdi-star-circle-outline::before {
  content: "\F09A4"; }

.mdi-star-cog::before {
  content: "\F1668"; }

.mdi-star-cog-outline::before {
  content: "\F1669"; }

.mdi-star-face::before {
  content: "\F09A5"; }

.mdi-star-four-points::before {
  content: "\F0AE2"; }

.mdi-star-four-points-outline::before {
  content: "\F0AE3"; }

.mdi-star-half::before {
  content: "\F0246"; }

.mdi-star-half-full::before {
  content: "\F04D0"; }

.mdi-star-minus::before {
  content: "\F1564"; }

.mdi-star-minus-outline::before {
  content: "\F1568"; }

.mdi-star-off::before {
  content: "\F04D1"; }

.mdi-star-off-outline::before {
  content: "\F155B"; }

.mdi-star-outline::before {
  content: "\F04D2"; }

.mdi-star-plus::before {
  content: "\F1563"; }

.mdi-star-plus-outline::before {
  content: "\F1567"; }

.mdi-star-remove::before {
  content: "\F1565"; }

.mdi-star-remove-outline::before {
  content: "\F1569"; }

.mdi-star-settings::before {
  content: "\F166A"; }

.mdi-star-settings-outline::before {
  content: "\F166B"; }

.mdi-star-three-points::before {
  content: "\F0AE4"; }

.mdi-star-three-points-outline::before {
  content: "\F0AE5"; }

.mdi-state-machine::before {
  content: "\F11EF"; }

.mdi-steam::before {
  content: "\F04D3"; }

.mdi-steering::before {
  content: "\F04D4"; }

.mdi-steering-off::before {
  content: "\F090E"; }

.mdi-step-backward::before {
  content: "\F04D5"; }

.mdi-step-backward-2::before {
  content: "\F04D6"; }

.mdi-step-forward::before {
  content: "\F04D7"; }

.mdi-step-forward-2::before {
  content: "\F04D8"; }

.mdi-stethoscope::before {
  content: "\F04D9"; }

.mdi-sticker::before {
  content: "\F1364"; }

.mdi-sticker-alert::before {
  content: "\F1365"; }

.mdi-sticker-alert-outline::before {
  content: "\F1366"; }

.mdi-sticker-check::before {
  content: "\F1367"; }

.mdi-sticker-check-outline::before {
  content: "\F1368"; }

.mdi-sticker-circle-outline::before {
  content: "\F05D0"; }

.mdi-sticker-emoji::before {
  content: "\F0785"; }

.mdi-sticker-minus::before {
  content: "\F1369"; }

.mdi-sticker-minus-outline::before {
  content: "\F136A"; }

.mdi-sticker-outline::before {
  content: "\F136B"; }

.mdi-sticker-plus::before {
  content: "\F136C"; }

.mdi-sticker-plus-outline::before {
  content: "\F136D"; }

.mdi-sticker-remove::before {
  content: "\F136E"; }

.mdi-sticker-remove-outline::before {
  content: "\F136F"; }

.mdi-stocking::before {
  content: "\F04DA"; }

.mdi-stomach::before {
  content: "\F1093"; }

.mdi-stop::before {
  content: "\F04DB"; }

.mdi-stop-circle::before {
  content: "\F0666"; }

.mdi-stop-circle-outline::before {
  content: "\F0667"; }

.mdi-store::before {
  content: "\F04DC"; }

.mdi-store-24-hour::before {
  content: "\F04DD"; }

.mdi-store-minus::before {
  content: "\F165E"; }

.mdi-store-outline::before {
  content: "\F1361"; }

.mdi-store-plus::before {
  content: "\F165F"; }

.mdi-store-remove::before {
  content: "\F1660"; }

.mdi-storefront::before {
  content: "\F07C7"; }

.mdi-storefront-outline::before {
  content: "\F10C1"; }

.mdi-stove::before {
  content: "\F04DE"; }

.mdi-strategy::before {
  content: "\F11D6"; }

.mdi-stretch-to-page::before {
  content: "\F0F2B"; }

.mdi-stretch-to-page-outline::before {
  content: "\F0F2C"; }

.mdi-string-lights::before {
  content: "\F12BA"; }

.mdi-string-lights-off::before {
  content: "\F12BB"; }

.mdi-subdirectory-arrow-left::before {
  content: "\F060C"; }

.mdi-subdirectory-arrow-right::before {
  content: "\F060D"; }

.mdi-submarine::before {
  content: "\F156C"; }

.mdi-subtitles::before {
  content: "\F0A16"; }

.mdi-subtitles-outline::before {
  content: "\F0A17"; }

.mdi-subway::before {
  content: "\F06AC"; }

.mdi-subway-alert-variant::before {
  content: "\F0D9D"; }

.mdi-subway-variant::before {
  content: "\F04DF"; }

.mdi-summit::before {
  content: "\F0786"; }

.mdi-sunglasses::before {
  content: "\F04E0"; }

.mdi-surround-sound::before {
  content: "\F05C5"; }

.mdi-surround-sound-2-0::before {
  content: "\F07F0"; }

.mdi-surround-sound-3-1::before {
  content: "\F07F1"; }

.mdi-surround-sound-5-1::before {
  content: "\F07F2"; }

.mdi-surround-sound-7-1::before {
  content: "\F07F3"; }

.mdi-svg::before {
  content: "\F0721"; }

.mdi-swap-horizontal::before {
  content: "\F04E1"; }

.mdi-swap-horizontal-bold::before {
  content: "\F0BCD"; }

.mdi-swap-horizontal-circle::before {
  content: "\F0FE1"; }

.mdi-swap-horizontal-circle-outline::before {
  content: "\F0FE2"; }

.mdi-swap-horizontal-variant::before {
  content: "\F08C1"; }

.mdi-swap-vertical::before {
  content: "\F04E2"; }

.mdi-swap-vertical-bold::before {
  content: "\F0BCE"; }

.mdi-swap-vertical-circle::before {
  content: "\F0FE3"; }

.mdi-swap-vertical-circle-outline::before {
  content: "\F0FE4"; }

.mdi-swap-vertical-variant::before {
  content: "\F08C2"; }

.mdi-swim::before {
  content: "\F04E3"; }

.mdi-switch::before {
  content: "\F04E4"; }

.mdi-sword::before {
  content: "\F04E5"; }

.mdi-sword-cross::before {
  content: "\F0787"; }

.mdi-syllabary-hangul::before {
  content: "\F1333"; }

.mdi-syllabary-hiragana::before {
  content: "\F1334"; }

.mdi-syllabary-katakana::before {
  content: "\F1335"; }

.mdi-syllabary-katakana-halfwidth::before {
  content: "\F1336"; }

.mdi-symbol::before {
  content: "\F1501"; }

.mdi-symfony::before {
  content: "\F0AE6"; }

.mdi-sync::before {
  content: "\F04E6"; }

.mdi-sync-alert::before {
  content: "\F04E7"; }

.mdi-sync-circle::before {
  content: "\F1378"; }

.mdi-sync-off::before {
  content: "\F04E8"; }

.mdi-tab::before {
  content: "\F04E9"; }

.mdi-tab-minus::before {
  content: "\F0B4B"; }

.mdi-tab-plus::before {
  content: "\F075C"; }

.mdi-tab-remove::before {
  content: "\F0B4C"; }

.mdi-tab-unselected::before {
  content: "\F04EA"; }

.mdi-table::before {
  content: "\F04EB"; }

.mdi-table-account::before {
  content: "\F13B9"; }

.mdi-table-alert::before {
  content: "\F13BA"; }

.mdi-table-arrow-down::before {
  content: "\F13BB"; }

.mdi-table-arrow-left::before {
  content: "\F13BC"; }

.mdi-table-arrow-right::before {
  content: "\F13BD"; }

.mdi-table-arrow-up::before {
  content: "\F13BE"; }

.mdi-table-border::before {
  content: "\F0A18"; }

.mdi-table-cancel::before {
  content: "\F13BF"; }

.mdi-table-chair::before {
  content: "\F1061"; }

.mdi-table-check::before {
  content: "\F13C0"; }

.mdi-table-clock::before {
  content: "\F13C1"; }

.mdi-table-cog::before {
  content: "\F13C2"; }

.mdi-table-column::before {
  content: "\F0835"; }

.mdi-table-column-plus-after::before {
  content: "\F04EC"; }

.mdi-table-column-plus-before::before {
  content: "\F04ED"; }

.mdi-table-column-remove::before {
  content: "\F04EE"; }

.mdi-table-column-width::before {
  content: "\F04EF"; }

.mdi-table-edit::before {
  content: "\F04F0"; }

.mdi-table-eye::before {
  content: "\F1094"; }

.mdi-table-eye-off::before {
  content: "\F13C3"; }

.mdi-table-furniture::before {
  content: "\F05BC"; }

.mdi-table-headers-eye::before {
  content: "\F121D"; }

.mdi-table-headers-eye-off::before {
  content: "\F121E"; }

.mdi-table-heart::before {
  content: "\F13C4"; }

.mdi-table-key::before {
  content: "\F13C5"; }

.mdi-table-large::before {
  content: "\F04F1"; }

.mdi-table-large-plus::before {
  content: "\F0F87"; }

.mdi-table-large-remove::before {
  content: "\F0F88"; }

.mdi-table-lock::before {
  content: "\F13C6"; }

.mdi-table-merge-cells::before {
  content: "\F09A6"; }

.mdi-table-minus::before {
  content: "\F13C7"; }

.mdi-table-multiple::before {
  content: "\F13C8"; }

.mdi-table-network::before {
  content: "\F13C9"; }

.mdi-table-of-contents::before {
  content: "\F0836"; }

.mdi-table-off::before {
  content: "\F13CA"; }

.mdi-table-plus::before {
  content: "\F0A75"; }

.mdi-table-refresh::before {
  content: "\F13A0"; }

.mdi-table-remove::before {
  content: "\F0A76"; }

.mdi-table-row::before {
  content: "\F0837"; }

.mdi-table-row-height::before {
  content: "\F04F2"; }

.mdi-table-row-plus-after::before {
  content: "\F04F3"; }

.mdi-table-row-plus-before::before {
  content: "\F04F4"; }

.mdi-table-row-remove::before {
  content: "\F04F5"; }

.mdi-table-search::before {
  content: "\F090F"; }

.mdi-table-settings::before {
  content: "\F0838"; }

.mdi-table-split-cell::before {
  content: "\F142A"; }

.mdi-table-star::before {
  content: "\F13CB"; }

.mdi-table-sync::before {
  content: "\F13A1"; }

.mdi-table-tennis::before {
  content: "\F0E68"; }

.mdi-tablet::before {
  content: "\F04F6"; }

.mdi-tablet-android::before {
  content: "\F04F7"; }

.mdi-tablet-cellphone::before {
  content: "\F09A7"; }

.mdi-tablet-dashboard::before {
  content: "\F0ECE"; }

.mdi-tablet-ipad::before {
  content: "\F04F8"; }

.mdi-taco::before {
  content: "\F0762"; }

.mdi-tag::before {
  content: "\F04F9"; }

.mdi-tag-faces::before {
  content: "\F04FA"; }

.mdi-tag-heart::before {
  content: "\F068B"; }

.mdi-tag-heart-outline::before {
  content: "\F0BCF"; }

.mdi-tag-minus::before {
  content: "\F0910"; }

.mdi-tag-minus-outline::before {
  content: "\F121F"; }

.mdi-tag-multiple::before {
  content: "\F04FB"; }

.mdi-tag-multiple-outline::before {
  content: "\F12F7"; }

.mdi-tag-off::before {
  content: "\F1220"; }

.mdi-tag-off-outline::before {
  content: "\F1221"; }

.mdi-tag-outline::before {
  content: "\F04FC"; }

.mdi-tag-plus::before {
  content: "\F0722"; }

.mdi-tag-plus-outline::before {
  content: "\F1222"; }

.mdi-tag-remove::before {
  content: "\F0723"; }

.mdi-tag-remove-outline::before {
  content: "\F1223"; }

.mdi-tag-text::before {
  content: "\F1224"; }

.mdi-tag-text-outline::before {
  content: "\F04FD"; }

.mdi-tailwind::before {
  content: "\F13FF"; }

.mdi-tank::before {
  content: "\F0D3A"; }

.mdi-tanker-truck::before {
  content: "\F0FE5"; }

.mdi-tape-drive::before {
  content: "\F16DF"; }

.mdi-tape-measure::before {
  content: "\F0B4D"; }

.mdi-target::before {
  content: "\F04FE"; }

.mdi-target-account::before {
  content: "\F0BD0"; }

.mdi-target-variant::before {
  content: "\F0A77"; }

.mdi-taxi::before {
  content: "\F04FF"; }

.mdi-tea::before {
  content: "\F0D9E"; }

.mdi-tea-outline::before {
  content: "\F0D9F"; }

.mdi-teach::before {
  content: "\F0890"; }

.mdi-teamviewer::before {
  content: "\F0500"; }

.mdi-telegram::before {
  content: "\F0501"; }

.mdi-telescope::before {
  content: "\F0B4E"; }

.mdi-television::before {
  content: "\F0502"; }

.mdi-television-ambient-light::before {
  content: "\F1356"; }

.mdi-television-box::before {
  content: "\F0839"; }

.mdi-television-classic::before {
  content: "\F07F4"; }

.mdi-television-classic-off::before {
  content: "\F083A"; }

.mdi-television-clean::before {
  content: "\F1110"; }

.mdi-television-guide::before {
  content: "\F0503"; }

.mdi-television-off::before {
  content: "\F083B"; }

.mdi-television-pause::before {
  content: "\F0F89"; }

.mdi-television-play::before {
  content: "\F0ECF"; }

.mdi-television-stop::before {
  content: "\F0F8A"; }

.mdi-temperature-celsius::before {
  content: "\F0504"; }

.mdi-temperature-fahrenheit::before {
  content: "\F0505"; }

.mdi-temperature-kelvin::before {
  content: "\F0506"; }

.mdi-tennis::before {
  content: "\F0DA0"; }

.mdi-tennis-ball::before {
  content: "\F0507"; }

.mdi-tent::before {
  content: "\F0508"; }

.mdi-terraform::before {
  content: "\F1062"; }

.mdi-terrain::before {
  content: "\F0509"; }

.mdi-test-tube::before {
  content: "\F0668"; }

.mdi-test-tube-empty::before {
  content: "\F0911"; }

.mdi-test-tube-off::before {
  content: "\F0912"; }

.mdi-text::before {
  content: "\F09A8"; }

.mdi-text-account::before {
  content: "\F1570"; }

.mdi-text-box::before {
  content: "\F021A"; }

.mdi-text-box-check::before {
  content: "\F0EA6"; }

.mdi-text-box-check-outline::before {
  content: "\F0EA7"; }

.mdi-text-box-minus::before {
  content: "\F0EA8"; }

.mdi-text-box-minus-outline::before {
  content: "\F0EA9"; }

.mdi-text-box-multiple::before {
  content: "\F0AB7"; }

.mdi-text-box-multiple-outline::before {
  content: "\F0AB8"; }

.mdi-text-box-outline::before {
  content: "\F09ED"; }

.mdi-text-box-plus::before {
  content: "\F0EAA"; }

.mdi-text-box-plus-outline::before {
  content: "\F0EAB"; }

.mdi-text-box-remove::before {
  content: "\F0EAC"; }

.mdi-text-box-remove-outline::before {
  content: "\F0EAD"; }

.mdi-text-box-search::before {
  content: "\F0EAE"; }

.mdi-text-box-search-outline::before {
  content: "\F0EAF"; }

.mdi-text-recognition::before {
  content: "\F113D"; }

.mdi-text-search::before {
  content: "\F13B8"; }

.mdi-text-shadow::before {
  content: "\F0669"; }

.mdi-text-short::before {
  content: "\F09A9"; }

.mdi-text-subject::before {
  content: "\F09AA"; }

.mdi-text-to-speech::before {
  content: "\F050A"; }

.mdi-text-to-speech-off::before {
  content: "\F050B"; }

.mdi-texture::before {
  content: "\F050C"; }

.mdi-texture-box::before {
  content: "\F0FE6"; }

.mdi-theater::before {
  content: "\F050D"; }

.mdi-theme-light-dark::before {
  content: "\F050E"; }

.mdi-thermometer::before {
  content: "\F050F"; }

.mdi-thermometer-alert::before {
  content: "\F0E01"; }

.mdi-thermometer-chevron-down::before {
  content: "\F0E02"; }

.mdi-thermometer-chevron-up::before {
  content: "\F0E03"; }

.mdi-thermometer-high::before {
  content: "\F10C2"; }

.mdi-thermometer-lines::before {
  content: "\F0510"; }

.mdi-thermometer-low::before {
  content: "\F10C3"; }

.mdi-thermometer-minus::before {
  content: "\F0E04"; }

.mdi-thermometer-off::before {
  content: "\F1531"; }

.mdi-thermometer-plus::before {
  content: "\F0E05"; }

.mdi-thermostat::before {
  content: "\F0393"; }

.mdi-thermostat-box::before {
  content: "\F0891"; }

.mdi-thought-bubble::before {
  content: "\F07F6"; }

.mdi-thought-bubble-outline::before {
  content: "\F07F7"; }

.mdi-thumb-down::before {
  content: "\F0511"; }

.mdi-thumb-down-outline::before {
  content: "\F0512"; }

.mdi-thumb-up::before {
  content: "\F0513"; }

.mdi-thumb-up-outline::before {
  content: "\F0514"; }

.mdi-thumbs-up-down::before {
  content: "\F0515"; }

.mdi-ticket::before {
  content: "\F0516"; }

.mdi-ticket-account::before {
  content: "\F0517"; }

.mdi-ticket-confirmation::before {
  content: "\F0518"; }

.mdi-ticket-confirmation-outline::before {
  content: "\F13AA"; }

.mdi-ticket-outline::before {
  content: "\F0913"; }

.mdi-ticket-percent::before {
  content: "\F0724"; }

.mdi-ticket-percent-outline::before {
  content: "\F142B"; }

.mdi-tie::before {
  content: "\F0519"; }

.mdi-tilde::before {
  content: "\F0725"; }

.mdi-timelapse::before {
  content: "\F051A"; }

.mdi-timeline::before {
  content: "\F0BD1"; }

.mdi-timeline-alert::before {
  content: "\F0F95"; }

.mdi-timeline-alert-outline::before {
  content: "\F0F98"; }

.mdi-timeline-check::before {
  content: "\F1532"; }

.mdi-timeline-check-outline::before {
  content: "\F1533"; }

.mdi-timeline-clock::before {
  content: "\F11FB"; }

.mdi-timeline-clock-outline::before {
  content: "\F11FC"; }

.mdi-timeline-help::before {
  content: "\F0F99"; }

.mdi-timeline-help-outline::before {
  content: "\F0F9A"; }

.mdi-timeline-minus::before {
  content: "\F1534"; }

.mdi-timeline-minus-outline::before {
  content: "\F1535"; }

.mdi-timeline-outline::before {
  content: "\F0BD2"; }

.mdi-timeline-plus::before {
  content: "\F0F96"; }

.mdi-timeline-plus-outline::before {
  content: "\F0F97"; }

.mdi-timeline-remove::before {
  content: "\F1536"; }

.mdi-timeline-remove-outline::before {
  content: "\F1537"; }

.mdi-timeline-text::before {
  content: "\F0BD3"; }

.mdi-timeline-text-outline::before {
  content: "\F0BD4"; }

.mdi-timer::before {
  content: "\F13AB"; }

.mdi-timer-10::before {
  content: "\F051C"; }

.mdi-timer-3::before {
  content: "\F051D"; }

.mdi-timer-off::before {
  content: "\F13AC"; }

.mdi-timer-off-outline::before {
  content: "\F051E"; }

.mdi-timer-outline::before {
  content: "\F051B"; }

.mdi-timer-sand::before {
  content: "\F051F"; }

.mdi-timer-sand-empty::before {
  content: "\F06AD"; }

.mdi-timer-sand-full::before {
  content: "\F078C"; }

.mdi-timetable::before {
  content: "\F0520"; }

.mdi-toaster::before {
  content: "\F1063"; }

.mdi-toaster-off::before {
  content: "\F11B7"; }

.mdi-toaster-oven::before {
  content: "\F0CD3"; }

.mdi-toggle-switch::before {
  content: "\F0521"; }

.mdi-toggle-switch-off::before {
  content: "\F0522"; }

.mdi-toggle-switch-off-outline::before {
  content: "\F0A19"; }

.mdi-toggle-switch-outline::before {
  content: "\F0A1A"; }

.mdi-toilet::before {
  content: "\F09AB"; }

.mdi-toolbox::before {
  content: "\F09AC"; }

.mdi-toolbox-outline::before {
  content: "\F09AD"; }

.mdi-tools::before {
  content: "\F1064"; }

.mdi-tooltip::before {
  content: "\F0523"; }

.mdi-tooltip-account::before {
  content: "\F000C"; }

.mdi-tooltip-check::before {
  content: "\F155C"; }

.mdi-tooltip-check-outline::before {
  content: "\F155D"; }

.mdi-tooltip-edit::before {
  content: "\F0524"; }

.mdi-tooltip-edit-outline::before {
  content: "\F12C5"; }

.mdi-tooltip-image::before {
  content: "\F0525"; }

.mdi-tooltip-image-outline::before {
  content: "\F0BD5"; }

.mdi-tooltip-minus::before {
  content: "\F155E"; }

.mdi-tooltip-minus-outline::before {
  content: "\F155F"; }

.mdi-tooltip-outline::before {
  content: "\F0526"; }

.mdi-tooltip-plus::before {
  content: "\F0BD6"; }

.mdi-tooltip-plus-outline::before {
  content: "\F0527"; }

.mdi-tooltip-remove::before {
  content: "\F1560"; }

.mdi-tooltip-remove-outline::before {
  content: "\F1561"; }

.mdi-tooltip-text::before {
  content: "\F0528"; }

.mdi-tooltip-text-outline::before {
  content: "\F0BD7"; }

.mdi-tooth::before {
  content: "\F08C3"; }

.mdi-tooth-outline::before {
  content: "\F0529"; }

.mdi-toothbrush::before {
  content: "\F1129"; }

.mdi-toothbrush-electric::before {
  content: "\F112C"; }

.mdi-toothbrush-paste::before {
  content: "\F112A"; }

.mdi-torch::before {
  content: "\F1606"; }

.mdi-tortoise::before {
  content: "\F0D3B"; }

.mdi-toslink::before {
  content: "\F12B8"; }

.mdi-tournament::before {
  content: "\F09AE"; }

.mdi-tow-truck::before {
  content: "\F083C"; }

.mdi-tower-beach::before {
  content: "\F0681"; }

.mdi-tower-fire::before {
  content: "\F0682"; }

.mdi-toy-brick::before {
  content: "\F1288"; }

.mdi-toy-brick-marker::before {
  content: "\F1289"; }

.mdi-toy-brick-marker-outline::before {
  content: "\F128A"; }

.mdi-toy-brick-minus::before {
  content: "\F128B"; }

.mdi-toy-brick-minus-outline::before {
  content: "\F128C"; }

.mdi-toy-brick-outline::before {
  content: "\F128D"; }

.mdi-toy-brick-plus::before {
  content: "\F128E"; }

.mdi-toy-brick-plus-outline::before {
  content: "\F128F"; }

.mdi-toy-brick-remove::before {
  content: "\F1290"; }

.mdi-toy-brick-remove-outline::before {
  content: "\F1291"; }

.mdi-toy-brick-search::before {
  content: "\F1292"; }

.mdi-toy-brick-search-outline::before {
  content: "\F1293"; }

.mdi-track-light::before {
  content: "\F0914"; }

.mdi-trackpad::before {
  content: "\F07F8"; }

.mdi-trackpad-lock::before {
  content: "\F0933"; }

.mdi-tractor::before {
  content: "\F0892"; }

.mdi-tractor-variant::before {
  content: "\F14C4"; }

.mdi-trademark::before {
  content: "\F0A78"; }

.mdi-traffic-cone::before {
  content: "\F137C"; }

.mdi-traffic-light::before {
  content: "\F052B"; }

.mdi-train::before {
  content: "\F052C"; }

.mdi-train-car::before {
  content: "\F0BD8"; }

.mdi-train-variant::before {
  content: "\F08C4"; }

.mdi-tram::before {
  content: "\F052D"; }

.mdi-tram-side::before {
  content: "\F0FE7"; }

.mdi-transcribe::before {
  content: "\F052E"; }

.mdi-transcribe-close::before {
  content: "\F052F"; }

.mdi-transfer::before {
  content: "\F1065"; }

.mdi-transfer-down::before {
  content: "\F0DA1"; }

.mdi-transfer-left::before {
  content: "\F0DA2"; }

.mdi-transfer-right::before {
  content: "\F0530"; }

.mdi-transfer-up::before {
  content: "\F0DA3"; }

.mdi-transit-connection::before {
  content: "\F0D3C"; }

.mdi-transit-connection-horizontal::before {
  content: "\F1546"; }

.mdi-transit-connection-variant::before {
  content: "\F0D3D"; }

.mdi-transit-detour::before {
  content: "\F0F8B"; }

.mdi-transit-skip::before {
  content: "\F1515"; }

.mdi-transit-transfer::before {
  content: "\F06AE"; }

.mdi-transition::before {
  content: "\F0915"; }

.mdi-transition-masked::before {
  content: "\F0916"; }

.mdi-translate::before {
  content: "\F05CA"; }

.mdi-translate-off::before {
  content: "\F0E06"; }

.mdi-transmission-tower::before {
  content: "\F0D3E"; }

.mdi-trash-can::before {
  content: "\F0A79"; }

.mdi-trash-can-outline::before {
  content: "\F0A7A"; }

.mdi-tray::before {
  content: "\F1294"; }

.mdi-tray-alert::before {
  content: "\F1295"; }

.mdi-tray-full::before {
  content: "\F1296"; }

.mdi-tray-minus::before {
  content: "\F1297"; }

.mdi-tray-plus::before {
  content: "\F1298"; }

.mdi-tray-remove::before {
  content: "\F1299"; }

.mdi-treasure-chest::before {
  content: "\F0726"; }

.mdi-tree::before {
  content: "\F0531"; }

.mdi-tree-outline::before {
  content: "\F0E69"; }

.mdi-trello::before {
  content: "\F0532"; }

.mdi-trending-down::before {
  content: "\F0533"; }

.mdi-trending-neutral::before {
  content: "\F0534"; }

.mdi-trending-up::before {
  content: "\F0535"; }

.mdi-triangle::before {
  content: "\F0536"; }

.mdi-triangle-outline::before {
  content: "\F0537"; }

.mdi-triangle-wave::before {
  content: "\F147C"; }

.mdi-triforce::before {
  content: "\F0BD9"; }

.mdi-trophy::before {
  content: "\F0538"; }

.mdi-trophy-award::before {
  content: "\F0539"; }

.mdi-trophy-broken::before {
  content: "\F0DA4"; }

.mdi-trophy-outline::before {
  content: "\F053A"; }

.mdi-trophy-variant::before {
  content: "\F053B"; }

.mdi-trophy-variant-outline::before {
  content: "\F053C"; }

.mdi-truck::before {
  content: "\F053D"; }

.mdi-truck-check::before {
  content: "\F0CD4"; }

.mdi-truck-check-outline::before {
  content: "\F129A"; }

.mdi-truck-delivery::before {
  content: "\F053E"; }

.mdi-truck-delivery-outline::before {
  content: "\F129B"; }

.mdi-truck-fast::before {
  content: "\F0788"; }

.mdi-truck-fast-outline::before {
  content: "\F129C"; }

.mdi-truck-outline::before {
  content: "\F129D"; }

.mdi-truck-trailer::before {
  content: "\F0727"; }

.mdi-trumpet::before {
  content: "\F1096"; }

.mdi-tshirt-crew::before {
  content: "\F0A7B"; }

.mdi-tshirt-crew-outline::before {
  content: "\F053F"; }

.mdi-tshirt-v::before {
  content: "\F0A7C"; }

.mdi-tshirt-v-outline::before {
  content: "\F0540"; }

.mdi-tumble-dryer::before {
  content: "\F0917"; }

.mdi-tumble-dryer-alert::before {
  content: "\F11BA"; }

.mdi-tumble-dryer-off::before {
  content: "\F11BB"; }

.mdi-tune::before {
  content: "\F062E"; }

.mdi-tune-variant::before {
  content: "\F1542"; }

.mdi-tune-vertical::before {
  content: "\F066A"; }

.mdi-tune-vertical-variant::before {
  content: "\F1543"; }

.mdi-turnstile::before {
  content: "\F0CD5"; }

.mdi-turnstile-outline::before {
  content: "\F0CD6"; }

.mdi-turtle::before {
  content: "\F0CD7"; }

.mdi-twitch::before {
  content: "\F0543"; }

.mdi-twitter::before {
  content: "\F0544"; }

.mdi-twitter-retweet::before {
  content: "\F0547"; }

.mdi-two-factor-authentication::before {
  content: "\F09AF"; }

.mdi-typewriter::before {
  content: "\F0F2D"; }

.mdi-ubisoft::before {
  content: "\F0BDA"; }

.mdi-ubuntu::before {
  content: "\F0548"; }

.mdi-ufo::before {
  content: "\F10C4"; }

.mdi-ufo-outline::before {
  content: "\F10C5"; }

.mdi-ultra-high-definition::before {
  content: "\F07F9"; }

.mdi-umbraco::before {
  content: "\F0549"; }

.mdi-umbrella::before {
  content: "\F054A"; }

.mdi-umbrella-closed::before {
  content: "\F09B0"; }

.mdi-umbrella-closed-outline::before {
  content: "\F13E2"; }

.mdi-umbrella-closed-variant::before {
  content: "\F13E1"; }

.mdi-umbrella-outline::before {
  content: "\F054B"; }

.mdi-undo::before {
  content: "\F054C"; }

.mdi-undo-variant::before {
  content: "\F054D"; }

.mdi-unfold-less-horizontal::before {
  content: "\F054E"; }

.mdi-unfold-less-vertical::before {
  content: "\F0760"; }

.mdi-unfold-more-horizontal::before {
  content: "\F054F"; }

.mdi-unfold-more-vertical::before {
  content: "\F0761"; }

.mdi-ungroup::before {
  content: "\F0550"; }

.mdi-unicode::before {
  content: "\F0ED0"; }

.mdi-unicorn::before {
  content: "\F15C2"; }

.mdi-unicorn-variant::before {
  content: "\F15C3"; }

.mdi-unicycle::before {
  content: "\F15E5"; }

.mdi-unity::before {
  content: "\F06AF"; }

.mdi-unreal::before {
  content: "\F09B1"; }

.mdi-untappd::before {
  content: "\F0551"; }

.mdi-update::before {
  content: "\F06B0"; }

.mdi-upload::before {
  content: "\F0552"; }

.mdi-upload-lock::before {
  content: "\F1373"; }

.mdi-upload-lock-outline::before {
  content: "\F1374"; }

.mdi-upload-multiple::before {
  content: "\F083D"; }

.mdi-upload-network::before {
  content: "\F06F6"; }

.mdi-upload-network-outline::before {
  content: "\F0CD8"; }

.mdi-upload-off::before {
  content: "\F10C6"; }

.mdi-upload-off-outline::before {
  content: "\F10C7"; }

.mdi-upload-outline::before {
  content: "\F0E07"; }

.mdi-usb::before {
  content: "\F0553"; }

.mdi-usb-flash-drive::before {
  content: "\F129E"; }

.mdi-usb-flash-drive-outline::before {
  content: "\F129F"; }

.mdi-usb-port::before {
  content: "\F11F0"; }

.mdi-valve::before {
  content: "\F1066"; }

.mdi-valve-closed::before {
  content: "\F1067"; }

.mdi-valve-open::before {
  content: "\F1068"; }

.mdi-van-passenger::before {
  content: "\F07FA"; }

.mdi-van-utility::before {
  content: "\F07FB"; }

.mdi-vanish::before {
  content: "\F07FC"; }

.mdi-vanish-quarter::before {
  content: "\F1554"; }

.mdi-vanity-light::before {
  content: "\F11E1"; }

.mdi-variable::before {
  content: "\F0AE7"; }

.mdi-variable-box::before {
  content: "\F1111"; }

.mdi-vector-arrange-above::before {
  content: "\F0554"; }

.mdi-vector-arrange-below::before {
  content: "\F0555"; }

.mdi-vector-bezier::before {
  content: "\F0AE8"; }

.mdi-vector-circle::before {
  content: "\F0556"; }

.mdi-vector-circle-variant::before {
  content: "\F0557"; }

.mdi-vector-combine::before {
  content: "\F0558"; }

.mdi-vector-curve::before {
  content: "\F0559"; }

.mdi-vector-difference::before {
  content: "\F055A"; }

.mdi-vector-difference-ab::before {
  content: "\F055B"; }

.mdi-vector-difference-ba::before {
  content: "\F055C"; }

.mdi-vector-ellipse::before {
  content: "\F0893"; }

.mdi-vector-intersection::before {
  content: "\F055D"; }

.mdi-vector-line::before {
  content: "\F055E"; }

.mdi-vector-link::before {
  content: "\F0FE8"; }

.mdi-vector-point::before {
  content: "\F055F"; }

.mdi-vector-polygon::before {
  content: "\F0560"; }

.mdi-vector-polyline::before {
  content: "\F0561"; }

.mdi-vector-polyline-edit::before {
  content: "\F1225"; }

.mdi-vector-polyline-minus::before {
  content: "\F1226"; }

.mdi-vector-polyline-plus::before {
  content: "\F1227"; }

.mdi-vector-polyline-remove::before {
  content: "\F1228"; }

.mdi-vector-radius::before {
  content: "\F074A"; }

.mdi-vector-rectangle::before {
  content: "\F05C6"; }

.mdi-vector-selection::before {
  content: "\F0562"; }

.mdi-vector-square::before {
  content: "\F0001"; }

.mdi-vector-triangle::before {
  content: "\F0563"; }

.mdi-vector-union::before {
  content: "\F0564"; }

.mdi-vhs::before {
  content: "\F0A1B"; }

.mdi-vibrate::before {
  content: "\F0566"; }

.mdi-vibrate-off::before {
  content: "\F0CD9"; }

.mdi-video::before {
  content: "\F0567"; }

.mdi-video-3d::before {
  content: "\F07FD"; }

.mdi-video-3d-off::before {
  content: "\F13D9"; }

.mdi-video-3d-variant::before {
  content: "\F0ED1"; }

.mdi-video-4k-box::before {
  content: "\F083E"; }

.mdi-video-account::before {
  content: "\F0919"; }

.mdi-video-box::before {
  content: "\F00FD"; }

.mdi-video-box-off::before {
  content: "\F00FE"; }

.mdi-video-check::before {
  content: "\F1069"; }

.mdi-video-check-outline::before {
  content: "\F106A"; }

.mdi-video-high-definition::before {
  content: "\F152E"; }

.mdi-video-image::before {
  content: "\F091A"; }

.mdi-video-input-antenna::before {
  content: "\F083F"; }

.mdi-video-input-component::before {
  content: "\F0840"; }

.mdi-video-input-hdmi::before {
  content: "\F0841"; }

.mdi-video-input-scart::before {
  content: "\F0F8C"; }

.mdi-video-input-svideo::before {
  content: "\F0842"; }

.mdi-video-minus::before {
  content: "\F09B2"; }

.mdi-video-minus-outline::before {
  content: "\F02BA"; }

.mdi-video-off::before {
  content: "\F0568"; }

.mdi-video-off-outline::before {
  content: "\F0BDB"; }

.mdi-video-outline::before {
  content: "\F0BDC"; }

.mdi-video-plus::before {
  content: "\F09B3"; }

.mdi-video-plus-outline::before {
  content: "\F01D3"; }

.mdi-video-stabilization::before {
  content: "\F091B"; }

.mdi-video-switch::before {
  content: "\F0569"; }

.mdi-video-switch-outline::before {
  content: "\F0790"; }

.mdi-video-vintage::before {
  content: "\F0A1C"; }

.mdi-video-wireless::before {
  content: "\F0ED2"; }

.mdi-video-wireless-outline::before {
  content: "\F0ED3"; }

.mdi-view-agenda::before {
  content: "\F056A"; }

.mdi-view-agenda-outline::before {
  content: "\F11D8"; }

.mdi-view-array::before {
  content: "\F056B"; }

.mdi-view-array-outline::before {
  content: "\F1485"; }

.mdi-view-carousel::before {
  content: "\F056C"; }

.mdi-view-carousel-outline::before {
  content: "\F1486"; }

.mdi-view-column::before {
  content: "\F056D"; }

.mdi-view-column-outline::before {
  content: "\F1487"; }

.mdi-view-comfy::before {
  content: "\F0E6A"; }

.mdi-view-comfy-outline::before {
  content: "\F1488"; }

.mdi-view-compact::before {
  content: "\F0E6B"; }

.mdi-view-compact-outline::before {
  content: "\F0E6C"; }

.mdi-view-dashboard::before {
  content: "\F056E"; }

.mdi-view-dashboard-outline::before {
  content: "\F0A1D"; }

.mdi-view-dashboard-variant::before {
  content: "\F0843"; }

.mdi-view-dashboard-variant-outline::before {
  content: "\F1489"; }

.mdi-view-day::before {
  content: "\F056F"; }

.mdi-view-day-outline::before {
  content: "\F148A"; }

.mdi-view-grid::before {
  content: "\F0570"; }

.mdi-view-grid-outline::before {
  content: "\F11D9"; }

.mdi-view-grid-plus::before {
  content: "\F0F8D"; }

.mdi-view-grid-plus-outline::before {
  content: "\F11DA"; }

.mdi-view-headline::before {
  content: "\F0571"; }

.mdi-view-list::before {
  content: "\F0572"; }

.mdi-view-list-outline::before {
  content: "\F148B"; }

.mdi-view-module::before {
  content: "\F0573"; }

.mdi-view-module-outline::before {
  content: "\F148C"; }

.mdi-view-parallel::before {
  content: "\F0728"; }

.mdi-view-parallel-outline::before {
  content: "\F148D"; }

.mdi-view-quilt::before {
  content: "\F0574"; }

.mdi-view-quilt-outline::before {
  content: "\F148E"; }

.mdi-view-sequential::before {
  content: "\F0729"; }

.mdi-view-sequential-outline::before {
  content: "\F148F"; }

.mdi-view-split-horizontal::before {
  content: "\F0BCB"; }

.mdi-view-split-vertical::before {
  content: "\F0BCC"; }

.mdi-view-stream::before {
  content: "\F0575"; }

.mdi-view-stream-outline::before {
  content: "\F1490"; }

.mdi-view-week::before {
  content: "\F0576"; }

.mdi-view-week-outline::before {
  content: "\F1491"; }

.mdi-vimeo::before {
  content: "\F0577"; }

.mdi-violin::before {
  content: "\F060F"; }

.mdi-virtual-reality::before {
  content: "\F0894"; }

.mdi-virus::before {
  content: "\F13B6"; }

.mdi-virus-outline::before {
  content: "\F13B7"; }

.mdi-vk::before {
  content: "\F0579"; }

.mdi-vlc::before {
  content: "\F057C"; }

.mdi-voice-off::before {
  content: "\F0ED4"; }

.mdi-voicemail::before {
  content: "\F057D"; }

.mdi-volleyball::before {
  content: "\F09B4"; }

.mdi-volume-high::before {
  content: "\F057E"; }

.mdi-volume-low::before {
  content: "\F057F"; }

.mdi-volume-medium::before {
  content: "\F0580"; }

.mdi-volume-minus::before {
  content: "\F075E"; }

.mdi-volume-mute::before {
  content: "\F075F"; }

.mdi-volume-off::before {
  content: "\F0581"; }

.mdi-volume-plus::before {
  content: "\F075D"; }

.mdi-volume-source::before {
  content: "\F1120"; }

.mdi-volume-variant-off::before {
  content: "\F0E08"; }

.mdi-volume-vibrate::before {
  content: "\F1121"; }

.mdi-vote::before {
  content: "\F0A1F"; }

.mdi-vote-outline::before {
  content: "\F0A20"; }

.mdi-vpn::before {
  content: "\F0582"; }

.mdi-vuejs::before {
  content: "\F0844"; }

.mdi-vuetify::before {
  content: "\F0E6D"; }

.mdi-walk::before {
  content: "\F0583"; }

.mdi-wall::before {
  content: "\F07FE"; }

.mdi-wall-sconce::before {
  content: "\F091C"; }

.mdi-wall-sconce-flat::before {
  content: "\F091D"; }

.mdi-wall-sconce-flat-variant::before {
  content: "\F041C"; }

.mdi-wall-sconce-round::before {
  content: "\F0748"; }

.mdi-wall-sconce-round-variant::before {
  content: "\F091E"; }

.mdi-wallet::before {
  content: "\F0584"; }

.mdi-wallet-giftcard::before {
  content: "\F0585"; }

.mdi-wallet-membership::before {
  content: "\F0586"; }

.mdi-wallet-outline::before {
  content: "\F0BDD"; }

.mdi-wallet-plus::before {
  content: "\F0F8E"; }

.mdi-wallet-plus-outline::before {
  content: "\F0F8F"; }

.mdi-wallet-travel::before {
  content: "\F0587"; }

.mdi-wallpaper::before {
  content: "\F0E09"; }

.mdi-wan::before {
  content: "\F0588"; }

.mdi-wardrobe::before {
  content: "\F0F90"; }

.mdi-wardrobe-outline::before {
  content: "\F0F91"; }

.mdi-warehouse::before {
  content: "\F0F81"; }

.mdi-washing-machine::before {
  content: "\F072A"; }

.mdi-washing-machine-alert::before {
  content: "\F11BC"; }

.mdi-washing-machine-off::before {
  content: "\F11BD"; }

.mdi-watch::before {
  content: "\F0589"; }

.mdi-watch-export::before {
  content: "\F058A"; }

.mdi-watch-export-variant::before {
  content: "\F0895"; }

.mdi-watch-import::before {
  content: "\F058B"; }

.mdi-watch-import-variant::before {
  content: "\F0896"; }

.mdi-watch-variant::before {
  content: "\F0897"; }

.mdi-watch-vibrate::before {
  content: "\F06B1"; }

.mdi-watch-vibrate-off::before {
  content: "\F0CDA"; }

.mdi-water::before {
  content: "\F058C"; }

.mdi-water-alert::before {
  content: "\F1502"; }

.mdi-water-alert-outline::before {
  content: "\F1503"; }

.mdi-water-boiler::before {
  content: "\F0F92"; }

.mdi-water-boiler-alert::before {
  content: "\F11B3"; }

.mdi-water-boiler-off::before {
  content: "\F11B4"; }

.mdi-water-check::before {
  content: "\F1504"; }

.mdi-water-check-outline::before {
  content: "\F1505"; }

.mdi-water-minus::before {
  content: "\F1506"; }

.mdi-water-minus-outline::before {
  content: "\F1507"; }

.mdi-water-off::before {
  content: "\F058D"; }

.mdi-water-off-outline::before {
  content: "\F1508"; }

.mdi-water-outline::before {
  content: "\F0E0A"; }

.mdi-water-percent::before {
  content: "\F058E"; }

.mdi-water-percent-alert::before {
  content: "\F1509"; }

.mdi-water-plus::before {
  content: "\F150A"; }

.mdi-water-plus-outline::before {
  content: "\F150B"; }

.mdi-water-polo::before {
  content: "\F12A0"; }

.mdi-water-pump::before {
  content: "\F058F"; }

.mdi-water-pump-off::before {
  content: "\F0F93"; }

.mdi-water-remove::before {
  content: "\F150C"; }

.mdi-water-remove-outline::before {
  content: "\F150D"; }

.mdi-water-well::before {
  content: "\F106B"; }

.mdi-water-well-outline::before {
  content: "\F106C"; }

.mdi-watering-can::before {
  content: "\F1481"; }

.mdi-watering-can-outline::before {
  content: "\F1482"; }

.mdi-watermark::before {
  content: "\F0612"; }

.mdi-wave::before {
  content: "\F0F2E"; }

.mdi-waveform::before {
  content: "\F147D"; }

.mdi-waves::before {
  content: "\F078D"; }

.mdi-waze::before {
  content: "\F0BDE"; }

.mdi-weather-cloudy::before {
  content: "\F0590"; }

.mdi-weather-cloudy-alert::before {
  content: "\F0F2F"; }

.mdi-weather-cloudy-arrow-right::before {
  content: "\F0E6E"; }

.mdi-weather-fog::before {
  content: "\F0591"; }

.mdi-weather-hail::before {
  content: "\F0592"; }

.mdi-weather-hazy::before {
  content: "\F0F30"; }

.mdi-weather-hurricane::before {
  content: "\F0898"; }

.mdi-weather-lightning::before {
  content: "\F0593"; }

.mdi-weather-lightning-rainy::before {
  content: "\F067E"; }

.mdi-weather-night::before {
  content: "\F0594"; }

.mdi-weather-night-partly-cloudy::before {
  content: "\F0F31"; }

.mdi-weather-partly-cloudy::before {
  content: "\F0595"; }

.mdi-weather-partly-lightning::before {
  content: "\F0F32"; }

.mdi-weather-partly-rainy::before {
  content: "\F0F33"; }

.mdi-weather-partly-snowy::before {
  content: "\F0F34"; }

.mdi-weather-partly-snowy-rainy::before {
  content: "\F0F35"; }

.mdi-weather-pouring::before {
  content: "\F0596"; }

.mdi-weather-rainy::before {
  content: "\F0597"; }

.mdi-weather-snowy::before {
  content: "\F0598"; }

.mdi-weather-snowy-heavy::before {
  content: "\F0F36"; }

.mdi-weather-snowy-rainy::before {
  content: "\F067F"; }

.mdi-weather-sunny::before {
  content: "\F0599"; }

.mdi-weather-sunny-alert::before {
  content: "\F0F37"; }

.mdi-weather-sunny-off::before {
  content: "\F14E4"; }

.mdi-weather-sunset::before {
  content: "\F059A"; }

.mdi-weather-sunset-down::before {
  content: "\F059B"; }

.mdi-weather-sunset-up::before {
  content: "\F059C"; }

.mdi-weather-tornado::before {
  content: "\F0F38"; }

.mdi-weather-windy::before {
  content: "\F059D"; }

.mdi-weather-windy-variant::before {
  content: "\F059E"; }

.mdi-web::before {
  content: "\F059F"; }

.mdi-web-box::before {
  content: "\F0F94"; }

.mdi-web-clock::before {
  content: "\F124A"; }

.mdi-webcam::before {
  content: "\F05A0"; }

.mdi-webhook::before {
  content: "\F062F"; }

.mdi-webpack::before {
  content: "\F072B"; }

.mdi-webrtc::before {
  content: "\F1248"; }

.mdi-wechat::before {
  content: "\F0611"; }

.mdi-weight::before {
  content: "\F05A1"; }

.mdi-weight-gram::before {
  content: "\F0D3F"; }

.mdi-weight-kilogram::before {
  content: "\F05A2"; }

.mdi-weight-lifter::before {
  content: "\F115D"; }

.mdi-weight-pound::before {
  content: "\F09B5"; }

.mdi-whatsapp::before {
  content: "\F05A3"; }

.mdi-wheel-barrow::before {
  content: "\F14F2"; }

.mdi-wheelchair-accessibility::before {
  content: "\F05A4"; }

.mdi-whistle::before {
  content: "\F09B6"; }

.mdi-whistle-outline::before {
  content: "\F12BC"; }

.mdi-white-balance-auto::before {
  content: "\F05A5"; }

.mdi-white-balance-incandescent::before {
  content: "\F05A6"; }

.mdi-white-balance-iridescent::before {
  content: "\F05A7"; }

.mdi-white-balance-sunny::before {
  content: "\F05A8"; }

.mdi-widgets::before {
  content: "\F072C"; }

.mdi-widgets-outline::before {
  content: "\F1355"; }

.mdi-wifi::before {
  content: "\F05A9"; }

.mdi-wifi-alert::before {
  content: "\F16B5"; }

.mdi-wifi-arrow-down::before {
  content: "\F16B6"; }

.mdi-wifi-arrow-left::before {
  content: "\F16B7"; }

.mdi-wifi-arrow-left-right::before {
  content: "\F16B8"; }

.mdi-wifi-arrow-right::before {
  content: "\F16B9"; }

.mdi-wifi-arrow-up::before {
  content: "\F16BA"; }

.mdi-wifi-arrow-up-down::before {
  content: "\F16BB"; }

.mdi-wifi-cancel::before {
  content: "\F16BC"; }

.mdi-wifi-check::before {
  content: "\F16BD"; }

.mdi-wifi-cog::before {
  content: "\F16BE"; }

.mdi-wifi-lock::before {
  content: "\F16BF"; }

.mdi-wifi-lock-open::before {
  content: "\F16C0"; }

.mdi-wifi-marker::before {
  content: "\F16C1"; }

.mdi-wifi-minus::before {
  content: "\F16C2"; }

.mdi-wifi-off::before {
  content: "\F05AA"; }

.mdi-wifi-plus::before {
  content: "\F16C3"; }

.mdi-wifi-refresh::before {
  content: "\F16C4"; }

.mdi-wifi-remove::before {
  content: "\F16C5"; }

.mdi-wifi-settings::before {
  content: "\F16C6"; }

.mdi-wifi-star::before {
  content: "\F0E0B"; }

.mdi-wifi-strength-1::before {
  content: "\F091F"; }

.mdi-wifi-strength-1-alert::before {
  content: "\F0920"; }

.mdi-wifi-strength-1-lock::before {
  content: "\F0921"; }

.mdi-wifi-strength-1-lock-open::before {
  content: "\F16CB"; }

.mdi-wifi-strength-2::before {
  content: "\F0922"; }

.mdi-wifi-strength-2-alert::before {
  content: "\F0923"; }

.mdi-wifi-strength-2-lock::before {
  content: "\F0924"; }

.mdi-wifi-strength-2-lock-open::before {
  content: "\F16CC"; }

.mdi-wifi-strength-3::before {
  content: "\F0925"; }

.mdi-wifi-strength-3-alert::before {
  content: "\F0926"; }

.mdi-wifi-strength-3-lock::before {
  content: "\F0927"; }

.mdi-wifi-strength-3-lock-open::before {
  content: "\F16CD"; }

.mdi-wifi-strength-4::before {
  content: "\F0928"; }

.mdi-wifi-strength-4-alert::before {
  content: "\F0929"; }

.mdi-wifi-strength-4-lock::before {
  content: "\F092A"; }

.mdi-wifi-strength-4-lock-open::before {
  content: "\F16CE"; }

.mdi-wifi-strength-alert-outline::before {
  content: "\F092B"; }

.mdi-wifi-strength-lock-open-outline::before {
  content: "\F16CF"; }

.mdi-wifi-strength-lock-outline::before {
  content: "\F092C"; }

.mdi-wifi-strength-off::before {
  content: "\F092D"; }

.mdi-wifi-strength-off-outline::before {
  content: "\F092E"; }

.mdi-wifi-strength-outline::before {
  content: "\F092F"; }

.mdi-wifi-sync::before {
  content: "\F16C7"; }

.mdi-wikipedia::before {
  content: "\F05AC"; }

.mdi-wind-turbine::before {
  content: "\F0DA5"; }

.mdi-window-close::before {
  content: "\F05AD"; }

.mdi-window-closed::before {
  content: "\F05AE"; }

.mdi-window-closed-variant::before {
  content: "\F11DB"; }

.mdi-window-maximize::before {
  content: "\F05AF"; }

.mdi-window-minimize::before {
  content: "\F05B0"; }

.mdi-window-open::before {
  content: "\F05B1"; }

.mdi-window-open-variant::before {
  content: "\F11DC"; }

.mdi-window-restore::before {
  content: "\F05B2"; }

.mdi-window-shutter::before {
  content: "\F111C"; }

.mdi-window-shutter-alert::before {
  content: "\F111D"; }

.mdi-window-shutter-open::before {
  content: "\F111E"; }

.mdi-windsock::before {
  content: "\F15FA"; }

.mdi-wiper::before {
  content: "\F0AE9"; }

.mdi-wiper-wash::before {
  content: "\F0DA6"; }

.mdi-wizard-hat::before {
  content: "\F1477"; }

.mdi-wordpress::before {
  content: "\F05B4"; }

.mdi-wrap::before {
  content: "\F05B6"; }

.mdi-wrap-disabled::before {
  content: "\F0BDF"; }

.mdi-wrench::before {
  content: "\F05B7"; }

.mdi-wrench-outline::before {
  content: "\F0BE0"; }

.mdi-xamarin::before {
  content: "\F0845"; }

.mdi-xamarin-outline::before {
  content: "\F0846"; }

.mdi-xing::before {
  content: "\F05BE"; }

.mdi-xml::before {
  content: "\F05C0"; }

.mdi-xmpp::before {
  content: "\F07FF"; }

.mdi-y-combinator::before {
  content: "\F0624"; }

.mdi-yahoo::before {
  content: "\F0B4F"; }

.mdi-yeast::before {
  content: "\F05C1"; }

.mdi-yin-yang::before {
  content: "\F0680"; }

.mdi-yoga::before {
  content: "\F117C"; }

.mdi-youtube::before {
  content: "\F05C3"; }

.mdi-youtube-gaming::before {
  content: "\F0848"; }

.mdi-youtube-studio::before {
  content: "\F0847"; }

.mdi-youtube-subscription::before {
  content: "\F0D40"; }

.mdi-youtube-tv::before {
  content: "\F0448"; }

.mdi-yurt::before {
  content: "\F1516"; }

.mdi-z-wave::before {
  content: "\F0AEA"; }

.mdi-zend::before {
  content: "\F0AEB"; }

.mdi-zigbee::before {
  content: "\F0D41"; }

.mdi-zip-box::before {
  content: "\F05C4"; }

.mdi-zip-box-outline::before {
  content: "\F0FFA"; }

.mdi-zip-disk::before {
  content: "\F0A23"; }

.mdi-zodiac-aquarius::before {
  content: "\F0A7D"; }

.mdi-zodiac-aries::before {
  content: "\F0A7E"; }

.mdi-zodiac-cancer::before {
  content: "\F0A7F"; }

.mdi-zodiac-capricorn::before {
  content: "\F0A80"; }

.mdi-zodiac-gemini::before {
  content: "\F0A81"; }

.mdi-zodiac-leo::before {
  content: "\F0A82"; }

.mdi-zodiac-libra::before {
  content: "\F0A83"; }

.mdi-zodiac-pisces::before {
  content: "\F0A84"; }

.mdi-zodiac-sagittarius::before {
  content: "\F0A85"; }

.mdi-zodiac-scorpio::before {
  content: "\F0A86"; }

.mdi-zodiac-taurus::before {
  content: "\F0A87"; }

.mdi-zodiac-virgo::before {
  content: "\F0A88"; }

.mdi-blank::before {
  content: "\F68C";
  visibility: hidden; }

.mdi-18px.mdi-set, .mdi-18px.mdi:before {
  font-size: 18px; }

.mdi-24px.mdi-set, .mdi-24px.mdi:before {
  font-size: 24px; }

.mdi-36px.mdi-set, .mdi-36px.mdi:before {
  font-size: 36px; }

.mdi-48px.mdi-set, .mdi-48px.mdi:before {
  font-size: 48px; }

.mdi-dark:before {
  color: rgba(0, 0, 0, 0.54); }

.mdi-dark.mdi-inactive:before {
  color: rgba(0, 0, 0, 0.26); }

.mdi-light:before {
  color: white; }

.mdi-light.mdi-inactive:before {
  color: rgba(255, 255, 255, 0.3); }

.mdi-rotate-45 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(45deg);
            transform: scaleX(-1) rotate(45deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: scaleY(-1) rotate(45deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */ }

.mdi-rotate-45:before {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

.mdi-rotate-90 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(90deg);
            transform: scaleX(-1) rotate(90deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(90deg);
            -ms-transform: rotate(90deg);
            transform: scaleY(-1) rotate(90deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */ }

.mdi-rotate-90:before {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg); }

.mdi-rotate-135 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(135deg);
            transform: scaleX(-1) rotate(135deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(135deg);
            -ms-transform: rotate(135deg);
            transform: scaleY(-1) rotate(135deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */ }

.mdi-rotate-135:before {
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg); }

.mdi-rotate-180 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(180deg);
            transform: scaleX(-1) rotate(180deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(180deg);
            -ms-transform: rotate(180deg);
            transform: scaleY(-1) rotate(180deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */ }

.mdi-rotate-180:before {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg); }

.mdi-rotate-225 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(225deg);
            transform: scaleX(-1) rotate(225deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(225deg);
            -ms-transform: rotate(225deg);
            transform: scaleY(-1) rotate(225deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */ }

.mdi-rotate-225:before {
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg); }

.mdi-rotate-270 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(270deg);
            transform: scaleX(-1) rotate(270deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(270deg);
            -ms-transform: rotate(270deg);
            transform: scaleY(-1) rotate(270deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */ }

.mdi-rotate-270:before {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg); }

.mdi-rotate-315 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(315deg);
            transform: scaleX(-1) rotate(315deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(315deg);
            -ms-transform: rotate(315deg);
            transform: scaleY(-1) rotate(315deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */ }

.mdi-rotate-315:before {
  -webkit-transform: rotate(315deg);
  transform: rotate(315deg); }

.mdi-flip-h:before {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  -webkit-filter: FlipH;
          filter: FlipH;
  -ms-filter: "FlipH"; }

.mdi-flip-v:before {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  -webkit-filter: FlipV;
          filter: FlipV;
  -ms-filter: "FlipV"; }

.mdi-spin:before {
  -webkit-animation: mdi-spin 2s infinite linear;
  animation: mdi-spin 2s infinite linear; }

@-webkit-keyframes mdi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@keyframes mdi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

/*# sourceMappingURL=materialdesignicons.css.map */
